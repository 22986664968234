import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import cn from 'classnames';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import CustomerName from 'components/CustomerName';
import PageHeading from 'components/PageHeading';
import TableSortCell from 'components/TableSortCell';
import TableCount from 'components/TableCount';
import TableActionCell from 'components/TableActionCell';
import DateTimePicker from 'components/DateTimePicker';
import SmallButton from 'components/SmallButton';
import Select from 'components/Select';
import RestaurantsDropdown from 'components/RestaurantsDropdown';
import OheicsOrdersErrorDialog from 'components/OheicsOrdersErrorDialog';
import CustomDialog from 'components/CustomDialog';
import OrderDetails from 'components/OrderDetails';
import ButtonWithTooltip from 'components/ButtonWithTooltip';
import OrderStatus from 'components/OrderStatus';

import {
  ORDER_STATUSES_LABEL,
  ORDER_TYPES_LABEL,
  ORDER_TYPES,
  ORDER_STATUSES,
  ABLE_STATUSES_TO_CANCEL_ORDER,
} from 'consts/orders';
import { MAX_ORDERS_COUNT_FOR_PRINT_LIST } from 'consts/printList';
import {
  getStartOfDay,
  getEndOfDay,
  addDaysToDate,
  formatOrderDeliveryDateTime,
} from 'utils/date';
import { confirmAlert } from 'utils';

const defaultRestaurantOption = { label: 'All', value: null };
const orderStatuses = Object.keys(ORDER_STATUSES_LABEL)
  .filter((key) => key !== 'draft')
  .map((key) => ({
    label: ORDER_STATUSES_LABEL[key],
    value: key,
  }));
// Adding All status to start of an array
orderStatuses.unshift({ label: 'All', value: null });

const orderTypes = Object.keys(ORDER_TYPES_LABEL).map((key) => ({
  label: ORDER_TYPES_LABEL[key],
  value: key,
}));
// Adding All type to start of an array
orderTypes.unshift({ label: 'All', value: null });

const getDateTime = (time, day) => {
  if (day === 'today') {
    return time;
  }
  return addDaysToDate(time, 1);
};

const defaultState = () => {
  return { selectedDay: null, startTime: null, endTime: null };
};

const styles = () => ({
  datePicker: {
    marginTop: 5,
    marginRight: 20,
  },
  selectWrapper: {
    display: 'inline-block',
    marginRight: 20,
  },
  errorInfo: {
    color: '#dc3535',
    padding: 0,
    marginRight: 8,
  },
  errorRow: {
    background: 'rgba(245, 8, 8, 0.4)',
  },
  textWrapper: {
    maxWidth: 200,
    wordBreak: 'break-word',
  },
});

const initialState = {
  ...defaultState(),
  selectedOrders: [],
  orderType: '',
  status: '',
  openOheicsDialog: false,
  orderWithErrors: null,
  oheicsErrors: null,
  openOrderDetails: false,
  orderId: null,
  userId: '',
  orderIdQuery: '',
};

class OrdersListPage extends React.PureComponent {
  state = {
    ...initialState,
    selectedRestaurant: this.props.selectedRestaurant || null,
    startTime: this.props.startTime || null,
    endTime: this.props.endTime || null,
    status: this.props.status || null,
    orderType: this.props.orderType || null,
    userId: this.props.userId || '',
    orderIdQuery: this.props.orderIdQuery || '',
  };

  onSelectOrder = (order, { target: { checked } }) => {
    const otherSelected = this.state.selectedOrders.filter(
      (o) => o !== order.id
    );
    const selectedOrders = checked
      ? [...otherSelected, order.id]
      : otherSelected;

    this.setState({
      selectedOrders,
    });
  };

  clearState = () => {
    this.setState({ ...initialState });
    this.props.onFilter({ ...initialState });
  };

  onSelectDay = ({ target: { value } }) => {
    const selectedDay = value;
    const { startTime, endTime } = this.state;
    this.setState(
      {
        selectedDay,
        startTime: getDateTime(startTime || getStartOfDay(), selectedDay),
        endTime: getDateTime(endTime || getEndOfDay(), selectedDay),
      },
      this.onStartEndTimeChange
    );
  };

  onStartTimeChange = (time) => {
    this.setState({ startTime: time }, this.onStartEndTimeChange);
  };

  onEndTimeChange = (time) => {
    this.setState({ endTime: time }, this.onStartEndTimeChange);
  };

  onStartEndTimeChange = () => {
    const { startTime, endTime } = this.state;
    if (startTime && endTime) {
      this.props.onFilter({ ...this.state });
    }
  };

  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handlePage = (event, page) => {
    return this.props.onPage({ page });
  };

  handleCancelOrder = (id) => {
    const { onCancel } = this.props;
    confirmAlert({
      okCb: () =>
        onCancel({ id, status: ORDER_STATUSES.cancelled }).then(() => {
          toastr.success('Success!', 'Order has been canceled');
        }),
    });
  };

  onSelectRestaurant = ({ value }) => {
    this.setState({ selectedRestaurant: value }, () => {
      this.props.onFilter({ ...this.state });
    });
  };

  onStatusChange = ({ value }) => {
    this.setState({ status: value }, () => {
      this.props.onFilter({ ...this.state });
    });
  };

  onOrderTypeChange = ({ value }) => {
    this.setState({ orderType: value }, () => {
      this.props.onFilter({ ...this.state });
    });
  };

  getSelectedOrders = () => {
    const { items } = this.props.initialData;
    const { selectedOrders } = this.state;
    return items.map((o) => o.id).filter((id) => selectedOrders.includes(id));
  };

  getOrdersIdsForChangeStatus = (status) => {
    const { items } = this.props.initialData;
    const { selectedOrders } = this.state;
    const orders = items.filter(
      (item) =>
        selectedOrders.includes(item.id) &&
        item.type === ORDER_TYPES.delivery &&
        item.status === status
    );
    return orders.map((o) => o.id);
  };

  goToProductionListPage = () => {
    const { getProductionList } = this.props;

    const orderIdsToChangeStatus = this.getOrdersIdsForChangeStatus(
      ORDER_STATUSES.paid
    );
    const ordersIds = this.getSelectedOrders();

    getProductionList({
      ordersIds,
      orderIdsToChangeStatus,
    }).then(() => {
      this.setState({
        selectedOrders: [],
      });
    });
  };

  goToPackListPage = () => {
    const { getPackList } = this.props;

    const orderIdsToChangeStatus = this.getOrdersIdsForChangeStatus(
      ORDER_STATUSES.inProduction
    );
    const ordersIds = this.getSelectedOrders();

    getPackList({
      ordersIds,
      orderIdsToChangeStatus,
    }).then(() => {
      this.setState({
        selectedOrders: [],
      });
    });
  };

  handleShowOheicsLogs = (orderId) => {
    const { items } = this.props.initialData;
    const orderWithErrors = items.find((order) => order.id === orderId);
    if (!orderWithErrors) {
      return;
    }
    this.setState({ openOheicsDialog: true, orderWithErrors });
  };

  handleCloseOheicsLogs = () => {
    this.setState({ openOheicsDialog: false, orderWithErrors: null });
  };

  handleSendOheicsOrder = () => {
    const { orderWithErrors } = this.state;
    const { onSendOrderOheics } = this.props;
    const orderId = orderWithErrors.id;
    this.setState({ openOheicsDialog: false, orderWithErrors: null }, () => {
      onSendOrderOheics(orderId);
    });
  };

  handleCloseOrderDetails = () => {
    this.setState({ openOrderDetails: false, orderId: null });
  };

  handleShowOrderDetails = (id) => {
    this.setState({ openOrderDetails: true, orderId: id });
  };

  canPrintOrders = (count) => {
    return !(count === 0 || count > MAX_ORDERS_COUNT_FOR_PRINT_LIST);
  };

  getCountOfOrdersToPrint = () => {
    const { ordersCountToPrint } = this.props;
    const { selectedOrders } = this.state;

    return selectedOrders && selectedOrders.length > 0
      ? selectedOrders.length
      : ordersCountToPrint;
  };

  handleUserQueryChange = ({ target: { value } }) => {
    this.setState({
      userId: value,
    });
  };

  handleOrderIdQueryChange = ({ target: { value } }) => {
    this.setState({
      orderIdQuery: value,
    });
  };

  onSearch = () => {
    const { onFilter } = this.props;
    const {
      selectedRestaurant,
      startTime,
      endTime,
      orderType,
      status,
      userId,
      orderIdQuery,
    } = this.state;
    onFilter({
      selectedRestaurant,
      startTime: startTime && endTime ? startTime : null,
      endTime: startTime && endTime ? endTime : null,
      orderType,
      status,
      userId,
      orderIdQuery,
    });
  };

  handleChangeStatus = (status, id, currStatus) => {
    if (currStatus !== status.value) {
      this.props.onChangeOrderStatus({ id, status: status.value });
    }
  };

  render() {
    const { items, totalResults } = this.props.initialData;
    const {
      sortName,
      sortDirection,
      selectedRestaurant,
      classes,
      page,
      pageLimit,
      restaurants,
    } = this.props;
    const {
      selectedDay,
      startTime,
      endTime,
      orderType,
      status,
      openOheicsDialog,
      orderWithErrors,
      openOrderDetails,
      orderId,
      selectedOrders,
      userId,
      orderIdQuery,
    } = this.state;

    const numberOfItems = this.getCountOfOrdersToPrint();

    return (
      <React.Fragment>
        <PageHeading
          title="Orders"
          right={
            <RestaurantsDropdown
              defaultOption={defaultRestaurantOption}
              value={selectedRestaurant}
              onChange={this.onSelectRestaurant}
            />
          }
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              position: 'relative',
            }}
          >
            <InputLabel shrink style={{ position: 'absolute', top: 4 }}>
              Create date
            </InputLabel>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: 4,
              }}
            >
              <div>
                <DateTimePicker
                  className={classes.datePicker}
                  value={startTime}
                  onChange={this.onStartTimeChange}
                />
              </div>
              <span
                style={{
                  margin: '10px 10px 7px -10px',
                  display: 'block',
                }}
              >
                &#8212;
              </span>
              <div>
                <DateTimePicker
                  className={classes.datePicker}
                  value={endTime}
                  onChange={this.onEndTimeChange}
                />
              </div>
            </div>
            <div className={classes.selectWrapper}>
              <Select
                options={orderStatuses}
                value={orderStatuses.find((i) => i.value === status)}
                onChange={this.onStatusChange}
                width={170}
                placeholder="All"
                label="Status"
              />
            </div>
            <div className={classes.selectWrapper}>
              <Select
                options={orderTypes}
                value={orderTypes.find((i) => i.value === orderType)}
                onChange={this.onOrderTypeChange}
                width={170}
                placeholder="All"
                label="Order type"
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              flex: 1,
              marginBottom: 4,
            }}
          >
            <div style={{ marginRight: 20 }}>
              <Input
                value={orderIdQuery}
                onChange={this.handleOrderIdQueryChange}
                placeholder="Order ID"
                label="Order ID"
                margin="normal"
                type="number"
                min={0}
                style={{ width: 150, marginBottom: 0 }}
              />
            </div>
            <div style={{ marginRight: 20 }}>
              <Input
                value={userId}
                onChange={this.handleUserQueryChange}
                placeholder="User ID"
                label="User ID"
                type="number"
                min={0}
                style={{ width: 150 }}
              />
            </div>
            <Button onClick={this.onSearch} variant="contained" color="primary">
              Search
            </Button>
          </div>
        </div>
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            value="today"
            control={<Radio />}
            label="today"
            checked={selectedDay === 'today'}
            onChange={this.onSelectDay}
            style={{ marginRight: 50, textTransform: 'capitalize' }}
          />
          <SmallButton id="clear-filter-button" onClick={this.clearState}>
            Clear
          </SmallButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}
        >
          {/* todo: don't use params from state when filters are connected to backend */}
          <ButtonWithTooltip
            id="go-to-production-list-button"
            disabled={!this.canPrintOrders(numberOfItems)}
            onClick={this.goToProductionListPage}
            hint="Max 500 orders printed at once. Please select individual orders, or limit your search"
          >
            Production list ({numberOfItems})
          </ButtonWithTooltip>
          <div id="production-print" />
          <ButtonWithTooltip
            id="go-to-pact-list-button"
            leftSpace
            disabled={!this.canPrintOrders(numberOfItems)}
            onClick={this.goToPackListPage}
            hint="Max 500 orders printed at once. Please select individual orders, or limit your search"
          >
            Pack list ({numberOfItems})
          </ButtonWithTooltip>
          <div id="pack-print" />
        </div>
        <TableCount count={totalResults} text=" - orders in selection" />
        <Paper style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Customer</TableCell>
                <TableSortCell
                  title="Order nr."
                  sortName="id"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Create Date"
                  sortName="createDate"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell>Delivery Date</TableCell>
                <TableCell>Store</TableCell>
                <TableSortCell
                  title="Order type"
                  sortName="type"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Total"
                  sortName="totalAmount"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                  align="right"
                />
                <TableSortCell
                  title="Status"
                  sortName="status"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow
                  key={item.id}
                  className={cn(
                    item.oheicsErrors &&
                      item.oheicsErrors.length > 0 &&
                      classes.errorRow
                  )}
                  hover
                  style={{
                    transition: 'background-color .2s',
                    cursor: 'pointer',
                  }}
                  onClick={() => this.handleShowOrderDetails(item.id)}
                >
                  <TableCell>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Checkbox
                        style={{ width: 25 }}
                        checked={selectedOrders.includes(item.id)}
                        onChange={(e) => this.onSelectOrder(item, e)}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <CustomerName item={item.user} />
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {item.oheicsErrors &&
                      item.oheicsErrors.length > 0 && (
                        <div
                          style={{ display: 'inline-block' }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <IconButton
                            className={classes.errorInfo}
                            aria-label="Errors info"
                            onClick={() => this.handleShowOheicsLogs(item.id)}
                          >
                            <InfoIcon />
                          </IconButton>
                        </div>
                      )}
                    #{item.id}
                  </TableCell>
                  <TableCell>{item.createDate || ''}</TableCell>
                  <TableCell>
                    {item.delivery && item.delivery.deliveryTime
                      ? formatOrderDeliveryDateTime(item.delivery.deliveryTime)
                      : ''}
                  </TableCell>
                  <TableCell>
                    <p className={classes.textWrapper}>
                      {restaurants && restaurants[item.restaurantId]
                        ? restaurants[item.restaurantId].nameVisible
                        : ''}
                    </p>
                  </TableCell>
                  <TableCell>{ORDER_TYPES_LABEL[item.type]}</TableCell>
                  <TableCell align="right">{item.totalAmount}</TableCell>
                  <TableCell style={{ textTransform: 'capitalize' }}>
                    <OrderStatus
                      status={item.status}
                      type={item.type}
                      onStatusChange={(value) =>
                        this.handleChangeStatus(value, item.id, item.status)
                      }
                    />
                  </TableCell>
                  {ABLE_STATUSES_TO_CANCEL_ORDER[item.status] ? (
                    <TableActionCell
                      action={() => this.handleCancelOrder(item.id)}
                      icon="delete"
                    />
                  ) : (
                    <TableCell />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageLimit]}
            component="div"
            count={totalResults}
            rowsPerPage={pageLimit}
            page={page}
            onChangePage={this.handlePage}
          />
        </Paper>
        {orderWithErrors && (
          <OheicsOrdersErrorDialog
            open={openOheicsDialog}
            onClose={this.handleCloseOheicsLogs}
            order={orderWithErrors}
            onSubmit={this.handleSendOheicsOrder}
          />
        )}
        <CustomDialog
          open={openOrderDetails}
          onClose={this.handleCloseOrderDetails}
        >
          <OrderDetails orderId={orderId} />
        </CustomDialog>
      </React.Fragment>
    );
  }
}

OrdersListPage.propTypes = {
  classes: PropTypes.object,
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number.isRequired,
  }).isRequired,
  restaurants: PropTypes.object,
  ordersCountToPrint: PropTypes.number,
  selectedRestaurant: PropTypes.number,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  orderIdQuery: PropTypes.string,
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onPage: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSendOrderOheics: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  getProductionList: PropTypes.func.isRequired,
  getPackList: PropTypes.func.isRequired,
  onChangeOrderStatus: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrdersListPage);
