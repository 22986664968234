import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import SmallButton from 'components/SmallButton';

class EditLinkModal extends PureComponent {
  state = {
    linkTarget: this.props.link ? this.props.link.target : '',
    linkTitle:
      this.props.link && this.props.link.title
        ? this.props.link.title
        : this.props.selectionText || '',
    linkTargetOption: this.props.link ? this.props.link.targetOption : '',
  };

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleCheckboxChange = ({ target: { checked } }) => {
    this.setState({
      linkTargetOption: checked ? '_blank' : '_self',
    });
  };

  handleSave = () => {
    const { linkTitle, linkTarget, linkTargetOption } = this.state;

    this.props.handleSave(linkTitle, linkTarget, linkTargetOption);
  };

  render() {
    const { linkTitle, linkTarget, linkTargetOption } = this.state;
    const { link = {} } = this.props;

    return (
      <Dialog
        onClose={this.props.handleClose}
        aria-labelledby="edit-link-modal"
        open={this.props.open}
      >
        <DialogTitle id="edit-link-title">
          <Typography color="secondary" style={{ fontSize: 18 }}>
            {!link.target ? 'Add new link' : 'Edit link data'}
          </Typography>
        </DialogTitle>
        <div style={{ padding: '0px 24px 30px' }}>
          <div>
            <Input
              value={linkTitle}
              type="string"
              onChange={this.handleChange('linkTitle')}
              placeholder="Link label"
              label="Link label"
              margin="normal"
              style={{ width: 300 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div>
            <Input
              value={linkTarget}
              type="string"
              onChange={this.handleChange('linkTarget')}
              placeholder="Website URL"
              label="Website URL"
              margin="normal"
              style={{ width: 300 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={linkTargetOption === '_blank'}
                  onChange={this.handleCheckboxChange}
                  value="openInNewTab"
                />
              }
              label="Open page in a new tab."
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SmallButton
              style={{ cursor: 'pointer' }}
              onClick={this.handleSave}
            >
              Save
            </SmallButton>
          </div>
        </div>
      </Dialog>
    );
  }
}

EditLinkModal.propTypes = {
  className: PropTypes.object,
  selectionText: PropTypes.string,
  link: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
};

export default EditLinkModal;
