import React from 'react';
import PropTypes from 'prop-types';

import FileUpload from 'components/FileUpload';

import { UPLOAD_IMAGE_URL } from 'consts';
import { UPLOAD_IMAGE_MAX_SIZE } from 'consts/upload';

class PDFUpload extends React.PureComponent {
  render() {
    const { file, onChange, onFileUpload, placeholder, label } = this.props;
    return (
      <FileUpload
        file={file}
        onChange={onChange}
        onFileUpload={onFileUpload}
        placeholder={placeholder}
        label={label}
        uploadURL={UPLOAD_IMAGE_URL}
        maxSize={UPLOAD_IMAGE_MAX_SIZE}
      />
    );
  }
}

PDFUpload.propTypes = {
  file: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

export default PDFUpload;
