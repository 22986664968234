import React from 'react';
import PropTypes from 'prop-types';

import PageHeading from 'components/PageHeading';
import PageHeadingTitle from 'components/PageHeadingTitle';

import TagList from './TagList';

class TagGroupDetailsPage extends React.PureComponent {
  render() {
    const { id, initialData } = this.props;
    return (
      <div>
        <PageHeading
          title={
            <PageHeadingTitle
              text="Tag Group"
              highlightedText={initialData.name}
            />
          }
        />
        <TagList id={id} />
      </div>
    );
  }
}

TagGroupDetailsPage.propTypes = {
  id: PropTypes.number,
  initialData: PropTypes.object,
};

export default TagGroupDetailsPage;
