import { calculateSort, calculatePage } from 'utils';

import apiRequest from 'sagas/utils/apiRequest';
import { getLocationsRequest } from 'sagas/locations';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';

/**
 * Retrieve pagination list of order deliveries.
 * @param {Object}
 * @return {Object} Response for request.
 */
export function* getOrderDeliveries({
  orderIds,
  deliveryTime,
  getLocations = false,
  sortName,
  sortDirection,
  page,
  pageLimit,
}) {
  try {
    const orderIdParam =
      orderIds && orderIds.length ? `orderId=${orderIds.join(',')}` : '';
    const pageParam = pageLimit ? `&${calculatePage(page, pageLimit)}` : '';
    const deliveryTimeParam = deliveryTime
      ? `&deliveryTime=${deliveryTime}`
      : '';
    let url = `/orders/deliveries?${calculateSort(
      sortDirection,
      sortName
    )}${orderIdParam}${deliveryTimeParam}${pageParam}`;
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = yield apiRequest(url, options);
    const deliveryIds =
      response.items && response.items.length > 0
        ? response.items
            .map((delivery) => delivery.locationId)
            .filter((locationId) => locationId > 0)
        : [];

    if (getLocations && deliveryIds && deliveryIds.length) {
      const locations = yield getLocationsRequest({
        ids: deliveryIds,
      });
      const locationsDict = locations.items.reduce((acc, location) => {
        acc[location.id] = location;
        return acc;
      }, {});
      response.items = response.items.map((item) => {
        const location = locationsDict[item.locationId] || {};
        return {
          ...item,
          location,
          deliveryAddress: `${location.streetAddress ||
            ''} ${location.postcode || ''} ${location.city || ''}`,
        };
      });
    }
    return response;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}
