import { compose } from 'recompose';
import { connect } from 'react-redux';

import { uploadPDF } from 'actions/upload';

import PDFUpload from './PDFUpload';

const mapDispatchToProps = {
  onFileUpload: uploadPDF,
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(PDFUpload);
