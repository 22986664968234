import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import PageHeading from 'components/PageHeading';
import PageHeadingTitle from 'components/PageHeadingTitle';

const stateFromProps = ({ initialData = {} }) => {
  return {
    name: initialData.name || '',
    pointAmount: initialData.pointAmount || 0,
    storeCreditAward: initialData.storeCreditAward || 0,
  };
};

class KickbackDetailsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleSubmit = () => {
    const { id, onSave, initialData = {} } = this.props;
    const { ...values } = this.state;
    const data = { ...initialData, ...values };
    const validateError = this.validateError(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      onSave({
        ...data,
        id,
      })
        .then(() => {
          toastr.success(
            'Success!',
            id
              ? 'Kickback level has been updated'
              : 'New Kickback level has been created'
          );
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  validateError = (data) => {
    if (data.pointAmount < 0) {
      return 'Number of points should be greater than or equal 0';
    }
    if (!(data.storeCreditAward && data.storeCreditAward > 0)) {
      return 'Reward should be greater than 0';
    }
  };

  render() {
    const { id, initialData: kickback } = this.props;
    const { name, pointAmount, storeCreditAward } = this.state;
    return (
      <div>
        <PageHeading
          title={
            id ? (
              <PageHeadingTitle
                text="Kickback level"
                highlightedText={kickback.name || 'No Name'}
              />
            ) : (
              'New Kickback level'
            )
          }
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        <div>
          <Input
            value={name}
            onChange={this.handleChange('name')}
            placeholder="Name"
            label="Name"
            margin="normal"
            style={{ width: 300 }}
          />
        </div>
        <div>
          <Input
            value={pointAmount}
            type="number"
            min={0}
            onChange={this.handleChange('pointAmount')}
            placeholder="Number of points needed"
            label="Number of points needed"
            margin="normal"
            style={{ width: 300 }}
          />
        </div>
        <div>
          <Input
            value={storeCreditAward}
            type="number"
            min={0}
            onChange={this.handleChange('storeCreditAward')}
            placeholder="Reward"
            label="Reward (kr)"
            margin="normal"
            style={{ width: 300 }}
          />
        </div>
      </div>
    );
  }
}

KickbackDetailsPage.propTypes = {
  id: PropTypes.number,
  initialData: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default KickbackDetailsPage;
