import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import ActionHeading from 'components/ActionHeading';
import TableActionCell from 'components/TableActionCell';
import SmallButton from 'components/SmallButton';

import MenuItemsDropdown from './MenuItemsDropdown';
import GroupsDropdown from './GroupsDropdown';

import { ITEM_STATUSES } from 'consts';

class IncludedItems extends React.PureComponent {
  state = {
    selectedGroup: null,
  };

  onSelectGroup = (group) => {
    this.setState({ selectedGroup: group });
  };

  handleSubmit = () => {
    const { selectedGroup } = this.state;
    if (selectedGroup) {
      this.props.onGroupAdd(selectedGroup);
      this.setState({ selectedGroup: null });
    }
  };

  onDefaultChange = (groupId, value) => {
    const group = this.props.items.find((g) => g.groupId === groupId);
    this.props.onGroupChange(groupId, { ...group, defaultMenuItemId: value });
  };

  onCountChange = (groupId, value) => {
    const group = this.props.items.find((g) => g.groupId === groupId);
    this.props.onGroupChange(groupId, { ...group, countLimit: Number(value) });
  };

  render() {
    const { items, inactive, onDelete } = this.props;
    const { selectedGroup } = this.state;
    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Default</TableCell>
                <TableCell>Number of items</TableCell>
                {!inactive && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow
                  key={item.groupId}
                  style={{
                    backgroundColor:
                      item.group.status === ITEM_STATUSES.inactive
                        ? 'rgba(0, 0, 0, 0.07)'
                        : '',
                  }}
                >
                  <TableCell>{item.group.name}</TableCell>
                  <TableCell>
                    <MenuItemsDropdown
                      items={item.group.menuItems}
                      selected={item.defaultMenuItemId}
                      disabled={
                        !!(
                          inactive ||
                          item.group.status === ITEM_STATUSES.inactive
                        )
                      }
                      onChange={(v) => this.onDefaultChange(item.groupId, v)}
                    />
                  </TableCell>
                  <TableCell>
                    <Input
                      value={item.countLimit}
                      onChange={(e) =>
                        this.onCountChange(item.groupId, e.target.value)
                      }
                      placeholder="Number of items"
                      type="number"
                      disabled={
                        !!(
                          inactive ||
                          item.group.status === ITEM_STATUSES.inactive
                        )
                      }
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'right' },
                      }}
                    />
                  </TableCell>
                  {!inactive && (
                    <TableActionCell
                      action={() => onDelete(item.groupId)}
                      icon="delete"
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {!inactive && (
          <div style={{ marginTop: 40 }}>
            <ActionHeading>Add group</ActionHeading>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <GroupsDropdown
                value={selectedGroup}
                onChange={this.onSelectGroup}
                excludeIds={items.map(({ groupId }) => groupId)}
              />
              <SmallButton
                onClick={this.handleSubmit}
                leftSpace
                id="add-group-button"
              >
                Add
              </SmallButton>
            </div>
          </div>
        )}
      </div>
    );
  }
}

IncludedItems.propTypes = {
  items: PropTypes.array.isRequired,
  inactive: PropTypes.bool,
  onGroupChange: PropTypes.func.isRequired,
  onGroupAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default IncludedItems;
