import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { showLoading, hideLoading } from 'actions/ui';
import { getPackSortingList, saveSettings } from 'actions/settings';

import { hideIfNoData } from 'utils/enhancers';
import { wrapWithSideEffect } from 'utils';

import PackListSortingPage from './PackListSortingPage';

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const mapDispatchToProps = {
  onInit: getPackSortingList,
  onSubmit: saveSettings,
  showLoading,
  hideLoading,
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', 'name'),
  withState('sortDirection', 'setSortDirection', 'asc')
);

const refresh = (props) => {
  const { onInit, setInitialData, sortName, sortDirection } = props;
  return wrapWithProgress(props)(
    onInit({
      sortName,
      sortDirection,
      getAllItems: true,
    }).then(setInitialData)
  );
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSubmit: (props) => (event) => {
    const { onSubmit } = props;
    return wrapWithProgress(props)(onSubmit(event).then(() => refresh(props)));
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(PackListSortingPage);
