import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';

import PageHeading from 'components/PageHeading';
import Section from 'components/Section';
import ImageUpload from 'components/ImageUpload';

import NewSectionButton from './NewSectionButton';
import ArticleDetails from './ArticleDetails';
import ListDetails from './ListDetails';

import { LANDING_SETTINGS_DATA_NAME } from 'consts/settings';
import { LANDING_SECTION_TYPES } from 'shared/consts/settings';
import { renderMultilineText } from './utils';
import { reorder } from 'utils/processors';

import styles from './styles';

const stateFromProps = ({ initialData = {} }) => ({
  expanded: false,
  items: initialData && initialData.items ? initialData.items : [],
  heroImage: initialData.heroImage || {},
  logo: initialData.logo || {},
});

class LandingSettings extends React.PureComponent {
  state = stateFromProps(this.props);

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { initialData } = this.props;
    if (initialData !== nextProps.initialData) {
      this.setState(stateFromProps(nextProps));
    }
  }

  reorder = (sourceIndex, destinationIndex, cb) => {
    const { items } = this.state;
    const newItems = reorder(items, sourceIndex, destinationIndex);

    this.setState({ items: newItems }, cb && cb());
  };

  handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    this.reorder(result.source.index, result.destination.index);
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  handleDelete = (id) => {
    const { items } = this.state;

    const newItems = items.filter((item) => item.id !== id);
    this.setState({
      expanded: false,
      items: newItems,
    });
  };

  handleHeroImageChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      heroImage: {
        ...this.state.heroImage,
        [fieldName]: value,
      },
    });
  };

  handleLogoChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      logo: {
        ...this.state.logo,
        [fieldName]: value,
      },
    });
  };

  handleSubmit = () => {
    const { onSubmit, initialData } = this.props;
    const { items, heroImage, logo } = this.state;
    const data = items.map((item, index) => ({
      ...item,
      index,
    }));
    const dataToSend = {
      isNew: !Object.keys(initialData).length,
      name: LANDING_SETTINGS_DATA_NAME,
      value: JSON.stringify({ items: data, heroImage, logo }),
    };
    onSubmit(dataToSend)
      .then(() => {
        toastr.success('Success!', 'Content has been saved');
      })
      .catch(() => {
        toastr.error('Error!', 'Something went wrong');
      });
  };

  render() {
    const { classes } = this.props;
    const { expanded, items, heroImage, logo } = this.state;

    return (
      <React.Fragment>
        <PageHeading
          title="Landing content"
          right={
            <div>
              <NewSectionButton />
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                size="large"
                style={{ marginLeft: 24 }}
              >
                Save
              </Button>
            </div>
          }
        />
        {items &&
          items.length > 0 && (
            <React.Fragment>
              <Typography
                variant="body2"
                component="p"
                style={{ marginBottom: 20 }}
              >
                Drag and drop items to change order
              </Typography>
              <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      className={classes.contentWrapper}
                      ref={provided.innerRef}
                    >
                      {items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ExpansionPanel
                                key={item.id}
                                style={{ marginBottom: 10 }}
                                expanded={expanded === item.id}
                                onChange={this.handleChange(item.id)}
                              >
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel${index}-content`}
                                  id={`panel${index}-header`}
                                >
                                  <Typography className={classes.itemType}>
                                    {item.type} section:
                                  </Typography>
                                  <div className={classes.headerTitle}>
                                    {item.type !== LANDING_SECTION_TYPES.list &&
                                      renderMultilineText(item.title)}
                                  </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails
                                  style={{
                                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                                  }}
                                >
                                  {item.type === LANDING_SECTION_TYPES.list ? (
                                    <ListDetails
                                      item={item}
                                      onDelete={this.handleDelete}
                                    />
                                  ) : (
                                    <ArticleDetails
                                      item={item}
                                      onDelete={this.handleDelete}
                                    />
                                  )}
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </React.Fragment>
          )}
        <Section heading="Hero image">
          <div className={classes.imagePreviewBg}>
            <ImageUpload
              image={heroImage.imageURL || ''}
              onChange={this.handleHeroImageChange('imageURL')}
              placeholder="Image URL"
              label="Image URL"
            />
          </div>
          <div className={classes.inputWrapper}>
            <Input
              value={heroImage.imageSEOAlt || ''}
              type="string"
              onChange={this.handleHeroImageChange('imageSEOAlt')}
              label="SEO alt text"
              margin="normal"
              className={classes.input}
            />
          </div>
        </Section>
        <Section heading="Landing page logo">
          <div className={classes.imagePreviewBg}>
            <ImageUpload
              image={logo.imageURL || ''}
              onChange={this.handleLogoChange('imageURL')}
              placeholder="Image URL"
              label="Image URL"
            />
          </div>
          <div className={classes.inputWrapper}>
            <Input
              value={logo.imageSEOAlt || ''}
              type="string"
              onChange={this.handleLogoChange('imageSEOAlt')}
              label="SEO alt text"
              margin="normal"
              className={classes.input}
            />
          </div>
        </Section>
      </React.Fragment>
    );
  }
}

LandingSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  initialData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(LandingSettings);
