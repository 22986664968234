import React from 'react';
import PropTypes from 'prop-types';

import { ORDER_TYPES, ORDER_TYPES_LABEL } from 'consts/orders';
import { DATETIME_FORMAT_WO_SECS } from 'consts';
import {
  formatDeliveryTimeForPackList,
  formatOrderDeliveryDateTime,
} from 'utils/date';

const fixedLabel = { width: 120, marginRight: 30, flexShrink: 0 };
const fixedSection = {
  display: 'flex',
  width: '50%',
};
const fullSection = { ...fixedSection, width: '100%' };
const rightAlign = { textAlign: 'right' };
const checkbox = {
  display: 'inline-block',
  width: 44,
  height: 28,
  boxSizing: 'border-box',
  border: '1px solid #4a4a4a',
};
const sectionHeader = {
  fontSize: 18,
  marginBottom: 15,
};
const infoColumn = {
  display: 'flex',
  marginBottom: 10,
};
const textWrapper = {
  width: '95%',
  wordBreak: 'break-word',
};
const fixedInfo = {
  flex: 1,
};

const printDate = (date, obj) =>
  obj && obj.deliveryCost && obj.deliveryCost.minimumTime
    ? formatDeliveryTimeForPackList(date, obj.deliveryCost.minimumTime)
    : formatOrderDeliveryDateTime(date, DATETIME_FORMAT_WO_SECS);

class PackListPage extends React.PureComponent {
  render() {
    const { sections, page, pages } = this.props;

    return (
      <React.Fragment>
        {page &&
          pages &&
          pages > 1 && (
            <p className="print-text" style={{ fontSize: 16 }}>
              Page {page} / {pages}
            </p>
          )}
        <div>
          {sections.map((section, index) => (
            <div className="print-page" key={index}>
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <img src="/img/logo_text.svg" alt="logo" />
                <div style={{ marginTop: 10 }}>
                  <img src="/img/logo.svg" alt="logo" />
                </div>
              </div>
              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <h1 className="print-page-heading">Order #{section.id}</h1>
                  <div style={{ marginTop: 4 }}>
                    <p className="print-text">
                      {formatOrderDeliveryDateTime(section.createdDate)}
                    </p>
                  </div>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <label className="print-heading">Antal påsar</label>
                  <div style={{ marginTop: 12 }}>
                    <div style={checkbox} />
                  </div>
                </div>
              </div>
              <div className="print-divider" />
              <div>
                <p style={sectionHeader}>Leveransdetaljer</p>
                <div style={{ display: 'flex' }}>
                  {section.type === ORDER_TYPES.delivery ? (
                    <React.Fragment>
                      <div style={{ width: '50%' }}>
                        {section.delivery &&
                          section.delivery.contactPerson && (
                            <div style={infoColumn} className="print-section">
                              <label
                                className="print-heading"
                                style={fixedLabel}
                              >
                                Mottagare
                              </label>
                              <div style={fixedInfo}>
                                <p style={textWrapper}>
                                  {section.delivery.contactPerson}
                                </p>
                              </div>
                            </div>
                          )}
                        {section.address && (
                          <div style={infoColumn} className="print-section">
                            <label className="print-heading" style={fixedLabel}>
                              Adress
                            </label>
                            <div style={fixedInfo}>
                              <p style={textWrapper}>{section.address}</p>
                            </div>
                          </div>
                        )}
                        {section.delivery &&
                          section.delivery.notice && (
                            <div style={infoColumn} className="print-section">
                              <label
                                className="print-heading"
                                style={fixedLabel}
                              >
                                Kommentar
                              </label>
                              <div style={fixedInfo}>
                                <p style={textWrapper}>
                                  {section.delivery.notice}
                                </p>
                              </div>
                            </div>
                          )}
                      </div>
                      <div style={{ width: '50%' }}>
                        <div style={infoColumn} className="print-section">
                          <label className="print-heading" style={fixedLabel}>
                            Tid
                          </label>
                          <p>{printDate(section.deliveryDate, section)}</p>
                        </div>
                        {section.deliveryCost &&
                          section.deliveryCost.minimumTime && (
                            <div style={infoColumn} className="print-section">
                              <label
                                className="print-heading"
                                style={fixedLabel}
                              >
                                Zon
                              </label>
                              <p>{section.deliveryCost.zoneName}</p>
                            </div>
                          )}
                        {section.delivery &&
                          section.delivery.deliveryPhone && (
                            <div style={infoColumn} className="print-section">
                              <label
                                className="print-heading"
                                style={fixedLabel}
                              >
                                Tele
                              </label>
                              <div>
                                <p>{section.delivery.deliveryPhone}</p>
                              </div>
                            </div>
                          )}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div style={{ width: '50%' }}>
                      <div style={infoColumn} className="print-section">
                        <label className="print-heading" style={fixedLabel}>
                          Tid
                        </label>
                        <p>{printDate(section.deliveryDate)}</p>
                      </div>
                      <div style={infoColumn} className="print-section">
                        <label className="print-heading" style={fixedLabel}>
                          Order typ
                        </label>
                        <p>{ORDER_TYPES_LABEL[section.type]}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="print-divider" />
              
              <div>
                <p style={sectionHeader}>Order information</p>
                {section.company ? (
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      {section.user && (
                        <div style={infoColumn} className="print-section">
                          <label className="print-heading" style={fixedLabel}>
                            Beställare
                          </label>
                          <div style={fixedInfo}>
                            <p style={textWrapper}>{section.user.name}</p>
                            <p style={textWrapper}>{section.user.phone}</p>
                            <p style={textWrapper}>{section.user.email}</p>
                          </div>
                        </div>
                      )}
                      {section.notice && (
                        <div style={infoColumn} className="print-section">
                          <label className="print-heading" style={fixedLabel}>
                            Order note
                          </label>
                          <div style={fixedInfo}>
                            <p style={textWrapper}>{section.notice}</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div style={{ width: '50%' }}>
                      <div style={infoColumn} className="print-section">
                        <label className="print-heading" style={fixedLabel}>
                          Fakturamottagare
                        </label>
                        <div style={fixedInfo}>
                          <p style={textWrapper}>{section.company.name}</p>
                        </div>
                      </div>
                      <div style={infoColumn} className="print-section">
                        <label className="print-heading" style={fixedLabel}>
                          ID
                        </label>
                        <p>{section.company.id}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    {section.user && (
                      <div
                        style={{ ...fullSection, ...infoColumn }}
                        className="print-section"
                      >
                        <label className="print-heading" style={fixedLabel}>
                          Beställare
                        </label>
                        <div style={fixedInfo}>
                          <p style={textWrapper}>{section.user.name}</p>
                          <p style={textWrapper}>{section.user.phone}</p>
                          <p style={textWrapper}>{section.user.email}</p>
                        </div>
                      </div>
                    )}
                    {section.notice && (
                      <div style={fullSection} className="print-section">
                        <label className="print-heading" style={fixedLabel}>
                          Order note
                        </label>
                        <div style={fixedInfo}>
                          <p style={textWrapper}>{section.notice}</p>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
              {section.commentToEdit && (
                <div style={infoColumn} className="print-section">
                  <label className="print-heading" style={fixedLabel}>
                    Kommentar från Panini
                  </label>
                  <div style={fixedInfo}>
                    <p style={textWrapper}>{section.commentToEdit}</p>
                  </div>
                </div>
              )}
              <table className="print-table" style={{ marginTop: 30 }}>
                <thead>
                  <tr>
                    <th>Produkt</th>
                    <th style={rightAlign}>Antal</th>
                    <th style={rightAlign}>Antal packade</th>
                  </tr>
                </thead>
                <tbody>
                  {section.items
                    .filter((item) => item.visible)
                    .map((item, jndex) => (
                      <tr key={jndex}>
                        <td>
                          {item.name} : {item.categoryName}
                        </td>
                        <td style={rightAlign}>{item.quantity}</td>
                        <td style={rightAlign}>
                          <div style={checkbox} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

PackListPage.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      createDate: PropTypes.string.isRequired,
      deliveryDate: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      address: PropTypes.string,
      notice: PropTypes.string,
      commentToEdit: PropTypes.string,
      user: PropTypes.shape({
        name: PropTypes.string,
        phone: PropTypes.string,
      }).isRequired,
      company: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  page: PropTypes.string,
  pages: PropTypes.string,
};

export default PackListPage;
