import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  defaultProps,
} from 'recompose';
import { connect } from 'react-redux';

import { showLoading, hideLoading } from 'actions/ui';
import { getUserCompanies, declineCompanyMember } from 'actions/userRegistry';

import { hideIfNoData } from 'utils/enhancers';
import { wrapWithSideEffect } from 'utils';
import { ACTIVE_MEMBER_STATUS } from 'consts/userRegistry';

import CompaniesList from './CompaniesList';

const PAGING_LIMIT = 10;

const mapDispatchToProps = {
  onInit: getUserCompanies,
  onDelete: declineCompanyMember,
  showLoading,
  hideLoading,
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', 'companyId'),
  withState('sortDirection', 'setSortDirection', 'desc'),
  withState('page', 'setPage', 0),
  defaultProps({
    pageLimit: PAGING_LIMIT,
  })
);

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const refresh = (props) => {
  const {
    userId,
    sortName,
    sortDirection,
    page,
    pageLimit,
    onInit,
    setInitialData,
  } = props;
  return onInit({
    userId,
    sortName,
    sortDirection,
    page,
    pageLimit,
    status: ACTIVE_MEMBER_STATUS,
  }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setPage(0);
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    return refresh({ ...props, sortName, sortDirection, page: 0 });
  },
  onPage: (props) => ({ page }) => {
    props.setPage(page);
    return refresh({ ...props, page });
  },
  onDelete: (props) => (id) => {
    const { onDelete } = props;
    return wrapWithProgress(props)(
      onDelete(id).then(() => {
        refresh(props);
      })
    );
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(CompaniesList);
