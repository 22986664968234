import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import InputLabel from '@material-ui/core/InputLabel';

import { ChromePicker } from 'react-color';
import ColorPickerLabel from 'components/ColorPickerLabel';

import styles from './styles';

class ColorPicker extends React.PureComponent {
  state = {
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  render() {
    const { color, label, onChange, classes } = this.props;
    const { displayColorPicker } = this.state;

    return (
      <div className={classes.wrapper}>
        <InputLabel className={classes.label}>{label}: </InputLabel>
        <ColorPickerLabel color={color} onClick={this.handleClick} />
        {displayColorPicker ? (
          <div className={classes.popover}>
            <div className={classes.cover} onClick={this.handleClose} />
            <ChromePicker color={color} disableAlpha onChange={onChange} />
          </div>
        ) : null}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ColorPicker);
