import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { toastr } from 'react-redux-toastr';
import cn from 'classnames';

import PageHeading from 'components/PageHeading';
import TableSortCell from 'components/TableSortCell';
import Select from 'components/Select';
import DateTimePicker from 'components/DateTimePicker';
import TableCount from 'components/TableCount';
import TableActionCell from 'components/TableActionCell';
import NoData from 'components/NoData';
import RestaurantsDropdown from 'components/RestaurantsDropdown';
import OheicsOrdersErrorDialog from 'components/OheicsOrdersErrorDialog';
import CustomDialog from 'components/CustomDialog';
import OrderDetails from 'components/OrderDetails';
import ButtonWithTooltip from 'components/ButtonWithTooltip';
import OrderStatus from 'components/OrderStatus';

import { confirmAlert } from 'utils';
import { getTypesOfDay, formatOrderDeliveryDateTime } from 'utils/date';

import { DAYS_OPTIONS_NAME, DAY_OPTIONS_TYPES } from 'consts';
import {
  ORDER_TYPES,
  ORDER_STATUSES,
  ABLE_STATUSES_TO_CANCEL_ORDER,
  ORDER_STATUSES_LABEL,
  ORDER_TYPES_LABEL,
} from 'consts/orders';
import { MAX_ORDERS_COUNT_FOR_PRINT_LIST } from 'consts/printList';

const dayOptions = Object.values(DAYS_OPTIONS_NAME);
const TIME_TO_UPDATE_ORDERS = 60000;

let timer = null;

const defaultRestaurantOption = { label: 'All', value: null };

const defaultState = (props) => {
  const selectedDay = dayOptions[0];

  const startTime = getTypesOfDay({
    day: selectedDay,
    type: DAY_OPTIONS_TYPES.start,
  });
  const endTime = getTypesOfDay({
    day: selectedDay,
    type: DAY_OPTIONS_TYPES.end,
  });

  return {
    selectedDay,
    startTime: props.startTime || startTime,
    endTime: props.endTime || endTime,
    status: props.status,
  };
};

const orderStatuses = Object.keys(ORDER_STATUSES_LABEL)
  .filter(
    (key) =>
      [
        ORDER_STATUSES.draft,
        ORDER_STATUSES.pending,
        ORDER_STATUSES.readyForPayment,
      ].indexOf(key) === -1
  )
  .map((key) => ({
    label: ORDER_STATUSES_LABEL[key],
    value: key,
  }));
// Adding All status to start of an array
orderStatuses.unshift({ label: 'Default', value: null });

const styles = () => ({
  companyCell: {
    minWidth: 160,
  },
  datePicker: {
    marginTop: 5,
    marginRight: 20,
  },
  selectWrapper: {
    display: 'inline-block',
    marginRight: 20,
  },
  totalSumCell: {
    textAlign: 'right',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 13,
    fontWeight: 'bold',
  },
  tableCell: {
    textAlign: 'center',
  },
  errorInfo: {
    color: '#dc3535',
    padding: 0,
    marginRight: 8,
  },
  errorRow: {
    background: 'rgba(245, 8, 8, 0.4)',
  },
  textWrapper: {
    maxWidth: 160,
    wordBreak: 'break-word',
  },
});

class OrdersTypeList extends React.PureComponent {
  state = {
    ...defaultState(this.props),
    selectedOrders: [], //use getSelectedOrders
    orders: this.props.initialData ? this.props.initialData.items : [],
    pageParams: {
      limit: this.props.initialData.limit,
      offset: this.props.initialData.offset,
      totalResults: this.props.initialData.totalResults,
    },
    timerEnabled: true,
    loading: false,
    openOheicsDialog: false,
    orderWithErrors: null,
    oheicsErrors: null,
    openOrderDetails: false,
    orderId: null,
    userId: this.props.userId || '',
    orderIdQuery: this.props.orderIdQuery || '',
  };

  componentDidMount() {
    this.getMoreInitData();
    timer = setInterval(() => {
      if (this.state.timerEnabled) {
        this.updateDeliveryItems({
          page: 0,
          setToTop: true,
        });
      }
    }, TIME_TO_UPDATE_ORDERS);

    window.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUpdate = (nextProps) => {
    const { isRefresh, initialData } = nextProps;
    const nextItems = initialData.items;
    const { items } = this.props.initialData;

    if (items !== nextItems && isRefresh) {
      this.setState(
        {
          orders: nextItems,
          pageParams: {
            limit: initialData.limit,
            offset: initialData.offset,
            totalResults: initialData.totalResults,
          },
        },
        this.getMoreInitData
      );
    }
    if (items !== nextItems && !isRefresh) {
      this.setState({
        orders: nextItems,
        pageParams: {
          limit: initialData.limit,
          offset: initialData.offset,
          totalResults: initialData.totalResults,
        },
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
    clearInterval(timer);
  }

  onScroll = () => {
    const { offset, limit, totalResults } = this.state.pageParams;

    if (document.documentElement.scrollTop <= 50) {
      this.setState({
        timerEnabled: true,
      });
    } else if (
      window.innerHeight + window.pageYOffset >=
      document.body.offsetHeight - 50
    ) {
      this.setState({
        timerEnabled: false,
      });
      if (offset + limit <= totalResults) {
        this.updateDeliveryItems({
          page: offset / limit + 1,
          setToTop: false,
        });
      }
    }
  };

  setLoadingValue = (value) => {
    this.setState({
      loading: value,
    });
  };

  getMoreInitData = () => {
    const { offset, limit, totalResults } = this.state.pageParams;

    if (
      window.innerHeight + window.pageYOffset === document.body.offsetHeight &&
      offset + limit <= totalResults
    ) {
      this.updateDeliveryItems({
        page: offset / limit + 1,
        setToTop: false,
        cb: this.getMoreInitData,
      });
    }
  };

  concatDeliveryItems = (items, setToTop) => {
    const { orders } = this.state;
    const map = new Map();
    const array = setToTop ? items.concat(orders) : orders.concat(items);
    const result = [];

    for (const item of array) {
      if (!map.has(item.id)) {
        map.set(item.id, true);
        result.push({
          ...item,
        });
      }
    }

    this.setState({
      orders: result,
    });
  };

  updateDeliveryItems = ({ page, setToTop, cb }) => {
    const { loading } = this.state;

    if (loading) {
      return;
    }

    this.setLoadingValue(true);
    this.props
      .onUpdate({
        page,
      })
      .then((data) => {
        if (data && data.items) {
          this.concatDeliveryItems(data.items, setToTop);
        }
        if (!setToTop) {
          this.setState({
            pageParams: {
              limit: data.limit,
              offset: data.offset,
              totalResults: data.totalResults,
            },
          });
        } else {
          this.setState({
            pageParams: {
              ...this.state.pageParams,
              totalResults: data.totalResults,
            },
          });
        }
      })
      .finally(() => {
        this.setLoadingValue(false);
        cb && cb();
      });
  };

  onSelectOrder = (order, { target: { checked } }) => {
    const otherSelected = this.state.selectedOrders.filter(
      (o) => o !== order.id
    );
    const selectedOrders = checked
      ? [...otherSelected, order.id]
      : otherSelected;

    this.setState({
      selectedOrders,
    });
  };

  onSelectRestaurant = ({ value }) => {
    this.setState({ selectedRestaurant: value }, () => {
      this.props.onFilter({ ...this.state });
    });
  };

  onSelectDay = ({ target: { value } }) => {
    const selectedDay = value;
    this.setState(
      {
        selectedDay,
        startTime: getTypesOfDay({
          day: selectedDay,
          type: DAY_OPTIONS_TYPES.start,
        }),
        endTime: getTypesOfDay({
          day: selectedDay,
          type: DAY_OPTIONS_TYPES.end,
        }),
      },
      this.onStartEndTimeChange
    );
  };

  onStartEndTimeChange = () => {
    const { startTime, endTime } = this.state;
    if (startTime && endTime) {
      this.props.onFilter({ ...this.state });
    }
  };

  onStartTimeChange = (time) => {
    this.setState({ startTime: time }, this.onStartEndTimeChange);
  };

  onEndTimeChange = (time) => {
    this.setState({ endTime: time }, this.onStartEndTimeChange);
  };

  onStatusChange = ({ value }) => {
    this.setState({ status: value }, () => {
      this.props.onFilter({ ...this.state });
    });
  };

  handleCancelOrder = (id) => {
    const { onCancel } = this.props;
    confirmAlert({
      okCb: () =>
        onCancel({ id, status: ORDER_STATUSES.cancelled }).then(() => {
          toastr.success('Success!', 'Order has been canceled');
        }),
      message: 'Do you really want to cancel order?',
    });
  };

  getSelectedOrders = () => {
    const { orders } = this.state;
    const { selectedOrders } = this.state;
    return orders.map((o) => o.id).filter((id) => selectedOrders.includes(id));
  };

  getOrdersIdsForChangeStatus = (status) => {
    const { orders } = this.state;
    const { selectedOrders } = this.state;
    const items = orders.filter(
      (item) =>
        selectedOrders.includes(item.id) &&
        item.type === ORDER_TYPES.delivery &&
        item.status === status
    );
    return items.map((o) => o.id);
  };

  goToProductionListPage = () => {
    const { getProductionList } = this.props;

    const orderIdsToChangeStatus = this.getOrdersIdsForChangeStatus(
      ORDER_STATUSES.paid
    );
    const ordersIds = this.getSelectedOrders();

    getProductionList({
      ordersIds,
      orderIdsToChangeStatus,
    }).then(() => {
      this.setState({
        selectedOrders: [],
      });
    });
  };

  goToPackListPage = () => {
    const { getPackList } = this.props;

    const orderIdsToChangeStatus = this.getOrdersIdsForChangeStatus(
      ORDER_STATUSES.inProduction
    );
    const ordersIds = this.getSelectedOrders();

    getPackList({
      ordersIds,
      orderIdsToChangeStatus,
    }).then(() => {
      this.setState({
        selectedOrders: [],
      });
    });
  };

  getCompanyAddressInfo = (address) => {
    try {
      const jsonAddress = JSON.parse(address);
      return (
        <Fragment>
          <p>
            {jsonAddress.street} {jsonAddress.zipCode} {jsonAddress.city}
          </p>
        </Fragment>
      );
    } catch (error) {
      return null;
    }
  };

  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handleShowOheicsLogs = (orderId) => {
    const { orders } = this.state;
    const orderWithErrors = orders.find((order) => order.id === orderId);
    if (!orderWithErrors) {
      return;
    }
    this.setState({ openOheicsDialog: true, orderWithErrors });
  };

  handleCloseOheicsLogs = () => {
    this.setState({ openOheicsDialog: false, orderWithErrors: null });
  };

  handleSendOheicsOrder = () => {
    const { orderWithErrors } = this.state;
    const { onSendOrderOheics } = this.props;
    const orderId = orderWithErrors.id;
    this.setState({ openOheicsDialog: false, orderWithErrors: null }, () => {
      onSendOrderOheics(orderId);
    });
  };

  handleCloseOrderDetails = () => {
    this.setState({ openOrderDetails: false, orderId: null });
  };

  handleShowOrderDetails = (id) => {
    this.setState({ openOrderDetails: true, orderId: id });
  };

  canPrintOrders = (count) => {
    return !(count === 0 || count > MAX_ORDERS_COUNT_FOR_PRINT_LIST);
  };

  handleUserQueryChange = ({ target: { value } }) => {
    this.setState({
      userId: value,
    });
  };

  handleOrderIdQueryChange = ({ target: { value } }) => {
    this.setState({
      orderIdQuery: value,
    });
  };

  onSearch = () => {
    const { onFilter } = this.props;
    const {
      selectedRestaurant,
      startTime,
      endTime,
      status,
      userId,
      orderIdQuery,
    } = this.state;
    onFilter({
      selectedRestaurant,
      startTime: startTime && endTime ? startTime : null,
      endTime: startTime && endTime ? endTime : null,
      status,
      userId,
      orderIdQuery,
    });
  };

  handleChangeStatus = (status, id, currStatus) => {
    if (currStatus !== status.value) {
      this.props.onChangeOrderStatus({ id, status: status.value });
    }
  };

  render() {
    const {
      classes,
      sortName,
      sortDirection,
      type,
      selectedRestaurant,
      restaurants,
    } = this.props;
    const {
      selectedDay,
      orders,
      startTime,
      endTime,
      status,
      openOheicsDialog,
      orderWithErrors,
      openOrderDetails,
      orderId,
      pageParams,
      userId,
      orderIdQuery,
    } = this.state;
    const selectedOrders = this.getSelectedOrders();
    const totalResults = pageParams.totalResults;

    const totalSum = Math.ceil(
      orders.reduce((acc, order) => {
        acc += order.totalAmount;
        return acc;
      }, 0)
    );

    const numberOfOrdersToPrint =
      selectedOrders && selectedOrders.length > 0
        ? selectedOrders.length
        : totalResults;

    return (
      <div>
        <PageHeading
          title={ORDER_TYPES_LABEL[type]}
          right={
            type === ORDER_TYPES.pickUp ? (
              <RestaurantsDropdown
                defaultOption={defaultRestaurantOption}
                value={selectedRestaurant}
                onChange={this.onSelectRestaurant}
              />
            ) : null
          }
        />
        <div style={{ marginTop: 24 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: 10,
              }}
            >
              <div style={{ display: 'flex', position: 'relative' }}>
                <InputLabel shrink style={{ position: 'absolute', top: -12 }}>
                  Delivery date
                </InputLabel>
                <div style={{ display: 'flex' }}>
                  <div>
                    <DateTimePicker
                      className={classes.datePicker}
                      value={startTime}
                      onChange={this.onStartTimeChange}
                    />
                  </div>
                  <span
                    style={{
                      margin: '10px 10px 0px -10px',
                      display: 'block',
                    }}
                  >
                    &#8212;
                  </span>
                  <div>
                    <DateTimePicker
                      className={classes.datePicker}
                      value={endTime}
                      onChange={this.onEndTimeChange}
                    />
                  </div>
                </div>
              </div>
              <div
                className={classes.selectWrapper}
                style={{ marginBottom: -4 }}
              >
                <Select
                  options={orderStatuses}
                  value={orderStatuses.find((i) => i.value === status)}
                  onChange={this.onStatusChange}
                  width={170}
                  placeholder="Default"
                  label="Status"
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                flex: 1,
                justifyContent: 'flex-end',
                marginBottom: 10,
              }}
            >
              <div style={{ marginRight: 20 }}>
                <Input
                  value={orderIdQuery}
                  onChange={this.handleOrderIdQueryChange}
                  placeholder="Order ID"
                  label="Order ID"
                  type="number"
                  min={0}
                  style={{ width: 150 }}
                />
              </div>
              <div style={{ marginRight: 20 }}>
                <Input
                  value={userId}
                  onChange={this.handleUserQueryChange}
                  placeholder="User ID"
                  label="User ID"
                  type="number"
                  min={0}
                  style={{ width: 150 }}
                />
              </div>
              <Button
                onClick={this.onSearch}
                variant="contained"
                color="primary"
              >
                Search
              </Button>
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <RadioGroup
              value={selectedDay}
              onChange={this.onSelectDay}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              {dayOptions.map((day) => (
                <FormControlLabel
                  key={day}
                  value={day}
                  control={<Radio />}
                  label={day}
                  style={{ marginRight: 50, textTransform: 'capitalize' }}
                />
              ))}
            </RadioGroup>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
            }}
          >
            <ButtonWithTooltip
              onClick={this.goToProductionListPage}
              disabled={!this.canPrintOrders(numberOfOrdersToPrint)}
              hint="Max 500 orders printed at once. Please select individual orders, or limit your search"
            >
              Production list ({numberOfOrdersToPrint})
            </ButtonWithTooltip>
            <div id="production-print" />
            <ButtonWithTooltip
              leftSpace
              onClick={this.goToPackListPage}
              disabled={!this.canPrintOrders(numberOfOrdersToPrint)}
              hint="Max 500 orders printed at once. Please select individual orders, or limit your search"
            >
              Pack list ({numberOfOrdersToPrint})
            </ButtonWithTooltip>
            <div id="pack-print" />
          </div>
        </div>
        <TableCount count={totalResults} text=" - orders in selection" />
        <Paper style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={9} className={classes.totalSumCell}>
                  Total: {totalSum} kr
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell>Customer</TableCell>
                <TableCell className={classes.companyCell}>
                  {type === ORDER_TYPES.pickUp ? 'Company' : 'Recipient'}
                </TableCell>
                <TableSortCell
                  title="Order nr."
                  sortName="id"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Create Date"
                  sortName="createDate"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Delivery Date"
                  sortName="deliveryTime"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                {type === ORDER_TYPES.pickUp && <TableCell>Store</TableCell>}
                <TableCell>Total</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((item) => (
                <TableRow
                  key={item.id}
                  className={cn(
                    item.oheicsErrors &&
                      item.oheicsErrors.length > 0 &&
                      classes.errorRow
                  )}
                  hover
                  style={{
                    transition: 'background-color .3s',
                    cursor: 'pointer',
                  }}
                  onClick={() => this.handleShowOrderDetails(item.id)}
                >
                  <TableCell padding="checkbox">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Checkbox
                        checked={selectedOrders.includes(item.id)}
                        onChange={(e) => this.onSelectOrder(item, e)}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {item.user ? (
                      <div className={classes.textWrapper}>
                        <p>{`${item.user.firstName} ${item.user.lastName}`}</p>
                        <p>{item.user.phone}</p>
                        <p>{item.user.email}</p>
                        {!item.user.firstName &&
                          !item.user.lastName &&
                          !item.user.phone &&
                          !item.user.email && <NoData>No Data</NoData>}
                      </div>
                    ) : (
                      <NoData>No User</NoData>
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {type === ORDER_TYPES.pickUp ? (
                      item.company ? (
                        <div className={classes.textWrapper}>
                          <p>{item.company.name}</p>
                          <p>{item.company.contactPerson}</p>
                          {this.getCompanyAddressInfo(
                            item.company.invoicePhysicalAddress
                          )}
                          <p>{item.company.invoiceDigitalAddress}</p>
                        </div>
                      ) : (
                        ''
                      )
                    ) : item.location && item.delivery ? (
                      <div className={classes.textWrapper}>
                        <p>{item.delivery.contactPerson}</p>
                        <p>{item.deliveryAddress}</p>
                        <p>{item.delivery.deliveryPhone}</p>
                      </div>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {item.oheicsErrors &&
                      item.oheicsErrors.length > 0 && (
                        <div
                          style={{ display: 'inline-block' }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <IconButton
                            className={classes.errorInfo}
                            aria-label="Errors info"
                            onClick={() => this.handleShowOheicsLogs(item.id)}
                          >
                            <InfoIcon />
                          </IconButton>
                        </div>
                      )}
                    #{item.id}
                  </TableCell>
                  <TableCell>{item.createDate}</TableCell>
                  <TableCell>
                    {item.delivery && item.delivery.deliveryTime
                      ? formatOrderDeliveryDateTime(item.delivery.deliveryTime)
                      : ''}
                  </TableCell>
                  {type === ORDER_TYPES.pickUp && (
                    <TableCell>
                      <p className={classes.textWrapper}>
                        {restaurants && restaurants[item.restaurantId]
                          ? restaurants[item.restaurantId].nameVisible
                          : ''}
                      </p>
                    </TableCell>
                  )}
                  <TableCell align="right" className={classes.tableCell}>
                    {item.totalAmount} {item.company ? 'INVOICE' : 'KLARNA'}
                  </TableCell>
                  <TableCell style={{ textTransform: 'capitalize' }}>
                    <OrderStatus
                      status={item.status}
                      type={item.type}
                      onStatusChange={(value) =>
                        this.handleChangeStatus(value, item.id, item.status)
                      }
                    />
                  </TableCell>
                  {ABLE_STATUSES_TO_CANCEL_ORDER[item.status] ? (
                    <TableActionCell
                      action={() => this.handleCancelOrder(item.id)}
                      icon="delete"
                    />
                  ) : (
                    <TableCell />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {orderWithErrors && (
          <OheicsOrdersErrorDialog
            open={openOheicsDialog}
            onClose={this.handleCloseOheicsLogs}
            order={orderWithErrors}
            onSubmit={this.handleSendOheicsOrder}
          />
        )}
        <CustomDialog
          open={openOrderDetails}
          onClose={this.handleCloseOrderDetails}
        >
          <OrderDetails orderId={orderId} />
        </CustomDialog>
      </div>
    );
  }
}

OrdersTypeList.propTypes = {
  classes: PropTypes.object,
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
  }).isRequired,
  selectedRestaurant: PropTypes.number,
  restaurants: PropTypes.object,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  orderIdQuery: PropTypes.string,
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  isRefresh: PropTypes.bool.isRequired,
  onFilter: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onSendOrderOheics: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  getProductionList: PropTypes.func.isRequired,
  getPackList: PropTypes.func.isRequired,
  onChangeOrderStatus: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrdersTypeList);
