import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';

import { S3_URL } from 'shared/consts';

class DynamicLink extends React.PureComponent {
  getLinkUrlByType = () => {
    const { item } = this.props;

    switch (item.type) {
      case 'file': {
        return `${S3_URL}${item.url}`;
      }
      case 'email': {
        return `mailto:${item.url}`;
      }
      case 'phone': {
        return `tel:${item.url}`;
      }
      default: {
        return item.url;
      }
    }
  };

  render() {
    const {
      className,
      activeClassName,
      item,
      children,
      isNavLink,
      style,
    } = this.props;

    return (
      <div style={{ textAlign: item.textAlign, ...style }}>
        {item.type === 'website_url' ? (
          isNavLink ? (
            <NavLink
              className={className}
              activeClassName={activeClassName}
              to={item.url}
            >
              {item.label}
              {children}
            </NavLink>
          ) : (
            <Link className={className} to={item.url}>
              {item.label}
              {children}
            </Link>
          )
        ) : (
          <a
            className={className}
            href={this.getLinkUrlByType()}
            rel="noopener noreferrer"
            target={item.openInNewTab ? '_blank' : '_self'}
          >
            {item.label}
            {children}
          </a>
        )}
      </div>
    );
  }
}

DynamicLink.propTypes = {
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  isNavLink: PropTypes.bool,
  item: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string,
    textAlign: PropTypes.string,
    openInNewTab: PropTypes.bool,
  }).isRequired,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default DynamicLink;
