import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';

import SmallButton from 'components/SmallButton';
import Section from 'components/Section';

import styles from './styles';

class MenuList extends React.PureComponent {
  render() {
    const {
      classes,
      title,
      items,
      withoutDivider,
      onAddNewItem,
      onDragEnd,
      onDeleteItem,
      onOpenModal,
    } = this.props;

    return (
      <Section
        heading={title}
        hint={
          items && items.length > 0 ? 'Drag and drop items to change order' : ''
        }
        withoutDivider={withoutDivider}
        button={
          <SmallButton style={{ cursor: 'pointer' }} onClick={onAddNewItem}>
            + New item
          </SmallButton>
        }
      >
        {items &&
          items.length > 0 && (
            <Paper style={{ marginTop: 15 }}>
              <div className={classes.headItemsWrapper}>
                <div className={cn(classes.headItem, classes.orderCell)} />
                <div className={cn(classes.headItem, classes.nameCell)}>
                  Link label
                </div>
                <div className={cn(classes.headItem, classes.linkCell)}>
                  Link url
                </div>
                <div className={cn(classes.headItem, classes.editCell)} />
                <div className={cn(classes.headItem, classes.deleteCell)} />
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <ul
                      className={cn(classes.menuItemsList)}
                      ref={provided.innerRef}
                    >
                      {items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <li
                              className={cn(
                                classes.menuItemsListItem,
                                snapshot.isDragging &&
                                  classes.menuItemsListItemHover
                              )}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className={classes.menuItemsListItemWrapper}>
                                <div
                                  className={cn(
                                    classes.cell,
                                    classes.orderCell
                                  )}
                                >
                                  {index + 1}
                                </div>
                                <div
                                  className={cn(classes.cell, classes.nameCell)}
                                >
                                  {item.label}
                                </div>
                                <div
                                  className={cn(classes.cell, classes.nameCell)}
                                >
                                  {item.url}
                                </div>
                                <div
                                  className={cn(classes.cell, classes.editCell)}
                                  style={{ paddingRight: 0 }}
                                >
                                  <IconButton
                                    aria-label="Edit"
                                    color="primary"
                                    onClick={() => onOpenModal(item)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </div>
                                <div
                                  className={cn(
                                    classes.cell,
                                    classes.deleteCell
                                  )}
                                >
                                  <IconButton
                                    aria-label="Delete"
                                    color="primary"
                                    onClick={() => onDeleteItem(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                              {item.notAuthLabel && (
                                <React.Fragment>
                                  <p className={classes.description}>
                                    Link for not autherized users:
                                  </p>
                                  <div
                                    className={classes.menuItemsListItemWrapper}
                                    style={{ marginBottom: 10 }}
                                  >
                                    <div
                                      className={cn(
                                        classes.cell,
                                        classes.orderCell
                                      )}
                                    />
                                    <div
                                      className={cn(
                                        classes.cell,
                                        classes.nameCell
                                      )}
                                    >
                                      {item.notAuthLabel}
                                    </div>
                                    <div
                                      className={cn(
                                        classes.cell,
                                        classes.nameCell
                                      )}
                                    >
                                      {item.notAuthUrl}
                                    </div>
                                    <div
                                      className={cn(
                                        classes.cell,
                                        classes.editCell
                                      )}
                                      style={{ paddingRight: 0 }}
                                    />
                                    <div
                                      className={cn(
                                        classes.cell,
                                        classes.deleteCell
                                      )}
                                    />
                                  </div>
                                </React.Fragment>
                              )}
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </Paper>
          )}
      </Section>
    );
  }
}

MenuList.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
  withoutDivider: PropTypes.bool,
  onAddNewItem: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
};

export default withStyles(styles)(MenuList);
