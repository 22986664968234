import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

class TableCount extends React.PureComponent {
  render() {
    const { count, text } = this.props;
    return (
      <Typography
        variant="caption"
        color="inherit"
        style={{ margin: '20px 0' }}
      >
        {count} {text || 'items'}
      </Typography>
    );
  }
}

TableCount.propTypes = {
  count: PropTypes.number.isRequired,
  text: PropTypes.string,
};

export default TableCount;
