import { styleConsts } from 'shared/styles/consts';
import { tablet, laptop } from 'shared/styles/breakpoints';
import { generalStyles } from '../generalStyles';

const styles = {
  blockTitle: {
    extend: generalStyles.blockTitle,
  },
  paragraph: {
    marginBottom: 26,
    extend: generalStyles.paragraph,
  },
  list: {
    marginLeft: -styleConsts.pagePadding,
    marginRight: -styleConsts.pagePadding,
    boxSizing: 'border-box',
  },
  listItem: {
    padding: [32, styleConsts.pagePadding],
    boxSizing: 'border-box',
  },
  flexColumn: {},
  [`@media (min-width: ${tablet})`]: {
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    list: {
      minHeight: 330,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 80,
      display: 'flex',
      flexWrap: 'wrap',
    },
    listItem: {
      width: (props) => `${100 / props.itemsPerRow}%`,
    },
  },
  [`@media (min-width: ${laptop})`]: {
    list: {
      minHeight: 400,
      marginBottom: 160,
    },
    listItem: {
      padding: 40,
    },
  },
};

export default styles;
