import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import Input from '@material-ui/core/TextField';

import { FilePond, File, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

let isMounted;

class FileUpload extends React.PureComponent {
  componentDidMount() {
    isMounted = true;
  }

  componentWillUnmount() {
    isMounted = false;
  }

  handleUpload = (file, filename, cb, errorCb) => {
    const { onFileUpload, onChange } = this.props;
    onFileUpload(file, filename)
      .then(() => {
        onChange({ target: { value: filename } });
        toastr.success('Success!', 'File has been successfully uploaded');
        cb && cb();
      })
      .catch((error) => {
        alert(error);
        toastr.error('Error!', 'Something went wrong');
        errorCb && errorCb();
      });
  };

  render() {
    const {
      file,
      onChange,
      placeholder,
      label,
      uploadURL,
      maxSize,
      disabled,
    } = this.props;

    return (
      <div>
        <Input
          value={file}
          disabled={true}
          onChange={onChange}
          placeholder={placeholder || 'Image'}
          label={label || 'Image'}
          margin="normal"
          style={{ width: '100%' }}
        />
        <FilePond
          ref={(ref) => (this.pond = ref)}
          allowMultiple={false}
          maxFileSize={maxSize}
          disabled={disabled}
          server={{
            process: (fieldName, file, metadata, load, error) => {
              this.handleUpload(file, file.name, load, error);
            },
            url: uploadURL,
            revert: (uniqueFileId, load) => {
              onChange({ target: { value: '' } });
              load();
            },

            remove: (source, load) => {
              onChange({ target: { value: '' } });
              load();
            },
            restore: null,
            load: '/',
            fetch: null,
          }}
          onupdatefiles={(fileItems) => {
            isMounted &&
              this.setState({
                files: fileItems.map((fileItem) => fileItem.file),
              });
          }}
        >
          {file && <File src={file} origin="local" />}
        </FilePond>
      </div>
    );
  }
}

FileUpload.propTypes = {
  file: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  uploadURL: PropTypes.string.isRequired,
  maxSize: PropTypes.string.isRequired,
};

export default FileUpload;
