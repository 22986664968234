import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { getRestaurants } from 'actions/restaurants';
import { hideIfNoData } from 'utils/enhancers';

import Restaurants from './Restaurants';

const mapDispatchToProps = {
  onInit: getRestaurants,
};

const defaultData = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', 'name'),
  withState('sortDirection', 'setSortDirection', 'asc')
);

const refresh = (props) => {
  const { sortName, sortDirection, onInit, setInitialData } = props;
  return onInit({
    sortName,
    sortDirection,
  }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    return refresh({ ...props, sortName, sortDirection });
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  defaultData,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(Restaurants);
