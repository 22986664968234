import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import ActionHeading from 'components/ActionHeading';
import TableActionCell from 'components/TableActionCell';
import SmallButton from 'components/SmallButton';

import MenuItemsDropdown from 'components/MenuItemsDropdown';

class MenuItems extends React.PureComponent {
  state = {
    selectedMenuItem: null,
  };

  onSelectMenuItem = (menuItem) => {
    this.setState({ selectedMenuItem: menuItem });
  };

  handleSubmit = () => {
    const { selectedMenuItem } = this.state;
    if (selectedMenuItem) {
      this.props.onAdd(selectedMenuItem);
      this.setState({ selectedMenuItem: null });
    }
  };

  render() {
    const { items, onDelete } = this.props;
    const { selectedMenuItem } = this.state;
    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableActionCell
                    action={() => onDelete(item.id)}
                    icon="delete"
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <div style={{ marginTop: 40 }}>
          <ActionHeading>Add menu item</ActionHeading>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <MenuItemsDropdown
              value={selectedMenuItem}
              onChange={this.onSelectMenuItem}
              excludeIds={items.map(({ id }) => id)}
            />
            <SmallButton
              onClick={this.handleSubmit}
              leftSpace
              id="add-menu-item-to-group-button"
            >
              Add
            </SmallButton>
          </div>
        </div>
      </div>
    );
  }
}

MenuItems.propTypes = {
  items: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default MenuItems;
