import { compose, withHandlers, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import { getDeliveryCosts, deleteDeliveryCost } from 'actions/settings';
import { showLoading, hideLoading } from 'actions/ui';

import { wrapWithSideEffect } from 'utils';
import { hideIfNoData } from 'utils/enhancers';

import Delivery from './Delivery';

const mapDispatchToProps = {
  onInit: getDeliveryCosts,
  onDelete: deleteDeliveryCost,
  showLoading,
  hideLoading,
};

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const defaultData = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', 'zoneName'),
  withState('sortDirection', 'setSortDirection', 'asc')
);

const refresh = (props) => {
  const { onInit, sortName, sortDirection, setInitialData } = props;
  return wrapWithProgress(props)(
    onInit({
      sortName,
      sortDirection,
    }).then(setInitialData)
  );
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    return refresh({ ...props, sortName, sortDirection });
  },
  onDelete: (props) => (event) =>
    props.onDelete(event).then(() => refresh(props)),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  defaultData,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(Delivery);
