import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import styles from './styles';

class ColorPickerLabel extends React.PureComponent {
  render() {
    const { color, classes, onClick } = this.props;

    return (
      <div className={classes.swatch} onClick={onClick}>
        <div className={classes.color} style={{ backgroundColor: color }} />
      </div>
    );
  }
}

ColorPickerLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(ColorPickerLabel);
