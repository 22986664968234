import React from 'react';
import PropTypes from 'prop-types';

import Autosuggest from 'components/Autosuggest';
import Select from 'components/Select';

import { FIELDS_TO_SEARCH } from 'consts/customers';

class UsersAutosuggest extends React.PureComponent {
  state = {
    suggestions: [],
    searchParam: FIELDS_TO_SEARCH[0],
  };

  handleSearchParamChange = (searchParam) => {
    this.setState({ searchParam });
    this.props.onSearchParamChange();
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const { searchParam } = this.state;
    this.props
      .onSearch({
        [searchParam.value]: searchParam.value === 'ids' ? [value] : value,
      })
      .then((result) => {
        this.setState({
          suggestions: result
            ? (result.items || []).map((item) => ({
                id: item.id,
                value:
                  searchParam.value === 'ids'
                    ? item.id.toString()
                    : item[searchParam.value],
                customer: item,
              }))
            : [],
        });
      });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { onChange } = this.props;

    onChange(suggestion.customer);
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.value;
  };

  render() {
    const { suggestions, searchParam } = this.state;
    const { query, onQueryChange } = this.props;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'inline-block', marginRight: 20 }}>
          <Select
            options={FIELDS_TO_SEARCH}
            placeholder="Field to search"
            value={searchParam}
            onChange={this.handleSearchParamChange}
            label="Field to search"
            width={170}
          />
        </div>
        <div style={{ marginTop: 19 }}>
          <Autosuggest
            value={query}
            label=""
            placeholder="Search a user"
            suggestions={suggestions.slice(0, 10)}
            handleSuggestionsFetchRequested={
              this.handleSuggestionsFetchRequested
            }
            handleSuggestionsClearRequested={
              this.handleSuggestionsClearRequested
            }
            getSuggestionValue={this.getSuggestionValue}
            onChange={onQueryChange}
            onSuggestionSelected={this.onSuggestionSelected}
          />
        </div>
      </div>
    );
  }
}

UsersAutosuggest.propTypes = {
  query: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialData: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
  }),
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onSearchParamChange: PropTypes.func.isRequired,
};

export default UsersAutosuggest;
