import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { toastr } from 'react-redux-toastr';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import PageHeading from 'components/PageHeading';
import PageHeadingTitle from 'components/PageHeadingTitle';
import Section from 'components/Section';

import OrderItems from './OrderItems';
import DeliveryInfo from './DeliveryInfo';

import { ORDER_TYPES_LABEL, ORDER_STATUSES_LABEL } from 'consts/orders';

import {
  changeSelectedGroupsCount,
  validateError,
  prepareDataToSubmit,
} from './utils';
import { modifiSchedule, getTimeSlots } from './DeliveryInfo/utils';

import {
  getStartOfDeliveryDate,
  getHour,
  getMinute,
  toSafariSuportedFormat,
} from 'utils/date';
import { DEFAULT_MIN_DELIVERY_TIME } from 'consts';

import styles from './styles';

const stateFromProps = ({ initialData = {} }) => {
  const deliveryDate = new Date(
    toSafariSuportedFormat((initialData.delivery || {}).deliveryTime)
  );
  const minTime =
    initialData.delivery && initialData.delivery.deliveryCost
      ? initialData.delivery.deliveryCost.minimumTime
      : DEFAULT_MIN_DELIVERY_TIME;
  return {
    orderItems: initialData.orderItems || [],
    deliveryPrice: parseFloat(initialData.deliveryPrice, 10) || 0,
    deliveryTime: deliveryDate,
    selectedHour: getHour(deliveryDate),
    selectedMinute: getMinute(deliveryDate),
    discount: initialData.discountAmount,
    compliment: initialData.compliment,
    oldCompliment: initialData.oldCompliment,
    minTime,
    schedule: modifiSchedule({
      day: deliveryDate,
      minTime,
      restaurant: initialData.restaurant,
    }),
    delivery: {
      locationId: (initialData.delivery || {}).locationId,
      name: (initialData.delivery || {}).contactPerson,
      phone: (initialData.delivery || {}).deliveryPhone,
      address: ((initialData.delivery || {}).location || {}).streetAddress,
      postcode: ((initialData.delivery || {}).location || {}).postcode,
      notice: (initialData.delivery || {}).notice,
    },
    deliveryWasChanged: false,
    comment: initialData.comment || '',
  };
};

class OrderDetailsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  componentWillReceiveProps = (newProps) => {
    if (newProps.initialData !== this.props.initialData) {
      this.setState({
        ...stateFromProps(newProps),
      });
    }
  };

  onMenuItemChange = (item, numberOfItems) => {
    const { orderItems } = this.state;
    const itemId = item.id;
    const newOrderItems =
      numberOfItems === 0
        ? orderItems.filter((item) => item.id !== itemId)
        : orderItems.map((item) => {
            if (item.id === itemId) {
              return {
                ...item,
                selectedGroupItems: changeSelectedGroupsCount(
                  item,
                  numberOfItems - item.numberOfItems
                ).map((sGI) => {
                  return {
                    ...sGI,
                    values: sGI.values.filter((value) => !!value.numberOfItems),
                  };
                }),
                numberOfItems,
              };
            }
            return item;
          });

    this.setState({
      orderItems: newOrderItems,
    });
  };

  onMenuItemAdd = (menuItem) => {
    this.setState({
      orderItems: [
        ...this.state.orderItems,
        {
          id: menuItem.id,
          item: menuItem,
          numberOfItems: 1,
          selectedGroupItems: [],
        },
      ],
    });
  };

  onMenuItemDelete = (id) => {
    const { orderItems } = this.state;

    if (orderItems && orderItems.length === 1) {
      toastr.error('Error!', 'Should be at least one order item');
      return;
    }

    this.setState({
      orderItems: this.state.orderItems.filter((oI) => oI.id !== id),
    });
  };

  onChangeDeliveryPrice = (value) => {
    this.setState({
      deliveryPrice: value,
    });
  };

  onChangeDeliveryTime = (time) => {
    const { minTime } = this.state;
    const newSchedule = modifiSchedule({
      day: time,
      minTime,
      restaurant: this.props.initialData.restaurant,
    });
    const timeSlots = getTimeSlots(newSchedule);
    const firstTimeSlot = timeSlots[0];
    this.setState({
      deliveryTime: getStartOfDeliveryDate(time),
      selectedHour: getHour(firstTimeSlot),
      selectedMinute: getMinute(firstTimeSlot),
      schedule: newSchedule,
    });
  };

  onChangeTime = (hours, minutes) => {
    this.setState({
      selectedHour: hours,
      selectedMinute: minutes,
    });
  };

  onChangeCompliment = ({ target: { value } }) => {
    this.setState({
      compliment: value,
    });
  };

  onChangeComment = ({ target: { value } }) => {
    this.setState({
      comment: value,
    });
  };

  onChangeDeliveryAddress = (fieldName) => ({ target: { value } }) => {
    this.setState({
      deliveryWasChanged: true,
      delivery: {
        ...this.state.delivery,
        [fieldName]: value,
      },
    });
  };

  onUpdateOrder = () => {
    const { ...data } = this.state;
    const { initialData, onSubmit } = this.props;

    const errorMessage = validateError(data, initialData);
    if (errorMessage) {
      toastr.error('Error!', errorMessage);
    } else {
      const newData = prepareDataToSubmit(data, initialData);
      onSubmit({ orderId: initialData.id, data: newData }).then(() => {
        toastr.success('Success!', 'Order has been updated');
      });
    }
  };

  handleSubmit = () => {
    const { ...data } = this.state;
    const { initialData } = this.props;
    const errorMessage = validateError(data, initialData);

    if (errorMessage) {
      toastr.error('Error!', errorMessage);
    } else {
      if (data.deliveryWasChanged) {
        this.saveDeliveryAddress();
        return;
      }
      this.onUpdateOrder();
    }
  };

  saveDeliveryAddress = () => {
    const { ...data } = this.state;
    const { initialData, onSaveDeliveryAddress } = this.props;

    onSaveDeliveryAddress({
      ...data.delivery,
      userId: initialData.userId,
      city: ((initialData.delivery || {}).location || {}).city,
      latitude: ((initialData.delivery || {}).location || {}).latitude,
      longitude: ((initialData.delivery || {}).location || {}).longitude,
    }).then((result) => {
      this.setState({
        delivery: {
          ...this.state.delivery,
          locationId: result.locationId,
        },
        minTime: result.deliveryCost.minimumTime,
        schedule: modifiSchedule({
          day: data.deliveryTime,
          minTime: result.deliveryCost.minimumTime,
          restaurant: initialData.restaurant,
        }),
        deliveryWasChanged: false,
      });
      // reset delivery time if delivery min time was changes
      if (
        initialData.delivery.deliveryCost.minimumTime !==
        result.deliveryCost.minimumTime
      ) {
        this.setState({
          deliveryTime: null,
          selectedHour: null,
          selectedMinute: null,
        });
      }
      this.onUpdateOrder();
    });
  };

  render() {
    const { id, classes, initialData: order } = this.props;
    const {
      orderItems,
      deliveryTime,
      deliveryPrice,
      selectedHour,
      selectedMinute,
      minTime,
      schedule,
      discount,
      compliment,
      oldCompliment,
      delivery,
      comment,
    } = this.state;

    return (
      <div>
        <PageHeading
          title={
            id ? (
              <PageHeadingTitle
                text="Order ID"
                highlightedText={`#${order.id}`}
              />
            ) : (
              'New Order'
            )
          }
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        <div className={classes.formWrapper}>
          <div className={classes.formBlock}>
            <div>
              <Input
                value={ORDER_TYPES_LABEL[order.type]}
                placeholder="Type"
                label="Type"
                margin="normal"
                style={{ width: 300 }}
                disabled={true}
              />
            </div>
            <div>
              <Input
                value={ORDER_STATUSES_LABEL[order.status]}
                placeholder="Status"
                label="Status"
                margin="normal"
                style={{ width: 300 }}
                disabled={true}
              />
            </div>
            <div>
              <Input
                value={order.createDate || ''}
                placeholder="Create date"
                label="Create date"
                margin="normal"
                style={{ width: 300 }}
                disabled={true}
              />
            </div>
            <div>
              <Input
                value={discount}
                placeholder="Discount"
                label="Discount"
                margin="normal"
                style={{ width: 300 }}
                disabled={true}
              />
            </div>
            <div>
              <InputLabel
                htmlFor="comment"
                style={{
                  marginTop: 15,
                  marginBottom: -10,
                  fontSize: '0.8rem',
                  lineHeight: '1.3',
                  display: 'block',
                }}
              >
                Comment (The reason why the order was changed)
              </InputLabel>
              <Input
                multiline
                value={comment}
                onChange={this.onChangeComment}
                placeholder=""
                label=""
                margin="normal"
                variant="outlined"
                maxLength="400"
                inputProps={{
                  style: { minHeight: 120, minWidth: 300 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          <div className={classes.formBlock}>
            <div>
              <InputLabel
                htmlFor="old-compliment"
                className={classes.complimentLabel}
              >
                Compliment applied to the order (in %)
              </InputLabel>
              <Input
                id="old-compliment"
                value={oldCompliment}
                placeholder="Compliment (in %)"
                margin="normal"
                style={{ width: 300 }}
                disabled={true}
              />
            </div>
            <div>
              <InputLabel
                htmlFor="compliment"
                className={classes.complimentLabel}
              >
                Compliment which will be applied to the order after update (in
                %)
              </InputLabel>
              <Input
                id="compliment"
                value={compliment}
                placeholder="Compliment (in %)"
                margin="normal"
                type="number"
                min={0}
                style={{ width: 300 }}
                onChange={this.onChangeCompliment}
              />
            </div>
            <div>
              <Input
                value={order.totalAmount}
                placeholder="Total amount"
                label="Total amount"
                margin="normal"
                disabled={true}
                style={{ width: 300 }}
              />
            </div>
          </div>
        </div>
        <Section heading="Order items">
          <OrderItems
            restaurantId={order.restaurantId}
            items={orderItems}
            onMenuItemChange={this.onMenuItemChange}
            onMenuItemAdd={this.onMenuItemAdd}
            onMenuItemDelete={this.onMenuItemDelete}
          />
        </Section>
        <Section heading="Delivery info">
          <DeliveryInfo
            restaurant={this.props.initialData.restaurant}
            minDeliveryTime={minTime}
            deliveryTime={deliveryTime}
            deliveryPrice={deliveryPrice}
            onChangePrice={this.onChangeDeliveryPrice}
            onChangeDate={this.onChangeDeliveryTime}
            onChangeTime={this.onChangeTime}
            selectedHour={selectedHour}
            selectedMinute={selectedMinute}
            schedule={schedule}
            delivery={delivery}
            onChangeDeliveryAddress={this.onChangeDeliveryAddress}
          />
        </Section>
      </div>
    );
  }
}

OrderDetailsPage.propTypes = {
  id: PropTypes.number.isRequired,
  classes: PropTypes.object,
  initialData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    restaurant: PropTypes.object.isRequired,
  }),
  onSaveDeliveryAddress: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default injectSheet(styles)(OrderDetailsPage);
