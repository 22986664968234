import React from 'react';

import FooterSettings from 'components/FooterSettings';

class FooterDynamicSocialIcons extends React.PureComponent {
  render() {
    return <FooterSettings settingsType="social-icons" />;
  }
}

export default FooterDynamicSocialIcons;
