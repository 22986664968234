import React from 'react';
import Typography from '@material-ui/core/Typography';

class ActionHeading extends React.PureComponent {
  render() {
    return (
      <Typography
        variant="h6"
        color="secondary"
        style={{ marginBottom: 0, fontSize: 16 }}
        {...this.props}
      />
    );
  }
}

export default ActionHeading;
