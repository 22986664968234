import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import EditLinkModal from './EditLinkModal';

import styles from '../ToolbarButtonsBar/styles';

class ToolbarLinkBar extends PureComponent {
  removeLink = () => {
    const { onChange } = this.props;
    onChange('unlink');
  };

  handleSave = (linkTitle, linkTarget, linkTargetOption) => {
    this.props.onChange(
      'link',
      linkTitle,
      linkTarget,
      linkTargetOption || this.props.config.defaultTargetOption
    );
    this.props.openModalbyType('');
  };

  handleClose = () => {
    this.props.openModalbyType('');
  };

  handleOpen = () => {
    this.props.openModalbyType('link');
  };

  render() {
    const {
      config,
      currentState: { link, selectionText },
      openedModalType,
      classes,
      className,
    } = this.props;

    return (
      <div>
        {config.options.map((type) => {
          const Icon = config[type].icon;
          return (
            <Button
              className={className}
              key={type}
              aria-label={type}
              onClick={type === 'link' ? this.handleOpen : this.removeLink}
              disabled={type === 'unlink' && !link}
              classes={{ root: classes.toolbarButon }}
            >
              {typeof Icon === 'object' ? Icon : <img src={Icon} alt={type} />}
            </Button>
          );
        })}
        <EditLinkModal
          open={openedModalType === 'link'}
          link={link}
          selectionText={selectionText}
          handleClose={this.handleClose}
          handleSave={this.handleSave}
        />
      </div>
    );
  }
}

ToolbarLinkBar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.object,
  config: PropTypes.object,
  currentState: PropTypes.object,
  onChange: PropTypes.func,
  openedModalType: PropTypes.string,
  openModalbyType: PropTypes.func,
};

export default injectSheet(styles)(ToolbarLinkBar);
