import apiRequest from 'sagas/utils/apiRequest';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';

/**
 * Retrieve attributes related to order
 * @param {Object} {name} - attribute name
 * @return {Object} Response object.
 */
export function* getOrderAttributes({ name }) {
  try {
    const nameParam = name ? `label=${name}` : '';
    const url = `/order-attributes?${nameParam}`;
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const payload = yield apiRequest(url, options);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
    return;
  }
}

/**
 * Retrieve order attributes values
 * @param {Object} {attributeId, orderId} - attribute id, array or orders ids
 * @return {Object} Response object.
 */
export function* getOrderAttributesValues({
  attributeId,
  orderId,
  withoutLimit = false,
}) {
  try {
    const params = [];
    if (withoutLimit) {
      params.push('limit=0');
    }
    if (attributeId) params.push(`attributeId=${attributeId}`);
    if (orderId) params.push(`orderId=${orderId.join(',')}`);

    const url = `/order-attribute-values?${params.join('&')}`;
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const payload = yield apiRequest(url, options);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
    return;
  }
}

export function* deleteOrderAttributeValueById(attrValueId) {
  const url = `/order-attribute-values/${attrValueId}`;
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);
  return payload;
}

export function* updateOrderAttributeValue({ attrName, orderId, value }) {
  try {
    let attrId;
    const attrResponse = yield getOrderAttributes({
      name: attrName,
    });
    if (attrResponse && attrResponse.items && attrResponse.items.length) {
      attrId = attrResponse.items[0].id;
    }
    const attrValues = yield getOrderAttributesValues({
      attributeId: attrId,
      orderId: [orderId],
    });
    const attrValueId =
      attrValues && attrValues.items && attrValues.items.length > 0
        ? attrValues.items[0].id
        : undefined;

    if (attrValueId && !value) {
      yield deleteOrderAttributeValueById(attrValueId);
      return;
    }
    if (value) {
      const url = attrValueId
        ? `/order-attribute-values/${attrValueId}`
        : '/order-attribute-values';
      const options = {
        method: attrValueId ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          attributeId: attrId,
          value,
          orderId,
        }),
      };
      const payload = yield apiRequest(url, options);
      return payload;
    }
    return;
  } catch (error) {
    showErrorOnErrorResponse(error);
    return;
  }
}

/**
 * Retrieve menu attributes
 * @return {Array} Array of attribures.
 */
export function* getMenuAttributes() {
  const url = '/menu-attributes';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);
  return payload.items;
}

/**
 * Retrieve menu attributes
 * @return {Array} Array of attribures.
 */
export function* getCategoryAttributes() {
  const url = '/category-attributes';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);
  return payload.items;
}

/**
 * Retrieve restaurant attributes.
 * @param {Object} { ids, name } - attributes ids joined with ",", name of attribute to fetch.
 * @return {Array} Response items.
 */
export function* getRestaurantAttributes({ ids, name } = {}) {
  const idsParam = ids ? `id=${ids}` : '';
  const nameParam = name ? `&name=${name}` : '';
  const url = `/restaurant-attributes?${idsParam}${nameParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);
  return payload.items;
}

export function* prepareRestaurantAttributes(values = {}) {
  const attributes = yield getRestaurantAttributes();
  return Object.keys(values).map((label) => ({
    restaurantAttributeId: (attributes.find((a) => a.label === label) || {}).id,
    value: values[label],
  }));
}

export function* prepareRestaurantJSONAttributes(values = {}) {
  const attributes = yield getRestaurantAttributes();
  return Object.keys(values).map((label) => ({
    restaurantAttributeId: (attributes.find((a) => a.label === label) || {}).id,
    value: JSON.stringify(values[label]),
  }));
}
