import React from 'react';
import Input from '@material-ui/core/TextField';

class CurrencyInput extends React.PureComponent {
  onKeyDown = (e) => {
    //minus sign
    if (e.keyCode === 189) {
      e.preventDefault();
    }
  };

  onChange = (e) => {
    const re = /^\d+(?:\.\d{1,2})?$/;
    const value = e.target.value;
    if (value === '' || re.test(value)) {
      const { onChange } = this.props;
      this.setState({ value });
      onChange && onChange(e);
    }
  };

  render() {
    return (
      <Input
        {...this.props}
        onKeyDown={this.onKeyDown}
        onChange={this.onChange}
        type="number"
        inputProps={{
          min: 0,
          style: { textAlign: 'right' },
        }}
      />
    );
  }
}

export default CurrencyInput;
