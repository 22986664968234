export const GET_TAG_GROUPS_REQUEST = 'GET_TAG_GROUPS_REQUEST';
export const GET_TAG_GROUPS_SUCCESS = 'GET_TAG_GROUPS_SUCCESS';
export const GET_TAG_GROUPS_FAILURE = 'GET_TAG_GROUPS_FAILURE';

export const GET_TAG_GROUP_BY_ID_REQUEST = 'GET_TAG_GROUP_BY_ID_REQUEST';
export const GET_TAG_GROUP_BY_ID_SUCCESS = 'GET_TAG_GROUP_BY_ID_SUCCESS';
export const GET_TAG_GROUP_BY_ID_FAILURE = 'GET_TAG_GROUP_BY_ID_FAILURE';

export const GET_TAGS_BY_GROUPID_REQUEST = 'GET_TAGS_BY_GROUPID_REQUEST';
export const GET_TAGS_BY_GROUPID_SUCCESS = 'GET_TAGS_BY_GROUPID_SUCCESS';
export const GET_TAGS_BY_GROUPID_FAILURE = 'GET_TAGS_BY_GROUPID_FAILURE';

export const SAVE_TAG_REQUEST = 'SAVE_TAG_REQUEST';
export const SAVE_TAG_SUCCESS = 'SAVE_TAG_SUCCESS';
export const SAVE_TAG_FAILURE = 'SAVE_TAG_FAILURE';

export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';

export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';
