export const LANDING_SECTION_TYPES = {
  article: 'article',
  cart: 'cart',
  list: 'list',
};

export const LINK_TYPES_VALUES = {
  url: 'url',
  websiteUrl: 'website_url',
  email: 'email',
  phone: 'phone',
  file: 'file',
};
