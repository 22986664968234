import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import cn from 'classnames';

import RichTextMarckup from 'shared/components/RichTextMarckup';
import { S3_URL } from 'shared/consts';

import LandingLink from '../LandingLink';

import { renderMultilineText } from '../utils';

import styles from './styles';

class ArticleSection extends React.PureComponent {
  render() {
    const { classes, item, simpleSection } = this.props;

    const TitleTag = item && item.titleHTag ? item.titleHTag : 'h2';

    return (
      <section
        className={cn(
          item.withBackgroundColor && classes.withBackground,
          simpleSection && classes.simpleSection
        )}
        style={{ color: item.textColor, textAlign: item.textAlign }}
      >
        <div
          className={cn(
            !simpleSection && classes.flexWrapper,
            item.imagePosition === 'right' && classes.flexReverse
          )}
        >
          <div className={cn(classes.col, classes.imgCol)}>
            <img src={`${S3_URL}${item.imageURL}`} alt={item.imageSEOAlt} />
          </div>
          <div className={cn(classes.col, classes.textCol)}>
            <div>
              {item.title && (
                <TitleTag
                  className={classes.title}
                  style={{ color: item.titleColor, textAlign: item.titleAlign }}
                >
                  {renderMultilineText(item.title)}
                </TitleTag>
              )}
              {item.description && (
                <RichTextMarckup
                  value={item.description}
                  component={'p'}
                  className={cn(classes.paragraph, classes.paragraphGray)}
                />
              )}
            </div>
            {item.link &&
              item.link.url && (
                <LandingLink item={item.link} color={item.textColor} />
              )}
          </div>
        </div>
      </section>
    );
  }
}

ArticleSection.propTypes = {
  classes: PropTypes.object.isRequired,
  simpleSection: PropTypes.bool,
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    link: PropTypes.object,
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    imageURL: PropTypes.string,
    imageSEOAlt: PropTypes.string,
    imagePosition: PropTypes.string,
  }),
};

export default injectSheet(styles)(ArticleSection);
