import {
  EditorState,
  RawDraftContentState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';

/**
 * Creates draft-js EditorState from string or raw state.
 * @param { RawDraftContentState | string } value input value,
 * EditorState to be created from.
 * @return { EditorState } draft-js EditorState.
 */
export const createEditorStateObj = (value) => {
  if (typeof value === 'string') {
    if (value.match(/<\w+>|<\w+ \/>|<\/\w+>/gi)) {
      if (value.match(/<br \/>/gi)) {
        value = value.replace(/<br \/>/gi, '</p><p>');
        value = ''.concat('<p>', value, '</p>');
      }

      const blocksFromHTML = convertFromHTML(value);

      return EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        )
      );
    }
    return EditorState.createWithContent(ContentState.createFromText(value));
  } else if (value instanceof Object) {
    try {
      return EditorState.createWithContent(convertFromRaw(value));
    } catch (error) {
      console.warn("Can't read article");
    }
  }
  return EditorState.createEmpty();
};

/**
 * Convertes draft-js EditorState to RawDraftContentState,
 * that is useful for saving data on Beck End.
 * @param { EditorState } value
 * @return { RawDraftContentState }
 */
export const getRawEditorState = (value: EditorState): RawDraftContentState =>
  convertToRaw(value.getCurrentContent());

export const findDefference = (a1, a2) =>
  a1.filter((x) => !a2.includes(x)).concat(a2.filter((x) => !a1.includes(x)));
