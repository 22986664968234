import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  defaultProps,
} from 'recompose';
import { connect } from 'react-redux';

import {
  getOrders,
  changeOrderStatus,
  sendManualOheicsOrder,
  changeMultipleOrdersStatus,
} from 'actions/orders';
import {
  getPaginatedOrdersForProductionList,
  getPaginatedOrdersForPackList,
  getOrdersCountForPrint,
} from 'actions/printList';
import { showLoading, hideLoading } from 'actions/ui';
import { setFilters, clearFilters } from 'actions/redirect';
import { getRestaurantsDictionary } from 'actions/restaurants';

import { wrapWithSideEffect } from 'utils';
import { hideIfNoData } from 'utils/enhancers';

import { ORDER_STATUSES, ABLE_TYPES_TO_PRINT } from 'consts/orders';

import OrdersListPage from './OrdersListPage';

const PAGING_LIMIT = 20;

const STATUS = `${ORDER_STATUSES.paid},${ORDER_STATUSES.done},${
  ORDER_STATUSES.inProduction
},${ORDER_STATUSES.readyForShipment},${ORDER_STATUSES.cancelled}`;

const defaultParams = {
  sortName: 'id',
  sortDirection: 'desc',
  status: STATUS,
};

const mapStateToProps = ({ redirect }) => ({
  filters: redirect.filters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit: (args) => dispatch(getOrders(args)),
  onCancel: (args) => dispatch(changeOrderStatus(args)),
  onChangeStatus: (args) => dispatch(changeMultipleOrdersStatus(args)),
  onSendOrderOheics: (id) => dispatch(sendManualOheicsOrder(id)),
  getRestaurants: () => dispatch(getRestaurantsDictionary()),
  getOrdersCountForPrint: (args) => dispatch(getOrdersCountForPrint(args)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
  setFilters: (args) => dispatch(setFilters(ownProps.location, args)),
  clearFilters: () => dispatch(clearFilters()),
  getProductionList: (args) =>
    dispatch(getPaginatedOrdersForProductionList(args)),
  getPackList: (args) => dispatch(getPaginatedOrdersForPackList(args)),
  onChangeOrderStatus: (args) => dispatch(changeOrderStatus(args)),
});

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', defaultParams.sortName),
  withState('sortDirection', 'setSortDirection', defaultParams.sortDirection),
  withState('selectedRestaurant', 'setSelectedRestaurant', null),
  withState('startTime', 'setStartTime', null),
  withState('endTime', 'setEndTime', null),
  withState('status', 'setStatus'),
  withState('orderType', 'setOrderType', null),
  withState('page', 'setPage', 0),
  withState('ordersCountToPrint', 'setOrdersCountToPrint', 0),
  withState('userId', 'setUserId', null),
  withState('orderIdQuery', 'setOrderIdQuery', ''),
  withState('restaurants', 'setRestaurants', {}),
  defaultProps({
    pageLimit: PAGING_LIMIT,
  })
);

const refresh = (props) => {
  const {
    sortName,
    sortDirection,
    selectedRestaurant,
    startTime,
    endTime,
    status,
    page,
    pageLimit,
    orderType,
    onInit,
    setInitialData,
    userId,
    orderIdQuery,
  } = props;
  return wrapWithProgress(props)(
    onInit({
      sortName,
      sortDirection,
      restaurantId: selectedRestaurant,
      startTime,
      endTime,
      status: status || defaultParams.status,
      page,
      pageLimit,
      orderType,
      userId,
      orderId: orderIdQuery,
      withOheicsLogs: true,
    }).then((data) => {
      setInitialData(data);
      if (!orderType || ABLE_TYPES_TO_PRINT[orderType]) {
        props
          .getOrdersCountForPrint({
            restaurantId: selectedRestaurant,
            startTime,
            endTime,
            status: status || defaultParams.status,
            orderType: orderType
              ? orderType
              : Object.keys(ABLE_TYPES_TO_PRINT).join(','),
            getOrdersByDelivery: false,
            userId,
            orderId: orderIdQuery,
            getAll: false,
          })
          .then((count) => {
            props.setOrdersCountToPrint(typeof count === 'number' ? count : 0);
          });
      } else {
        props.setOrdersCountToPrint(0);
      }
    })
  );
};

const handlers = withHandlers({
  onInit: (props) => () => {
    const { location, filters } = props;
    if (location && filters && location.pathname !== filters.url) {
      props.clearFilters();
      return refresh(props);
    }
    const {
      sortName,
      sortDirection,
      selectedRestaurant,
      startTime,
      endTime,
      status,
      page,
      orderType,
      userId,
      orderIdQuery,
    } = props.filters;
    if (sortName) props.setSortName(sortName);
    if (sortDirection) props.setSortDirection(sortDirection);
    if (selectedRestaurant) props.setSelectedRestaurant(selectedRestaurant);
    if (startTime) props.setStartTime(startTime);
    if (endTime) props.setEndTime(endTime);
    if (status) props.setStatus(status);
    if (orderType) props.setOrderType(orderType);
    if (page) props.setPage(page);
    if (userId) props.setUserId(userId);
    if (orderIdQuery) props.setOrderIdQuery(orderIdQuery);
    return refresh({
      ...props,
      sortName: sortName || defaultParams.sortName,
      sortDirection: sortDirection || defaultParams.sortDirection,
      selectedRestaurant,
      startTime,
      endTime,
      status: status,
      orderType,
      page: page || 0,
      userId: userId || '',
      orderIdQuery: orderIdQuery || '',
    });
  },
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setPage(0);
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    props.setFilters({
      sortName,
      sortDirection,
      selectedRestaurant: props.selectedRestaurant,
      startTime: props.startTime,
      endTime: props.endTime,
      status: props.status,
      orderType: props.orderType,
      page: 0,
      userId: props.userId,
      orderIdQuery: props.orderIdQuery,
    });
    return refresh({
      ...props,
      sortName,
      sortDirection,
      status: props.status,
      page: 0,
    });
  },
  onPage: (props) => ({ page }) => {
    props.setPage(page);
    props.setFilters({
      sortName: props.sortName,
      sortDirection: props.sortDirection,
      selectedRestaurant: props.selectedRestaurant,
      startTime: props.startTime,
      endTime: props.endTime,
      status: props.status,
      orderType: props.orderType,
      page,
      userId: props.userId,
      orderIdQuery: props.orderIdQuery,
    });
    return refresh({
      ...props,
      page,
      status: props.status,
    });
  },
  onFilter: (props) => ({
    selectedRestaurant,
    startTime,
    endTime,
    status,
    orderType,
    userId,
    orderIdQuery,
  }) => {
    const sortLabel = startTime && endTime ? 'createDate' : 'id';
    const sortDirectionLabel = startTime && endTime ? 'asc' : 'desc';
    props.setSortName(sortLabel);
    props.setSortDirection(sortDirectionLabel);
    props.setPage(0);
    props.setSelectedRestaurant(selectedRestaurant);
    props.setStartTime(startTime);
    props.setEndTime(endTime);
    props.setStatus(status);
    props.setOrderType(orderType);
    props.setUserId(userId);
    props.setOrderIdQuery(orderIdQuery);
    props.setFilters({
      sortName: sortLabel,
      sortDirection: sortDirectionLabel,
      selectedRestaurant,
      startTime,
      endTime,
      status,
      orderType,
      page: props.page,
      userId: userId || '',
      orderIdQuery: orderIdQuery || '',
    });
    return refresh({
      ...props,
      selectedRestaurant,
      startTime,
      endTime,
      status: status,
      orderType,
      page: 0,
      userId,
      orderIdQuery,
      sortName: sortLabel,
      sortDirection: sortDirectionLabel,
    });
  },
  onCancel: (props) => (event) =>
    props.onCancel(event).then(() => refresh(props)),
  onChangeStatus: (props) => (event) => {
    return props.onChangeStatus(event).then(() => {
      props.setStatus(event.status);
      return refresh({
        ...props,
        status: event.status || defaultParams.status,
      });
    });
  },
  onSendOrderOheics: (props) => (event) => {
    const { onSendOrderOheics } = props;
    return wrapWithProgress(props)(
      onSendOrderOheics(event)
        .then(() => refresh(props))
        .catch(() => refresh(props))
    );
  },
  getProductionList: (props) => ({ ordersIds, orderIdsToChangeStatus }) => {
    return wrapWithProgress(props)(
      props
        .getProductionList({
          ...props,
          status: props.status || defaultParams.status,
          orderType: props.orderType
            ? props.orderType
            : Object.keys(ABLE_TYPES_TO_PRINT).join(','),
          ordersIds,
          orderIdsToChangeStatus,
          restaurantId: props.selectedRestaurant,
          getOrdersByDelivery: false,
          userId: props.userId,
          orderId: props.orderIdQuery,
        })
        .then(() => refresh(props))
    );
  },
  getPackList: (props) => ({ ordersIds, orderIdsToChangeStatus }) => {
    return wrapWithProgress(props)(
      props
        .getPackList({
          ...props,
          status: props.status || defaultParams.status,
          orderType: props.orderType
            ? props.orderType
            : Object.keys(ABLE_TYPES_TO_PRINT).join(','),
          ordersIds,
          orderIdsToChangeStatus,
          restaurantId: props.selectedRestaurant,
          getOrdersByDelivery: false,
          userId: props.userId,
          orderId: props.orderIdQuery,
        })
        .then(() => refresh(props))
    );
  },
  getRestaurants: (props) => () =>
    wrapWithProgress(props)(
      props.getRestaurants().then((data) => props.setRestaurants(data))
    ),
  onChangeOrderStatus: (props) => (event) => {
    return props.onChangeOrderStatus(event).then(() => {
      return refresh({ ...props });
    });
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit().then(this.props.getRestaurants);
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(OrdersListPage);
