import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Input from '@material-ui/core/TextField';

import Section from 'components/Section';
import ImageUpload from 'components/ImageUpload';

const styles = () => ({
  imagePreviewBg: {
    '& .filepond--image-preview': {
      background: 'rgba(255,255,255,0.7)',
    },
  },
  inputWrapper: {
    width: 360,
    marginRight: 20,
  },
  input: {
    width: 360,
  },
});

class ImageSection extends React.PureComponent {
  render() {
    const { classes, item, title, onChange } = this.props;

    return (
      <Section heading={title}>
        <div className={classes.imagePreviewBg}>
          <ImageUpload
            image={item.imageURL || ''}
            onChange={(e) => onChange('imageURL', e.target.value)}
            placeholder="Image URL"
            label="Image URL"
          />
        </div>
        <div className={classes.inputWrapper}>
          <Input
            value={item.imageSEOAlt || ''}
            type="string"
            onChange={(e) => onChange('imageSEOAlt', e.target.value)}
            label="SEO alt text"
            margin="normal"
            className={classes.input}
          />
        </div>
      </Section>
    );
  }
}

ImageSection.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.shape({
    imageURL: PropTypes.string,
    imageSEOAlt: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(ImageSection);
