export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_PROGRESS = 'UPLOAD_IMAGE_PROGRESS';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';

export const UPLOAD_PDF_REQUEST = 'UPLOAD_PDF_REQUEST';
export const UPLOAD_PDF_PROGRESS = 'UPLOAD_PDF_PROGRESS';
export const UPLOAD_PDF_SUCCESS = 'UPLOAD_PDF_SUCCESS';
export const UPLOAD_PDF_FAILURE = 'UPLOAD_PDF_FAILURE';

export const UPLOAD_IMAGE_MAX_SIZE = '2Mb';
