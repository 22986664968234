const styles = {
  itemType: {
    width: 30,
    paddingRight: 15,
    fontSize: 16,
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: '500',
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export default styles;
