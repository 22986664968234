const styles = {
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    '&:not(:first-child)': {
      marginTop: 48,
    },
  },
};

export default styles;
