import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { getSettings, saveSettings } from 'actions/settings';
import { showLoading, hideLoading } from 'actions/ui';

import { LANDING_SETTINGS_DATA_NAME } from 'consts/settings';

import { wrapWithSideEffect } from 'utils';
import { routerPassNotNumberId, hideIfNoData } from 'utils/enhancers';

import ListSectionDetails from './ListSectionDetails';

const mapDispatchToProps = {
  onInit: getSettings,
  onSubmit: saveSettings,
  showLoading,
  hideLoading,
};

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props) => {
  const { onInit, setInitialData } = props;
  return wrapWithProgress(props)(
    onInit({ name: LANDING_SETTINGS_DATA_NAME }).then((data) =>
      setInitialData(data)
    )
  );
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSubmit: (props) => (event) =>
    wrapWithProgress(props)(
      props.onSubmit(event).then(() => {
        props.history.push('/landing-content');
      })
    ),
});

const retrieveData = lifecycle({
  componentDidMount() {
    const { onInit } = this.props;
    onInit();
  },
});

export default compose(
  routerPassNotNumberId,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => props.id && !props.initialData)
)(ListSectionDetails);
