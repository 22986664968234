import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class ToolbarBbockTypeBar extends PureComponent {
  state = {
    blockType: this.props.currentState || '',
  };

  handleChange = ({ target: { value } }) => {
    this.props.onChange(value);
    this.setState({ blockType: value });
  };

  render() {
    const { config = {} } = this.props;

    return (
      <Select
        className={this.props.className}
        value={this.state.blockType}
        onChange={this.handleChange}
        displayEmpty={!this.props.currentState}
      >
        {!this.props.currentState ? (
          <MenuItem value="" disabled>
            Size
          </MenuItem>
        ) : null}

        {config.options.map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    );
  }
}

ToolbarBbockTypeBar.propTypes = {
  className: PropTypes.string,
  config: PropTypes.object,
  currentState: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default React.memo(ToolbarBbockTypeBar);
