import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toastr } from 'react-redux-toastr';

import PageHeading from 'components/PageHeading';
import PageHeadingTitle from 'components/PageHeadingTitle';
import Section from 'components/Section';

import MenuItems from './MenuItems';

const stateFromProps = ({ initialData = {} }) => ({
  name: initialData.name || '',
  menuItems: initialData.menuItems || [],
});

class GroupDetailsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  validateError = (data) => {
    if (!data.name) {
      return 'Name is required';
    }
  };

  handleSubmit = () => {
    const { id, onSubmit } = this.props;
    const data = {
      id,
      ...this.state,
      label: `${this.state.name.toLowerCase()}_label`,
    };
    const validateError = this.validateError(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      onSubmit(data)
        .then(() => {
          toastr.success(
            'Success!',
            id ? 'Group has been updated' : 'New Group has been created'
          );
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  onAddMenuItem = (menuItem) => {
    const menuItems = [...this.state.menuItems, menuItem];
    this.setState({ menuItems });
  };

  onDeleteMenuItem = (id) => {
    const menuItems = this.state.menuItems.filter((item) => item.id !== id);
    this.setState({ menuItems });
  };

  render() {
    const { id, initialData } = this.props;
    const { name, menuItems } = this.state;
    return (
      <div>
        <PageHeading
          title={
            id ? (
              <PageHeadingTitle
                text="Group"
                highlightedText={initialData.name}
              />
            ) : (
              'New Group'
            )
          }
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        <div>
          <Input
            value={name}
            onChange={this.handleChange('name')}
            placeholder="Name"
            label="Name"
            margin="normal"
            style={{ width: 240 }}
          />
        </div>
        <Section heading="Menu items">
          <MenuItems
            items={menuItems}
            onAdd={this.onAddMenuItem}
            onDelete={this.onDeleteMenuItem}
          />
        </Section>
      </div>
    );
  }
}

GroupDetailsPage.propTypes = {
  id: PropTypes.number,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default GroupDetailsPage;
