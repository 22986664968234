import { put, all } from 'redux-saga/effects';

import {
  ADD_TO_RESTAURANT_REQUEST,
  ADD_TO_RESTAURANT_SUCCESS,
  DELETE_FROM_RESTAURANT_REQUEST,
  DELETE_FROM_RESTAURANT_SUCCESS,
  RESTAURANT_MENU_ITEMS_ATTRS_DEFAULT,
  MENU_ITEMS_ATTRIBUTES,
  CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_REQUEST,
  CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_SUCCESS,
} from 'consts/menu-items';

import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';

import { getMenuItemById, sendSaveMenuItemRequest } from './menu-items';
import { getMenuAttributes } from 'sagas/attributes';

export function* addAttributesToMenuItem({
  menuItem,
  restaurantId,
  restaurantAttributes = [],
}) {
  let newAttributes = [...menuItem.attributes];
  // Searching for default attributes for current restaurant
  const restAttrs = menuItem.attributes.filter(
    (attr) =>
      attr.menuItemId === menuItem.id && attr.restaurantId === restaurantId
  );
  if (restAttrs && restAttrs.length) {
    // Just checking if available attr exists, just for some cases
    const availableAttr = restAttrs.find(
      (attr) =>
        attr.label === MENU_ITEMS_ATTRIBUTES.available &&
        attr.restaurantId === restaurantId &&
        attr.menuItemId === menuItem.id
    );
    if (availableAttr) {
      // Changing availability for restaurant
      newAttributes = [
        ...newAttributes.filter(
          (attr) =>
            !(
              attr.label === MENU_ITEMS_ATTRIBUTES.available &&
              attr.restaurantId === restaurantId &&
              attr.menuItemId === menuItem.id
            )
        ),
        {
          ...availableAttr,
          value: 'true',
        },
      ];
    } else {
      // Adding availability for restaurant, rare case but still
      const availRestAttr = restaurantAttributes.find(
        (attr) => attr.label === MENU_ITEMS_ATTRIBUTES.available
      );
      newAttributes = [
        ...newAttributes,
        {
          attributeId: availRestAttr.id,
          value: 'true',
          restaurantId,
        },
      ];
    }
  } else {
    // Create new attributes
    newAttributes = [...newAttributes, ...restaurantAttributes];
  }
  // Sending data
  yield sendSaveMenuItemRequest(menuItem.id, {
    ...menuItem,
    attributes: newAttributes,
  });
}

export function* addMenuItemAttributesForRestaurant(menuItems, restaurantId) {
  const attributes = yield getMenuAttributes();
  const defaultRestaurantAttributes = Object.keys(
    RESTAURANT_MENU_ITEMS_ATTRS_DEFAULT
  );
  const restaurantAttributes = attributes
    .filter((a) => defaultRestaurantAttributes.includes(a.label))
    .map((a) => ({
      attributeId: a.id,
      value: RESTAURANT_MENU_ITEMS_ATTRS_DEFAULT[a.label],
      restaurantId,
      label: a.label,
    }));

  yield all(
    menuItems.map((menuItem) =>
      addAttributesToMenuItem({ menuItem, restaurantId, restaurantAttributes })
    )
  );
}

export function* deleteMenuItemAttributesForRestaurant(
  menuItems,
  restaurantId
) {
  yield all(
    menuItems.map(function*(menuItem) {
      const newAttributes = menuItem.attributes.map((attr) => {
        if (
          attr.label === MENU_ITEMS_ATTRIBUTES.available &&
          attr.restaurantId === restaurantId
        ) {
          return {
            ...attr,
            value: 'false',
          };
        }
        return attr;
      });
      const url = `/menu-items/${menuItem.id}`;
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...menuItem, attributes: newAttributes }),
      };
      yield apiRequest(url, options);
    })
  );
}

export function* addToRestaurant({ id, restaurantId }) {
  try {
    const menuItem = yield getMenuItemById({ id });
    yield addMenuItemAttributesForRestaurant([menuItem], restaurantId);
    const payload = {};
    const newAction = {
      type: ADD_TO_RESTAURANT_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* addToRestaurantSaga() {
  yield takeFirst(ADD_TO_RESTAURANT_REQUEST, addToRestaurant);
}

export function* deleteFromRestaurant({ id, restaurantId }) {
  try {
    const menuItem = yield getMenuItemById({ id });
    yield deleteMenuItemAttributesForRestaurant([menuItem], restaurantId);
    const payload = {};
    const newAction = {
      type: DELETE_FROM_RESTAURANT_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* deleteFromRestaurantSaga() {
  yield takeFirst(DELETE_FROM_RESTAURANT_REQUEST, deleteFromRestaurant);
}

export function* changeMenuItemAttributeToRestaurant({
  id,
  restaurantId,
  attribute,
  attributeValue,
}) {
  try {
    const menuItem = yield getMenuItemById({ id });
    let attrToChange = menuItem.attributes.find(
      (attr) =>
        attr.restaurantId === restaurantId && attr.attributeId === attribute.id
    );
    if (attrToChange) {
      attrToChange.value = attributeValue;
    } else {
      attrToChange = {
        attributeId: attribute.id,
        label: attribute.label,
        menuItemId: id,
        restaurantId,
        value: attributeValue,
      };
    }
    const url = `/menu-items/${id}`;
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...menuItem,
        attributes: [
          ...menuItem.attributes.filter(
            (attr) =>
              !(
                attr.restaurantId === restaurantId &&
                attr.attributeId === attribute.attributeId
              )
          ),
          { ...attrToChange },
        ],
      }),
    };
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* changeMenuItemAttributeToRestaurantSaga() {
  yield takeFirst(
    CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_REQUEST,
    changeMenuItemAttributeToRestaurant
  );
}

export default combine([
  addToRestaurantSaga,
  deleteFromRestaurantSaga,
  changeMenuItemAttributeToRestaurantSaga,
]);
