import { put, all } from 'redux-saga/effects';

import {
  GET_COMPANY_MEMBERS_REQUEST,
  GET_COMPANY_MEMBERS_SUCCESS,
  ADD_MEMBER_TO_COMPANY_REQUEST,
  ADD_MEMBER_TO_COMPANY_SUCCESS,
  ADD_MEMBER_TO_COMPANY_FAILURE,
  APPROVE_COMPANY_MEMBER_REQUEST,
  APPROVE_COMPANY_MEMBER_SUCCESS,
  APPROVE_ALL_MEMBERS_REQUEST,
  APPROVE_ALL_MEMBERS_SUCCESS,
  DECLINE_COMPANY_MEMBER_REQUEST,
  DECLINE_COMPANY_MEMBER_SUCCESS,
  GET_USER_COMPANIES_REQUEST,
  GET_USER_COMPANIES_SUCCESS,
} from 'consts/userRegistry';

import { getCustomers } from 'sagas/customers';
import { getCompaniesRequest } from 'sagas/companies';
import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';

import { calculateSort, calculatePage, prepareURLRequest } from 'utils';

function* getUserRegistry({ companyId, userId }) {
  try {
    const params = [];
    if (userId) params.push(`requestedUserId=${userId}`);
    if (companyId) params.push(`companyId=${companyId}`);

    const url = '/users-registry';
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const response = yield apiRequest(prepareURLRequest(url, params), options);
    if (response && response.items && response.items.length > 0) {
      return response.items[0];
    }
    return;
  } catch (error) {
    return;
  }
}

function* addCompanyMember({ companyId, userId }) {
  try {
    const userRegistry = yield getUserRegistry({ companyId, userId });
    let payload;
    if (userRegistry && userRegistry.id) {
      // approve user registry insted of add new
      payload = yield approveCompanyMember({ userRegistryId: userRegistry.id });
    } else {
      const url = '/users-registry';
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          requestedUserId: userId,
          companyId,
        }),
      };
      payload = yield apiRequest(url, options);
    }
    const newAction = {
      type: ADD_MEMBER_TO_COMPANY_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
    yield put({
      type: ADD_MEMBER_TO_COMPANY_FAILURE,
      error,
    });
    return;
  }
}

function* addCompanyMemberSaga() {
  yield takeFirst(ADD_MEMBER_TO_COMPANY_REQUEST, addCompanyMember);
}

function* approveCompanyMember({ userRegistryId }) {
  const url = `/users-registry/${userRegistryId}/status/approved`;
  const options = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: APPROVE_COMPANY_MEMBER_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* approveCompanyMemberSaga() {
  yield takeFirst(APPROVE_COMPANY_MEMBER_REQUEST, approveCompanyMember);
}

function* approveAllPendingMember({ userRegistryIds }) {
  const options = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield all(
      userRegistryIds.map((id) =>
        apiRequest(`/users-registry/${id}/status/approved`, options)
      )
    );
    const newAction = {
      type: APPROVE_ALL_MEMBERS_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* approveAllPendingMemberSaga() {
  yield takeFirst(APPROVE_ALL_MEMBERS_REQUEST, approveAllPendingMember);
}

function* declineCompanyMember({ userRegistryId }) {
  const url = `/users-registry/${userRegistryId}/status/declined`;
  const options = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: DECLINE_COMPANY_MEMBER_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* declineCompanyMemberSaga() {
  yield takeFirst(DECLINE_COMPANY_MEMBER_REQUEST, declineCompanyMember);
}

export function* getCompanyUsers({ companyIds }) {
  const companyIdParam =
    companyIds && companyIds.length ? `companyId=${companyIds.join(',')}` : '';
  const url = `/users-registry?${companyIdParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const registers = yield apiRequest(url, options);
    const users = [];
    if (registers.items && registers.items.length) {
      const registerIds = registers.items.map((item) => item.requestedUserId);
      const registerCustomersDict = registers.items.reduce((acc, item) => {
        acc[item.requestedUserId] = item;
        return acc;
      }, {});
      const customers = yield getCustomers({
        ids: registerIds,
        pageLimit: 100,
        page: 0,
        getAllItems: true,
        isAction: false,
      });
      customers.items.forEach((customer) => {
        users.push({
          ...customer,
          userRegistry: registerCustomersDict[customer.id],
        });
      });
    }
    const newAction = {
      type: GET_COMPANY_MEMBERS_SUCCESS,
      payload: users,
    };
    yield put(newAction);
    return users;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getCompanyUsersSaga() {
  yield takeFirst(GET_COMPANY_MEMBERS_REQUEST, getCompanyUsers);
}

export function* getUserCompanies({
  userId,
  sortName,
  sortDirection,
  page,
  pageLimit,
  status,
}) {
  try {
    const params = [];
    if (userId) params.push(`requestedUserId=${userId}`);
    if (status) params.push(`status=${status}`);
    params.push(calculateSort(sortDirection, sortName));

    const url = '/users-registry';
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const registers = yield apiRequest(
      prepareURLRequest(
        url,
        pageLimit
          ? params.concat([`${calculatePage(page, pageLimit)}`])
          : params
      ),
      options
    );

    let companiesDict = {};
    if (registers.items && registers.items.length) {
      const companiesIds = registers.items.map((item) => item.companyId);

      const companies = yield getCompaniesRequest(companiesIds);
      companiesDict = (companies.items || []).reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    }
    const payload =
      registers.items && registers.items.length
        ? {
            ...registers,
            items: registers.items.map((item) => ({
              ...item,
              company: companiesDict[item.companyId],
            })),
          }
        : registers;
    const newAction = {
      type: GET_USER_COMPANIES_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getUserCompaniesSaga() {
  yield takeFirst(GET_USER_COMPANIES_REQUEST, getUserCompanies);
}

export default combine([
  getCompanyUsersSaga,
  addCompanyMemberSaga,
  approveCompanyMemberSaga,
  approveAllPendingMemberSaga,
  declineCompanyMemberSaga,
  getUserCompaniesSaga,
]);
