import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { getSettings, saveSettings } from 'actions/settings';
import { showLoading, hideLoading } from 'actions/ui';

import { LANDING_SETTINGS_DATA_NAME } from 'consts/settings';

import { wrapWithSideEffect } from 'utils';
import { hideIfNoData } from 'utils/enhancers';

import LandingSettings from './LandingSettings';

const mapDispatchToProps = {
  onInit: getSettings,
  onSubmit: saveSettings,
  showLoading,
  hideLoading,
};

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props) => {
  const { onInit, setInitialData } = props;
  return wrapWithProgress(props)(
    onInit({ name: LANDING_SETTINGS_DATA_NAME, withReplace: true }).then(
      (data) => setInitialData(data)
    )
  );
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSubmit: (props) => (event) =>
    wrapWithProgress(props)(props.onSubmit(event).then(() => refresh(props))),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(LandingSettings);
