import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/TextField';

import { toastr } from 'react-redux-toastr';

import SmallButton from 'components/SmallButton';

import styles from './styles';

const PARAMS_TO_SAVE = {
  pointsToAdd: 'pointsToAdd',
  storeCreditsToAdd: 'storeCreditsToAdd',
};

class PointsStoreCredits extends React.PureComponent {
  state = {
    pointsToAdd: 0,
    storeCreditsToAdd: 0,
  };

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleSubmit = (param) => {
    const { onSave, userId, kickbackDate } = this.props;
    const { points, storeCredits } = this.props.initialData;
    const value = this.state[param];
    onSave({
      userId,
      points:
        param === PARAMS_TO_SAVE.pointsToAdd
          ? points + (parseInt(value, 10) || 0)
          : points,
      storeCredits:
        param === PARAMS_TO_SAVE.storeCreditsToAdd
          ? storeCredits + (parseInt(value) || 0)
          : storeCredits,
      kickbackDate,
    })
      .then(() => {
        this.setState({ [param]: 0 });
        toastr.success(
          'Success!',
          `${
            param === PARAMS_TO_SAVE.pointsToAdd ? 'Points' : 'Store credits'
          } has been updated`
        );
      })
      .catch(() => {
        this.setState({ [param]: 0 });
        toastr.error('Error!', 'Something went wrong');
      });
  };

  render() {
    const { classes } = this.props;
    const { points, storeCredits } = this.props.initialData;
    const { pointsToAdd, storeCreditsToAdd } = this.state;
    return (
      <React.Fragment>
        <Paper className={classes.wrapper}>
          <div className={classes.sideBlock}>
            <div className={classes.balanceRowWrapper}>
              <span>Current points balance</span>
              <span className={classes.balancePoints}>{points}</span>
            </div>
            <div className={classes.balanceRowWrapper}>
              <span>Current store credit balance</span>
              <span className={classes.balancePoints}>{storeCredits}</span>
            </div>
          </div>
          <div className={classes.sideBlock}>
            <div>
              <Input
                value={pointsToAdd}
                onChange={this.handleChange('pointsToAdd')}
                placeholder="Add or remove points"
                type="number"
                label="Add or remove points"
                style={{ width: 200 }}
              />
              <SmallButton
                id="add-points-button"
                onClick={() => this.handleSubmit('pointsToAdd')}
                leftSpace
              >
                Apply
              </SmallButton>
            </div>
            <div style={{ marginTop: 24 }}>
              <Input
                value={storeCreditsToAdd}
                onChange={this.handleChange('storeCreditsToAdd')}
                placeholder="Add or remove store credits"
                type="number"
                label="Add or remove store credits"
                style={{ width: 200 }}
              />
              <SmallButton
                id="add-store-credits-button"
                onClick={() => this.handleSubmit('storeCreditsToAdd')}
                leftSpace
              >
                Apply
              </SmallButton>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

PointsStoreCredits.propTypes = {
  classes: PropTypes.object.isRequired,
  initialData: PropTypes.shape({
    points: PropTypes.number.isRequired,
    storeCredits: PropTypes.number.isRequired,
  }).isRequired,
  userId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default injectSheet(styles)(PointsStoreCredits);
