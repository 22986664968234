import { combine } from './utils/effects';
import tags from './tags';
import categories from './categories';
import companies from './companies';
import restaurants from './restaurants';
import menuItems from './menu-items';
import groups from './groups';
import orders from './orders';
import customers from './customers';
import loyalties from './loyalties';
import auth from './auth';
import upload from './upload';
import settings from './settings';
import userRegistry from './userRegistry';
import printList from './printList';

export default combine([
  auth,
  tags,
  categories,
  restaurants,
  menuItems,
  groups,
  orders,
  customers,
  companies,
  loyalties,
  upload,
  settings,
  userRegistry,
  printList,
]);
