import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import ActionHeading from 'components/ActionHeading';
import TableActionCell from 'components/TableActionCell';
import SmallButton from 'components/SmallButton';

import { ITEM_STATUSES } from 'consts';

import MenuItemsDropdown from './MenuItemsDropdown';

class Related extends React.PureComponent {
  state = {
    selectedMenuItem: null,
  };

  onSelectMenuItem = (menuItem) => {
    this.setState({ selectedMenuItem: menuItem });
  };

  handleSubmit = () => {
    const { selectedMenuItem } = this.state;
    if (selectedMenuItem) {
      this.props.onAdd({
        ...selectedMenuItem,
        suggestedItemId: selectedMenuItem.id,
      });
      this.setState({ selectedMenuItem: null });
    }
  };

  render() {
    const { items, onDelete, menuItemId, label, inactive } = this.props;
    const { selectedMenuItem } = this.state;

    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                {!inactive && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow
                  key={item.suggestedItemId}
                  style={{
                    backgroundColor:
                      item.status === ITEM_STATUSES.inactive
                        ? 'rgba(0, 0, 0, 0.07)'
                        : '',
                  }}
                >
                  <TableCell>{item.name}</TableCell>
                  {!inactive && (
                    <TableActionCell
                      action={() => onDelete(item.suggestedItemId)}
                      icon="delete"
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {!inactive && (
          <div style={{ marginTop: 40 }}>
            <ActionHeading>Add {label} item</ActionHeading>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <MenuItemsDropdown
                value={selectedMenuItem}
                onChange={this.onSelectMenuItem}
                excludeIds={[
                  menuItemId,
                  ...items.map(({ suggestedItemId }) => suggestedItemId),
                ]}
              />
              <SmallButton
                onClick={this.handleSubmit}
                leftSpace
                id="add-retated-item-button"
              >
                Add
              </SmallButton>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Related.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  menuItemId: PropTypes.number,
  inactive: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Related;
