import { styleConsts } from 'shared/styles/consts';
const styles = {
  block: {
    display: 'block',
  },
  reachTextContainer: {
    width: '100%',

    '& a': {
      color: styleConsts.darkGreen,
    },
    '& ol, & ul': {
      listStyle: 'unset',
      padding: [0, 0, 0, 16],
    },

    '& > h3': {
      fontSize: 20,
    },
    '& > h4': {
      fontSize: 16,
    },
    '& > h5': {
      fontSize: 12,
    },
    '& > h6': {
      fontSize: 8,
    },
    '& blockquote': {
      width: '60%',
      fontStyle: 'italic',
      color: styleConsts.darkGreen,
      padding: [8, 12, 8, 32],
      borderLeft: `8px solid ${styleConsts.green}`,
      position: 'relative',
      background: styleConsts.pageMobileBg,
      display: 'block',
      boxSizing: 'content-box',
      margin: [8, 'auto'],
      quotes: 'unset',

      '&::before': {
        content: 'open-quote',
        color: styleConsts.green,
        fontSize: 48,
        position: 'absolute',
        left: 4,
        top: -8,
      },
      '& *': {
        display: 'block',
        color: styleConsts.secondTextColor,
        fontWeight: 'bold',
        textAlign: 'right',
        marginTop: 4,
      },
    },
  },
  italic: { fontStyle: 'italic' },
  bold: { fontWeight: 'bold' },
};

export default styles;
