export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const S3_ROOT = process.env.REACT_APP_S3_ROOT;
export const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL;
export const UPLOAD_IMAGE_URL = process.env.REACT_APP_UPLOAD_IMAGE_URL;
export const GENERIC_REQUEST_ERROR = 'GENERIC_REQUEST_ERROR';

export const DEFAULT_TAX = 0.12;
export const TOAST_TIME_HIDE = 15000;
export const ERROR_TOAST_TIME_HIDE = 20000;
export const DEFAULT_PAGE_LIMIT = 100;
export const MAX_SELECTED_DATE = '2037-12-31 23:59:59'; // maximum date that mysql could store on current system configuration

export const ITEM_STATUSES = {
  active: 'active',
  inactive: 'inactive',
};

export const ITEM_STATUSES_LABELS = {
  active: 'Active',
  inactive: 'Inactive',
};

export const DEFAULT_TIMEZONE = 'Europe/Stockholm';
export const UTC_TIMEZONE = 'UTC';
export const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const DATETIME_FORMAT_WO_SECS = 'yyyy-MM-dd HH:mm';
export const SHORT_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_MONTH_FORMAT = 'dd MMM';
export const DAYS_OPTIONS_NAME = {
  today: 'today',
  tomorrow: 'tomorrow',
};
export const DAY_OPTIONS_TYPES = {
  start: 'start',
  end: 'end',
};
export const MAX_DELIVERY_DAY = 90; // days
export const TIME_SLOT_VALUE = 15;
export const MIN_DELIVERY_TIME = 0; // minutes
export const DEFAULT_MIN_DELIVERY_TIME = 15;

export const DAYS_FOR_MAPING = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
