import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import FooterMenuLinks from './FooterMenuLinks';
import FooterImagesList from './FooterImagesList';
import FooterSocialIcons from './FooterSocialIcons';

import { FOOTER_SETTINGS_DATA_NAME } from 'consts/settings';
import { generateListItems } from './utils';

class FooterSettings extends React.PureComponent {
  handleSubmit = (data) => {
    const { onSubmit, initialData } = this.props;
    const dataToSend = {
      isNew: !Object.keys(initialData).length,
      name: FOOTER_SETTINGS_DATA_NAME,
      value: JSON.stringify(data),
    };
    onSubmit(dataToSend)
      .then(() => {
        toastr.success('Success!', 'Content has been saved');
      })
      .catch(() => {
        toastr.error('Error!', 'Something went wrong');
      });
  };

  handleFooterSettingsSubmit = (data) => {
    const { ...oldData } = this.props.initialData;
    this.handleSubmit({
      ...oldData,
      ...data,
    });
  };

  render() {
    const {
      linksList,
      imagesList,
      socialIcons,
      logo,
      license,
    } = this.props.initialData;
    const { settingsType } = this.props;

    return (
      <React.Fragment>
        {settingsType === 'menu' && (
          <FooterMenuLinks
            items={generateListItems(linksList)}
            logo={logo}
            license={license}
            onSubmit={this.handleFooterSettingsSubmit}
          />
        )}
        {settingsType === 'partners' && (
          <FooterImagesList
            items={generateListItems(imagesList)}
            onSubmit={this.handleFooterSettingsSubmit}
          />
        )}
        {settingsType === 'social-icons' && (
          <FooterSocialIcons
            items={generateListItems(socialIcons)}
            onSubmit={this.handleFooterSettingsSubmit}
          />
        )}
      </React.Fragment>
    );
  }
}

FooterSettings.propTypes = {
  classes: PropTypes.object,
  initialData: PropTypes.shape({
    linksList: PropTypes.array,
    imagesList: PropTypes.array,
    socialIcons: PropTypes.array,
    logo: PropTypes.shape({
      imageURL: PropTypes.string,
      imageSEOAlt: PropTypes.string,
      imageWebsite: PropTypes.string,
    }),
    license: PropTypes.string,
  }).isRequired,
  settingsType: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default FooterSettings;
