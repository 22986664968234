import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { getStoreCreditsAnalytics } from 'actions/loyalties';

import { ANALYTICS_STORE_CREDITS_START_DATE } from 'consts/loyalties';
import { SHORT_DATE_FORMAT } from 'consts';

import { showLoading, hideLoading } from 'actions/ui';
import { convertCurrentTimezoneToUTC } from 'utils/date';

import { wrapWithSideEffect } from 'utils';
import { hideIfNoData } from 'utils/enhancers';

import StoreCreditsPage from './StoreCreditsPage';

const mapDispatchToProps = {
  onInit: getStoreCreditsAnalytics,
  showLoading,
  hideLoading,
};

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props, ...params) => {
  const { onInit, setInitialData } = props;
  return wrapWithProgress(props)(onInit(...params).then(setInitialData));
};

const handlers = withHandlers({
  onInit: (props) => (...params) => refresh(props, ...params),
});

const retrieveData = lifecycle({
  componentDidMount() {
    const { onInit } = this.props;
    onInit(
      ANALYTICS_STORE_CREDITS_START_DATE,
      convertCurrentTimezoneToUTC(new Date(), SHORT_DATE_FORMAT)
    );
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(StoreCreditsPage);
