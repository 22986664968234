const styles = (theme) => ({
  contentWrapper: {
    margin: 0,
    padding: '24px 36px',
    minWidth: 300,
    '&:first-child': {
      paddingTop: 36,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
});

export default styles;
