const styles = {
  description: {
    color: '#4a4a4a',
    fontSize: 24,
    fontWeight: 'bold',
  },
  headItemsWrapper: {
    display: 'flex',
    borderBottom: '1px solid #4a4a4a',
    paddingBottom: 8,
    marginBottom: 16,
    marginTop: 16,
  },
  headItem: {
    flex: 1,
    color: '#4a4a4a',
    fontWeight: 'bold',
  },
  orderCell: {
    maxWidth: 32,
  },
  visibleCell: {
    maxWidth: 56,
  },
  visibilityCheckbox: {
    padding: 0,
  },
  menuItemsList: {
    listStyle: 'none',
  },
  menuItemsListItem: {
    display: 'flex',
    padding: '6px 0',
    alignItems: 'center',
  },
  menuItemsListItemName: {
    flex: 1,
  },
  menuItemsListItemCategory: {
    flex: 1,
  },
};

export default styles;
