import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { formatDateTime } from 'utils/date';

import PageHeading from 'components/PageHeading';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

const styles = {
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  description: {
    fontSize: 18,
    padding: '6px 0',
  },
  tableDescription: {
    marginTop: 24,
    marginBottom: 16,
  },
};

const getDate = (data) => formatDateTime(data.startDate, 'MMMM yyyy');

class StoreCreditsPage extends React.PureComponent {
  render() {
    const {
      rangeItems,
      totalStoreCredits,
      pointsInStoreCredits,
    } = this.props.initialData;
    const { classes } = this.props;
    return (
      <div>
        <PageHeading title="Store credits" />
        <div className={classes.formWrapper}>
          <div className={classes.description} id="total-store-credits">
            Users currently own <b>{totalStoreCredits}</b> kr in store credits
          </div>
          <div className={classes.description} id="points-in-store-credits">
            Users currently own points equal to <b>{pointsInStoreCredits}</b> kr
            in store credits
          </div>
          <div className={classes.tableDescription}>
            Used store credit per month
          </div>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Month</TableCell>
                  <TableCell>Store credit used</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rangeItems.map((item) => (
                  <TableRow key={getDate(item)}>
                    <TableCell>{getDate(item)}</TableCell>
                    <TableCell>{item.credits}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    );
  }
}

StoreCreditsPage.propTypes = {
  initialData: PropTypes.shape({
    rangeItems: PropTypes.array,
    totalStoreCredits: PropTypes.number,
    pointsInStoreCredits: PropTypes.number,
  }),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StoreCreditsPage);
