import { put } from 'redux-saga/effects';

import {
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  GET_CATEGORY_MENU_ITEMS_REQUEST,
  GET_CATEGORY_MENU_ITEMS_SUCCESS,
  ADD_CATEGORY_TO_RESTAURANT_REQUEST,
  ADD_CATEGORY_TO_RESTAURANT_SUCCESS,
  REMOVE_CATEGORY_FROM_RESTAURANT_REQUEST,
  REMOVE_CATEGORY_FROM_RESTAURANT_SUCCESS,
} from 'consts/menu-items';
import { calculateSort } from 'utils';
import { fixMenuItemsPayload } from 'utils/processors';

import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';
import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';
import { getCategories } from 'sagas/categories';
import { getMenuAttributes } from 'sagas/attributes';

import { getMenuItemById, getMenuItems } from './menu-items';
import {
  addMenuItemAttributesForRestaurant,
  deleteMenuItemAttributesForRestaurant,
} from './restaurant-menu-items';

/**
 * Add menu item to category
 * @param {Object} {id, categoryId} - menu item id, category is
 */
export function* updateCategory({ id, categoryId }) {
  try {
    const menuItem = yield getMenuItemById({ id });
    const url = `/menu-items/${id}`;
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...menuItem, categoryId }),
    };
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: UPDATE_CATEGORY_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* updateCategorySaga() {
  yield takeFirst(UPDATE_CATEGORY_REQUEST, updateCategory);
}

/**
 * Retrieve categories menu items
 * @param {Object} {sortName, sortDirection, selectedCategory} - name of field to sort, direction for sorting, category id
 */
export function* getCategoryMenuItems({
  sortName,
  sortDirection,
  selectedCategory,
}) {
  try {
    const categoriesPayload = yield getCategories({});
    const categoryIds = categoriesPayload.items
      .filter(
        (c) => c.id === selectedCategory || c.parentId === selectedCategory
      )
      .map((c) => c.id);
    const categoryParam = `&categoryId=${categoryIds.join(',')}`;
    const url = `/menu-items?${calculateSort(
      sortDirection,
      sortName
    )}${categoryParam}`;
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const rawPayload = yield apiRequest(url, options);
    const attributes = yield getMenuAttributes();
    const payload = fixMenuItemsPayload(
      rawPayload,
      categoriesPayload.items,
      attributes,
      {
        sortName,
        sortDirection,
      }
    );
    const newAction = {
      type: GET_CATEGORY_MENU_ITEMS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getCategoryMenuItemsSaga() {
  yield takeFirst(GET_CATEGORY_MENU_ITEMS_REQUEST, getCategoryMenuItems);
}

/**
 * Add category to restaurant
 * @param {Object} {categoryId, restaurantId} - id of category to add, restaurant id
 */
export function* addCategoryToRestaurant({ categoryId, restaurantId }) {
  try {
    const menuItemsPayload = yield getMenuItems({
      selectedCategory: categoryId,
    });
    yield addMenuItemAttributesForRestaurant(
      menuItemsPayload.items,
      restaurantId
    );
    const payload = {};
    const newAction = {
      type: ADD_CATEGORY_TO_RESTAURANT_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* addCategoryToRestaurantSaga() {
  yield takeFirst(ADD_CATEGORY_TO_RESTAURANT_REQUEST, addCategoryToRestaurant);
}

/**
 * Remove category from restaurant
 * @param {Object} {categoryId, restaurantId} - id of category to remove, restaurant id
 */
export function* removeCategoryFromRestaurant({ categoryId, restaurantId }) {
  try {
    const menuItemsPayload = yield getMenuItems({
      selectedCategory: categoryId,
    });
    yield deleteMenuItemAttributesForRestaurant(
      menuItemsPayload.items,
      restaurantId
    );
    const payload = {};
    const newAction = {
      type: REMOVE_CATEGORY_FROM_RESTAURANT_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* removeCategoryFromRestaurantSaga() {
  yield takeFirst(
    REMOVE_CATEGORY_FROM_RESTAURANT_REQUEST,
    removeCategoryFromRestaurant
  );
}

export default combine([
  updateCategorySaga,
  getCategoryMenuItemsSaga,
  addCategoryToRestaurantSaga,
  removeCategoryFromRestaurantSaga,
]);
