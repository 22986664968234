import { put } from 'redux-saga/effects';

import {
  GET_TAG_GROUPS_REQUEST,
  GET_TAG_GROUPS_SUCCESS,
  GET_TAG_GROUP_BY_ID_REQUEST,
  GET_TAG_GROUP_BY_ID_SUCCESS,
  GET_TAGS_BY_GROUPID_REQUEST,
  GET_TAGS_BY_GROUPID_SUCCESS,
  SAVE_TAG_REQUEST,
  SAVE_TAG_SUCCESS,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCESS,
} from 'consts/tags';
import { ITEM_STATUSES } from 'consts';
import { calculateSort } from 'utils';

import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';

function* getTagGroups({ sortName, sortDirection }) {
  let url = `/tag-types?${calculateSort(sortDirection, sortName)}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: GET_TAG_GROUPS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getTagGroupsSaga() {
  yield takeFirst(GET_TAG_GROUPS_REQUEST, getTagGroups);
}

function* getTagGroupById({ id }) {
  let url = `/tag-types/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: GET_TAG_GROUP_BY_ID_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getTagGroupByIdSaga() {
  yield takeFirst(GET_TAG_GROUP_BY_ID_REQUEST, getTagGroupById);
}

function* getTagsByGroupId({ groupId, sortName, sortDirection }) {
  let url = `/tags?typeId=${groupId}&${calculateSort(
    sortDirection,
    sortName
  )}&status=${ITEM_STATUSES.active}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: GET_TAGS_BY_GROUPID_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getTagsByGroupIdSaga() {
  yield takeFirst(GET_TAGS_BY_GROUPID_REQUEST, getTagsByGroupId);
}

function* getTags({ sortName, sortDirection }) {
  let url = `/tags?${calculateSort(sortDirection, sortName)}&status=${
    ITEM_STATUSES.active
  }`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: GET_TAGS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getTagsSaga() {
  yield takeFirst(GET_TAGS_REQUEST, getTags);
}

function* saveTag({ groupId, name, status }) {
  let url = '/tags';
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ value: name, typeId: groupId, status }),
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SAVE_TAG_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* saveTagSaga() {
  yield takeFirst(SAVE_TAG_REQUEST, saveTag);
}

function* deleteTag({ id }) {
  let url = `/tags/${id}`;
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: DELETE_TAG_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* deleteTagSaga() {
  yield takeFirst(DELETE_TAG_REQUEST, deleteTag);
}

export default combine([
  getTagGroupsSaga,
  getTagGroupByIdSaga,
  getTagsByGroupIdSaga,
  saveTagSaga,
  deleteTagSaga,
  getTagsSaga,
]);
