import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

class SmallButton extends React.PureComponent {
  render() {
    const { leftSpace, ...rest } = this.props;
    return (
      <Button
        color="secondary"
        variant="contained"
        {...(leftSpace ? { style: { marginLeft: 20 } } : {})}
        {...rest}
      />
    );
  }
}

SmallButton.propTypes = {
  leftSpace: PropTypes.bool,
};

export default SmallButton;
