import randomstring from 'randomstring';

import {
  validateEmail,
  validateURL,
  validatePhoneNumber,
} from 'utils/processors';
import { LINK_TYPES_VALUES } from 'shared/consts/settings';

export function validateSocialIconItem(data) {
  if (!data.iconURL) {
    return 'Valid icon URL is required';
  }
  if (!data.imageSEOAlt) {
    return 'Valid icon alt text is required';
  }
  if (data.linkType === LINK_TYPES_VALUES.email && !validateEmail(data.url)) {
    return 'Valid email is required';
  }
  if (data.linkType === LINK_TYPES_VALUES.url && !validateURL(data.url)) {
    return 'Valid website url is required';
  }
  if (
    data.linkType === LINK_TYPES_VALUES.phone &&
    !validatePhoneNumber(data.url)
  ) {
    return 'Valid phone number is required';
  }
}

export function validateSocialIconList(items) {
  if (items && items.length) {
    for (let i = 0; i < items.length; i++) {
      const error = validateSocialIconItem(items[i]) || '';
      if (error) {
        return error;
      }
    }
  }
}

export function generateListItems(items) {
  return (items || [])
    .map((item) => ({
      ...item,
      id: item.id || randomstring.generate(7),
    }))
    .sort((a, b) => a.index - b.index);
}

export function validateImageItem(data) {
  if (!data.imageURL) {
    return 'Valid image URL is required';
  }
  if (!data.imageSEOAlt) {
    return 'Valid image alt text is required';
  }
  if (data.imageWebsite && !validateURL(data.imageWebsite)) {
    return 'Valid website url is required';
  }
}

export function validateImagesList(items) {
  if (items && items.length) {
    for (let i = 0; i < items.length; i++) {
      const error = validateImageItem(items[i]) || '';
      if (error) {
        return error;
      }
    }
  }
}
