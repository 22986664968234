const styles = {
  dialogActions: {
    justifyContent: 'center',
    marginBottom: 24,
    marginTop: 0,
  },
  searchedCompaniesList: {
    marginTop: 36,
  },
  searchedCompaniesListItem: {
    cursor: 'pointer',
    background: '#fafafa',
    padding: '8px 48px 8px 12px',
    marginBottom: 16,
  },
  companyPerson: {
    float: 'right',
  },
  idWrapper: {
    fontSize: 24,
    marginTop: 16,
    marginBottom: 16,
  },
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formBlock: {
    flex: 1,
    '&:nth-child(1)': {
      marginRight: 30,
    },
  },
  discountWrapper: {
    marginTop: 16,
  },
  discountLabel: {
    fontSize: '0.9rem',
    display: 'block',
  },
  discountDateWrapper: {
    display: 'inline-block',
    paddingTop: 20,
    marginLeft: 16,
  },
  datePicker: {
    marginLeft: 12,
    marginTop: '-3px',
  },
};

export default styles;
