import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import {
  getMenuItemById,
  saveMenuItem,
  restoreMenuItem,
} from 'actions/menu-items';
import { showLoading, hideLoading } from 'actions/ui';
import { wrapWithSideEffect } from 'utils';
import { routerPassId, hideIfNoData } from 'utils/enhancers';

import MenuItemDetailsPage from './MenuItemDetailsPage';

const mapDispatchToProps = {
  onInit: getMenuItemById,
  onSubmit: saveMenuItem,
  onRestore: restoreMenuItem,
  showLoading,
  hideLoading,
};

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props) => {
  const { id, onInit, setInitialData } = props;
  return id && wrapWithProgress(props)(onInit(id).then(setInitialData));
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSubmit: (props) => (event) => {
    const { onSubmit } = props;
    return wrapWithProgress(props)(onSubmit(event).then(() => refresh(props)));
  },
  onRestore: (props) => (id, item) =>
    props.onRestore(id, item).then(() => refresh(props)),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
  componentWillReceiveProps(newProps) {
    if (newProps.id !== this.props.id) {
      this.props.onInit();
    }
  },
});

export default compose(
  routerPassId,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => props.id && !props.initialData)
)(MenuItemDetailsPage);
