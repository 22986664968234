import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_KICKBACKS_LIST_REQUEST,
  GET_KICKBACKS_LIST_SUCCESS,
  GET_KICKBACKS_LIST_FAILURE,
  SAVE_KICKBACKS_LIST_REQUEST,
  SAVE_KICKBACKS_LIST_SUCCESS,
  SAVE_KICKBACKS_LIST_FAILURE,
  GET_KICKBACK_BY_ID_REQUEST,
  GET_KICKBACK_BY_ID_SUCCESS,
  GET_KICKBACK_BY_ID_FAILURE,
  DELETE_KICKBACK_REQUEST,
  DELETE_KICKBACK_SUCCESS,
  DELETE_KICKBACK_FAILURE,
  GET_KICKBACK_HISTORY_REQUEST,
  GET_KICKBACK_HISTORY_SUCCESS,
  GET_KICKBACK_HISTORY_FAILURE,
  GET_PUNCH_CARD_LIST_REQUEST,
  GET_PUNCH_CARD_LIST_SUCCESS,
  GET_PUNCH_CARD_LIST_FAILURE,
  GET_PUNCH_CARD_BY_ID_REQUEST,
  GET_PUNCH_CARD_BY_ID_SUCCESS,
  GET_PUNCH_CARD_BY_ID_FAILURE,
  SAVE_PUNCH_CARD_REQUEST,
  SAVE_PUNCH_CARD_SUCCESS,
  SAVE_PUNCH_CARD_FAILURE,
  SAVE_PUNCH_CARD_MENU_ITEM_REQUEST,
  SAVE_PUNCH_CARD_MENU_ITEM_SUCCESS,
  SAVE_PUNCH_CARD_MENU_ITEM_FAILURE,
  DELETE_PUNCH_CARD_MENU_ITEM_REQUEST,
  DELETE_PUNCH_CARD_MENU_ITEM_SUCCESS,
  DELETE_PUNCH_CARD_MENU_ITEM_FAILURE,
  GET_USER_PUNCH_CARDS_REQUEST,
  GET_USER_PUNCH_CARDS_SUCCESS,
  GET_USER_PUNCH_CARDS_FAILURE,
  GET_USER_STORE_CREDITS_POINTS_REQUEST,
  GET_USER_STORE_CREDITS_POINTS_SUCCESS,
  GET_USER_STORE_CREDITS_POINTS_FAILURE,
  SAVE_USER_STORE_CREDITS_POINTS_REQUEST,
  SAVE_USER_STORE_CREDITS_POINTS_SUCCESS,
  SAVE_USER_STORE_CREDITS_POINTS_FAILURE,
  GET_CREDITS_ANALYTICS_REQUEST,
  GET_CREDITS_ANALYTICS_SUCCESS,
  GET_CREDITS_ANALYTICS_FAILURE,
} from 'consts/loyalties';

export function getKickbacks({ sortName, sortDirection, page, pageLimit }) {
  return {
    type: GET_KICKBACKS_LIST_REQUEST,
    sortName,
    sortDirection,
    page,
    pageLimit,
    [WAIT_FOR_ACTION]: GET_KICKBACKS_LIST_SUCCESS,
    [ERROR_ACTION]: GET_KICKBACKS_LIST_FAILURE,
  };
}

export function getKickbackById(id) {
  return {
    type: GET_KICKBACK_BY_ID_REQUEST,
    id,
    [WAIT_FOR_ACTION]: GET_KICKBACK_BY_ID_SUCCESS,
    [ERROR_ACTION]: GET_KICKBACK_BY_ID_FAILURE,
  };
}

export function getKickbackHistory({
  userId,
  sortName,
  sortDirection,
  page,
  pageLimit,
}) {
  return {
    type: GET_KICKBACK_HISTORY_REQUEST,
    userId,
    sortName,
    sortDirection,
    page,
    pageLimit,
    [WAIT_FOR_ACTION]: GET_KICKBACK_HISTORY_SUCCESS,
    [ERROR_ACTION]: GET_KICKBACK_HISTORY_FAILURE,
  };
}

export function saveKickback({ id, pointAmount, name, storeCreditAward }) {
  return {
    type: SAVE_KICKBACKS_LIST_REQUEST,
    id,
    name,
    pointAmount,
    storeCreditAward,
    [WAIT_FOR_ACTION]: SAVE_KICKBACKS_LIST_SUCCESS,
    [ERROR_ACTION]: SAVE_KICKBACKS_LIST_FAILURE,
  };
}

export function deleteKickback(id) {
  return {
    type: DELETE_KICKBACK_REQUEST,
    id,
    [WAIT_FOR_ACTION]: DELETE_KICKBACK_SUCCESS,
    [ERROR_ACTION]: DELETE_KICKBACK_FAILURE,
  };
}

export function getPunchCards({ sortName, sortDirection, page, pageLimit }) {
  return {
    type: GET_PUNCH_CARD_LIST_REQUEST,
    sortName,
    sortDirection,
    page,
    pageLimit,
    [WAIT_FOR_ACTION]: GET_PUNCH_CARD_LIST_SUCCESS,
    [ERROR_ACTION]: GET_PUNCH_CARD_LIST_FAILURE,
  };
}

export function getPunchCardById(id) {
  return {
    type: GET_PUNCH_CARD_BY_ID_REQUEST,
    id,
    [WAIT_FOR_ACTION]: GET_PUNCH_CARD_BY_ID_SUCCESS,
    [ERROR_ACTION]: GET_PUNCH_CARD_BY_ID_FAILURE,
  };
}

export function savePunchCard({
  id,
  name,
  activationDate,
  deactivationDate,
  amountForFreeMenuItem,
  description,
  image,
}) {
  return {
    type: SAVE_PUNCH_CARD_REQUEST,
    id,
    name,
    activationDate,
    deactivationDate,
    amountForFreeMenuItem,
    description,
    image,
    [WAIT_FOR_ACTION]: SAVE_PUNCH_CARD_SUCCESS,
    [ERROR_ACTION]: SAVE_PUNCH_CARD_FAILURE,
  };
}

export function savePunchCardMenuItem({ id, menuItemId }) {
  return {
    type: SAVE_PUNCH_CARD_MENU_ITEM_REQUEST,
    id,
    menuItemId,
    [WAIT_FOR_ACTION]: SAVE_PUNCH_CARD_MENU_ITEM_SUCCESS,
    [ERROR_ACTION]: SAVE_PUNCH_CARD_MENU_ITEM_FAILURE,
  };
}

export function deletePunchCardMenuItem({ id, menuItemId }) {
  return {
    type: DELETE_PUNCH_CARD_MENU_ITEM_REQUEST,
    id,
    menuItemId,
    [WAIT_FOR_ACTION]: DELETE_PUNCH_CARD_MENU_ITEM_SUCCESS,
    [ERROR_ACTION]: DELETE_PUNCH_CARD_MENU_ITEM_FAILURE,
  };
}

export function getUserPunchCards({
  userId,
  sortName,
  sortDirection,
  page,
  pageLimit,
}) {
  return {
    type: GET_USER_PUNCH_CARDS_REQUEST,
    userId,
    sortName,
    sortDirection,
    page,
    pageLimit,
    [WAIT_FOR_ACTION]: GET_USER_PUNCH_CARDS_SUCCESS,
    [ERROR_ACTION]: GET_USER_PUNCH_CARDS_FAILURE,
  };
}

export function getUserStoreCreditsPoints({ userId }) {
  return {
    type: GET_USER_STORE_CREDITS_POINTS_REQUEST,
    userId,
    [WAIT_FOR_ACTION]: GET_USER_STORE_CREDITS_POINTS_SUCCESS,
    [ERROR_ACTION]: GET_USER_STORE_CREDITS_POINTS_FAILURE,
  };
}

export function saveUserStoreCreditsPoints({
  userId,
  points,
  storeCredits,
  kickbackDate,
}) {
  return {
    type: SAVE_USER_STORE_CREDITS_POINTS_REQUEST,
    userId,
    points,
    storeCredits,
    kickbackDate,
    [WAIT_FOR_ACTION]: SAVE_USER_STORE_CREDITS_POINTS_SUCCESS,
    [ERROR_ACTION]: SAVE_USER_STORE_CREDITS_POINTS_FAILURE,
  };
}

export function getStoreCreditsAnalytics(startDate, endDate) {
  return {
    type: GET_CREDITS_ANALYTICS_REQUEST,
    startDate,
    endDate,
    [WAIT_FOR_ACTION]: GET_CREDITS_ANALYTICS_SUCCESS,
    [ERROR_ACTION]: GET_CREDITS_ANALYTICS_FAILURE,
  };
}
