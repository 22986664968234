export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

export const UPDATE_ORDERS_REQUEST = 'UPDATE_ORDERS_REQUEST';
export const UPDATE_ORDERS_SUCCESS = 'UPDATE_ORDERS_SUCCESS';
export const UPDATE_ORDERS_FAILURE = 'UPDATE_ORDERS_FAILURE';

export const CHANGE_ORDER_STATUS_REQUEST = 'CHANGE_ORDER_STATUS_REQUEST';
export const CHANGE_ORDER_STATUS_SUCCESS = 'CHANGE_ORDER_STATUS_SUCCESS';
export const CHANGE_ORDER_STATUS_FAILURE = 'CHANGE_ORDER_STATUS_FAILURE';

export const CHANGE_MULTIPLE_ORDERS_STATUS_REQUEST =
  'CHANGE_MULTIPLE_ORDERS_STATUS_REQUEST';
export const CHANGE_MULTIPLE_ORDERS_STATUS_SUCCESS =
  'CHANGE_MULTIPLE_ORDERS_STATUS_SUCCESS';
export const CHANGE_MULTIPLE_ORDERS_STATUS_FAILURE =
  'CHANGE_MULTIPLE_ORDERS_STATUS_FAILURE';

export const SEND_MANUAL_OHEICS_ORDER_REQUEST =
  'SEND_MANUAL_OHEICS_ORDER_REQUEST';
export const SEND_MANUAL_OHEICS_ORDER_SUCCESS =
  'SEND_MANUAL_OHEICS_ORDER_SUCCESS';
export const SEND_MANUAL_OHEICS_ORDER_FAILURE =
  'SEND_MANUAL_OHEICS_ORDER_FAILURE';

export const GET_ORDER_DETAILS_REQUEST = 'GET_ORDER_DETAILS_REQUEST';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_FAILURE = 'GET_ORDER_DETAILS_FAILURE';

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';

export const ORDER_STATUSES_LABEL = {
  draft: 'Draft',
  pending: 'Pending',
  readyForPayment: 'Ready for Payment',
  done: 'Done',
  paid: 'Paid',
  readyForShipment: 'Ready for Shipment',
  inProduction: 'In Production',
  cancelled: 'Cancelled',
};

export const ORDER_TYPES_LABEL = {
  pickUp: 'Pick-up',
  delivery: 'Delivery',
  direct: 'Direct',
  grabAndGo: 'Grab & Go',
};

export const ORDER_STATUSES = {
  draft: 'draft',
  pending: 'pending',
  done: 'done',
  readyForPayment: 'readyForPayment',
  paid: 'paid',
  inProduction: 'inProduction',
  readyForShipment: 'readyForShipment',
  cancelled: 'cancelled',
};

export const ORDER_TYPES = {
  delivery: 'delivery',
  pickUp: 'pickUp',
};

export const OHEICS_ORDER_STATUSES = {
  failed: 'failed',
};

export const ABLE_STATUSES_TO_CANCEL_ORDER = {
  [ORDER_STATUSES.paid]: ORDER_STATUSES.paid,
  [ORDER_STATUSES.inProduction]: ORDER_STATUSES.inProduction,
  [ORDER_STATUSES.readyForShipment]: ORDER_STATUSES.readyForShipment,
};

export const ABLE_STATUSES_TO_PRINT = {
  [ORDER_STATUSES.paid]: ORDER_STATUSES.paid,
  [ORDER_STATUSES.inProduction]: ORDER_STATUSES.inProduction,
  [ORDER_STATUSES.readyForShipment]: ORDER_STATUSES.readyForShipment,
};

export const ABLE_TYPES_TO_PRINT = {
  [ORDER_TYPES.delivery]: ORDER_TYPES.delivery,
  [ORDER_TYPES.pickUp]: ORDER_TYPES.pickUp,
};

export const COMMENT_TO_EDIT_ORDER = 'commentToEditOrder';

export const USER_DISCOUNT = 'UserDiscount';
export const STORE_CREDITS_DISCOUNT = 'StoreCreditsDiscount';
export const COMPANY_DISCOUNT = 'CompanyDiscount';
export const PUNCH_CARD_DISCOUNT = 'PunchCardDiscount';
export const COMPLIMENT_DISCOUNT = 'ComplimentDiscount';
export const EXTERNAL_DISCOUNT = 'externalDiscount';
export const POS_DISCOUNT = 'PosDiscount';
