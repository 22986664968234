import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMER_BY_ID_REQUEST,
  GET_CUSTOMER_BY_ID_SUCCESS,
  GET_CUSTOMER_BY_ID_FAILURE,
  SAVE_CUSTOMER_REQUEST,
  SAVE_CUSTOMER_SUCCESS,
  SAVE_CUSTOMER_FAILURE,
  CHANGE_CUSTOMER_STATUS_REQUEST,
  CHANGE_CUSTOMER_STATUS_SUCCESS,
  CHANGE_CUSTOMER_STATUS_FAILURE,
  GET_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
  GET_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
  GET_CUSTOMER_DELIVERY_ADDRESSES_FAILURE,
  GET_CUSTOMER_DELIVERY_REQUEST,
  GET_CUSTOMER_DELIVERY_SUCCESS,
  GET_CUSTOMER_DELIVERY_FAILURE,
  CHANGE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
  CHANGE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
  CHANGE_CUSTOMER_DELIVERY_ADDRESSES_FAILURE,
  SAVE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
  SAVE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
  SAVE_CUSTOMER_DELIVERY_ADDRESSES_FAILURE,
  CLEAR_USER_REQUEST,
  CLEAR_USER_SUCCESS,
  CLEAR_USER_FAILURE,
  SEARCH_CUSTOMER_REQUEST,
} from 'consts/customers';

export function getCustomers({
  sortName,
  sortDirection,
  status,
  ids,
  email,
  phone,
  firstName,
  lastName,
  page,
  pageLimit,
  getAllItems,
}) {
  return {
    type: GET_CUSTOMERS_REQUEST,
    sortName,
    sortDirection,
    status,
    ids,
    email,
    phone,
    firstName,
    lastName,
    page,
    pageLimit,
    getAllItems,
    [WAIT_FOR_ACTION]: GET_CUSTOMERS_SUCCESS,
    [ERROR_ACTION]: GET_CUSTOMERS_FAILURE,
  };
}

export function getCustomerById(id, withLoyalty) {
  return {
    type: GET_CUSTOMER_BY_ID_REQUEST,
    id,
    withLoyalty,
    [WAIT_FOR_ACTION]: GET_CUSTOMER_BY_ID_SUCCESS,
    [ERROR_ACTION]: GET_CUSTOMER_BY_ID_FAILURE,
  };
}

export function saveCustomer({
  id,
  firstName,
  lastName,
  email,
  phone,
  status,
  roles,
  discount,
  discountId,
  discountDate,
  compliment,
  complimentId,
  punchCardsToChange,
}) {
  return {
    type: SAVE_CUSTOMER_REQUEST,
    id,
    firstName,
    lastName,
    email,
    phone,
    status,
    roles,
    discount,
    discountId,
    discountDate,
    compliment,
    complimentId,
    punchCardsToChange,
    [WAIT_FOR_ACTION]: SAVE_CUSTOMER_SUCCESS,
    [ERROR_ACTION]: SAVE_CUSTOMER_FAILURE,
  };
}

export function changeCustomerStatus({ id, status }) {
  return {
    type: CHANGE_CUSTOMER_STATUS_REQUEST,
    id,
    status,
    [WAIT_FOR_ACTION]: CHANGE_CUSTOMER_STATUS_SUCCESS,
    [ERROR_ACTION]: CHANGE_CUSTOMER_STATUS_FAILURE,
  };
}

export function getCustomerDeliveryAddresses({ userId }) {
  return {
    type: GET_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
    userId,
    [WAIT_FOR_ACTION]: GET_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
    [ERROR_ACTION]: GET_CUSTOMER_DELIVERY_ADDRESSES_FAILURE,
  };
}

export function getCustomerDelivery(deliveryId) {
  return {
    type: GET_CUSTOMER_DELIVERY_REQUEST,
    deliveryId,
    [WAIT_FOR_ACTION]: GET_CUSTOMER_DELIVERY_SUCCESS,
    [ERROR_ACTION]: GET_CUSTOMER_DELIVERY_FAILURE,
  };
}

export function changeCustomerDeliveryAddress({
  id,
  userId,
  name,
  address,
  postcode,
  phone,
  notice,
  locationId,
  city,
  latitude,
  longitude,
}) {
  return {
    type: CHANGE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
    id,
    userId,
    name,
    address,
    postcode,
    phone,
    notice,
    locationId,
    city,
    latitude,
    longitude,
    [WAIT_FOR_ACTION]: CHANGE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
    [ERROR_ACTION]: CHANGE_CUSTOMER_DELIVERY_ADDRESSES_FAILURE,
  };
}

export function saveCustomerDeliveryAddress({
  userId,
  name,
  address,
  postcode,
  phone,
  notice,
  city,
  latitude,
  longitude,
}) {
  return {
    type: SAVE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
    userId,
    name,
    address,
    postcode,
    phone,
    notice,
    city,
    latitude,
    longitude,
    [WAIT_FOR_ACTION]: SAVE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
    [ERROR_ACTION]: SAVE_CUSTOMER_DELIVERY_ADDRESSES_FAILURE,
  };
}

export function clearCustomer(id) {
  return {
    type: CLEAR_USER_REQUEST,
    id,
    [WAIT_FOR_ACTION]: CLEAR_USER_SUCCESS,
    [ERROR_ACTION]: CLEAR_USER_FAILURE,
  };
}

export function searchCustomers({
  status,
  email,
  phone,
  lastName,
  firstName,
  ids,
}) {
  return {
    type: SEARCH_CUSTOMER_REQUEST,
    status,
    email,
    phone,
    lastName,
    firstName,
    ids,
    [WAIT_FOR_ACTION]: GET_CUSTOMERS_SUCCESS,
    [ERROR_ACTION]: GET_CUSTOMERS_FAILURE,
  };
}
