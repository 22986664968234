import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';

import ColorPickerLabel from 'components/ColorPickerLabel';

import { renderMultilineText } from '../utils';

import styles from './styles';

class ListDetails extends React.PureComponent {
  render() {
    const { classes, item, onDelete } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <Typography className={classes.description}>
          {item.itemsPerRow} {item.itemsPerRow === 1 ? 'item' : 'items'} per row
        </Typography>
        {item.items && item.items.length > 0 && (
          <ul className={classes.listWrapper}>
            {item.items.map((listItem, index) => (
              <React.Fragment key={listItem.id}>
                <Divider
                  variant="middle"
                  component="li"
                  style={{ margin: '20px 16px 20px 40px' }}
                />
                <li className={classes.listItem}>
                  <div className={classes.counter}>{index + 1}.</div>
                  <div className={classes.listWrapper}>
                    {listItem.title && (
                      <div className={classes.itemWrapper}>
                        <Typography className={classes.itemKey}>
                          Title
                        </Typography>
                        <Typography className={classes.itemValue}>
                          {renderMultilineText(listItem.title)}
                        </Typography>
                      </div>
                    )}
                    {listItem.description && (
                      <div className={classes.itemWrapper}>
                        <Typography className={classes.itemKey}>
                          Description
                        </Typography>
                        <Typography className={classes.itemValue}>
                          {renderMultilineText(listItem.description)}
                        </Typography>
                      </div>
                    )}
                    {listItem.link && listItem.link.label && (
                      <React.Fragment>
                        <div className={classes.itemWrapper}>
                          <Typography className={classes.itemKey}>
                            Link label
                          </Typography>
                          <Typography className={classes.itemValue}>
                            {listItem.link.label}
                          </Typography>
                        </div>
                        <div className={classes.itemWrapper}>
                          <Typography className={classes.itemKey}>
                            Link URL
                          </Typography>
                          <Typography className={classes.itemValue}>
                            {listItem.link.url}
                          </Typography>
                        </div>
                        <div className={classes.itemWrapper}>
                          <Typography className={classes.itemKey}>
                            Link text align
                          </Typography>
                          <Typography className={classes.itemValue}>
                            {listItem.link.textAlign}
                          </Typography>
                        </div>
                      </React.Fragment>
                    )}
                    <div className={classes.itemWrapper}>
                      <Typography className={classes.itemKey}>
                        Text align
                      </Typography>
                      <Typography className={classes.itemValue}>
                        {listItem.textAlign}
                      </Typography>
                    </div>
                    {listItem.title && (
                      <div className={classes.itemWrapper}>
                        <Typography className={classes.itemKey}>
                          Title text align
                        </Typography>
                        <Typography className={classes.itemValue}>
                          {listItem.titleAlign}
                        </Typography>
                      </div>
                    )}
                    <div className={classes.itemWrapper}>
                      <Typography className={classes.itemKey}>
                        Background color
                      </Typography>
                      <div className={classes.itemValue}>
                        <ColorPickerLabel color={listItem.backgroundColor} />
                      </div>
                    </div>
                    <div className={classes.itemWrapper}>
                      <Typography className={classes.itemKey}>
                        Text color
                      </Typography>
                      <div className={classes.itemValue}>
                        <ColorPickerLabel color={listItem.textColor} />
                      </div>
                    </div>
                    {listItem.title && (
                      <div className={classes.itemWrapper}>
                        <Typography className={classes.itemKey}>
                          Title color
                        </Typography>
                        <div className={classes.itemValue}>
                          <ColorPickerLabel color={listItem.titleColor} />
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              </React.Fragment>
            ))}
          </ul>
        )}
        <div className={classes.btnWrapper}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Link to={`/list-section-details/${item.id}`}>
              <Button color="primary" className={classes.button}>
                Edit
              </Button>
            </Link>
          </div>
          <Button
            color="primary"
            className={classes.button}
            onClick={() => onDelete(item.id)}
          >
            Delete
          </Button>
        </div>
      </div>
    );
  }
}

ListDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListDetails);
