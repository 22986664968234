import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';

import SectionHeading from 'components/SectionHeading';
import SectionHint from 'components/SectionHint';

class Section extends React.PureComponent {
  render() {
    const {
      heading,
      hint,
      children,
      withoutDivider,
      withoutMargin,
      button,
    } = this.props;
    return (
      <React.Fragment>
        {!withoutDivider && <Divider style={{ margin: '40px 0' }} />}
        <div
          style={{
            padding: '30px 40px',
            marginLeft: withoutMargin ? 0 : -40,
            marginRight: withoutMargin ? 0 : -40,
            backgroundColor: '#fafafa',
          }}
        >
          {heading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <SectionHeading>{heading}</SectionHeading>
              {button}
            </div>
          )}
          {hint && <SectionHint>{hint}</SectionHint>}
          {children}
        </div>
      </React.Fragment>
    );
  }
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  hint: PropTypes.string,
  withoutDivider: PropTypes.bool,
  withoutMargin: PropTypes.bool,
  button: PropTypes.node,
};

export default Section;
