import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { routerPassId, hideIfNoData } from 'utils/enhancers';
import { wrapWithSideEffect } from 'utils';

import { showLoading, hideLoading } from 'actions/ui';
import { getOrder, updateOrder } from 'actions/orders';
import { saveCustomerDeliveryAddress } from 'actions/customers';

import OrderDetailsPage from './OrderDetailsPage';

const mapDispatchToProps = {
  onInit: getOrder,
  onSaveDeliveryAddress: saveCustomerDeliveryAddress,
  onSubmit: updateOrder,
  showLoading,
  hideLoading,
};

const state = compose(withState('initialData', 'setInitialData'));

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const refresh = (props) => {
  const { id, onInit, setInitialData } = props;
  return (
    id &&
    wrapWithProgress(props)(
      onInit({ id, getRestaurant: true }).then(setInitialData)
    )
  );
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSaveDeliveryAddress: (props) => (data) => {
    const { onSaveDeliveryAddress } = props;
    return wrapWithProgress(props)(onSaveDeliveryAddress(data));
  },
  onSubmit: (props) => (event) =>
    wrapWithProgress(props)(props.onSubmit(event).then(() => refresh(props))),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
  componentWillReceiveProps(newProps) {
    if (newProps.id !== this.props.id) {
      this.props.onInit();
    }
  },
});

export default compose(
  routerPassId,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => props.id && !props.initialData)
)(OrderDetailsPage);
