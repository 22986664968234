import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { STAMP_CARD_FREE_ITEMS_AMOUNT } from 'consts/loyalties';
import {
  formatOrderDeliveryDateTime,
  formatDateTime,
  convertDefaultToUTC,
  convertCurrentTimezoneToUTC,
  addYearsToDays,
  changeDateMinutes,
} from 'utils/date';
import { DATETIME_FORMAT } from 'consts';

import DateTimePicker from 'components/DateTimePicker';
import PageHeading from 'components/PageHeading';
import PageHeadingTitle from 'components/PageHeadingTitle';
import StatusDropdown from 'components/StatusDropdown';
import Section from 'components/Section';
import ImageUpload from 'components/ImageUpload';
import MenuItems from './MenuItems';

const statuses = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Expired',
    value: 'expired',
  },
];

const stateFromProps = ({ initialData = {} }) => {
  return {
    name: initialData.name || '',
    description: initialData.description || '',
    image: initialData.image || '',
    status:
      initialData.deactivationDate &&
      Date.now() > new Date(initialData.deactivationDate).getTime()
        ? 'expired'
        : 'active',
    amountForFreeMenuItem:
      initialData.amountForFreeMenuItem || `${STAMP_CARD_FREE_ITEMS_AMOUNT}`,
    deactivationDate: initialData.deactivationDate
      ? formatOrderDeliveryDateTime(initialData.deactivationDate)
      : addYearsToDays({
          date: convertCurrentTimezoneToUTC(new Date()),
          years: 1,
        }),
    menuItems: initialData.menuItems || [],
  };
};

const styles = () => ({
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formBlock: {
    flex: 1,
    '&:nth-child(1)': {
      marginRight: 30,
    },
  },
});

function convertSelectedDate(time) {
  const formatedDateTime = formatDateTime(time, DATETIME_FORMAT);
  const utcTime = convertDefaultToUTC(formatedDateTime);
  return utcTime;
}

class StampCardDetailsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleSubmit = () => {
    const { initialData = {}, onSave, id } = this.props;
    const { ...values } = this.state;
    const data = { ...initialData, ...values };
    const validateError = this.validateError(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      const dataToSend = {
        name: data.name,
        activationDate:
          convertSelectedDate(data.activationDate) ||
          convertCurrentTimezoneToUTC(new Date(), DATETIME_FORMAT),
        deactivationDate: convertSelectedDate(data.deactivationDate),
        amountForFreeMenuItem: parseInt(data.amountForFreeMenuItem, 10),
        description: data.description,
        image: data.image,
        menuItems: (initialData.menuItems || []).map((item) => ({
          menuItemId: item.id,
        })),
      };
      onSave({
        ...dataToSend,
        id,
      })
        .then(() => {
          toastr.success(
            'Success!',
            id
              ? 'Stamp card has been updated'
              : 'New Stamp card has been created'
          );
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  validateError = (data) => {
    if (data.amountForFreeMenuItem < STAMP_CARD_FREE_ITEMS_AMOUNT) {
      return `Free stamps should be greater than ${STAMP_CARD_FREE_ITEMS_AMOUNT}`;
    }
    if (!data.name) {
      return 'Please provide name for Stamp card';
    }
    if (!data.description) {
      return 'Please provide description for Stamp card';
    }
  };

  handleStatusChange = (status) => {
    const stateToChange = { status };
    if (status === 'expired') {
      // if changing to expired, set deactivationDate to current time - 1 minute
      stateToChange.deactivationDate = changeDateMinutes({
        minutes: 1,
        format: DATETIME_FORMAT,
      });
    } else {
      // if changing to expired, set deactivationDate to current time + 1 year
      stateToChange.deactivationDate = addYearsToDays({
        years: 1,
        format: DATETIME_FORMAT,
      });
    }
    this.setState({ ...stateToChange });
  };

  handleDeactivationDateChange = (time) => {
    this.setState({ deactivationDate: time });
  };

  handleDeleteMenuItem = (menuItemId) => {
    const { onDeleteMenuItem, id } = this.props;
    onDeleteMenuItem({ id, menuItemId });
  };

  handleAddMenuItem = (menuItem) => {
    const { onAddMenuItem, id } = this.props;
    onAddMenuItem({ id, menuItemId: menuItem.id });
  };

  render() {
    const { id, initialData: stamCard, classes } = this.props;
    const {
      name,
      description,
      image,
      status,
      amountForFreeMenuItem,
      deactivationDate,
    } = this.state;
    return (
      <div>
        <PageHeading
          title={
            id ? (
              <PageHeadingTitle
                text="Stamp card"
                highlightedText={stamCard.name || 'No Name'}
              />
            ) : (
              'New Stamp card'
            )
          }
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        <div className={classes.formWrapper}>
          <div className={classes.formBlock}>
            <div>
              <Input
                value={name}
                onChange={this.handleChange('name')}
                placeholder="Name"
                label="Name"
                margin="normal"
                style={{ width: '100%' }}
              />
            </div>
            <div>
              <ImageUpload
                image={image}
                onChange={this.handleChange('image')}
              />
            </div>
            <div>
              <StatusDropdown
                value={status}
                onChange={this.handleStatusChange}
                options={statuses}
              />
            </div>
            <div>
              <Input
                value={amountForFreeMenuItem}
                type="number"
                min="1"
                onChange={this.handleChange('amountForFreeMenuItem')}
                placeholder="Number of stamps"
                label="Number of stamps (3-20) needed to complete the card"
                margin="normal"
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div className={classes.formBlock}>
            <div>
              <Input
                multiline
                value={description}
                onChange={this.handleChange('description')}
                placeholder="Description"
                label="Description"
                margin="normal"
                variant="outlined"
                inputProps={{
                  style: { minHeight: 120, minWidth: 300 },
                }}
              />
            </div>
            <div>
              <DateTimePicker
                label="Deactivation Date"
                value={new Date(deactivationDate)}
                onChange={this.handleDeactivationDateChange}
              />
            </div>
          </div>
        </div>
        {id && (
          <Section heading="Menu Items">
            <MenuItems
              items={stamCard.menuItems || []}
              onDelete={this.handleDeleteMenuItem}
              onAddMenuItem={this.handleAddMenuItem}
            />
          </Section>
        )}
      </div>
    );
  }
}

StampCardDetailsPage.propTypes = {
  id: PropTypes.number,
  initialData: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onAddMenuItem: PropTypes.func.isRequired,
  onDeleteMenuItem: PropTypes.func.isRequired,
};

export default withStyles(styles)(StampCardDetailsPage);
