import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import SmallButton from 'components/SmallButton';

class ButtonWithTooltip extends React.PureComponent {
  render() {
    const { children, hint, ...rest } = this.props;
    return rest.disabled ? (
      <Tooltip title={<p style={{ fontSize: 12 }}>{hint}</p>}>
        <span>
          <SmallButton {...rest}>{children}</SmallButton>
        </span>
      </Tooltip>
    ) : (
      <SmallButton {...rest}>{children}</SmallButton>
    );
  }
}

ButtonWithTooltip.propTypes = {
  children: PropTypes.node,
  hint: PropTypes.string,
};

export default ButtonWithTooltip;
