import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';
import { toastr } from 'react-redux-toastr';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Input from '@material-ui/core/TextField';

import PageHeading from 'components/PageHeading';

import { capitilizeString, reorder } from 'utils/processors';

import styles from './styles';

const checkAllVisibility = (items) => items.every((el) => el.visible);

class MenuItemsSortList extends React.PureComponent {
  state = {
    items: this.props.items,
    openModal: false,
    moveToPosition: 1,
    clickedItemIndex: undefined,
    checkAll:
      this.props.items && this.props.items.length
        ? checkAllVisibility(this.props.items)
        : false,
  };

  reorder = (sourceIndex, destinationIndex, cb) => {
    const { items } = this.state;
    const newItems = reorder(items, sourceIndex, destinationIndex);

    this.setState({ items: newItems }, cb && cb());
  };

  handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    this.reorder(result.source.index, result.destination.index);
  };

  handleChangeVisibility = ({ target: { checked } }, index) => {
    const { items } = this.state;
    const newItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, visible: checked };
      }
      return item;
    });
    this.setState({
      items: newItems,
      checkAll: checkAllVisibility(newItems),
    });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { items } = this.state;
    onSubmit(items);
  };

  handleCancel = () => {
    this.setState({
      items: this.props.items,
      checkAll:
        this.props.items && this.props.items.length
          ? checkAllVisibility(this.props.items)
          : false,
    });
  };

  handleItemClick = (event, index) => {
    this.setState({ clickedItemIndex: index, openModal: true });
  };

  handleClose = () => {
    this.setState({
      openModal: false,
      clickedItemIndex: undefined,
      moveToPosition: 1,
    });
  };

  handleMoveClick = (position) => {
    const { clickedItemIndex } = this.state;
    this.reorder(
      clickedItemIndex,
      position,
      this.setState({ openModal: false })
    );
  };

  handlePositionKeyPress = (e) => {
    if (e.key === 'Enter') {
      const { clickedItemIndex, moveToPosition, items } = this.state;
      if (moveToPosition < 1 || moveToPosition > items.length) {
        toastr.error('Error!', 'Wrong position number');
        return;
      }
      this.reorder(
        clickedItemIndex,
        moveToPosition - 1,
        this.setState({ openModal: false })
      );
    }
  };

  handleChangePosition = ({ target: { value } }) => {
    this.setState({ moveToPosition: value });
  };

  handleCheckAll = ({ target: { checked } }) => {
    const { items } = this.state;
    this.setState({
      checkAll: checked,
      items: items.map((item) => ({ ...item, visible: checked })),
    });
  };

  render() {
    const { classes, type } = this.props;
    const { items, openModal, moveToPosition, checkAll } = this.state;
    return (
      <Fragment>
        <PageHeading
          title={`${capitilizeString(type)} list sorting`}
          right={
            <div>
              <Button
                onClick={this.handleCancel}
                variant="contained"
                size="large"
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                size="large"
                style={{ marginLeft: 24 }}
              >
                Save
              </Button>
            </div>
          }
        />
        <div className={classes.description}>
          This affect the menu items order in the {type} list
        </div>
        <div className={classes.headItemsWrapper}>
          <div className={cn(classes.headItem, classes.orderCell)}>
            <Checkbox
              className={classes.visibilityCheckbox}
              checked={checkAll}
              onChange={this.handleCheckAll}
            />
          </div>
          <div className={cn(classes.headItem, classes.visibleCell)}>
            Visible
          </div>
          <div className={classes.headItem}>Name</div>
          <div className={classes.headItem}>Category</div>
        </div>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <ul className={classes.menuItemsList} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        className={classes.menuItemsListItem}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          className={cn(
                            classes.menuItemsListItemName,
                            classes.orderCell
                          )}
                        >
                          {index + 1}
                        </div>
                        <div
                          className={cn(
                            classes.menuItemsListItemName,
                            classes.visibleCell
                          )}
                        >
                          <Checkbox
                            className={classes.visibilityCheckbox}
                            checked={item.visible}
                            onChange={(e) =>
                              this.handleChangeVisibility(e, index)
                            }
                          />
                        </div>
                        <div
                          className={classes.menuItemsListItemName}
                          onClick={(event) =>
                            this.handleItemClick(event, index)
                          }
                        >
                          {item.name}
                        </div>
                        <div className={classes.menuItemsListItemCategory}>
                          {item.categoryName}
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <Dialog open={openModal} onClose={this.handleClose}>
          <div>
            <List>
              <ListItem button onClick={() => this.handleMoveClick(0)}>
                Move to the top
              </ListItem>
              <ListItem
                button
                onClick={() => this.handleMoveClick(items.length)}
              >
                Move to the bottom
              </ListItem>
              <ListItem>
                Move to position
                <Input
                  value={moveToPosition}
                  placeholder="Name"
                  label="Name"
                  margin="normal"
                  type="number"
                  onChange={this.handleChangePosition}
                  style={{ width: 75, marginLeft: 16 }}
                  onKeyPress={this.handlePositionKeyPress}
                />
              </ListItem>
            </List>
          </div>
        </Dialog>
      </Fragment>
    );
  }
}

MenuItemsSortList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default injectSheet(styles)(MenuItemsSortList);
