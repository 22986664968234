import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import {
  getCustomerById,
  saveCustomer,
  changeCustomerStatus,
  clearCustomer,
} from 'actions/customers';
import { showLoading, hideLoading } from 'actions/ui';

import { routerPassId, hideIfNoData } from 'utils/enhancers';

import { wrapWithSideEffect } from 'utils';

import CustomersDetailsPage from './CustomersDetailsPage';

const mapDispatchToProps = {
  onInit: getCustomerById,
  onSubmit: saveCustomer,
  onChangeCustomerStatus: changeCustomerStatus,
  onClearCustomer: clearCustomer,
  showLoading,
  hideLoading,
};

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props) => {
  const { id, onInit, setInitialData } = props;
  return id && wrapWithProgress(props)(onInit(id).then(setInitialData));
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSubmit: (props) => (event) => {
    const { onSubmit } = props;
    return wrapWithProgress(props)(onSubmit(event).then(() => refresh(props)));
  },
  onChangeCustomerStatus: (props) => (event) => {
    const { onChangeCustomerStatus } = props;
    return wrapWithProgress(props)(
      onChangeCustomerStatus(event).then(() => refresh(props))
    );
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
  componentWillReceiveProps(newProps) {
    if (newProps.id !== this.props.id) {
      this.props.onInit();
    }
  },
});

export default compose(
  routerPassId,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => props.id && !props.initialData)
)(CustomersDetailsPage);
