import { put, all } from 'redux-saga/effects';

import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  UPDATE_ORDERS_REQUEST,
  UPDATE_ORDERS_SUCCESS,
  UPDATE_ORDERS_FAILURE,
  CHANGE_ORDER_STATUS_REQUEST,
  CHANGE_ORDER_STATUS_SUCCESS,
  CHANGE_MULTIPLE_ORDERS_STATUS_REQUEST,
  CHANGE_MULTIPLE_ORDERS_STATUS_SUCCESS,
  GET_ORDER_DETAILS_REQUEST,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  SEND_MANUAL_OHEICS_ORDER_REQUEST,
  SEND_MANUAL_OHEICS_ORDER_SUCCESS,
  SEND_MANUAL_OHEICS_ORDER_FAILURE,
  OHEICS_ORDER_STATUSES,
  ORDER_TYPES,
  COMMENT_TO_EDIT_ORDER,
  USER_DISCOUNT,
  STORE_CREDITS_DISCOUNT,
  COMPANY_DISCOUNT,
  PUNCH_CARD_DISCOUNT,
  COMPLIMENT_DISCOUNT,
  POS_DISCOUNT,
} from 'consts/orders';
import { TIME_ZONE_LABEL } from 'consts/restaurants';
import { prepareURLRequest, findAttributeValue } from 'utils';
import {
  formatOrderDeliveryDateTime,
  convertFromUTCToTimeZone,
  formatOrderFiltersDate,
} from 'utils/date';
import { getOpeningHoursList, getSpecialHoursList } from 'utils/restaurant';

import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';
import {
  getParamsMenuItems,
  getSimpleMenuItems,
  getMenuItemGroupsForOrder,
} from 'sagas/menu-items/menu-items';
import { getCompanies } from 'sagas/companies';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';
import { getDeliveryCostById } from 'sagas/settings';
import {
  getOrdersByDeliveryRequest,
  processOrdersWithDeliveries,
  getOrdersRequest,
  getOrderPickupNumber,
} from 'sagas/processOrders';
import { getOrderDeliveries } from 'sagas/orderDeliveries';
import {
  getOrderAttributes,
  getOrderAttributesValues,
  updateOrderAttributeValue,
} from 'sagas/attributes';
import { getRestaurantById } from 'sagas/restaurants';
import { getOrderCompliment, getUserCompliments } from 'sagas/loyalties';
import { getCustomerById } from 'sagas/customers';

export function* getOrders({
  sortName,
  sortDirection,
  restaurantId,
  startTime,
  endTime,
  status,
  page,
  pageLimit,
  orderType,
  userId,
  companyId,
  orderId,
  getOrdersByDelivery,
  withCompany = false,
  withOheicsLogs = false,
  getLocations,
}) {
  try {
    let payload;

    if (getOrdersByDelivery) {
      payload = yield getOrdersByDeliveryRequest({
        sortName,
        sortDirection,
        restaurantId,
        startTime,
        endTime,
        status,
        page,
        pageLimit,
        orderType,
        userId,
        companyId,
        orderId,
        getLocations,
      });
    } else {
      let ids = '';
      let deliveryItems = [];
      let createDate = '';
      if (startTime && endTime) {
        createDate = `${formatOrderFiltersDate(
          startTime
        )},${formatOrderFiltersDate(endTime)}`;
      }
      payload = yield getOrdersRequest({
        sortName,
        sortDirection,
        restaurantId,
        createDate,
        ids,
        status,
        page,
        pageLimit,
        orderType,
        userId,
        orderId,
      });
      payload.items = yield processOrdersWithDeliveries({
        orderIds: payload.items.map((order) => order.id),
        orders: payload.items,
        deliveryItems,
        getDelivery: true,
      });
    }
    const orderIds = payload.items.map((order) => order.id);

    if (withOheicsLogs) {
      const ordersMap = yield getOrderOheicsMap({
        orderIds,
        status: OHEICS_ORDER_STATUSES.failed,
      });
      if (ordersMap && ordersMap.items && ordersMap.items.length) {
        const failedOrders = ordersMap.items.map((item) => item.orderId);
        const oheicsLogs = yield getOrderOheicsLogs({ orderIds: failedOrders });
        if (oheicsLogs && oheicsLogs.items && oheicsLogs.items.length) {
          const oheicsLogsDict = oheicsLogs.items.reduce((acc, log) => {
            if (!acc[log.orderId]) acc[log.orderId] = [];
            acc[log.orderId].push(log);
            return acc;
          }, {});
          payload = {
            ...payload,
            items: payload.items.map((item) => ({
              ...item,
              oheicsErrors:
                oheicsLogsDict && oheicsLogsDict[item.id]
                  ? oheicsLogsDict[item.id]
                  : [],
            })),
          };
        }
      }
    }

    if (withCompany) {
      let companyAttrId;

      const attrResponse = yield getOrderAttributes({ name: 'companyId' });
      if (attrResponse && attrResponse.items && attrResponse.items.length) {
        companyAttrId = attrResponse.items[0].id;
      }

      const attrValues = yield getOrderAttributesValues({
        attributeId: companyAttrId,
        orderId: orderIds,
        withoutLimit: true,
      });
      const companiesIds = [];
      const companyOrderDict = attrValues.items.reduce((acc, attrVal) => {
        const companyId = parseInt(attrVal.value, 10);
        companiesIds.push(companyId);
        acc[attrVal.orderId] = companyId;
        return acc;
      }, {});

      let companiesDict;
      if (companiesIds && companiesIds.length) {
        const companies = yield getCompanies({ ids: companiesIds });
        companiesDict =
          companies && companies.items
            ? companies.items.reduce((acc, company) => {
                acc[company.id] = company;
                return acc;
              }, {})
            : {};
      }

      payload = {
        ...payload,
        items: payload.items.map((item) => ({
          ...item,
          company:
            companiesDict && companyOrderDict[item.id]
              ? companiesDict[companyOrderDict[item.id]]
              : undefined,
        })),
      };
    }

    const newAction = {
      type: GET_ORDERS_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getOrdersSaga() {
  yield takeFirst(GET_ORDERS_REQUEST, getOrders);
}

/**
 * Change order status
 * @param {Object} {id, status} - order id, status to change
 * @return {Object} Order with new status.
 */
export function* changeOrderStatus({ id, status }) {
  const url = `/orders/${id}/status/${status}`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: CHANGE_ORDER_STATUS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* changeOrderStatusSaga() {
  yield takeFirst(CHANGE_ORDER_STATUS_REQUEST, changeOrderStatus);
}

/**
 * Change status for multiple orders
 * @param {Object} {ids, status} - array of orders ids, status to change
 * @return {Array} array of orders with new status.
 */
export function* changeMultipleOrdersStatus({ ids, statusToChange }) {
  try {
    const payload = yield all(
      ids.map((id) => changeOrderStatus({ id, status: statusToChange }))
    );
    const newAction = {
      type: CHANGE_MULTIPLE_ORDERS_STATUS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    return;
  }
}

export function* changeMultipleOrdersStatusSaga() {
  yield takeFirst(
    CHANGE_MULTIPLE_ORDERS_STATUS_REQUEST,
    changeMultipleOrdersStatus
  );
}

/**
 * Retrieve order oheics map
 * @param {Object} {orderIds, status} - array of orders ids, order status
 * @return {Object} Response object
 */
export function* getOrderOheicsMap({ orderIds, status }) {
  const params = [];
  if (orderIds && orderIds.length) params.push(`orderId=${orderIds.join(',')}`);
  if (status) params.push(`status=${status}`);

  const url = '/oheics-order-map';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(prepareURLRequest(url, params), options);
  return payload;
}

/**
 * Retrieve order oheics logs
 * @param {Object} {orderIds} - array of orders ids
 * @return {Object} Response object
 */
export function* getOrderOheicsLogs({ orderIds }) {
  const params = [];
  if (orderIds && orderIds.length) params.push(`orderId=${orderIds.join(',')}`);

  const url = '/oheics-logs';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(prepareURLRequest(url, params), options);
  return payload;
}

/**
 * Send manual oheics order for failed order
 * @param {Object} {orderIds} - array of orders ids
 */
export function* sendManualOheicsOrder({ orderId }) {
  try {
    const url = `/oheics/orders/${orderId}`;
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SEND_MANUAL_OHEICS_ORDER_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
    yield put({ type: SEND_MANUAL_OHEICS_ORDER_FAILURE, error });
  }
}

export function* sendManualOheicsOrderSaga() {
  yield takeFirst(SEND_MANUAL_OHEICS_ORDER_REQUEST, sendManualOheicsOrder);
}

export function* getOrderDetails({ id }) {
  const url = `/orders/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const order = yield apiRequest(url, options);

    const deliveryRespons = yield getOrderDeliveries({
      orderIds: [id],
      getLocations: true,
    });
    const delivery =
      deliveryRespons &&
      deliveryRespons.items &&
      deliveryRespons.items.length > 0
        ? deliveryRespons.items[0]
        : {};

    const orderItemsIds =
      order.orderItems && order.orderItems.length > 0
        ? order.orderItems.map((mI) => mI.menuItemId)
        : [];
    const menuItems =
      orderItemsIds && orderItemsIds.length > 0
        ? yield getSimpleMenuItems({ ids: orderItemsIds })
        : [];
    const orderItemsDict =
      order.orderItems && order.orderItems.length > 0
        ? order.orderItems.reduce((acc, item) => {
            if (acc[item.menuItemId]) {
              acc[item.menuItemId] = {
                ...acc[item.menuItemId],
                quantity: acc[item.menuItemId].quantity + item.quantity,
                price: acc[item.menuItemId].price + item.price,
                quantityOfFree:
                  item.price === 0
                    ? acc[item.menuItemId].quantityOfFree + item.quantity
                    : acc[item.menuItemId].quantityOfFree,
              };
            } else {
              acc[item.menuItemId] = {
                ...item,
                quantityOfFree: item.price === 0 ? item.quantity : 0,
              };
            }
            return acc;
          }, {})
        : {};
    let pickupNumber = null;
    if (order.type === ORDER_TYPES.pickUp) {
      pickupNumber = yield getOrderPickupNumber(id);
    }
    let deliveryCost = null;
    if (
      order.type === ORDER_TYPES.delivery &&
      delivery &&
      delivery.deliveryCostId
    ) {
      deliveryCost = yield getDeliveryCostById({
        id: delivery.deliveryCostId,
      });
    }

    const restaurantResponse = yield getRestaurantById({
      id: order.restaurantId,
    });

    const restaurant = restaurantResponse
      ? {
          ...restaurantResponse,
          timeZone: findAttributeValue(
            restaurantResponse.attributes,
            TIME_ZONE_LABEL
          ),
        }
      : null;

    const customer = yield getCustomerById({
      id: order.userId,
      withLoyalty: false,
    });

    const attributes = yield getOrderAttributesValue(id);

    const newAction = {
      type: GET_ORDER_DETAILS_SUCCESS,
      payload: {
        ...order,
        createDate: formatOrderDeliveryDateTime(order.createDate),
        updateDate: formatOrderDeliveryDateTime(order.updateDate),
        delivery,
        deliveryCost,
        pickupNumber,
        restaurant,
        orderItems: menuItems.map((item) => ({
          name: item.name,
          defaultPrice: item.price,
          ...orderItemsDict[item.id],
        })),
        user: customer,
        attributes,
      },
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
    return;
  }
}

export function* getOrderDetailsSaga() {
  yield takeFirst(GET_ORDER_DETAILS_REQUEST, getOrderDetails);
}

function* getOrderAttributesValue(orderId) {
  const attrResponse = yield getOrderAttributes({});
  const attributes = [
    COMMENT_TO_EDIT_ORDER,
    USER_DISCOUNT,
    STORE_CREDITS_DISCOUNT,
    COMPANY_DISCOUNT,
    PUNCH_CARD_DISCOUNT,
    COMPLIMENT_DISCOUNT,
    POS_DISCOUNT,
  ];
  const attributesId = (attrResponse.items || [])
    .filter((attr) => attributes.includes(attr.label))
    .map((attr) => attr.id);
  const attrResponsDict = (attrResponse.items || []).reduce((obj, attr) => {
    obj[attr.label] = attr;
    return obj;
  }, {});
  const attrDict = attributes.reduce((obj, label) => {
    obj[label] = attrResponsDict[label] ? attrResponsDict[label].id : undefined;
    return obj;
  }, {});
  const attrValuesResponse = yield getOrderAttributesValues({
    orderId: [orderId],
    attributeId: attributesId,
  });
  const attrValuesDict = (attrValuesResponse.items || []).reduce(
    (obj, attr) => {
      obj[attr.attributeId] = attr;
      return obj;
    },
    {}
  );
  return {
    commentToEditOrder: attrDict[COMMENT_TO_EDIT_ORDER]
      ? (attrValuesDict[attrDict[COMMENT_TO_EDIT_ORDER]] || {}).value
      : undefined,
    userDiscount: attrDict[USER_DISCOUNT]
      ? (attrValuesDict[attrDict[USER_DISCOUNT]] || {}).value
      : undefined,
    storeCreditsDiscount: attrDict[STORE_CREDITS_DISCOUNT]
      ? (attrValuesDict[attrDict[STORE_CREDITS_DISCOUNT]] || {}).value
      : undefined,
    companyDiscount: attrDict[COMPANY_DISCOUNT]
      ? (attrValuesDict[attrDict[COMPANY_DISCOUNT]] || {}).value
      : undefined,
    punchCardDiscount: attrDict[PUNCH_CARD_DISCOUNT]
      ? (attrValuesDict[attrDict[PUNCH_CARD_DISCOUNT]] || {}).value
      : undefined,
    complimentDiscount: attrDict[COMPLIMENT_DISCOUNT]
      ? (attrValuesDict[attrDict[COMPLIMENT_DISCOUNT]] || {}).value
      : undefined,
    posDiscount: attrDict[POS_DISCOUNT]
      ? (attrValuesDict[attrDict[POS_DISCOUNT]] || {}).value
      : undefined,
  };
}

function* prepareOrderItemsWithSelectedGroups(orderItems) {
  const menuItems = orderItems && orderItems.length > 0 ? orderItems : [];
  const menuItemsIds = menuItems
    .filter((mI) => !mI.parentMenuItemId)
    .map((mI) => mI.menuItemId);
  const additionalItemsDict = menuItemsIds.reduce((el, id) => {
    el[id] = menuItems
      .filter((mI) => mI.parentMenuItemId === id)
      .reduce((mI, currMI) => {
        mI[currMI.menuItemId] = currMI;
        return mI;
      }, {});
    return el;
  }, {});
  const menuItemsFull = yield getParamsMenuItems(menuItemsIds);
  const groupsDict = yield all(
    menuItemsIds.reduce((el, id) => {
      el[id] = getMenuItemGroupsForOrder(id);
      return el;
    }, {})
  );

  return menuItems.filter((mI) => !mI.parentMenuItemId).map((item) => {
    const groups = groupsDict[item.menuItemId];
    const menuItem = menuItemsFull.find((mI) => mI.id === item.menuItemId);

    return {
      id: menuItem.id,
      item: {
        ...menuItem,
        groups,
      },
      numberOfItems: item.quantity,
      selectedGroupItems: groups
        .map((gr) => ({
          groupId: gr.id,
          countLimit: gr.countLimit,
          values: gr.items
            .filter(
              (grI) =>
                additionalItemsDict[item.menuItemId] &&
                additionalItemsDict[item.menuItemId][grI.id]
            )
            .map((grI) => ({
              ...grI,
              numberOfItems: (
                additionalItemsDict[item.menuItemId][grI.id] || {}
              ).quantity,
            })),
        }))
        .filter((sGI) => sGI.values && sGI.values.length > 0),
    };
  });
}

export function* getOrder({ id, getRestaurant = false }) {
  const url = `/orders/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const order = yield apiRequest(url, options);

    const deliveryRespons = yield getOrderDeliveries({
      orderIds: [id],
      getLocations: true,
    });
    const delivery =
      deliveryRespons &&
      deliveryRespons.items &&
      deliveryRespons.items.length > 0
        ? deliveryRespons.items[0]
        : {};

    const deliveryCost = yield getDeliveryCostById({
      id: delivery.deliveryCostId,
    });

    const newOrderItems = yield prepareOrderItemsWithSelectedGroups(
      order.orderItems
    );

    let pickupNumber = null;
    if (order.type === ORDER_TYPES.pickUp) {
      pickupNumber = yield getOrderPickupNumber(id);
    }

    let rawRestaurant = null;
    if (getRestaurant) {
      rawRestaurant = yield getRestaurantById({ id: order.restaurantId });
    }

    const restaurant = rawRestaurant
      ? {
          ...rawRestaurant,
          timeZone: findAttributeValue(
            rawRestaurant.attributes,
            TIME_ZONE_LABEL
          ),
          openingHours: getOpeningHoursList(rawRestaurant),
          specialOpeningHours: getSpecialHoursList(rawRestaurant),
        }
      : null;

    let compliment;
    const orderCompliment = yield getOrderCompliment(order.id);
    if (orderCompliment) {
      compliment =
        orderCompliment && orderCompliment.discount
          ? orderCompliment.discount * 100
          : 0;
    } else {
      const userCompliment = yield getUserCompliments(order.userId);
      compliment = userCompliment.compliment;
    }

    const customer = yield getCustomerById({
      id: order.userId,
      withLoyalty: false,
    });

    let orderCommentAttrId;
    const attrResponse = yield getOrderAttributes({
      name: COMMENT_TO_EDIT_ORDER,
    });
    if (attrResponse && attrResponse.items && attrResponse.items.length) {
      orderCommentAttrId = attrResponse.items[0].id;
    }

    const attrValues = yield getOrderAttributesValues({
      attributeId: orderCommentAttrId,
      orderId: [id],
    });

    const newAction = {
      type: GET_ORDER_SUCCESS,
      payload: {
        ...order,
        createDate: formatOrderDeliveryDateTime(order.createDate),
        updateDate: formatOrderDeliveryDateTime(order.updateDate),
        delivery: {
          ...delivery,
          deliveryCost,
          deliveryTime:
            restaurant && delivery && delivery.deliveryTime
              ? convertFromUTCToTimeZone(
                  delivery.deliveryTime,
                  restaurant.timeZone
                )
              : delivery.deliveryTime,
        },
        pickupNumber,
        restaurant,
        orderItems: newOrderItems,
        compliment,
        oldCompliment:
          orderCompliment && orderCompliment.discount
            ? orderCompliment.discount * 100
            : 0,
        user: customer,
        comment:
          attrValues && attrValues.items && attrValues.items.length > 0
            ? attrValues.items[0].value
            : '',
      },
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
    return;
  }
}

export function* getOrderSaga() {
  yield takeFirst(GET_ORDER_REQUEST, getOrder);
}

function* updateOrder({ orderId, data }) {
  try {
    const url = `/constructor/delivery-orders/${orderId}`;
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    const payload = yield apiRequest(url, options);

    yield updateOrderAttributeValue({
      attrName: COMMENT_TO_EDIT_ORDER,
      orderId,
      value: data.comment,
    });

    const newAction = {
      type: UPDATE_ORDERS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
    yield put({ type: UPDATE_ORDERS_FAILURE, error });
  }
}

export function* updateOrderSaga() {
  yield takeFirst(UPDATE_ORDERS_REQUEST, updateOrder);
}

export default combine([
  getOrdersSaga,
  changeOrderStatusSaga,
  changeMultipleOrdersStatusSaga,
  sendManualOheicsOrderSaga,
  getOrderSaga,
  getOrderDetailsSaga,
  updateOrderSaga,
]);
