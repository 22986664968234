import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  GET_MENU_ITEMS_REQUEST,
  GET_MENU_ITEMS_SUCCESS,
  GET_MENU_ITEMS_LIST_REQUEST,
  GET_MENU_ITEMS_LIST_SUCCESS,
  GET_MENU_ITEM_BY_ID_REQUEST,
  GET_MENU_ITEM_BY_ID_SUCCESS,
  SAVE_MENU_ITEM_REQUEST,
  SAVE_MENU_ITEM_SUCCESS,
  GET_MENU_ATTRIBUTES_REQUEST,
  GET_MENU_ATTRIBUTES_SUCCESS,
  MENU_ITEMS_ATTRIBUTES,
  DELETE_MENU_ITEM_REQUEST,
  DELETE_MENU_ITEM_SUCCESS,
  RESTORE_MENU_ITEM_REQUEST,
  RESTORE_MENU_ITEM_SUCCESS,
} from 'consts/menu-items';
import { ITEM_STATUSES } from 'consts';

import { calculateSort, calculatePage, prepareURLRequest } from 'utils';
import { fixMenuItemsPayload } from 'utils/processors';

import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';
import { getCategories } from 'sagas/categories';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';
import { paginateRequest } from 'sagas/utils';
import { getMenuAttributes } from 'sagas/attributes';
import {
  getRelationshipsNamesById,
  getRelationships,
  prepareRelationship,
} from './menu-items-relationships';

/**
 * Retrieve menu item groups by menu item id
 * @param {Number} menuItemId - menu item id
 * @return {Array} Response items.
 */
export function* getMenuItemGroups(menuItemId) {
  const url = `/menu-item-groups?menuItemId=${menuItemId}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);
  return payload.items;
}

export function* getMenuItemGroupsForOrder(menuItemId) {
  const url = `/menu-item-groups?menuItemId=${menuItemId}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);
  return payload.items
    .map((item) => {
      const items = item.group.menuItems
        .sort((a, b) => b.price - a.price)
        .map((mI) => ({
          ...mI,
          parentMenuItemId: item.menuItemId,
          isDefault: mI.id === item.defaultMenuItemId,
          label: mI.name,
          key: mI.name.toLowerCase(),
          numberOfItems: mI.id === item.defaultMenuItemId ? 1 : 0,
        }));

      return {
        id: item.group.id,
        discount: item.discount || 0,
        countLimit: item.countLimit,
        title: item.group.name,
        items,
      };
    })
    .filter((gI) => gI.items && gI.items.length > 0);
}

/**
 * Retrieve menu items
 * @param {Object} {sortName, sortDirection, ids, page, pageLimit, selectedCategory, searchName, restaurantId, status, getAllItems}
 * @return {Array} Response items.
 */
export function* getMenuItemsRequest({
  sortName,
  sortDirection,
  ids,
  page,
  pageLimit,
  selectedCategory,
  searchName,
  restaurantId,
  status = ITEM_STATUSES.active,
  getAllItems = false,
  onlyAvailableForRestaurant = false,
} = {}) {
  const params = [];
  if (selectedCategory) params.push(`categoryId=${selectedCategory}`);
  if (ids && ids.length) params.push(`id=${ids.join(',')}`);
  if (searchName) params.push(`name=(like%7C${searchName})`);
  if (status) params.push(`status=${status}`);
  params.push(calculateSort(sortDirection, sortName));

  const base = restaurantId
    ? `/menu-items/by-restaurant/${restaurantId}`
    : '/menu-items';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const categoriesPayload = yield getCategories({
      status: ITEM_STATUSES.active,
    });
    const attributes = yield getMenuAttributes();

    let rawPayload;
    if (getAllItems) {
      rawPayload = yield paginateRequest({ url: base, params, options });
    } else {
      rawPayload = yield apiRequest(
        prepareURLRequest(
          base,
          pageLimit
            ? params.concat([`${calculatePage(page, pageLimit)}`])
            : params
        ),
        options
      );
    }
    const payload = fixMenuItemsPayload(
      rawPayload,
      categoriesPayload.items,
      attributes,
      {
        sortName,
        sortDirection,
        onlyAvailableForRestaurant,
        restaurantId,
      }
    );
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getMenuItems({
  sortName,
  sortDirection,
  ids,
  page,
  pageLimit,
  selectedCategory,
  searchName,
  restaurantId,
  status = ITEM_STATUSES.active,
  getAllItems = false,
  onlyAvailableForRestaurant = false,
} = {}) {
  const payload = yield getMenuItemsRequest({
    sortName,
    sortDirection,
    ids,
    page,
    pageLimit,
    selectedCategory,
    searchName,
    restaurantId,
    status,
    getAllItems,
    onlyAvailableForRestaurant,
  });
  const newAction = {
    type: GET_MENU_ITEMS_SUCCESS,
    payload,
  };
  yield put(newAction);
  return payload;
}

export function* getMenuItemsSaga() {
  yield takeFirst(GET_MENU_ITEMS_REQUEST, getMenuItems);
}

export function* getMenuItemsList({
  sortName,
  sortDirection,
  ids,
  page,
  pageLimit,
  selectedCategory,
  searchName,
  restaurantId,
  status = ITEM_STATUSES.active,
  getAllItems = false,
  onlyAvailableForRestaurant = false,
} = {}) {
  const payload = yield getMenuItemsRequest({
    sortName,
    sortDirection,
    ids,
    page,
    pageLimit,
    selectedCategory,
    searchName,
    restaurantId,
    status,
    getAllItems,
    onlyAvailableForRestaurant,
  });
  const newAction = {
    type: GET_MENU_ITEMS_LIST_SUCCESS,
    payload,
  };
  yield put(newAction);
  return payload;
}

export function* getMenuItemsListSaga() {
  yield takeFirst(GET_MENU_ITEMS_LIST_REQUEST, getMenuItemsList);
}

/**
 * Retrieve menu item by id
 * @param {Object} {id} - menu item id
 * @return {Object}
 */
export function* getMenuItemById({ id }) {
  const url = `/menu-items/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const attributes = yield getMenuAttributes();
    const relationships = yield getRelationships();
    const groups = yield getMenuItemGroups(id);
    const rawPayload = yield apiRequest(url, options);
    let redirectId = null;
    if (rawPayload.seoRedirectMenuItem) {
      const redirectMI = yield apiRequest(
        `/menu-items/${rawPayload.seoRedirectMenuItem}`,
        options
      );
      if (redirectMI && redirectMI.status === ITEM_STATUSES.active) {
        redirectId = redirectMI.id;
      }
    }
    let payload = {
      ...rawPayload,
      seoRedirectMenuItem: redirectId,
      attributes:
        rawPayload.attributes && rawPayload.attributes.length > 0
          ? rawPayload.attributes.map((attr) => ({
              ...attr,
              label: (attributes.find((a) => a.id === attr.attributeId) || {})
                .label,
            }))
          : [],
      relationship:
        rawPayload.relationship && rawPayload.relationship.length > 0
          ? rawPayload.relationship.map((rel) => ({
              ...rel,
              label: (
                relationships.find((r) => r.id === rel.typeOfRelationship) || {}
              ).name,
            }))
          : [],
      groups,
    };
    if (payload.relationship.length > 0) {
      const ids = payload.relationship.map((r) => r.suggestedItemId);
      const relationshipNames = yield getRelationshipsNamesById(ids);
      payload = {
        ...payload,
        relationship: payload.relationship.map((rel) => {
          const relationshipItem = relationshipNames.find(
            (rn) => rn.id === rel.suggestedItemId
          );
          return {
            ...rel,
            name: (relationshipItem || {}).name,
            status: (relationshipItem || {}).status,
          };
        }),
      };
    }
    const newAction = {
      type: GET_MENU_ITEM_BY_ID_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getMenuItemByIdSaga() {
  yield takeFirst(GET_MENU_ITEM_BY_ID_REQUEST, getMenuItemById);
}

export function* prepareAttributes(values = {}) {
  const attributes = yield getMenuAttributes();
  return Object.keys(values).map((label) => ({
    attributeId: (attributes.find((a) => a.label === label) || {}).id,
    value: values[label] ? values[label] : '',
  }));
}

export function* prepareJSONAttributes(values = {}) {
  const attributes = yield getMenuAttributes();
  return Object.keys(values).map((label) => ({
    attributeId: (attributes.find((a) => a.label === label) || {}).id,
    value: JSON.stringify(values[label]),
  }));
}

export function* deleteMenuItemGroups(groups = []) {
  for (let g of groups) {
    const url = `/menu-item/${g.menuItemId}/group/${g.groupId}`;
    const options = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };
    yield apiRequest(url, options);
  }
}

export function* saveMenuItemGroups(groups = []) {
  for (let g of groups) {
    const url = `/menu-item/${g.menuItemId}/group/${g.groupId}`;
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        defaultMenuItemId: g.defaultMenuItemId,
        discount: 1,
        countLimit: g.countLimit,
      }),
    };
    yield apiRequest(url, options);
  }
}

export function* sendSaveMenuItemRequest(id, body) {
  const url = id ? `/menu-items/${id}` : '/menu-items';
  const options = {
    method: id ? 'PUT' : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  return yield apiRequest(url, options);
}

export function* saveMenuItem({
  id,
  sku,
  price,
  tax,
  categoryId,
  name,
  ean,
  seoTitle,
  seoDescription,
  seoCoverImage,
  seoNoIndex,
  seoRedirectMenuItem,
  coverImgUrl,
  descriptionLong,
  descriptionShort,
  tags,
  groups,
  groupsToDelete,
  nutrition,
  recommended,
  similar,
  restaurantIds,
}) {
  try {
    let oldAttributes = [];
    if (id) {
      const oldMenuItem = yield getMenuItemById({ id });
      oldAttributes = oldMenuItem.attributes;
    }
    const menuAttributes = yield getMenuAttributes();
    const stringAttributes = yield prepareAttributes({
      ean,
      'seo-title': seoTitle,
      'seo-description': seoDescription,
      'seo-coverimage': seoCoverImage,
      coverImgUrl,
      descriptionLong,
      descriptionShort,
      seoRedirectMenuItem,
    });
    const jsonAttributes = yield prepareJSONAttributes({ nutrition });
    const seoNoIndexAttr = menuAttributes.find((a) => a.label === 'seoNoindex');
    const attributes = [
      ...stringAttributes,
      ...jsonAttributes,
      { attributeId: seoNoIndexAttr.id, value: seoNoIndex ? 'true' : 'false' },
    ];
    let newAttributes = [
      ...attributes,
      ...oldAttributes.filter(
        (a) =>
          !attributes
            .map(({ attributeId }) => attributeId)
            .includes(a.attributeId)
      ),
    ];
    // Adding new default attributes for selected restaurants if there are no such attributes
    const availabilityAttr = menuAttributes.find(
      (a) => a.label === MENU_ITEMS_ATTRIBUTES.available
    );
    const outOfStockAttr = menuAttributes.find(
      (a) => a.label === MENU_ITEMS_ATTRIBUTES.outOfStock
    );
    const popularAttr = menuAttributes.find(
      (a) => a.label === MENU_ITEMS_ATTRIBUTES.popular
    );
    const recommendedAttr = menuAttributes.find(
      (a) => a.label === MENU_ITEMS_ATTRIBUTES.recommendedOnCheckout
    );
    restaurantIds.forEach((restaurantId) => {
      const availableAttrItem = newAttributes.find(
        (a) =>
          a.attributeId === availabilityAttr.id &&
          a.restaurantId === restaurantId
      );
      if (!availableAttrItem) {
        newAttributes.push({
          attributeId: availabilityAttr.id,
          value: 'true',
          restaurantId,
        });
      }
      const outOfStockAttrItem = newAttributes.find(
        (a) =>
          a.attributeId === outOfStockAttr.id && a.restaurantId === restaurantId
      );
      if (!outOfStockAttrItem) {
        newAttributes.push({
          attributeId: outOfStockAttr.id,
          value: 'false',
          restaurantId,
        });
      }
      const popularAttrItem = newAttributes.find(
        (a) =>
          a.attributeId === popularAttr.id && a.restaurantId === restaurantId
      );
      if (!popularAttrItem) {
        newAttributes.push({
          attributeId: popularAttr.id,
          value: 'false',
          restaurantId,
        });
      }
      const recommendedAttrItem = newAttributes.find(
        (a) =>
          a.attributeId === recommendedAttr.id &&
          a.restaurantId === restaurantId
      );
      if (!recommendedAttrItem) {
        newAttributes.push({
          attributeId: recommendedAttr.id,
          value: 'false',
          restaurantId,
        });
      }
    });
    // Check attributes array and if there is no restaurant in new restaurant ids array, set availability and outOfStock to false
    newAttributes = newAttributes.map((attr) => {
      if (
        attr.restaurantId &&
        !restaurantIds.includes(attr.restaurantId) &&
        (outOfStockAttr && attr.attributeId === outOfStockAttr.id)
      ) {
        attr.value = 'true';
        return attr;
      } else if (
        attr.restaurantId &&
        restaurantIds.includes(attr.restaurantId) &&
        availabilityAttr &&
        attr.attributeId === availabilityAttr.id
      ) {
        attr.value = 'true';
        return attr;
      } else if (
        attr.restaurantId &&
        !restaurantIds.includes(attr.restaurantId) &&
        availabilityAttr &&
        attr.attributeId === availabilityAttr.id
      ) {
        attr.value = 'false';
        return attr;
      }
      return attr;
    });
    const relationship = yield prepareRelationship(recommended, similar);
    const payload = yield sendSaveMenuItemRequest(id, {
      name,
      sku,
      price: parseFloat(price),
      tax: parseFloat((tax / 100).toFixed(2)),
      categoryId,
      attributes: newAttributes,
      tags,
      relationship,
    });
    yield deleteMenuItemGroups(groupsToDelete);
    yield saveMenuItemGroups(groups);
    const newAction = {
      type: SAVE_MENU_ITEM_SUCCESS,
      payload,
    };
    yield put(newAction);
    if (!id) {
      yield put(push(`/menu-item-details/${payload.id}`));
    }
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* saveMenuItemSaga() {
  yield takeFirst(SAVE_MENU_ITEM_REQUEST, saveMenuItem);
}

/**
 * Retrieve simple menu items with id and name params
 * @param {Object} {ids} - {menu item ids}
 */
export function* getSimpleMenuItems({ ids }) {
  try {
    const payload = yield getRelationshipsNamesById(ids);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getParamsMenuItems(ids = []) {
  const url = `/menu-items?id=${ids.join(',')}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);
  return payload.items;
}

/**
 * Retrieve menu items attributes
 */
export function* getMenuItemsAttributes() {
  try {
    const payload = yield getMenuAttributes();
    const newAction = {
      type: GET_MENU_ATTRIBUTES_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getMenuItemsAttributesSaga() {
  yield takeFirst(GET_MENU_ATTRIBUTES_REQUEST, getMenuItemsAttributes);
}

/**
 * Delete menu item by id
 * @param {Object} {id} - {menu item id}
 */
export function* deleteMenuItem({ id }) {
  let url = `/menu-items/${id}`;
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: DELETE_MENU_ITEM_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* deleteMenuItemSaga() {
  yield takeFirst(DELETE_MENU_ITEM_REQUEST, deleteMenuItem);
}

export function* restoreMenuItem({ id, item }) {
  try {
    const payload = yield sendSaveMenuItemRequest(id, {
      ...item,
      status: ITEM_STATUSES.active,
    });
    const newAction = {
      type: RESTORE_MENU_ITEM_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
    return error;
  }
}

export function* restoreMenuItemSaga() {
  yield takeFirst(RESTORE_MENU_ITEM_REQUEST, restoreMenuItem);
}

export default combine([
  getMenuItemsSaga,
  getMenuItemByIdSaga,
  saveMenuItemSaga,
  getMenuItemsAttributesSaga,
  deleteMenuItemSaga,
  getMenuItemsListSaga,
  restoreMenuItemSaga,
]);
