import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import {
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_PDF_REQUEST,
  UPLOAD_PDF_SUCCESS,
  UPLOAD_PDF_FAILURE,
} from 'consts/upload';

export function uploadImage(file, filename) {
  return {
    type: UPLOAD_IMAGE_REQUEST,
    payload: file,
    filename: filename,
    [WAIT_FOR_ACTION]: UPLOAD_IMAGE_SUCCESS,
    [ERROR_ACTION]: UPLOAD_IMAGE_FAILURE,
  };
}

export function uploadPDF(file, filename) {
  return {
    type: UPLOAD_PDF_REQUEST,
    payload: file,
    filename: filename,
    [WAIT_FOR_ACTION]: UPLOAD_PDF_SUCCESS,
    [ERROR_ACTION]: UPLOAD_PDF_FAILURE,
  };
}
