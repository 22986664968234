import React from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Section from 'components/Section';
import SmallButton from 'components/SmallButton';
import EditLinkModal from 'components/EditLinkModal';

class CustomLinkSection extends React.PureComponent {
  state = { modalShown: false };

  handleOpenModal = () => {
    this.setState({
      modalShown: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalShown: false,
    });
  };

  render() {
    const { modalShown } = this.state;
    const { item, onSave } = this.props;

    return (
      <React.Fragment>
        <Section
          heading="Link data"
          withoutDivider
          withoutMargin
          button={
            <SmallButton
              style={{ cursor: 'pointer' }}
              onClick={this.handleOpenModal}
            >
              Edit
            </SmallButton>
          }
        >
          <div>
            <Input
              value={item.label || ''}
              type="string"
              disabled
              placeholder="Link label"
              label="Link label"
              margin="normal"
              style={{ width: 300 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div>
            <Input
              value={item.url || ''}
              type="string"
              disabled
              placeholder="Link URL"
              label="Link URL"
              margin="normal"
              style={{ width: 300 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div>
            <Input
              value={item.textAlign || ''}
              type="string"
              disabled
              placeholder="Link text align"
              label="Link text align"
              margin="normal"
              style={{ width: 300 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  checked={item.openInNewTab}
                  value="openInNewTab"
                />
              }
              label="Open page in a new tab."
            />
          </div>
        </Section>
        <EditLinkModal
          open={modalShown}
          item={item}
          key={item}
          onClose={this.handleCloseModal}
          onSave={onSave}
        />
      </React.Fragment>
    );
  }
}

CustomLinkSection.propTypes = {
  item: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default CustomLinkSection;
