export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE';

export const GET_CUSTOMER_BY_ID_REQUEST = 'GET_CUSTOMER_BY_ID_REQUEST';
export const GET_CUSTOMER_BY_ID_SUCCESS = 'GET_CUSTOMER_BY_ID_SUCCESS';
export const GET_CUSTOMER_BY_ID_FAILURE = 'GET_CUSTOMER_BY_ID_FAILURE';

export const SAVE_CUSTOMER_REQUEST = 'SAVE_CUSTOMER_REQUEST';
export const SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS';
export const SAVE_CUSTOMER_FAILURE = 'SAVE_CUSTOMER_FAILURE';

export const CHANGE_CUSTOMER_STATUS_REQUEST = 'CHANGE_CUSTOMER_STATUS_REQUEST';
export const CHANGE_CUSTOMER_STATUS_SUCCESS = 'CHANGE_CUSTOMER_STATUS_SUCCESS';
export const CHANGE_CUSTOMER_STATUS_FAILURE = 'CHANGE_CUSTOMER_STATUS_FAILURE';

export const GET_CUSTOMER_DELIVERY_ADDRESSES_REQUEST =
  'GET_CUSTOMER_DELIVERY_ADDRESSES_REQUEST';
export const GET_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS =
  'GET_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS';
export const GET_CUSTOMER_DELIVERY_ADDRESSES_FAILURE =
  'GET_CUSTOMER_DELIVERY_ADDRESSES_FAILURE';

export const GET_CUSTOMER_DELIVERY_REQUEST = 'GET_CUSTOMER_DELIVERY_REQUEST';
export const GET_CUSTOMER_DELIVERY_SUCCESS = 'GET_CUSTOMER_DELIVERY_SUCCESS';
export const GET_CUSTOMER_DELIVERY_FAILURE = 'GET_CUSTOMER_DELIVERY_FAILURE';

export const CLEAR_USER_REQUEST = 'CLEAR_USER_REQUEST';
export const CLEAR_USER_SUCCESS = 'CLEAR_USER_SUCCESS';
export const CLEAR_USER_FAILURE = 'CLEAR_USER_FAILURE';

export const CHANGE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST =
  'CHANGE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST';
export const CHANGE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS =
  'CHANGE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS';
export const CHANGE_CUSTOMER_DELIVERY_ADDRESSES_FAILURE =
  'CHANGE_CUSTOMER_DELIVERY_ADDRESSES_FAILURE';

export const SAVE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST =
  'SAVE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST';
export const SAVE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS =
  'SAVE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS';
export const SAVE_CUSTOMER_DELIVERY_ADDRESSES_FAILURE =
  'SAVE_CUSTOMER_DELIVERY_ADDRESSES_FAILURE';

export const SEARCH_CUSTOMER_REQUEST = 'SEARCH_CUSTOMER_REQUEST';

export const FIELDS_TO_SEARCH = [
  {
    label: 'Phone number',
    value: 'phone',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'ID',
    value: 'ids',
  },
  {
    label: 'First name',
    value: 'firstName',
  },
  {
    label: 'Last name',
    value: 'lastName',
  },
];

export const FIELDS_TO_SEARCH_CUSTOMERS = [
  {
    label: 'Phone number',
    value: 'phone',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Customer nr.',
    value: 'ids',
  },
];

export const STATUSES = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Blocked',
    value: 'blocked',
  },
];

export const USER_STATUSES = {
  active: 'active',
  deleted: 'deleted',
};
