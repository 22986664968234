import {
  format,
  parseISO,
  addMonths,
  addMinutes,
  endOfMonth,
  startOfMonth,
  addDays,
  startOfDay,
  endOfDay,
  addYears,
  differenceInMonths,
  isBefore,
  subMinutes,
  setMinutes,
  setHours,
} from 'date-fns';
import spacetime from 'spacetime';
import listTimeZones from 'consts/timezones';

import {
  DEFAULT_TIMEZONE,
  UTC_TIMEZONE,
  DATETIME_FORMAT,
  SHORT_DATE_FORMAT,
  DAYS_OPTIONS_NAME,
  DAY_OPTIONS_TYPES,
  MAX_DELIVERY_DAY,
  MIN_DELIVERY_TIME,
} from 'consts';

function checkDateParam(date) {
  if (!date) date = new Date();
  if (!(date instanceof Date))
    date = new Date(date.replace(/-/g, '/').toString());
  return date;
}

/**
 * Formats provided date to default format.
 * @param {Date || string} dateTime - Date or string.
 * @return {String} Formatted date string.
 */
export function formatOrderDeliveryDateTime(
  dateTime,
  format = DATETIME_FORMAT
) {
  // Use replase for Safari support. The pattern yyyy-MM-dd isn't an officially supported format for Date constructor.
  if (
    dateTime &&
    new Date(dateTime.replace(/-/g, '/')).toString() === 'Invalid Date'
  ) {
    return '';
  }
  const s = spacetime(dateTime || new Date(), UTC_TIMEZONE);
  return s.goto(DEFAULT_TIMEZONE).unixFmt(format);
}

/**
 * Converts provided date to utc and returns in default format.
 * @param {Date || string} dateTime - Date or string.
 * @return {String} Converted and formatted date string.
 */
export function convertDefaultToUTC(dateTime) {
  if (
    dateTime &&
    new Date(dateTime.replace(/-/g, '/')).toString() === 'Invalid Date'
  ) {
    return '';
  }
  const s = spacetime(dateTime || new Date(), DEFAULT_TIMEZONE);
  return s.goto(UTC_TIMEZONE).unixFmt(DATETIME_FORMAT);
}

export function formatOrderFiltersDate(date) {
  date = checkDateParam(date);
  date = format(date, DATETIME_FORMAT);

  return spacetime(date, UTC_TIMEZONE).unixFmt(DATETIME_FORMAT);
}

/**
 * Converts provided date to utc and returns in provided format.
 * @param {Date || string} date - Date or string.
 * @param {string} dateFormat - Date or string.
 * @return {String} Converted and formatted date string.
 */
export function convertCurrentTimezoneToUTC(date, dateFormat) {
  date = checkDateParam(date);
  const s = spacetime(date || new Date());
  const convertedDate = s.goto(UTC_TIMEZONE);
  return dateFormat ? convertedDate.unixFmt(DATETIME_FORMAT) : convertedDate.d;
}

export function convertFromUTCToTimeZone(dateTime, timeZone) {
  if (
    dateTime &&
    new Date(dateTime.replace(/-/g, '/')).toString() === 'Invalid Date'
  ) {
    return '';
  }
  const s = spacetime(dateTime || new Date(), UTC_TIMEZONE);
  return s.goto(timeZone).unixFmt(DATETIME_FORMAT);
}

/**
 * Returns array of months with start and end dates of each month.
 * @param {Date || string} startDate - Date or string.
 * @param {Date || string} endDate - Date or string.
 * @return {Array} Array of months.
 */
export function getMonthDateRange(startDate, endDate) {
  const range = [];

  if (!(startDate && endDate)) {
    return range;
  }

  const diff = differenceInMonths(new Date(endDate), new Date(startDate));
  for (let i = 0; i <= diff; i++) {
    const period = {};
    range.push(period);

    if (i === 0) {
      period.startDate = formatDateTime(startDate, SHORT_DATE_FORMAT);
    } else {
      period.startDate = formatDateTime(
        startOfMonth(addMonths(new Date(startDate), i)),
        SHORT_DATE_FORMAT
      );
    }
    if (i === diff) {
      period.endDate = endDate;
    } else {
      period.endDate = formatDateTime(
        endOfMonth(addMonths(new Date(startDate), i)),
        SHORT_DATE_FORMAT
      );
    }
  }
  if (range.length === 0) {
    return [
      {
        startDate,
        endDate,
      },
    ];
  }
  return range;
}

/**
 * Format provided date.
 * @param {Date || string} date - Date or string.
 * @param {string} dateFormat - Date or string.
 * @return {String} Formatted date string.
 */
export function formatDateTime(date, dateFormat = DATETIME_FORMAT) {
  date = checkDateParam(date);
  return format(date, dateFormat);
}

/**
 * Returns start of the provided date.
 * @param {Date || string} date - Date or string.
 * @param {string} format - Date or string.
 * @return {Date || String} Formatted start of date string.
 */
export function getStartOfDay({ date, format } = {}) {
  date = checkDateParam(date);
  const newDate = startOfDay(date);
  return format ? formatDateTime(newDate, format) : newDate;
}

/**
 * Returns end of the provided date.
 * @param {Date || string} date - Date or string.
 * @param {string} format - Date or string.
 * @return {Date || String} Formatted end of date string.
 */
export function getEndOfDay({ date, format } = {}) {
  date = checkDateParam(date);
  const newDate = endOfDay(date);
  return format ? formatDateTime(newDate, format) : newDate;
}

/**
 * Adds days to provided date.
 * @param {Date || string} date - Date or string.
 * @param {Number} daysToAdd - days to add from date.
 * @return {Date} Converted instance of date.
 */
export function addDaysToDate(dateTime, daysToAdd = 0) {
  dateTime = checkDateParam(dateTime);
  return addDays(dateTime, daysToAdd);
}

/**
 * Adds years to provided date.
 * @param {Date || string} date - Date or string.
 * @param {Number} years - years to add from date.
 * @param {String} format - format of returned date.
 * @return {Date || String} Converted string or instance of date.
 */
export function addYearsToDays({ date, years = 0, format } = {}) {
  if (!date || new Date(date).toString() === 'Invalid Date') {
    date = new Date();
  }
  if (!(date instanceof Date)) date = new Date(date);
  const addedDate = addYears(date, years);
  return format ? formatDateTime(addedDate, format) : addedDate;
}

/**
 * Compare two dates and return boolean if first date is before second.
 * @param {Date || string} date1 - Date or string.
 * @param {Date || string} date2 - Date or string.
 * @return {Boolean} Boolean reprasantation if first date is before the second.
 */
export function isDateBefore(date1, date2) {
  date1 = checkDateParam(date1);
  date2 = checkDateParam(date2);
  return isBefore(date1, date2);
}

/**
 * Returns list of timezones.
 * @return {Array} List of timezones.
 */
export function getTimeZones() {
  return listTimeZones;
}

/**
 * Subtracts minutes from provided date.
 * @param {Date || string} date - Date or string.
 * @param {Number} minutes - Minutes to subtracts from date.
 * @param {String} format - format of returned date.
 * @return {String || Date} Formatted date string or instance of date.
 */
export function changeDateMinutes({ date, minutes = 0, format } = {}) {
  date = checkDateParam(date);
  const subbedDate = subMinutes(date, minutes);
  return format ? formatDateTime(subbedDate, format) : subbedDate;
}

/**
 * Returns start or end of todays or tomorrow days depends on params.
 * @param {Date || string} date - Date or string.
 * @param {String} day - Type of day: today or tomorrow.
 * @param {String} type - Type of day: start or end.
 * @return {Date} Converted Date.
 */
export function getTypesOfDay({ day, type } = {}) {
  if (!day) day = DAYS_OPTIONS_NAME.today;
  if (!type) type = DAY_OPTIONS_TYPES.start;
  if (day === DAYS_OPTIONS_NAME.today && type === DAY_OPTIONS_TYPES.start) {
    return getStartOfDay();
  } else if (
    day === DAYS_OPTIONS_NAME.today &&
    type === DAY_OPTIONS_TYPES.end
  ) {
    return getEndOfDay();
  } else if (
    day === DAYS_OPTIONS_NAME.tomorrow &&
    type === DAY_OPTIONS_TYPES.start
  ) {
    return addDaysToDate(getStartOfDay(), 1);
  } else if (
    day === DAYS_OPTIONS_NAME.tomorrow &&
    type === DAY_OPTIONS_TYPES.end
  ) {
    return addDaysToDate(getEndOfDay(), 1);
  }
  return null;
}

export function getMaxDeliveryDate(timeZone) {
  const result = spacetime(new Date(), timeZone)
    .add(MAX_DELIVERY_DAY - 1, 'day')
    .unixFmt(DATETIME_FORMAT);
  return result;
}

export function getMinDeliveryDate(timeZone) {
  const result = spacetime(new Date(), timeZone).unixFmt(DATETIME_FORMAT);
  return result;
}

export function isToday(date, timeZone) {
  const now = spacetime(new Date(), timeZone);

  return (
    date.getFullYear() === now.year() &&
    date.getMonth() === now.month() &&
    date.getDate() === now.date()
  );
}

export function timeToDate(time, timeZone) {
  const hours = time.substring(0, time.indexOf(':'));
  const minutes = time.substring(time.lastIndexOf(':') + 1);
  const s = spacetime(new Date(), timeZone).unixFmt(DATETIME_FORMAT);

  return setHours(
    setMinutes(parseISO(s), parseInt(minutes, 10)),
    parseInt(hours, 10)
  );
}

export function getMinOpenTime(minTime, date) {
  const parsedDate = date instanceof Date ? date : parseISO(date);
  return addMinutes(parsedDate, minTime);
}

export function getMaxCloseTime(date) {
  const parsedDate = date instanceof Date ? date : parseISO(date);
  return addMinutes(parsedDate, MIN_DELIVERY_TIME);
}

export function convertToFormatedRestaurantTimeZone(
  timeZone,
  date = new Date()
) {
  return spacetime(date, timeZone).unixFmt(DATETIME_FORMAT);
}

export function getHour(time) {
  const date = time instanceof Date ? time : parseISO(time);
  return date.getHours();
}

export function getMinute(time) {
  const date = time instanceof Date ? time : parseISO(time);
  return date.getMinutes();
}

export function getStartOfDeliveryDate(date) {
  return setMinutes(setHours(date, 0), 0);
}

export function prepareDeliveryDate({ date, hours, minutes, timeZone }) {
  const dateFotmated = spacetime(date || new Date()).unixFmt(SHORT_DATE_FORMAT);
  const s = spacetime(dateFotmated, timeZone)
    .hour(hours)
    .minute(minutes);
  return s.goto(UTC_TIMEZONE).unixFmt(DATETIME_FORMAT);
}

export function toSafariSuportedFormat(date) {
  return date ? date.replace(/-/g, '/') : '';
}

export function formatDeliveryTimeForPackList(date, minTime = 0) {
  const deliveryDate = parseISO(formatOrderDeliveryDateTime(date));
  const timeTo = format(deliveryDate, 'HH:mm');
  const dateFrom = subMinutes(deliveryDate, minTime);
  const timeFrom = format(dateFrom, 'HH:mm');
  return `${format(deliveryDate, SHORT_DATE_FORMAT)} ${timeFrom}-${timeTo}`;
}
