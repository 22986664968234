import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';

import PageHeading from 'components/PageHeading';
import TableSortCell from 'components/TableSortCell';
import TableActionCell from 'components/TableActionCell';
import TableCount from 'components/TableCount';
import PageHeadingButton from 'components/PageHeadingButton';

import { confirmAlert } from 'utils';

class KickbackListPage extends React.PureComponent {
  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handleDelete = (id) => {
    const { onDelete } = this.props;
    confirmAlert({ okCb: () => onDelete(id) });
  };

  handlePage = (event, page) => {
    return this.props.onPage({ page });
  };

  render() {
    const { items, totalResults } = this.props.initialData;
    const { sortName, sortDirection, pageLimit, page } = this.props;
    return (
      <Fragment>
        <PageHeading
          title="Kickback Levels"
          right={
            <PageHeadingButton link="/kickback-details">
              + New Kickback level
            </PageHeadingButton>
          }
        />
        <TableCount count={totalResults} text="items in current selection" />
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="Name"
                  sortName="name"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Limit"
                  sortName="pointAmount"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Reward"
                  sortName="storeCreditAward"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name || 'No Name'}</TableCell>
                  <TableCell>{item.pointAmount}</TableCell>
                  <TableCell>{item.storeCreditAward}</TableCell>
                  <TableActionCell
                    link={`/kickback-details/${item.id}`}
                    icon="edit"
                  />
                  <TableActionCell
                    action={() => this.handleDelete(item.id)}
                    icon="delete"
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageLimit]}
            component="div"
            count={totalResults}
            rowsPerPage={pageLimit}
            page={page}
            onChangePage={this.handlePage}
          />
        </Paper>
      </Fragment>
    );
  }
}

KickbackListPage.propTypes = {
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onPage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default KickbackListPage;
