import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import {
  addCompanyMember,
  approveCompanyMember,
  getCompanyMembers,
  declineCompanyMember,
  approveAllPendingMember,
} from 'actions/userRegistry';

import { showLoading, hideLoading } from 'actions/ui';

import { hideIfNoData } from 'utils/enhancers';
import { wrapWithSideEffect } from 'utils';

import Members from './Members';

const mapDispatchToProps = {
  onInit: getCompanyMembers,
  onSubmitMember: addCompanyMember,
  onDeclineMember: declineCompanyMember,
  onApproveMember: approveCompanyMember,
  onApproveAll: approveAllPendingMember,
  showLoading,
  hideLoading,
};

const state = compose(withState('members', 'setMembers', []));

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(() => {
    return props.hideLoading();
  });
};

const refresh = (props) => {
  const { companyId, onInit, setMembers } = props;
  return wrapWithProgress(props)(onInit([companyId]).then(setMembers));
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onDeclineMember: (props) => (event) => {
    const { onDeclineMember } = props;
    return wrapWithProgress(props)(
      onDeclineMember(event).then(() => {
        refresh(props);
      })
    );
  },
  onApproveMember: (props) => (event) => {
    const { onApproveMember } = props;
    return wrapWithProgress(props)(
      onApproveMember(event).then(() => {
        refresh(props);
      })
    );
  },
  onApproveAll: (props) => (event) => {
    const { onApproveAll } = props;
    return wrapWithProgress(props)(
      onApproveAll(event).then(() => {
        refresh(props);
      })
    );
  },
  onSubmitMember: (props) => (event) => {
    const { onSubmitMember } = props;
    return wrapWithProgress(props)(
      onSubmitMember(event).then(() => {
        refresh(props);
      })
    );
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    const { companyId, onInit } = this.props;
    if (companyId) {
      onInit([companyId]);
    }
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.companyId)
)(Members);
