import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';

import NoData from 'components/NoData';
import TableSortCell from 'components/TableSortCell';
import TableActionCell from 'components/TableActionCell';

import { convertFromUTCToTimeZone } from 'utils/date';
import { DEFAULT_TIMEZONE } from 'consts';

import styles from './styles';

function customSort(sortName, sortDirection) {
  const compA = sortDirection === 'asc' ? 1 : -1;
  const compB = sortDirection === 'desc' ? 1 : -1;
  return (a, b) =>
    a[sortName] > b[sortName] ? compA : b[sortName] > a[sortName] ? compB : 0;
}

class MembersTable extends React.PureComponent {
  state = {
    sortName: 'id',
    sortDirection: 'asc',
  };

  handleSort = (sortName, sortDirection) => {
    this.setState({
      sortName,
      sortDirection,
    });
  };

  render() {
    const { sortName, sortDirection } = this.state;
    const {
      classes,
      items,
      isPending,
      handleDelete,
      handleApprove,
      handleApproveAll,
    } = this.props;

    return (
      <React.Fragment>
        <div>
          <div>
            <Typography
              className={classes.pendingHeading}
              variant="h6"
              color="secondary"
            >
              {isPending ? 'Pending Members' : 'Members'}
            </Typography>
            {isPending && (
              <Button className={classes.approveBtn} onClick={handleApproveAll}>
                Approve all
              </Button>
            )}
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="ID"
                  sortName="id"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Name"
                  sortName="name"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Emal"
                  sortName="email"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Phone number"
                  sortName="phone"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                {!isPending && <TableCell>Approve date</TableCell>}
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.sort(customSort(sortName, sortDirection)).map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    {`${item.firstName || ''} ${item.lastName ||
                      ''}`.trim() || <NoData>No name</NoData>}
                  </TableCell>
                  <TableCell>
                    {item.email || <NoData>No email</NoData>}
                  </TableCell>
                  <TableCell>
                    {item.phone || <NoData>No phone</NoData>}
                  </TableCell>
                  {!isPending && (
                    <TableCell>
                      {item.userRegistry && item.userRegistry.updateDate
                        ? convertFromUTCToTimeZone(
                            item.userRegistry.updateDate,
                            DEFAULT_TIMEZONE
                          )
                        : ''}
                    </TableCell>
                  )}
                  <TableCell>
                    {isPending && (
                      <Button
                        className={classes.approveBtn}
                        onClick={() => handleApprove(item)}
                      >
                        Approve member
                      </Button>
                    )}
                  </TableCell>
                  <TableActionCell
                    action={() => handleDelete(item)}
                    icon="delete"
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

MembersTable.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.array,
  isPending: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleApprove: PropTypes.func,
  handleApproveAll: PropTypes.func,
};

export default injectSheet(styles)(MembersTable);
