import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { withTheme } from '@material-ui/core';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { findDefference } from '../utils';

import styles from './styles';

const ToolbarButtonsBar = ({
  config = {},
  currentState = {},
  classes,
  ...props
}) => {
  const [formats, setFormats] = React.useState(currentState);

  return (
    <ToggleButtonGroup
      value={formats}
      onChange={(e, values = []) => {
        setFormats(values);
        props.onChange(findDefference(formats, values)[0] || '');
      }}
      aria-label="text formatting"
      classes={{ root: classes.buttonWrapper }}
    >
      {config.options.map((type) => {
        const Icon = config[type].icon;
        return (
          <ToggleButton
            key={type}
            value={type}
            aria-label={type}
            classes={{ root: classes.toolbarButon }}
          >
            {typeof Icon === 'object' ? Icon : <img src={Icon} alt={type} />}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

ToolbarButtonsBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  config: PropTypes.object,
  currentState: PropTypes.array,
};

export default compose(
  React.memo,
  withTheme(),
  injectSheet(styles)
)(ToolbarButtonsBar);
