import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';

const STEP = 2;
const years = new Array(2 * STEP + 1)
  .fill(0)
  .map((v, i) => new Date().getFullYear() + STEP - i);
const formatSelectValue = (value) =>
  Number.isInteger(value)
    ? {
        label: `${value}`,
        value,
      }
    : null;

class YearDropdown extends React.PureComponent {
  render() {
    const { value, onChange } = this.props;
    const options = [
      {
        label: 'Every year',
      },
      ...years.map(formatSelectValue),
    ];
    return (
      <Select
        options={options}
        width={140}
        placeholder="Every year"
        value={formatSelectValue(value)}
        onChange={onChange}
      />
    );
  }
}

YearDropdown.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default YearDropdown;
