import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';

import PageHeading from 'components/PageHeading';
import PageHeadingTitle from 'components/PageHeadingTitle';
import Section from 'components/Section';
import CurrencyInput from 'components/CurrencyInput';
import ImageUpload from 'components/ImageUpload';

import CategoriesDropdown from './CategoriesDropdown';
import MenuItemsDropdown from './MenuItemsDropdown';
import Tags from './Tags';
import IncludedItems from './IncludedItems';
import Nutrition from './Nutrition';
import Restaurants from './Restaurants';
import Related from './Related';

import {
  findAttributeValue,
  findAttributeJSONValue,
  findAvailableRestaurantIds,
} from 'utils';
import { DEFAULT_TAX, ITEM_STATUSES } from 'consts';

const stateFromProps = ({ initialData = {} }) => {
  const seoNoIndexAttr = findAttributeValue(
    initialData.attributes,
    'seoNoindex'
  );
  return {
    name: initialData.name || '',
    sku: initialData.sku || '',
    price: initialData.price || 0,
    tax: initialData.tax * 100 || DEFAULT_TAX * 100,
    categoryId: initialData.categoryId || null,
    tags: initialData.tags || [],
    groups: initialData.groups || [],
    coverImgUrl:
      findAttributeValue(initialData.attributes, 'coverImgUrl') || '',
    descriptionLong:
      findAttributeValue(initialData.attributes, 'descriptionLong') || '',
    descriptionShort:
      findAttributeValue(initialData.attributes, 'descriptionShort') || '',
    nutrition:
      findAttributeJSONValue(initialData.attributes, 'nutrition') || {},
    recommended: (initialData.relationship || []).filter(
      (r) => r.label === 'recommended'
    ),
    similar: (initialData.relationship || []).filter(
      (r) => r.label === 'similar'
    ),
    restaurantIds: findAvailableRestaurantIds(initialData.attributes),
    coverFile: initialData.coverImgUrl || '',
    ean: findAttributeValue(initialData.attributes, 'ean') || '',
    seoTitle: findAttributeValue(initialData.attributes, 'seo-title') || '',
    seoDescription:
      findAttributeValue(initialData.attributes, 'seo-description') || '',
    seoCoverImage:
      findAttributeValue(initialData.attributes, 'seo-coverimage') || '',
    seoNoIndex: seoNoIndexAttr && seoNoIndexAttr === 'true' ? true : false,
    checkAllRestaurants: false,
    seoRedirectMenuItem: initialData.seoRedirectMenuItem,
  };
};

class MenuItemDetailsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleChangeTax = ({ target: { value } }) => {
    this.setState({ tax: value });
  };

  onSEONoIndexCheck = ({ target: { checked } }) => {
    this.setState({ seoNoIndex: checked });
  };

  onSelectRedirectMenuItem = (menuItem) => {
    this.setState({
      seoRedirectMenuItem: menuItem && menuItem.id ? menuItem.id : null,
    });
  };

  onSelectCategory = ({ value }) => {
    this.setState({ categoryId: value });
  };

  onAddTag = (tag) => {
    const tags = [...this.state.tags, tag];
    this.setState({ tags });
  };

  onDeleteTag = (id) => {
    const tags = this.state.tags.filter((item) => item.id !== id);
    this.setState({ tags });
  };

  onGroupChange = (groupId, value) => {
    this.setState({
      groups: this.state.groups.map(
        (group) => (group.groupId === groupId ? value : group)
      ),
    });
  };

  onGroupAdd = (group) => {
    this.setState({
      groups: [
        ...this.state.groups,
        {
          menuItemId: this.props.id,
          groupId: group.id,
          defaultMenuItemId: null,
          countLimit: 1,
          group,
        },
      ],
    });
  };

  onGroupDelete = (groupId) => {
    this.setState({
      groups: this.state.groups.filter((g) => g.groupId !== groupId),
    });
  };

  onNutritionChange = (nutrition) => {
    this.setState({ nutrition });
  };

  onAddRecommended = (value) => {
    const recommended = [...this.state.recommended, value];
    this.setState({ recommended });
  };

  onDeleteRecommended = (id) => {
    const recommended = this.state.recommended.filter(
      (item) => item.suggestedItemId !== id
    );
    this.setState({ recommended });
  };

  onAddSimilar = (value) => {
    const similar = [...this.state.similar, value];
    this.setState({ similar });
  };

  onDeleteSimilar = (id) => {
    const similar = this.state.similar.filter(
      (item) => item.suggestedItemId !== id
    );
    this.setState({ similar });
  };

  onRestaurantCheck = (restaurantId, { target: { checked } }) => {
    const otherIds = this.state.restaurantIds.filter(
      (id) => id !== restaurantId
    );
    const restaurantIds = checked ? [...otherIds, restaurantId] : otherIds;
    this.setState({ restaurantIds });
  };

  onAllRestaurantCheck = ({ target: { checked } }, restaurantIds) => {
    this.setState({
      restaurantIds: checked ? restaurantIds : [],
      checkAllRestaurants: checked,
    });
  };

  validateError = (data) => {
    if (!data.name) {
      return 'Name is required';
    }
    if (!data.sku) {
      return 'SKU is required';
    }
    if (!data.price && data.price !== 0) {
      return 'Price is required';
    }
    if ((!data.tax && data.tax !== 0) || data.tax < 0) {
      return 'Valid Tax is required';
    }
    if (!data.categoryId) {
      return 'Category is required';
    }
    if (data.groups && data.groups.length) {
      for (let i = 0; i < data.groups.length; i++) {
        const g = data.groups[i];
        if ((!g.countLimit && g.countLimit !== 0) || g.countLimit < 0) {
          return 'Included item number of items is required';
        }
      }
    }
  };

  handleRestore = () => {
    const { id, initialData: menuItem, onRestore } = this.props;
    onRestore(id, menuItem);
  };

  handleSubmit = () => {
    const { id, onSubmit, initialData = {} } = this.props;
    const prevGroups = initialData.groups || [];
    const { groups, ...values } = this.state;
    const groupsToSubmit = groups.map((g) => ({
      menuItemId: id,
      groupId: g.groupId,
      defaultMenuItemId: g.defaultMenuItemId,
      countLimit: g.countLimit,
    }));
    const groupsToDelete = prevGroups
      .map((g) => g.groupId)
      .filter(
        (id) => !groupsToSubmit.map(({ groupId }) => groupId).includes(id)
      )
      .map((groupId) => ({
        menuItemId: id,
        groupId,
      }));
    const data = { id, ...values, groups: groupsToSubmit, groupsToDelete };
    const validateError = this.validateError(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      onSubmit(data)
        .then(() => {
          toastr.success(
            'Success!',
            id ? 'Menu Item has been updated' : 'New Menu Item has been created'
          );
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  render() {
    const { id, initialData: menuItem } = this.props;
    const {
      sku,
      price,
      tax,
      categoryId,
      tags,
      name,
      ean,
      seoTitle,
      seoDescription,
      seoCoverImage,
      seoNoIndex,
      seoRedirectMenuItem,
      coverImgUrl,
      descriptionLong,
      descriptionShort,
      groups,
      nutrition,
      recommended,
      similar,
      restaurantIds,
      checkAllRestaurants,
    } = this.state;

    const inactive = !!(
      id &&
      menuItem &&
      menuItem.status === ITEM_STATUSES.inactive
    );

    return (
      <div>
        <PageHeading
          title={
            id ? (
              <PageHeadingTitle
                text="Menu Item"
                highlightedText={menuItem.name}
              />
            ) : (
              'New Menu Item'
            )
          }
          right={
            <div>
              {id &&
                menuItem &&
                inactive && (
                  <Button
                    onClick={this.handleRestore}
                    variant="contained"
                    size="large"
                    style={{ marginRight: 20 }}
                  >
                    Restore
                  </Button>
                )}
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                size="large"
              >
                Save
              </Button>
            </div>
          }
        />
        <div>
          <Input
            value={name}
            onChange={this.handleChange('name')}
            placeholder="Name"
            label="Name"
            margin="normal"
            style={{ width: 300 }}
            disabled={inactive}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Input
            value={sku}
            onChange={this.handleChange('sku')}
            placeholder="SKU"
            label="SKU"
            margin="normal"
            disabled={inactive}
          />
          <CurrencyInput
            value={price}
            onChange={this.handleChange('price')}
            placeholder="Price"
            label="price"
            margin="normal"
            style={{ marginLeft: 20, width: 100 }}
            disabled={inactive}
          />
          <Input
            value={tax}
            type="number"
            placeholder="SKU"
            onChange={this.handleChangeTax}
            label="Tax, %"
            margin="normal"
            disabled={inactive}
            inputProps={{
              style: { textAlign: 'right' },
            }}
            style={{ marginLeft: 20, width: 50 }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <CategoriesDropdown
            value={categoryId}
            onChange={this.onSelectCategory}
            disabled={inactive}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <ImageUpload
            image={coverImgUrl}
            disabled={inactive}
            onChange={this.handleChange('coverImgUrl')}
          />
        </div>
        <div style={{ marginTop: 20, display: 'flex' }}>
          <div>
            <Input
              multiline
              value={descriptionShort}
              onChange={this.handleChange('descriptionShort')}
              placeholder="Description short"
              label="Description short"
              margin="normal"
              variant="outlined"
              disabled={inactive}
              inputProps={{
                style: { minHeight: 120, minWidth: 300 },
              }}
            />
            <p style={{ marginTop: -6, color: '#666', marginBottom: 6 }}>
              Try to keep it under 80 characters
            </p>
          </div>
          <div style={{ marginLeft: 20 }}>
            <Input
              multiline
              value={descriptionLong}
              onChange={this.handleChange('descriptionLong')}
              placeholder="Description long"
              label="Description long"
              margin="normal"
              variant="outlined"
              disabled={inactive}
              inputProps={{
                style: { minHeight: 120, minWidth: 300 },
              }}
            />
            <p style={{ marginTop: -6, color: '#666', marginBottom: 6 }}>
              Try to keep it under 400 characters
            </p>
          </div>
        </div>
        <div>
          <Input
            value={ean}
            onChange={this.handleChange('ean')}
            placeholder="EAN"
            label="EAN"
            margin="normal"
            style={{ width: 300 }}
            disabled={inactive}
          />
        </div>
        <div>
          <Input
            value={seoTitle}
            onChange={this.handleChange('seoTitle')}
            placeholder="Seo Title"
            label="Seo Title"
            margin="normal"
            style={{ width: 300 }}
            disabled={inactive}
          />
        </div>
        <div>
          <Input
            value={seoDescription}
            onChange={this.handleChange('seoDescription')}
            placeholder="seo Description"
            label="Seo Description"
            margin="normal"
            style={{ width: 300 }}
            disabled={inactive}
          />
        </div>
        <div>
          <Input
            value={seoCoverImage}
            onChange={this.handleChange('seoCoverImage')}
            placeholder="Seo CoverImage"
            label="Seo CoverImage"
            margin="normal"
            style={{ width: 300 }}
            disabled={inactive}
          />
        </div>
        <div>
          <Checkbox
            style={{ paddingLeft: 0 }}
            checked={inactive ? true : seoNoIndex}
            disabled={inactive}
            onChange={this.onSEONoIndexCheck}
          />
          Enable a noindex-tag for menu item
        </div>
        <div>
          <div>
            <InputLabel
              style={{
                marginTop: 15,
                fontSize: '0.8rem',
                lineHeight: '1.3',
                display: 'block',
              }}
            >
              SEO redirect menu item
            </InputLabel>
            <MenuItemsDropdown
              value={seoRedirectMenuItem}
              onChange={this.onSelectRedirectMenuItem}
              excludeIds={menuItem && menuItem.id ? [menuItem.id] : []}
            />
          </div>
        </div>
        <Section heading="Tags">
          <Tags
            items={tags}
            onAdd={this.onAddTag}
            onDelete={this.onDeleteTag}
            inactive={inactive}
          />
        </Section>
        {id && (
          <Section heading="Included items">
            <IncludedItems
              items={groups}
              inactive={inactive}
              onGroupChange={this.onGroupChange}
              onGroupAdd={this.onGroupAdd}
              onDelete={this.onGroupDelete}
            />
          </Section>
        )}
        <Section heading="Nutritional value">
          <Nutrition
            {...nutrition}
            inactive={inactive}
            onChange={this.onNutritionChange}
          />
        </Section>
        <Section heading="Restaurants">
          <Restaurants
            checkAll={checkAllRestaurants}
            value={restaurantIds}
            inactive={inactive}
            onCheck={this.onRestaurantCheck}
            onCheckAll={this.onAllRestaurantCheck}
          />
        </Section>
        <Section heading="Recommended items">
          <Related
            label="recommended"
            items={recommended}
            onAdd={this.onAddRecommended}
            onDelete={this.onDeleteRecommended}
            menuItemId={id}
            inactive={inactive}
          />
        </Section>
        <Section heading="Similar items">
          <Related
            label="similar"
            items={similar}
            onAdd={this.onAddSimilar}
            onDelete={this.onDeleteSimilar}
            menuItemId={id}
            inactive={inactive}
          />
        </Section>
      </div>
    );
  }
}

MenuItemDetailsPage.propTypes = {
  id: PropTypes.number,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
};

export default MenuItemDetailsPage;
