const styles = {
  pendingHeading: {
    marginTop: 0,
    marginBottom: 10,
    display: 'inline-block',
  },
  approveBtn: {
    textTransform: 'none',
    textDecoration: 'underline',
  },
};

export default styles;
