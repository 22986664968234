import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  defaultProps,
} from 'recompose';
import { connect } from 'react-redux';

import { getKickbackHistory } from 'actions/loyalties';
import { hideIfNoData } from 'utils/enhancers';

import KickbackHistory from './KickbackHistory';

const PAGING_LIMIT = 40;

const mapDispatchToProps = {
  onInit: getKickbackHistory,
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', 'createDate'),
  withState('sortDirection', 'setSortDirection', 'desc'),
  withState('page', 'setPage', 0),
  defaultProps({
    pageLimit: PAGING_LIMIT,
  })
);

const refresh = (props) => {
  const {
    userId,
    page,
    pageLimit,
    sortName,
    sortDirection,
    onInit,
    setInitialData,
  } = props;
  return onInit({
    userId,
    sortName,
    sortDirection,
    page,
    pageLimit,
  }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    return refresh({ ...props, sortName, sortDirection });
  },
  onPage: (props) => ({ page }) => {
    props.setPage(page);
    return refresh({ ...props, page });
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(KickbackHistory);
