const styles = {
  color: {
    width: 36,
    height: 14,
    borderRadius: 2,
  },
  swatch: {
    padding: 5,
    background: '#fff',
    borderRadius: 1,
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
};

export default styles;
