import React from 'react';

import OrdersTypeList from 'components/OrdersTypeList';

import { ORDER_TYPES } from 'consts/orders';

class PickupOrdersListPage extends React.PureComponent {
  render() {
    return <OrdersTypeList type={ORDER_TYPES.pickUp} {...this.props} />;
  }
}

export default PickupOrdersListPage;
