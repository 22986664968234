import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';

const mapOption = (item) => ({
  label: item.name,
  value: item.id,
});

class MenuItemsDropdown extends React.PureComponent {
  handleChange = ({ value }) => {
    const { items } = this.props.initialData;
    const menuItem = items.find((i) => i.id === value);
    this.props.onChange(menuItem);
  };

  render() {
    const { items } = this.props.initialData;
    const { value, excludeIds } = this.props;
    const selectItems = items.filter(({ id }) => !excludeIds.includes(id));

    return (
      <Select
        options={selectItems.map(mapOption)}
        placeholder="Select"
        value={value ? mapOption(value) : null}
        onChange={this.handleChange}
      />
    );
  }
}

MenuItemsDropdown.propTypes = {
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  value: PropTypes.object,
  excludeIds: PropTypes.array,
  onChange: PropTypes.func,
};

export default MenuItemsDropdown;
