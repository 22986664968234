import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import ColorPickerLabel from 'components/ColorPickerLabel';
import RichTextMarckup from 'shared/components/RichTextMarckup';

import { UPLOAD_IMAGE_URL } from 'consts';
import { LANDING_SECTION_TYPES } from 'shared/consts/settings';

import { renderMultilineText } from '../utils';

import styles from './styles';

class ArticleDetails extends React.PureComponent {
  render() {
    const { classes, item, onDelete } = this.props;

    return (
      <div style={{ width: '100%' }}>
        {item.title && (
          <div className={classes.itemWrapper}>
            <Typography className={classes.itemKey}>Title</Typography>
            <Typography className={classes.itemValue}>
              {renderMultilineText(item.title)}
            </Typography>
          </div>
        )}
        {item.description && (
          <div className={classes.itemWrapper}>
            <Typography className={classes.itemKey}>Description</Typography>
            <RichTextMarckup
              className={classes.itemValue}
              value={item.description}
              component={'div'}
            />
          </div>
        )}
        {item.link &&
          item.link.label && (
            <React.Fragment>
              <div className={classes.itemWrapper}>
                <Typography className={classes.itemKey}>Link label</Typography>
                <Typography className={classes.itemValue}>
                  {item.link.label}
                </Typography>
              </div>
              <div className={classes.itemWrapper}>
                <Typography className={classes.itemKey}>Link URL</Typography>
                <Typography className={classes.itemValue}>
                  {item.link.url}
                </Typography>
              </div>
              <div className={classes.itemWrapper}>
                <Typography className={classes.itemKey}>
                  Link text align
                </Typography>
                <Typography className={classes.itemValue}>
                  {item.link.textAlign}
                </Typography>
              </div>
            </React.Fragment>
          )}
        {item.imageURL && (
          <React.Fragment>
            <div className={classes.itemWrapper}>
              <Typography className={classes.itemKey}>Image</Typography>
              <Typography className={classes.itemValue}>
                <img
                  className={classes.image}
                  src={`${UPLOAD_IMAGE_URL}/${item.imageURL}`}
                  alt={item.imageSEOAlt}
                />
              </Typography>
            </div>
            <div className={classes.itemWrapper}>
              <Typography className={classes.itemKey}>
                Image SEO alt text
              </Typography>
              <Typography className={classes.itemValue}>
                {item.imageSEOAlt}
              </Typography>
            </div>
            <div className={classes.itemWrapper}>
              <Typography className={classes.itemKey}>
                Image position
              </Typography>
              <Typography className={classes.itemValue}>
                {item.imagePosition}
              </Typography>
            </div>
          </React.Fragment>
        )}
        <div className={classes.itemWrapper}>
          <Typography className={classes.itemKey}>Text align</Typography>
          <Typography className={classes.itemValue}>
            {item.textAlign}
          </Typography>
        </div>
        {item.title && (
          <div className={classes.itemWrapper}>
            <Typography className={classes.itemKey}>
              Title text align
            </Typography>
            <Typography className={classes.itemValue}>
              {item.titleAlign}
            </Typography>
          </div>
        )}
        <div className={classes.itemWrapper}>
          <Typography className={classes.itemKey}>Background color</Typography>
          <div className={classes.itemValue}>
            <ColorPickerLabel color={item.backgroundColor} />
          </div>
        </div>
        {item.type === LANDING_SECTION_TYPES.cart && (
          <div className={classes.itemWrapper}>
            <Typography className={classes.itemKey}>
              Cart background color
            </Typography>
            <div className={classes.itemValue}>
              <ColorPickerLabel color={item.cartBgColor} />
            </div>
          </div>
        )}
        <div className={classes.itemWrapper}>
          <Typography className={classes.itemKey}>Text color</Typography>
          <div className={classes.itemValue}>
            <ColorPickerLabel color={item.textColor} />
          </div>
        </div>
        {item.title && (
          <div className={classes.itemWrapper}>
            <Typography className={classes.itemKey}>Title color</Typography>
            <div className={classes.itemValue}>
              <ColorPickerLabel color={item.titleColor} />
            </div>
          </div>
        )}
        <div className={classes.btnWrapper}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Link
              to={
                item.type === LANDING_SECTION_TYPES.article
                  ? `/article-section-details/${item.id}`
                  : `/cart-section-details/${item.id}`
              }
            >
              <Button color="primary" className={classes.button}>
                Edit
              </Button>
            </Link>
          </div>
          <Button
            color="primary"
            className={classes.button}
            onClick={() => onDelete(item.id)}
          >
            Delete
          </Button>
        </div>
      </div>
    );
  }
}

ArticleDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(ArticleDetails);
