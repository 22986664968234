import { call, put, select } from 'redux-saga/effects';

import { GENERIC_REQUEST_ERROR } from 'consts';
import { processUrl } from 'utils/processors';

import request from 'utils/request';

const tokenSelector = (state) => state.auth.token;

export function* apiNotSecureRequest(url, options) {
  try {
    const payload = yield call(request, processUrl(url), options);
    return payload;
  } catch (error) {
    yield put({
      type: GENERIC_REQUEST_ERROR,
      error,
    });
    throw error;
  }
}

export function* apiRequest(url, options) {
  const token = yield select(tokenSelector);
  const newOptions = Object.assign({ headers: {} }, options);
  url.startsWith('/') &&
    (newOptions.headers['Authorization'] = `Bearer ${token.accessToken}`);
  const payload = yield apiNotSecureRequest(url, newOptions);
  return payload;
}
