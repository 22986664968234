import { compose } from 'recompose';
import { connect } from 'react-redux';

import { uploadImage } from 'actions/upload';

import ImageUpload from './ImageUpload';

const mapDispatchToProps = {
  onFileUpload: uploadImage,
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(ImageUpload);
