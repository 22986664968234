import { tablet } from 'shared/styles/breakpoints';

export const generalStyles = {
  paragraph: {
    marginBottom: 26,
    fontSize: 16,
    lineHeight: '1.5',
    [`@media (min-width: ${tablet})`]: {
      fontSize: 17,
      lineHeight: '1.41',
    },
  },
  blockTitle: {
    marginBottom: 16,
    fontSize: 26,
    lineHeight: '1.4',
    letterSpacing: '-0.1px',
    [`@media (min-width: ${tablet})`]: {
      marginBottom: 24,
      fontSize: 32,
      lineHeight: '1.25',
      letterSpacing: '-0.2px',
    },
  },
};

export default generalStyles;
