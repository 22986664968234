import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';

export default function Suggestion(suggestion, { isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        <span style={{ fontWeight: isHighlighted ? 500 : 400 }}>
          {suggestion.value}
        </span>
      </div>
    </MenuItem>
  );
}
