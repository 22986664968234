import { connect } from 'react-redux';

import { clearFilters } from 'actions/redirect';
import { logout } from 'actions/auth';

import BaseLayout from './BaseLayout';

const mapDispatchToProps = {
  onLogout: logout,
  onClearFilters: clearFilters,
};

export default connect(
  null,
  mapDispatchToProps
)(BaseLayout);
