import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import Input from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

import PDFUpload from 'components/PDFUpload';
import Select from 'components/Select';
import SmallButton from 'components/SmallButton';

import { TEXT_ALIGN, LINK_TYPES } from 'consts/settings';
import { getInputPlaceholder, getInputLabel, validateError } from './utils';

const stateFromProps = ({ item = {} }) => {
  return {
    type: (item || {}).type || LINK_TYPES[0].value,
    label: (item || {}).label || '',
    url: (item || {}).url || '',
    notAuthType: (item || {}).notAuthType || LINK_TYPES[0].value,
    notAuthLabel: (item || {}).notAuthLabel || '',
    notAuthUrl: (item || {}).notAuthUrl || '',
    textAlign: (item || {}).textAlign || TEXT_ALIGN[0].value,
    openInNewTab: (item || {}).openInNewTab || false,
    hideOnMobile: (item || {}).hideOnMobile || false,
    isOrderFlowMarker: (item || {}).isOrderFlowMarker || false,
    withNotificationMarket: (item || {}).withNotificationMarket || false,
  };
};

class EditHeaderLinkModal extends React.PureComponent {
  state = stateFromProps(this.props);

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleCheckboxChange = (fieldName) => ({ target: { checked } }) => {
    this.setState({
      [fieldName]: checked,
    });
  };

  handleTextAlignChange = ({ value }) => {
    this.setState({
      textAlign: value,
    });
  };

  handleTypeChange = ({ value }) => {
    this.setState({
      type: value,
      url: '',
    });
  };

  handleNotAuthTypeChange = ({ value }) => {
    this.setState({
      notAuthType: value,
      notAuthUrl: '',
    });
  };

  handleSave = () => {
    const { ...data } = this.state;
    const { item, onSave, onClose } = this.props;

    const validateErrorMessage = validateError(data);
    if (validateErrorMessage) {
      toastr.error('Error!', validateErrorMessage);
    } else {
      const id = item && item.id ? item.id : undefined;
      onSave(data, id);
      onClose();
    }
  };

  render() {
    const { open, item, onClose } = this.props;
    const { ...data } = this.state;

    return (
      <Dialog onClose={onClose} aria-labelledby="edit-link-modal" open={open}>
        <DialogTitle id="edit-link-title">
          <Typography color="secondary" style={{ fontSize: 18 }}>
            {!item ? 'Add new link' : 'Edit link data'}
          </Typography>
        </DialogTitle>
        <div style={{ padding: '0px 24px 30px' }}>
          <div>
            <InputLabel style={{ fontSize: 12, position: 'relative', top: 4 }}>
              Link type
            </InputLabel>
            <div>
              <Select
                options={LINK_TYPES}
                value={LINK_TYPES.find((i) => i.value === data.type)}
                onChange={this.handleTypeChange}
                width={200}
                placeholder="Link type"
              />
            </div>
          </div>
          <div>
            <Input
              value={data.label}
              type="string"
              onChange={this.handleChange('label')}
              placeholder="Link label"
              label="Link label"
              margin="normal"
              style={{ width: 300 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {data.type === 'file' ? (
            <div>
              <PDFUpload
                file={data.url}
                onChange={this.handleChange('url')}
                placeholder="File URL"
                label="File URL"
              />
              {/* <input type="file" id="file" accept=".pdf" />
              <SmallButton onClick={this.handleUpload}>Save</SmallButton> */}
            </div>
          ) : (
            <div>
              <Input
                value={data.url}
                type="string"
                onChange={this.handleChange('url')}
                placeholder={getInputPlaceholder(data.type)}
                label={getInputLabel(data.type)}
                margin="normal"
                style={{ width: 300 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          )}
          <div>
            <InputLabel style={{ fontSize: 12, position: 'relative', top: 4 }}>
              Link text align
            </InputLabel>
            <div>
              <Select
                options={TEXT_ALIGN}
                value={TEXT_ALIGN.find((i) => i.value === data.textAlign)}
                onChange={this.handleTextAlignChange}
                width={200}
                placeholder="Link text align"
              />
            </div>
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.openInNewTab}
                  onChange={this.handleCheckboxChange('openInNewTab')}
                  value="openInNewTab"
                />
              }
              label="Open page in a new tab."
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.hideOnMobile}
                  onChange={this.handleCheckboxChange('hideOnMobile')}
                  value="hideOnMobile"
                />
              }
              label="Hide on mobile"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.isOrderFlowMarker}
                  onChange={this.handleCheckboxChange('isOrderFlowMarker')}
                  value="isOrderFlowMarker"
                />
              }
              label="Is order flow marker"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.withNotificationMarket}
                  onChange={this.handleCheckboxChange('withNotificationMarket')}
                  value="withNotificationMarket"
                />
              }
              label="With notification marker"
            />
          </div>
          <p>Additional link for not authorized users</p>
          <div>
            <InputLabel style={{ fontSize: 12, position: 'relative', top: 4 }}>
              Link type
            </InputLabel>
            <div>
              <Select
                options={LINK_TYPES}
                value={LINK_TYPES.find((i) => i.value === data.notAuthType)}
                onChange={this.handleNotAuthTypeChange}
                width={200}
                placeholder="Link type"
              />
            </div>
          </div>
          <div>
            <Input
              value={data.notAuthLabel}
              type="string"
              onChange={this.handleChange('notAuthLabel')}
              placeholder="Link label"
              label="Link label"
              margin="normal"
              style={{ width: 300 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {data.type === 'file' ? (
            <div>
              <PDFUpload
                file={data.notAuthUrl}
                onChange={this.handleChange('notAuthUrl')}
                placeholder="File URL"
                label="File URL"
              />
            </div>
          ) : (
            <div>
              <Input
                value={data.notAuthUrl}
                type="string"
                onChange={this.handleChange('notAuthUrl')}
                placeholder={getInputPlaceholder(data.notAuthType)}
                label={getInputLabel(data.notAuthType)}
                margin="normal"
                style={{ width: 300 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SmallButton
              style={{ cursor: 'pointer' }}
              onClick={this.handleSave}
            >
              Save
            </SmallButton>
          </div>
        </div>
      </Dialog>
    );
  }
}

EditHeaderLinkModal.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  item: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    textAlign: PropTypes.string,
    openInNewTab: PropTypes.bool,
    hideOnMobile: PropTypes.bool,
    isOrderFlowMarker: PropTypes.bool,
  }),
};

export default EditHeaderLinkModal;
