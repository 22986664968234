import {
  AUTH_SUCCESS,
  AUTH_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  LOGOUT,
} from 'consts/auth';

const initialState = {
  token: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
    case REFRESH_TOKEN_SUCCESS: {
      return { ...state, token: action.payload };
    }

    case AUTH_FAILURE:
    case LOGOUT: {
      return { ...state, token: null };
    }

    default:
      return state;
  }
};

export default authReducer;
