import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Select from 'components/Select';

import { getHours, getMinutes } from 'date-fns';
import { getTimeSlots } from '../utils';
import { convertHours } from 'utils/restaurant';

const styles = () => ({
  wrapper: {
    marginTop: '-4px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  col: {
    '&:first-child': {
      margin: '0px 15px',
    },
  },
});

class TimePicker extends React.PureComponent {
  setHours = (timeSlots) => {
    return [...new Set(timeSlots.map((tS) => tS.hour))];
  };

  setMinutes = (h, timeSlots) => {
    return timeSlots.filter((tS) => tS.hour === h).map((item) => ({
      label: ('0' + item.minutes).slice(-2),
      value: item.minutes,
    }));
  };

  onChangeHour = (hour, timeSlots) => {
    const minutes = timeSlots.find((tS) => tS.hour === hour).minutes;
    this.props.onTimeChange(hour, minutes);
  };

  onChangeMinutes = (minutes) => {
    const { selectedHour } = this.props;
    this.props.onTimeChange(selectedHour, minutes);
  };

  render() {
    const { classes, schedule, selectedHour, selectedMinute } = this.props;

    const timeSlotDates = getTimeSlots(schedule);
    const timeSlots = timeSlotDates.map((tS) => ({
      hour: getHours(tS),
      minutes: getMinutes(tS),
    }));
    const hours = this.setHours(timeSlots);
    const timeDict = hours.reduce((el, h) => {
      el[h] = this.setMinutes(h, timeSlots);
      return el;
    }, {});
    const hoursOptions = (hours || []).map((h) => ({
      label: ('0' + h).slice(-2),
      value: h,
    }));

    return (
      <div className={classes.wrapper}>
        <div className={classes.col}>
          <Select
            options={hoursOptions}
            placeholder={selectedHour >= 0 ? convertHours(selectedHour) : '--'}
            width={70}
            value={{ value: selectedHour }}
            onChange={({ value }) => this.onChangeHour(value, timeSlots)}
          />
        </div>
        <div className={classes.col}>
          <Select
            options={timeDict[selectedHour]}
            placeholder={
              selectedMinute >= 0 ? convertHours(selectedMinute) : '--'
            }
            width={70}
            value={{ value: selectedMinute }}
            onChange={({ value }) => this.onChangeMinutes(value)}
          />
        </div>
      </div>
    );
  }
}

TimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  restaurant: PropTypes.object,
  onTimeChange: PropTypes.func.isRequired,
  schedule: PropTypes.shape({
    openHour: PropTypes.instanceOf(Date),
    closeHour: PropTypes.instanceOf(Date),
  }),
  selectedHour: PropTypes.number,
  selectedMinute: PropTypes.number,
};

export default withStyles(styles)(TimePicker);
