import apiRequest from 'sagas/utils/apiRequest';

/**
 * Retrieve simple menu items with name parameter
 * @param {Array} ids - Array of menu items ids
 * @return {Array} Response items with id and name params.
 */
export function* getRelationshipsNamesById(ids = []) {
  const url = `/menu-items?id=${ids.join(',')}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);
  return payload.items.map((i) => ({
    id: i.id,
    name: i.name,
    price: i.price,
    status: i.status,
  }));
}

/**
 * Retrieve food menu relationships
 * @return {Array} Response items.
 */
export function* getRelationships() {
  const url = '/menu-food-relationship';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);
  return payload.items;
}

/**
 * Prepare array of recommended and similar menu items
 * @param {Array} recommended - Array of recommended menu items
 * @param {Array} similar - Array of similar menu items
 * @return {Array} Array of recommended and similar menu items
 */
export function* prepareRelationship(recommended = [], similar = []) {
  const relationships = yield getRelationships();
  return [
    ...recommended.map((i) => ({
      suggestedItemId: i.suggestedItemId,
      typeOfRelationship: (
        relationships.find((r) => r.name === 'recommended') || {}
      ).id,
    })),
    ...similar.map((i) => ({
      suggestedItemId: i.suggestedItemId,
      typeOfRelationship: (
        relationships.find((r) => r.name === 'similar') || {}
      ).id,
    })),
  ];
}
