import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  defaultProps,
} from 'recompose';
import { connect } from 'react-redux';

import { getOrders } from 'actions/orders';
import { hideIfNoData } from 'utils/enhancers';

import { ORDER_STATUSES } from 'consts/orders';

import OrderHistory from './OrderHistory';

const PAGING_LIMIT = 20;

const mapDispatchToProps = {
  onInit: getOrders,
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', 'id'),
  withState('sortDirection', 'setSortDirection', 'desc'),
  withState('page', 'setPage', 0),
  defaultProps({
    pageLimit: PAGING_LIMIT,
  })
);

const refresh = (props) => {
  const {
    userId,
    companyId,
    sortName,
    sortDirection,
    page,
    pageLimit,
    onInit,
    setInitialData,
    getOrdersByDelivery = false,
  } = props;
  return onInit({
    userId,
    companyId,
    sortName,
    sortDirection,
    page,
    pageLimit,
    getOrdersByDelivery,
    status: [
      ORDER_STATUSES.done,
      ORDER_STATUSES.paid,
      ORDER_STATUSES.readyForPayment,
      ORDER_STATUSES.inProduction,
      ORDER_STATUSES.readyForShipment,
      ORDER_STATUSES.cancelled,
    ].join(','),
    withOheicsLogs: false,
  }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setPage(0);
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    return refresh({ ...props, sortName, sortDirection, page: 0 });
  },
  onPage: (props) => ({ page }) => {
    props.setPage(page);
    return refresh({ ...props, page });
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(OrderHistory);
