import { put, all } from 'redux-saga/effects';

import {
  GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_SUCCESS,
  GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_REQUEST,
  GET_PAGINATER_ORDERS_FOR_PACK_LIST_REQUEST,
  GET_PAGINATER_ORDERS_FOR_PACK_LIST_SUCCESS,
  GET_PRODUCTION_ORDERS_REQUEST,
  GET_PRODUCTION_ORDERS_SUCCESS,
  GET_PACK_ORDERS_REQUEST,
  GET_PACK_ORDERS_SUCCESS,
  GET_ORDERS_COUNT_FOR_PRINT_REQUEST,
  GET_ORDERS_COUNT_FOR_PRINT_SUCCESS,
  MAX_ORDERS_COUNT_FOR_PRINT_LIST,
} from 'consts/printList';
import {
  PRODUCTION_LIST_SORTING_NAME,
  PACK_LIST_SORTING_NAME,
} from 'consts/settings';
import { ORDER_STATUSES } from 'consts/orders';
import {
  calculateSort,
  prepareURLRequest,
  openProductionPrintList,
  openPackPrintList,
} from 'utils';
import { fullName } from 'utils/user';
import { formatOrderFiltersDate } from 'utils/date';
import { chunkArray } from 'utils/processors';

import apiRequest from 'sagas/utils/apiRequest';
import { changeMultipleOrdersStatus } from 'sagas/orders';
import { takeFirst, combine } from 'sagas/utils/effects';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';
import { paginateRequest } from 'sagas/utils';
import { getOrdersByIds, getOrderItems } from 'sagas/processOrders';
import { getSettings, getDeliveryCosts } from 'sagas/settings';
import { getCustomers } from 'sagas/customers';
import { getOrderAttributes, getOrderAttributesValues } from 'sagas/attributes';
import { getCompanies } from 'sagas/companies';
import { getLocationsRequest } from 'sagas/locations';
import { getCategories } from 'sagas/categories';

function* getPaginatedOrdersForPrintList({
  restaurantId,
  startTime,
  endTime,
  status,
  orderType,
  userId,
  orderId,
  getOrdersByDelivery,
  getAll = true,
}) {
  try {
    const params = [];
    if (restaurantId) params.push(`restaurantId=${restaurantId}`);
    if (status) params.push(`status=${status}`);
    if (orderType) params.push(`type=${orderType}`);
    if (userId) params.push(`userId=${userId}`);
    if (orderId) params.push(`id=${orderId}`);
    if (getOrdersByDelivery && startTime && endTime)
      params.push(
        `deliveryTime=${formatOrderFiltersDate(
          startTime
        )},${formatOrderFiltersDate(endTime)}`
      );
    if (!getOrdersByDelivery && startTime && endTime)
      params.push(
        `createDate=${formatOrderFiltersDate(
          startTime
        )},${formatOrderFiltersDate(endTime)}`
      );
    if (getOrdersByDelivery) {
      params.push(calculateSort('asc', 'deliveryTime'));
    } else {
      params.push(calculateSort('asc', 'id'));
    }

    const url = getOrdersByDelivery ? '/orders-by-deliveries' : '/orders';
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let rawPayload = null;
    if (getAll) {
      rawPayload = yield paginateRequest({
        url,
        params,
        options,
        maxTotal: MAX_ORDERS_COUNT_FOR_PRINT_LIST,
      });
    } else {
      const requestedUrl = prepareURLRequest(url, params);
      if (!requestedUrl) {
        return;
      }
      rawPayload = yield apiRequest(requestedUrl, options);
    }
    return rawPayload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getOrdersCountForPrint({
  restaurantId,
  startTime,
  endTime,
  status,
  orderType,
  userId,
  orderId,
  getOrdersByDelivery,
  getAll,
}) {
  const response = yield getPaginatedOrdersForPrintList({
    restaurantId,
    startTime,
    endTime,
    status,
    orderType,
    userId,
    orderId,
    getOrdersByDelivery,
    getAll,
  });
  const payload =
    response && response.totalResults > 0 ? response.totalResults : 0;

  const newAction = {
    type: GET_ORDERS_COUNT_FOR_PRINT_SUCCESS,
    payload,
  };
  yield put(newAction);
  return payload;
}

export function* getOrdersCountForPrintSaga() {
  yield takeFirst(GET_ORDERS_COUNT_FOR_PRINT_REQUEST, getOrdersCountForPrint);
}

export function* getPaginatedOrdersForProductionList({
  restaurantId,
  startTime,
  endTime,
  status,
  orderType,
  userId,
  orderId,
  ordersIds,
  orderIdsToChangeStatus,
  getOrdersByDelivery,
}) {
  try {
    let ids = [];
    let idsToChangeStatus = [];
    if (ordersIds && ordersIds.length > 0) {
      ids = ordersIds;
      idsToChangeStatus = orderIdsToChangeStatus;
    } else {
      const response = yield getPaginatedOrdersForPrintList({
        restaurantId,
        startTime,
        endTime,
        status,
        orderType,
        userId,
        orderId,
        getOrdersByDelivery,
      });
      const ordersResponse = response && response.items ? response.items : [];
      idsToChangeStatus = ordersResponse
        .filter(
          (item) =>
            item.status === ORDER_STATUSES.paid && item.type === 'delivery'
        )
        .map((item) => item.id);
      ids = ordersResponse.map((item) => item.id);
    }
    yield changeMultipleOrdersStatus({
      ids: idsToChangeStatus,
      statusToChange: ORDER_STATUSES.inProduction,
    });

    const params = [];
    if (restaurantId) params.push(`restaurantId=${restaurantId}`);
    if (status) params.push(`status=${status}`);
    if (orderType) params.push(`type=${orderType}`);
    params.push(`sortBy=${getOrdersByDelivery ? 'delivery' : 'orderId'}`);
    const paramsString =
      params && params.length > 0 ? `${params.join('&')}&` : '';

    // simulate click to prevent disable open new pages
    const el = document.getElementById('production-print');
    el.onclick = () => {
      openProductionPrintList(ids, paramsString);
    };

    if (el.onclick) {
      el.onclick();
    } else if (el.click) {
      el.click();
    }

    const newAction = {
      type: GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_SUCCESS,
      ids,
    };
    yield put(newAction);
    return ids;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getPaginatedOrdersForProductionListSaga() {
  yield takeFirst(
    GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_REQUEST,
    getPaginatedOrdersForProductionList
  );
}

export function* getPaginatedOrdersForPackList({
  restaurantId,
  startTime,
  endTime,
  status,
  orderType,
  userId,
  orderId,
  ordersIds,
  orderIdsToChangeStatus,
  getOrdersByDelivery,
}) {
  try {
    let ids = [];
    let idsToChangeStatus = [];
    if (ordersIds && ordersIds.length > 0) {
      ids = ordersIds;
      idsToChangeStatus = orderIdsToChangeStatus;
    } else {
      const response = yield getPaginatedOrdersForPrintList({
        restaurantId,
        startTime,
        endTime,
        status,
        orderType,
        userId,
        orderId,
        getOrdersByDelivery,
      });
      const ordersResponse = response && response.items ? response.items : [];
      idsToChangeStatus = ordersResponse
        .filter(
          (item) =>
            item.status === ORDER_STATUSES.inProduction &&
            item.type === 'delivery'
        )
        .map((item) => item.id);
      ids = ordersResponse.map((item) => item.id);
    }
    yield changeMultipleOrdersStatus({
      ids: idsToChangeStatus,
      statusToChange: ORDER_STATUSES.readyForShipment,
    });
    const restaurantParam = restaurantId ? `&restaurantId=${restaurantId}` : '';

    // simulate click to prevent disable open new pages
    const el = document.getElementById('pack-print');
    el.onclick = () => {
      openPackPrintList(ids, restaurantParam);
    };

    if (el.onclick) {
      el.onclick();
    } else if (el.click) {
      el.click();
    }

    const newAction = {
      type: GET_PAGINATER_ORDERS_FOR_PACK_LIST_SUCCESS,
      ids,
    };
    yield put(newAction);
    return ids;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getPaginatedOrdersForPackListSaga() {
  yield takeFirst(
    GET_PAGINATER_ORDERS_FOR_PACK_LIST_REQUEST,
    getPaginatedOrdersForPackList
  );
}

function* getPaginatedMenuItemsByIds(ids) {
  try {
    const chankedArray = chunkArray(ids, 100);
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const categoriesPayload = yield getCategories();
    const categoriesDict =
      categoriesPayload &&
      categoriesPayload.items &&
      categoriesPayload.items.length
        ? categoriesPayload.items.reduce((obj, item) => {
            obj[item.id] = item.name;
            return obj;
          }, {})
        : {};
    let response = [];
    yield all(
      chankedArray.map(function*(mIds) {
        const result = yield apiRequest(
          `/menu-items?id=${mIds.join()}`,
          options
        );
        if (result && result.items && result.items.length > 0) {
          response = [...response, ...result.items];
        }
      })
    );
    return response.map((item) => ({
      ...item,
      categoryName: categoriesDict[item.categoryId],
    }));
  } catch (error) {
    throw error;
  }
}

/**
 * Retrieve orders for production list.
 * @param {Object} { ids } - orders ids joined with ","
 * @return {Object} Response for request.
 */
export function* getProductionOrders({ ids }) {
  try {
    const [orders, orderItems, productionListSettings] = yield all([
      getOrdersByIds(ids, 'asc', 'deliveryTime'),
      getOrderItems({ ids }),
      getSettings({ name: PRODUCTION_LIST_SORTING_NAME }),
    ]);
    let productionListSettingsDict = undefined;
    if (Object.keys(productionListSettings).length) {
      const settings = JSON.parse(productionListSettings.value);
      productionListSettingsDict = settings.reduce((acc, item) => {
        acc[item.menuItemId] = item;
        return acc;
      }, {});
    }
    const menuItemsIds = [];
    Object.values(orderItems).forEach((orderItemResponse) => {
      if (orderItemResponse.items && orderItemResponse.items.length) {
        orderItemResponse.items.forEach((orderItem) => {
          if (
            orderItem.menuItemId &&
            menuItemsIds.indexOf(orderItem.menuItemId) === -1
          ) {
            menuItemsIds.push(orderItem.menuItemId);
          }
        });
      }
    });

    const mappedOrdersItems = {
      ...orders,
      items: orders.items.map((order) => ({
        ...order,
        items:
          orderItems && Object.keys(orderItems).length
            ? orderItems[order.id].items || []
            : [],
      })),
    };

    const menuItems =
      menuItemsIds && menuItemsIds.length
        ? yield getPaginatedMenuItemsByIds(menuItemsIds)
        : [];

    const menuItemsDict = {};
    (menuItems || []).forEach((menuItem) => {
      menuItemsDict[menuItem.id] = menuItem;
    });
    const payload = {
      ...mappedOrdersItems,
      items: mappedOrdersItems.items.map((order) => {
        const newOrderItems = order.items.map((orderItem, index) => ({
          ...orderItem,
          menuItem: {
            ...menuItemsDict[orderItem.menuItemId],
            visible:
              productionListSettingsDict &&
              productionListSettingsDict[orderItem.menuItemId]
                ? productionListSettingsDict[orderItem.menuItemId].visible
                : true,
            order:
              productionListSettingsDict &&
              productionListSettingsDict[orderItem.menuItemId]
                ? productionListSettingsDict[orderItem.menuItemId].index
                : index,
          },
        }));
        const newOrder = {
          ...order,
          items: newOrderItems,
        };
        return newOrder;
      }),
    };

    const newAction = {
      type: GET_PRODUCTION_ORDERS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getProductionOrdersSaga() {
  yield takeFirst(GET_PRODUCTION_ORDERS_REQUEST, getProductionOrders);
}

/**
 * Retrieve orders for pack list.
 * @param {Object} { ids } - orders ids joined with ","
 * @return {Object} Response for request.
 */
export function* getPackOrders({ ids }) {
  try {
    const [orders, orderItems, packListSettings, orderAttrsCompany, orderAttrsCommentToEdit] = yield all([
      getOrdersByIds(ids, 'asc', 'id'),
      getOrderItems({ ids }),
      getSettings({ name: PACK_LIST_SORTING_NAME }),
      getOrderAttributes({ name: 'companyId' }),
      getOrderAttributes({ name: 'commentToEditOrder'}), 
    ]);
    let companyAttrId;
    if (orderAttrsCompany && orderAttrsCompany.items && orderAttrsCompany.items.length) {
      companyAttrId = orderAttrsCompany.items[0].id;
    }

    let commentToEditAttrId;
    if (orderAttrsCommentToEdit && orderAttrsCommentToEdit.items && orderAttrsCommentToEdit.items.length) {
      commentToEditAttrId = orderAttrsCommentToEdit.items[0].id;
    }

    const attrValuesCompany = yield getOrderAttributesValues({
      attributeId: companyAttrId,
      orderId: orders.items.map((order) => order.id),
    });

    const attrValuesCommentToEdit = yield getOrderAttributesValues({
      attributeId: commentToEditAttrId,
      orderId: orders.items.map((order) => order.id),
    });

    const companiesIds = [];
    const companyOrderDict = attrValuesCompany.items.reduce((acc, attrVal) => {
      const companyId = parseInt(attrVal.value, 10);
      companiesIds.push(companyId);
      acc[attrVal.orderId] = companyId;
      return acc;
    }, {});

    let companiesDict = {};
    if (companiesIds && companiesIds.length) {
      const companies = yield getCompanies({ ids: companiesIds });
      companiesDict =
        companies && companies.items
          ? companies.items.reduce((acc, company) => {
              acc[company.id] = company;
              return acc;
            }, {})
          : {};
    }

    const commentsToEditDict = attrValuesCommentToEdit.items.reduce((acc, attrVal) => {
      acc[attrVal.orderId] = attrVal.value;
      return acc;
    }, {});

    let packListSettingsDict;
    if (Object.keys(packListSettings).length) {
      const settings = JSON.parse(packListSettings.value);
      packListSettingsDict = settings.reduce((acc, item) => {
        acc[item.menuItemId] = item;
        return acc;
      }, {});
    }
    const userIds = [];
    orders.items.forEach((order) => {
      if (order && order.userId && userIds.indexOf(order.userId) === -1) {
        userIds.push(order.userId);
      }
    });
    const menuItemsIds = [];
    Object.values(orderItems).forEach((orderItemResponse) => {
      if (orderItemResponse.items && orderItemResponse.items.length) {
        orderItemResponse.items.forEach((orderItem) => {
          if (
            orderItem.menuItemId &&
            menuItemsIds.indexOf(orderItem.menuItemId) === -1
          ) {
            menuItemsIds.push(orderItem.menuItemId);
          }
        });
      }
    });

    const locationsIds = orders.items
      .map((item) => item.delivery)
      .filter((item) => item && item.location !== 0)
      .map((item) => item.locationId);
    const locations = yield getLocationsRequest({ ids: locationsIds });
    const locationDictionary = locations.items.reduce((acc, location) => {
      acc[location.id] = location;
      return acc;
    }, {});

    const deliveryCost = yield getDeliveryCosts({});
    const costDictionary = deliveryCost.items.reduce((acc, cost) => {
      acc[cost.id] = cost;
      return acc;
    }, {});

    const mappedOrdersItems = {
      ...orders,
      items: orders.items.map((order) => {
        const currLocation =
          order.delivery &&
          order.delivery.locationId &&
          locationDictionary[order.delivery.locationId]
            ? locationDictionary[order.delivery.locationId]
            : '';
        return {
          ...order,
          type: order.type,
          deliveryDate: order.delivery ? order.delivery.deliveryTime : '',
          address: currLocation
            ? `${currLocation.streetAddress || ''} ${currLocation.postcode ||
                ''} ${currLocation.city || ''}`
            : '',
          deliveryCost:
            order.delivery && order.delivery.deliveryCostId
              ? costDictionary[order.delivery.deliveryCostId]
              : {},
          items:
            orderItems && Object.keys(orderItems).length
              ? orderItems[order.id].items || []
              : [],
        };
      }),
    };

    const userItemsDict = {};
    const menuItemsDict = {};
    const userItems = userIds.length
      ? yield getCustomers({ ids: userIds })
      : [];
    const menuItems = menuItemsIds.length
      ? yield getPaginatedMenuItemsByIds(menuItemsIds)
      : [];
    if (userItems && userItems.items && userItems.items.length) {
      userItems.items.forEach((user) => {
        userItemsDict[user.id] = user;
      });
    }
    if (menuItems && menuItems.length) {
      menuItems.forEach((menuItem) => {
        menuItemsDict[menuItem.id] = menuItem;
      });
    }

    const payload = {
      ...mappedOrdersItems,
      items: mappedOrdersItems.items.map((order) => {
        const newOrderItems = order.items.map((orderItem) => ({
          ...orderItem,
          menuItem: menuItemsDict[orderItem.menuItemId],
          visible:
            packListSettingsDict && packListSettingsDict[orderItem.menuItemId]
              ? packListSettingsDict[orderItem.menuItemId].visible
              : true,
          order:
            packListSettingsDict && packListSettingsDict[orderItem.menuItemId]
              ? packListSettingsDict[orderItem.menuItemId].index
              : undefined,
        }));
        const userItem = userItemsDict[order.userId] || {};
        const newOrder = {
          ...order,
          commentToEdit: commentsToEditDict && commentsToEditDict[order.id] ? commentsToEditDict[order.id] : undefined,
          user: {
            name: fullName(userItem),
            phone: userItem.phone,
            email: userItem.email,
          },
          company:
            companiesDict && companyOrderDict[order.id]
              ? companiesDict[companyOrderDict[order.id]]
              : undefined,
          items: newOrderItems,
        };
        return newOrder;
      }),
    };

    const newAction = {
      type: GET_PACK_ORDERS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getPackOrdersSaga() {
  yield takeFirst(GET_PACK_ORDERS_REQUEST, getPackOrders);
}

export default combine([
  getPaginatedOrdersForProductionListSaga,
  getPaginatedOrdersForPackListSaga,
  getProductionOrdersSaga,
  getPackOrdersSaga,
  getOrdersCountForPrintSaga,
]);
