const styles = {
  buttonWrapper: {
    height: 36,
  },
  toolbarButon: {
    height: 36,
    width: 40,
    minWidth: 40,
    boxSizing: 'border-box',
    color: (props) =>
      props.theme ? props.theme.palette.text.hint : 'rgba(0,0,0,0.38)',
  },
};

export default styles;
