import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  SAVE_COMPANY_REQUEST,
  SAVE_COMPANY_SUCCESS,
  SAVE_COMPANY_FAILURE,
  SEARCH_STRICT_ORGANIZATION_NUMBER_REQUEST,
  SEARCH_STRICT_ORGANIZATION_NUMBER_SUCCESS,
  SEARCH_STRICT_ORGANIZATION_NUMBER_FAILURE,
} from 'consts/companies';

export function getCompanies({
  sortName,
  sortDirection,
  ids,
  search,
  searchParam,
  page,
  pageLimit,
}) {
  return {
    type: GET_COMPANIES_REQUEST,
    sortName,
    sortDirection,
    ids,
    search,
    searchParam,
    page,
    pageLimit,
    [WAIT_FOR_ACTION]: GET_COMPANIES_SUCCESS,
    [ERROR_ACTION]: GET_COMPANIES_FAILURE,
  };
}

export function searchByOrganNumber(organisationNumber) {
  return {
    type: SEARCH_STRICT_ORGANIZATION_NUMBER_REQUEST,
    organisationNumber,
    [WAIT_FOR_ACTION]: SEARCH_STRICT_ORGANIZATION_NUMBER_SUCCESS,
    [ERROR_ACTION]: SEARCH_STRICT_ORGANIZATION_NUMBER_FAILURE,
  };
}

export function getCompanyById(id) {
  return {
    type: GET_COMPANY_REQUEST,
    id,
    [WAIT_FOR_ACTION]: GET_COMPANY_SUCCESS,
    [ERROR_ACTION]: GET_COMPANY_FAILURE,
  };
}

export function saveCompany({
  id,
  name,
  organisationNumber,
  contactPerson,
  invoicePhysicalAddress,
  invoiceDigitalAddress,
  discount,
  discountId,
  discountDate,
  compliment,
  complimentId,
}) {
  return {
    type: SAVE_COMPANY_REQUEST,
    id,
    name,
    organisationNumber,
    contactPerson,
    invoicePhysicalAddress,
    invoiceDigitalAddress,
    discount,
    discountId,
    discountDate,
    compliment,
    complimentId,
    [WAIT_FOR_ACTION]: SAVE_COMPANY_SUCCESS,
    [ERROR_ACTION]: SAVE_COMPANY_FAILURE,
  };
}
