import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { DATETIME_FORMAT_WO_SECS } from 'consts';
import { ORDER_STATUSES_LABEL, ORDER_TYPES_LABEL } from 'consts/orders';
import { formatDateTime, formatOrderDeliveryDateTime } from 'utils/date';

const rightAlign = { textAlign: 'right' };

const styles = () => ({
  printPageHeadingDetails: {
    marginTop: 4,
  },
  printDeliveriesWrapper: {
    display: 'flex',
  },
  printDeliveriesDetails: {
    marginRight: 60,
  },
  printDeliveriesText: {
    marginTop: 6,
  },
  checkbox: {
    display: 'inline-block',
    width: 44,
    height: 28,
    boxSizing: 'border-box',
    border: '1px solid #4a4a4a',
  },
  printCommentarLabel: {
    fontStyle: 'italic',
    marginRight: 8,
  },
  printCommentarValue: {
    wordBreak: 'break-all',
  },
  filtersSection: {
    marginBottom: 20,
  },
  column: {
    width: '50%',
    paddinRight: 20,
    boxSizing: 'border-box',
    '&:last-child': {
      paddinRight: 0,
    },
  },
});

class ProductionListPage extends React.PureComponent {
  render() {
    const printDate = formatDateTime(new Date(), DATETIME_FORMAT_WO_SECS);
    const {
      sections,
      numberOfOrders,
      classes,
      page,
      pages,
      selectedRestaurants,
      sortBy,
      ids,
      type,
      status,
    } = this.props;
    const idsArray = ids ? ids.split(',') : [];
    const statusArray = status ? status.split(',') : [];
    const typeArray = type ? type.split(',') : [];

    return (
      <div>
        <h1 className="print-page-heading">Production list</h1>
        {page &&
          pages &&
          pages > 1 && (
            <p className="print-text" style={{ fontSize: 16 }}>
              Page {page} / {pages}
            </p>
          )}
        <div style={{ display: 'flex' }}>
          <div
            className={classes.printPageHeadingDetails}
            style={{ width: 250 }}
          >
            <p className="print-text">{numberOfOrders} orders in selection</p>
            <p className="print-text">Printed {printDate}</p>
          </div>
        </div>
        <div className="print-divider" />
        <div
          className={classes.printPageHeadingDetails}
          style={{ display: 'flex' }}
        >
          <div className={classes.column}>
            <div className={classes.filtersSection}>
              {sortBy === 'delivery' &&
                sections &&
                sections[0] &&
                sections[0].deliveryStart &&
                sections[0].deliveryEnd && (
                  <div>
                    <p className="print-heading">Delivery</p>
                    <p className="print-text">
                      {formatOrderDeliveryDateTime(sections[0].deliveryStart)} -{' '}
                      {formatOrderDeliveryDateTime(sections[0].deliveryEnd)}
                    </p>
                  </div>
                )}
              {sortBy === 'orderId' &&
                idsArray &&
                idsArray.length > 1 && (
                  <div>
                    <p className="print-heading">Orders Range</p>
                    <p className="print-text">
                      {idsArray[0]} - {idsArray[idsArray.length - 1]}
                    </p>
                  </div>
                )}
            </div>
            {selectedRestaurants &&
              selectedRestaurants.length > 0 && (
                <div>
                  <p className="print-heading">Restaurant</p>
                  <p className="print-text">
                    {selectedRestaurants.map((item) => item.name).join(', ')}
                  </p>
                </div>
              )}
          </div>
          <div className={classes.column}>
            {typeArray &&
              typeArray.length > 0 && (
                <div className={status ? classes.filtersSection : ''}>
                  <p className="print-heading">Order type</p>
                  <p className="print-text">
                    {typeArray
                      .map((item) => ORDER_TYPES_LABEL[item])
                      .join(', ')}
                  </p>
                </div>
              )}
            {statusArray &&
              statusArray.length > 0 && (
                <div>
                  <p className="print-heading">Status</p>
                  <p className="print-text">
                    {statusArray
                      .map((item) => ORDER_STATUSES_LABEL[item])
                      .join(', ')}
                  </p>
                </div>
              )}
          </div>
        </div>
        <div />
        <div className="print-divider" />
        <table className="print-table" style={{ marginTop: 60 }}>
          <thead>
            <tr>
              <th>Product</th>
              <th style={rightAlign}>Quantity</th>
              <th style={rightAlign}>Done</th>
            </tr>
          </thead>
          <tbody>
            {sections.map((section, index) => (
              <React.Fragment key={index}>
                {section.items
                  .filter((item) => item.visible)
                  .sort(
                    (a, b) =>
                      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
                  )
                  .map((item, jndex) => (
                    <React.Fragment key={jndex}>
                      <tr>
                        <td>
                          {item.name} : {item.categoryName}
                        </td>
                        <td style={rightAlign}>{item.quantity}</td>
                        <td style={rightAlign}>
                          <div className={classes.checkbox} />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

ProductionListPage.propTypes = {
  classes: PropTypes.object,
  numberOfOrders: PropTypes.number.isRequired,
  ids: PropTypes.string,
  page: PropTypes.string,
  pages: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  sortBy: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      notice: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  selectedRestaurants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};

export default withStyles(styles)(ProductionListPage);
