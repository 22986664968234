export const GET_RESTAURANTS_REQUEST = 'GET_RESTAURANTS_REQUEST';
export const GET_RESTAURANTS_SUCCESS = 'GET_RESTAURANTS_SUCCESS';
export const GET_RESTAURANTS_FAILURE = 'GET_RESTAURANTS_FAILURE';

export const GET_RESTAURANTS_DICTIONARY_REQUEST =
  'GET_RESTAURANTS_DICTIONARY_REQUEST';
export const GET_RESTAURANTS_DICTIONARY_SUCCESS =
  'GET_RESTAURANTS_DICTIONARY_SUCCESS';
export const GET_RESTAURANTS_DICTIONARY_FAILURE =
  'GET_RESTAURANTS_DICTIONARY_FAILURE';

export const GET_RESTAURANT_BY_ID_REQUEST = 'GET_RESTAURANT_BY_ID_REQUEST';
export const GET_RESTAURANT_BY_ID_SUCCESS = 'GET_RESTAURANT_BY_ID_SUCCESS';
export const GET_RESTAURANT_BY_ID_FAILURE = 'GET_RESTAURANT_BY_ID_FAILURE';

export const SAVE_RESTAURANT_REQUEST = 'SAVE_RESTAURANT_REQUEST';
export const SAVE_RESTAURANT_SUCCESS = 'SAVE_RESTAURANT_SUCCESS';
export const SAVE_RESTAURANT_FAILURE = 'SAVE_RESTAURANT_FAILURE';

export const SYNC_OHEICS_RESTAURANT_REQUEST = 'SYNC_OHEICS_RESTAURANT_REQUEST';
export const SYNC_OHEICS_RESTAURANT_SUCCESS = 'SYNC_OHEICS_RESTAURANT_SUCCESS';
export const SYNC_OHEICS_RESTAURANT_FAILURE = 'SYNC_OHEICS_RESTAURANT_FAILURE';

export const restaurantOwnerOptions = [
  {
    name: 'Panini AB',
    orgNumber: '5567508501',
  },
  {
    name: 'Panini Trading AB',
    orgNumber: '5566413810',
  },
  {
    name: 'Panininu AB',
    orgNumber: '5569408288',
  },
];

export const TIME_ZONE_LABEL = 'timeZone';
export const OPENING_HOURS_LABEL = 'openingHours';
export const SPECIAL_OPENING_HOURS_LABEL = 'specialOpeningHours';
export const NAME_VISIBLE = 'nameVisible';
export const DELIVERY_TIMEOUT = 'deliveryTimeout';
