import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { getCategoryMenuItems, updateCategory } from 'actions/menu-items';
import { hideIfNoData } from 'utils/enhancers';

import MenuItems from './MenuItems';

const mapDispatchToProps = {
  onInit: getCategoryMenuItems,
  onSubmit: updateCategory,
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', 'name'),
  withState('sortDirection', 'setSortDirection', 'asc')
);

const refresh = (props) => {
  const { category, sortName, sortDirection, onInit, setInitialData } = props;
  return onInit({
    sortName,
    sortDirection,
    selectedCategory: category.id,
  }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    return refresh({ ...props, sortName, sortDirection });
  },
  onSubmit: (props) => (event) =>
    props.onSubmit(event).then(() => refresh(props)),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(MenuItems);
