import {
  compose,
  mapProps,
  lifecycle,
  withHandlers,
  withState,
} from 'recompose';
import { connect } from 'react-redux';

import { getPackOrders } from 'actions/printList';
import { routerPassQuery, hideIfNoData } from 'utils/enhancers';

import PackListPage from './PackListPage';
import groupToSections from './utils';

const mapDispatchToProps = {
  onInit: getPackOrders,
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props) => {
  const { ids, onInit, setInitialData } = props;
  return onInit({ ids }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

const mapInitialData = mapProps(({ initialData, ...rest }) => ({
  sections: groupToSections(initialData.items),
  ...rest,
}));

export default compose(
  routerPassQuery,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData),
  mapInitialData
)(PackListPage);
