import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_COMPANY_MEMBERS_REQUEST,
  GET_COMPANY_MEMBERS_SUCCESS,
  GET_COMPANY_MEMBERS_FAILURE,
  ADD_MEMBER_TO_COMPANY_REQUEST,
  ADD_MEMBER_TO_COMPANY_SUCCESS,
  ADD_MEMBER_TO_COMPANY_FAILURE,
  APPROVE_COMPANY_MEMBER_REQUEST,
  APPROVE_COMPANY_MEMBER_SUCCESS,
  APPROVE_COMPANY_MEMBER_FAILURE,
  APPROVE_ALL_MEMBERS_REQUEST,
  APPROVE_ALL_MEMBERS_SUCCESS,
  APPROVE_ALL_MEMBERS_FAILURE,
  DECLINE_COMPANY_MEMBER_REQUEST,
  DECLINE_COMPANY_MEMBER_SUCCESS,
  DECLINE_COMPANY_MEMBER_FAILURE,
  GET_USER_COMPANIES_REQUEST,
  GET_USER_COMPANIES_SUCCESS,
  GET_USER_COMPANIES_FAILURE,
} from 'consts/userRegistry';

export function getCompanyMembers(companyIds) {
  return {
    type: GET_COMPANY_MEMBERS_REQUEST,
    companyIds,
    [WAIT_FOR_ACTION]: GET_COMPANY_MEMBERS_SUCCESS,
    [ERROR_ACTION]: GET_COMPANY_MEMBERS_FAILURE,
  };
}

export function addCompanyMember({ companyId, userId }) {
  return {
    type: ADD_MEMBER_TO_COMPANY_REQUEST,
    companyId,
    userId,
    [WAIT_FOR_ACTION]: ADD_MEMBER_TO_COMPANY_SUCCESS,
    [ERROR_ACTION]: ADD_MEMBER_TO_COMPANY_FAILURE,
  };
}

export function approveCompanyMember(userRegistryId) {
  return {
    type: APPROVE_COMPANY_MEMBER_REQUEST,
    userRegistryId,
    [WAIT_FOR_ACTION]: APPROVE_COMPANY_MEMBER_SUCCESS,
    [ERROR_ACTION]: APPROVE_COMPANY_MEMBER_FAILURE,
  };
}

export function approveAllPendingMember(userRegistryIds) {
  return {
    type: APPROVE_ALL_MEMBERS_REQUEST,
    userRegistryIds,
    [WAIT_FOR_ACTION]: APPROVE_ALL_MEMBERS_SUCCESS,
    [ERROR_ACTION]: APPROVE_ALL_MEMBERS_FAILURE,
  };
}

export function declineCompanyMember(userRegistryId) {
  return {
    type: DECLINE_COMPANY_MEMBER_REQUEST,
    userRegistryId,
    [WAIT_FOR_ACTION]: DECLINE_COMPANY_MEMBER_SUCCESS,
    [ERROR_ACTION]: DECLINE_COMPANY_MEMBER_FAILURE,
  };
}

export function getUserCompanies({
  userId,
  sortName,
  sortDirection,
  page,
  pageLimit,
  status,
}) {
  return {
    type: GET_USER_COMPANIES_REQUEST,
    userId,
    sortName,
    sortDirection,
    page,
    pageLimit,
    status,
    [WAIT_FOR_ACTION]: GET_USER_COMPANIES_SUCCESS,
    [ERROR_ACTION]: GET_USER_COMPANIES_FAILURE,
  };
}
