import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import randomstring from 'randomstring';

import Button from '@material-ui/core/Button';

import PageHeading from 'components/PageHeading';

import EditHeaderLinkModal from './EditHeaderLinkModal';
import ImageSection from './ImageSection';
import MenuList from './MenuList';

import { reorder } from 'utils/processors';

import { HEADER_SETTINGS_DATA_NAME } from 'consts/settings';

class HeaderDynamicContent extends React.PureComponent {
  state = {
    leftMenu: this.props.initialData.leftMenu || [],
    rightMenu: this.props.initialData.rightMenu || [],
    desktopLogo: this.props.initialData.desktopLogo || {},
    mobileLogo: this.props.initialData.mobileLogo || {},
    modalShown: false,
    selectedItem: null,
    selectedSection: '',
  };

  handleOpenModal = (section) => (item) => {
    this.setState({
      modalShown: true,
      selectedItem: item,
      selectedSection: section,
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalShown: false,
      selectedItem: null,
      selectedSection: '',
    });
  };

  handleAddNewMenuItemToSection = (section) => () => {
    if (this.state[section].length >= 3) {
      toastr.error(
        'Error!',
        'You can not add more than 3 menu items for left or right menu section'
      );
      return;
    }
    this.handleOpenModal(section)();
  };

  reorder = (section, sourceIndex, destinationIndex, cb) => {
    const newItems = reorder(
      this.state[section],
      sourceIndex,
      destinationIndex
    );

    this.setState({ [section]: newItems }, cb && cb());
  };

  handleDragEnd = (section) => (result) => {
    if (!result.destination) {
      return;
    }

    this.reorder(section, result.source.index, result.destination.index);
  };

  handleDeleteMenuItem = (section) => (index) => {
    const newItems = this.state[section].filter((value, i) => i !== index);
    this.setState({
      [section]: newItems,
    });
  };

  handleEditMenuItem = (section, id, newItem) => {
    const newItems = this.state[section].map((item) =>
      item.id === id
        ? {
            ...item,
            ...newItem,
          }
        : item
    );
    this.setState({
      [section]: newItems,
    });
  };

  handleAddMenuItem = (section, newItem) => {
    this.setState({
      [section]: [
        ...this.state[section],
        { ...newItem, id: randomstring.generate(7) },
      ],
    });
  };

  handleSaveMenuItem = (data, id) => {
    const { selectedSection } = this.state;
    const itemToSave = (this.state[selectedSection] || []).find(
      (link) => link.id === id
    );

    if (itemToSave) {
      this.handleEditMenuItem(selectedSection, id, data);
    } else {
      this.handleAddMenuItem(selectedSection, data);
    }
  };

  handleLogoChange = (logoType) => (fieldName, value) => {
    const oldLogo = this.state[logoType];
    this.setState({
      [logoType]: {
        ...oldLogo,
        [fieldName]: value,
      },
    });
  };

  handleSubmit = () => {
    const { onSubmit, initialData } = this.props;
    const { leftMenu, rightMenu, desktopLogo, mobileLogo } = this.state;
    const dataToSend = {
      isNew: !Object.keys(initialData).length,
      name: HEADER_SETTINGS_DATA_NAME,
      value: JSON.stringify({
        leftMenu,
        rightMenu,
        desktopLogo,
        mobileLogo,
      }),
    };
    onSubmit(dataToSend)
      .then(() => {
        toastr.success('Success!', 'Content has been saved');
      })
      .catch(() => {
        toastr.error('Error!', 'Something went wrong');
      });
  };

  render() {
    const {
      leftMenu,
      rightMenu,
      desktopLogo,
      mobileLogo,
      modalShown,
      selectedItem,
    } = this.state;

    return (
      <React.Fragment>
        <PageHeading
          title="Header dynamic content"
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        <MenuList
          title="Left menu"
          items={leftMenu}
          withoutDivider
          onAddNewItem={this.handleAddNewMenuItemToSection('leftMenu')}
          onDragEnd={this.handleDragEnd('leftMenu')}
          onOpenModal={this.handleOpenModal('leftMenu')}
          onDeleteItem={this.handleDeleteMenuItem('leftMenu')}
        />
        <MenuList
          title="Right menu"
          items={rightMenu}
          onAddNewItem={this.handleAddNewMenuItemToSection('rightMenu')}
          onDragEnd={this.handleDragEnd('rightMenu')}
          onOpenModal={this.handleOpenModal('rightMenu')}
          onDeleteItem={this.handleDeleteMenuItem('rightMenu')}
        />
        <ImageSection
          item={desktopLogo}
          title="Desktop logo"
          onChange={this.handleLogoChange('desktopLogo')}
        />
        <ImageSection
          item={mobileLogo}
          title="Mobile logo"
          onChange={this.handleLogoChange('mobileLogo')}
        />
        <EditHeaderLinkModal
          open={modalShown}
          item={selectedItem}
          key={selectedItem}
          onClose={this.handleCloseModal}
          onSave={this.handleSaveMenuItem}
        />
      </React.Fragment>
    );
  }
}

HeaderDynamicContent.propTypes = {
  initialData: PropTypes.shape({
    leftMenu: PropTypes.array,
    rightMenu: PropTypes.array,
    desktopLogo: PropTypes.shape({
      imageURL: PropTypes.string,
      imageSEOAlt: PropTypes.string,
    }),
    mobileLogo: PropTypes.shape({
      imageURL: PropTypes.string,
      imageSEOAlt: PropTypes.string,
    }),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default HeaderDynamicContent;
