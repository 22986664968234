const styles = (theme) => ({
  root: {
    padding: '12px 24px 12px',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
  content: {
    padding: '0 24px',
  },
  actions: {
    justifyContent: 'center',
  },
});

export default styles;
