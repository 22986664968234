import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  branch,
  renderComponent,
} from 'recompose';
import { connect } from 'react-redux';

import { getGroups } from 'actions/groups';

import GroupsDropdown from './GroupsDropdown';
import DefaultDropdown from './DefaultDropdown';

const mapDispatchToProps = {
  onInit: getGroups,
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props) => {
  const { onInit, setInitialData } = props;
  return onInit({ sortName: 'name', sortDirection: 'asc' }).then(
    setInitialData
  );
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  branch((props) => !props.initialData, renderComponent(DefaultDropdown))
)(GroupsDropdown);
