import React from 'react';

export const renderMultilineText = (text) => {
  if (!text) return '';

  const array = text.split('<br />');

  return array.map(
    (item, index) =>
      array.length - 1 === index ? (
        <React.Fragment key={index}>{item}</React.Fragment>
      ) : (
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
      )
  );
};
