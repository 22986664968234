import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILURE,
  GET_GROUP_BY_ID_REQUEST,
  GET_GROUP_BY_ID_SUCCESS,
  GET_GROUP_BY_ID_FAILURE,
  SAVE_GROUP_REQUEST,
  SAVE_GROUP_SUCCESS,
  SAVE_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
} from 'consts/groups';

export function getGroups({ sortName, sortDirection }) {
  return {
    type: GET_GROUPS_REQUEST,
    sortName,
    sortDirection,
    [WAIT_FOR_ACTION]: GET_GROUPS_SUCCESS,
    [ERROR_ACTION]: GET_GROUPS_FAILURE,
  };
}

export function getGroupById(id) {
  return {
    type: GET_GROUP_BY_ID_REQUEST,
    id,
    [WAIT_FOR_ACTION]: GET_GROUP_BY_ID_SUCCESS,
    [ERROR_ACTION]: GET_GROUP_BY_ID_FAILURE,
  };
}

export function saveGroup({ id, name, label, menuItems }) {
  return {
    type: SAVE_GROUP_REQUEST,
    id,
    name,
    label,
    menuItems,
    [WAIT_FOR_ACTION]: SAVE_GROUP_SUCCESS,
    [ERROR_ACTION]: SAVE_GROUP_FAILURE,
  };
}

export function deleteGroup(id) {
  return {
    type: DELETE_GROUP_REQUEST,
    id,
    [WAIT_FOR_ACTION]: DELETE_GROUP_SUCCESS,
    [ERROR_ACTION]: DELETE_GROUP_FAILURE,
  };
}
