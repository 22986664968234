import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';

class CustomDialog extends React.Component {
  render() {
    const { open, onClose, classes, children } = this.props;
    return (
      <Dialog
        onClose={onClose}
        maxWidth="md"
        aria-labelledby="custom-dialog"
        open={open}
      >
        <DialogContent className={classes.contentWrapper}>
          <div>
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
            <div className={classes.content}>
              {children ? children : <div>No Data</div>}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

CustomDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(CustomDialog);
