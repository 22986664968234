import React from 'react';
import PropTypes from 'prop-types';

import NoData from 'components/NoData';

import { fullName } from 'utils/user';

const CustomerName = (props) =>
  fullName(props.item) ||
  props.item.phone ||
  props.item.email || <NoData>No name</NoData>;

CustomerName.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CustomerName;
