import React from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import Select from 'components/Select';
import ColorPicker from 'components/ColorPicker';
import CustomLinkSection from 'components/CustomLinkSection';

import { TEXT_ALIGN, H_TAGS } from 'consts/settings';

class ListItemForm extends React.PureComponent {
  render() {
    const {
      item,
      index,
      onChange,
      onChangeColor,
      onChangeLink,
      onChangeSelect,
    } = this.props;

    return (
      <React.Fragment>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            value={item.title}
            type="string"
            onChange={onChange('title', index)}
            label="Title"
            placeholder="Title"
            multiline
            rowsMax="4"
            margin="normal"
            style={{ width: 600, marginRight: 25 }}
          />
          <div>
            <InputLabel style={{ fontSize: 12, position: 'relative', top: 4 }}>
              H-tag
            </InputLabel>
            <div>
              <Select
                options={H_TAGS}
                value={H_TAGS.find((i) => i.value === item.titleHTag)}
                onChange={onChangeSelect('titleHTag', index)}
                width={170}
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div>
          <Input
            value={item.description}
            type="string"
            onChange={onChange('description', index)}
            label="Description"
            placeholder="Description"
            multiline
            rowsMax="10"
            margin="normal"
            style={{ width: 600 }}
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, position: 'relative', top: 4 }}>
            Text align
          </InputLabel>
          <div>
            <Select
              options={TEXT_ALIGN}
              value={TEXT_ALIGN.find((i) => i.value === item.textAlign)}
              onChange={onChangeSelect('textAlign', index)}
              width={170}
              placeholder="Text align"
            />
          </div>
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, position: 'relative', top: 4 }}>
            Title text align
          </InputLabel>
          <div>
            <Select
              options={TEXT_ALIGN}
              value={TEXT_ALIGN.find((i) => i.value === item.titleAlign)}
              onChange={onChangeSelect('titleAlign', index)}
              width={170}
              placeholder="Title align"
            />
          </div>
        </div>
        <div>
          <ColorPicker
            label="Background color"
            color={item.backgroundColor}
            onChange={onChangeColor('backgroundColor', index)}
          />
        </div>
        <div>
          <ColorPicker
            label="Text color"
            color={item.textColor}
            onChange={onChangeColor('textColor', index)}
          />
        </div>
        <div>
          <ColorPicker
            label="Title color"
            color={item.titleColor}
            onChange={onChangeColor('titleColor', index)}
          />
        </div>
        <div style={{ margin: '20px 0' }}>
          <CustomLinkSection
            item={item.link || {}}
            onSave={onChangeLink(index)}
          />
        </div>
      </React.Fragment>
    );
  }
}

ListItemForm.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onChangeColor: PropTypes.func.isRequired,
  onChangeLink: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
};

export default ListItemForm;
