import { SET_FILTERS, CLEAR_FILTER } from 'consts/redirect';

export function setFilters(location, filters) {
  return {
    type: SET_FILTERS,
    filters,
    location,
  };
}

export function clearFilters() {
  return {
    type: CLEAR_FILTER,
  };
}
