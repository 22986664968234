import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  GET_RESTAURANTS_REQUEST,
  GET_RESTAURANTS_SUCCESS,
  GET_RESTAURANT_BY_ID_REQUEST,
  GET_RESTAURANT_BY_ID_SUCCESS,
  SAVE_RESTAURANT_REQUEST,
  SAVE_RESTAURANT_SUCCESS,
  SYNC_OHEICS_RESTAURANT_REQUEST,
  SYNC_OHEICS_RESTAURANT_SUCCESS,
  GET_RESTAURANTS_DICTIONARY_REQUEST,
  GET_RESTAURANTS_DICTIONARY_SUCCESS,
  DELIVERY_TIMEOUT,
} from 'consts/restaurants';
import { calculateSort, findAttributeValue } from 'utils';
import { fixRestaurantsPayload } from 'utils/processors';

import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';
import {
  getRestaurantAttributes,
  prepareRestaurantAttributes,
  prepareRestaurantJSONAttributes,
} from 'sagas/attributes';
import { saveLocation } from 'sagas/locations';

/**
 * Retrieve restaurants list.
 * @param {Object} { ids, sortName, sortDirection } - restaurants ids joined with ",", name of field to sort, direction for sorting.
 * @return {Object} Response for request.
 */
export function* getRestaurants({ ids, sortName, sortDirection } = {}) {
  const idsParam = ids ? `&id=${ids}` : '';
  let url = `/restaurants?${calculateSort(sortDirection, sortName)}${idsParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const rawPayload = yield apiRequest(url, options);
    const payload = fixRestaurantsPayload(rawPayload, {
      sortName,
      sortDirection,
    });
    const newAction = {
      type: GET_RESTAURANTS_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getRestaurantsSaga() {
  yield takeFirst(GET_RESTAURANTS_REQUEST, getRestaurants);
}

export function* getRestaurantsDictionary(ids) {
  const idsParam = ids && ids.length ? `?ids=${ids.join(',')}` : '';
  let url = `/restaurants${idsParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const rawPayload = yield apiRequest(url, options);
    const payload =
      rawPayload && rawPayload.items && rawPayload.items.length > 0
        ? rawPayload.items
            .map((item) => {
              const nameVisible = findAttributeValue(
                item.attributes,
                'nameVisible'
              );
              return {
                ...item,
                nameVisible: nameVisible ? nameVisible : item.name,
              };
            })
            .reduce((obj, item) => {
              obj[item.id] = item;
              return obj;
            }, {})
        : {};
    const newAction = {
      type: GET_RESTAURANTS_DICTIONARY_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getRestaurantsDictionarySaga() {
  yield takeFirst(GET_RESTAURANTS_DICTIONARY_REQUEST, getRestaurantsDictionary);
}

/**
 * Retrieve restaurant by restaurant id.
 * @param {Object} { id } - restaurant id.
 * @return {Object} Response for request.
 */
export function* getRestaurantById({ id }) {
  let url = `/restaurants/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const attributes = yield getRestaurantAttributes();
    const rawPayload = yield apiRequest(url, options);
    const payload = {
      ...rawPayload,
      attributes: rawPayload.attributes.map((attr) => ({
        ...attr,
        label:
          attributes && attributes.length > 0
            ? (
                attributes.find((a) => a.id === attr.restaurantAttributeId) ||
                {}
              ).label
            : '',
      })),
    };
    const newAction = {
      type: GET_RESTAURANT_BY_ID_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getRestaurantByIdSaga() {
  yield takeFirst(GET_RESTAURANT_BY_ID_REQUEST, getRestaurantById);
}

/**
 * Update restaurant info if calling with id parameter else create new restaurant.
 * @param {Object} - restaurant info.
 * @return {Object} Response for request.
 */
export function* saveRestaurant({
  id,
  name,
  locationId,
  streetAddress,
  city,
  postcode,
  latitude,
  longitude,
  phoneNumber,
  coverImgUrl,
  restaurantCode,
  timeZone,
  availableForPickup = '',
  availableForInfo = '',
  owner,
  openingHours,
  specialOpeningHours,
  nameVisible,
  deliveryRestaurant = '',
  seoTitle,
  seoDescription,
  seoCoverImage,
  restaurantOrganizationNumber,
  deliveryTimeout,
} = {}) {
  try {
    const locationPayload = yield saveLocation({
      locationId,
      streetAddress,
      city,
      postcode,
      latitude,
      longitude,
    });
    let stringAttrList = {
      phoneNumber,
      coverImgUrl,
      restaurantCode,
      timeZone,
      availableForPickup: availableForPickup.toString(),
      availableForInfo: availableForInfo.toString(),
      owner,
      nameVisible,
      restaurantOrganizationNumber,
      deliveryRestaurant: deliveryRestaurant.toString(),
      'seo-title': seoTitle,
      'seo-description': seoDescription,
      'seo-coverimage': seoCoverImage,
    };
    if (!deliveryRestaurant) {
      stringAttrList = {
        ...stringAttrList,
        deliveryTimeout,
      };
    }
    const stringAttributes = yield prepareRestaurantAttributes(stringAttrList);
    const jsonAttributes = yield prepareRestaurantJSONAttributes({
      openingHours,
      specialOpeningHours,
    });
    const attributes = [...stringAttributes, ...jsonAttributes];
    const url = id ? `/restaurants/${id}` : '/restaurants';
    const options = {
      method: id ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name,
        locationId: locationPayload.id,
        attributes,
      }),
    };
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SAVE_RESTAURANT_SUCCESS,
      payload,
    };
    yield put(newAction);
    if (!id) {
      yield put(push(`/restaurant-details/${payload.id}`));
    }
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* saveRestaurantSaga() {
  yield takeFirst(SAVE_RESTAURANT_REQUEST, saveRestaurant);
}

/**
 * Update restaurants list from oheics.
 * @return {Object} Response for request.
 */
export function* syncRestaurants() {
  try {
    const url = '/restaurants/oheics';
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    };
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SYNC_OHEICS_RESTAURANT_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* syncRestaurantsSaga() {
  yield takeFirst(SYNC_OHEICS_RESTAURANT_REQUEST, syncRestaurants);
}

export function* getRestaurantsDafaultDeliveryMinTime() {
  let url = '/restaurants/defaults';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const response = yield apiRequest(url, options);
    const { items } = response;
    const deliveryTimeoutAttr = (items || []).find(
      (attr) => attr.label === DELIVERY_TIMEOUT
    );
    return deliveryTimeoutAttr;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* saveRestaurantsDefaultDeliveryMinTime({ id, value }) {
  try {
    const url = `/restaurants/defaults/${id}`;
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        value,
      }),
    };
    const payload = yield apiRequest(url, options);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export default combine([
  getRestaurantsSaga,
  getRestaurantByIdSaga,
  saveRestaurantSaga,
  syncRestaurantsSaga,
  getRestaurantsDictionarySaga,
]);
