export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE';

export const SEARCH_STRICT_ORGANIZATION_NUMBER_REQUEST =
  'SEARCH_STRICT_ORGANIZATION_NUMBER_REQUEST';
export const SEARCH_STRICT_ORGANIZATION_NUMBER_SUCCESS =
  'SEARCH_STRICT_ORGANIZATION_NUMBER_SUCCESS';
export const SEARCH_STRICT_ORGANIZATION_NUMBER_FAILURE =
  'SEARCH_STRICT_ORGANIZATION_NUMBER_FAILURE';

export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';

export const SAVE_COMPANY_REQUEST = 'SAVE_COMPANY_REQUEST';
export const SAVE_COMPANY_SUCCESS = 'SAVE_COMPANY_SUCCESS';
export const SAVE_COMPANY_FAILURE = 'SAVE_COMPANY_FAILURE';
