import { LINK_TYPES_VALUES } from 'shared/consts/settings';
import {
  validateEmail,
  validatePhoneNumber,
  validateRelativeURL,
  validateURL,
} from 'utils/processors';

export const getInputPlaceholder = (type) => {
  switch (type) {
    case LINK_TYPES_VALUES.websiteUrl: {
      return '/example';
    }
    case LINK_TYPES_VALUES.email: {
      return 'example@domain.com';
    }
    case LINK_TYPES_VALUES.phone: {
      return 'Phone number';
    }
    default: {
      return 'https://example.com';
    }
  }
};

export const getInputLabel = (type) => {
  switch (type) {
    case LINK_TYPES_VALUES.websiteUrl: {
      return 'Panini website URL';
    }
    case LINK_TYPES_VALUES.email: {
      return 'Email';
    }
    case LINK_TYPES_VALUES.phone: {
      return 'Phone number';
    }
    default: {
      return 'URL';
    }
  }
};

export const validateError = (data) => {
  if (!data.type) {
    return 'Link type is required';
  }
  if (!data.label) {
    return 'Link label is required';
  }
  if (data.type === LINK_TYPES_VALUES.email && !validateEmail(data.url)) {
    return 'Valid email is required';
  }
  if (data.type === LINK_TYPES_VALUES.phone && !validatePhoneNumber(data.url)) {
    return 'Valid phone is required';
  }
  if (
    data.type === LINK_TYPES_VALUES.websiteUrl &&
    !validateRelativeURL(data.url)
  ) {
    return 'URL should be started from /';
  }
  if (data.type === LINK_TYPES_VALUES.url && !validateURL(data.url)) {
    return 'Valid website url is required';
  }
  if (data.notAuthUrl && !data.notAuthLabel) {
    return 'Valid label for not autherized users is required';
  }
  if (
    data.notAuthLabel &&
    data.notAuthType === LINK_TYPES_VALUES.email &&
    !validateEmail(data.notAuthUrl)
  ) {
    return 'Valid email for not autherized users is required';
  }
  if (
    data.notAuthLabel &&
    data.notAuthType === LINK_TYPES_VALUES.phone &&
    !validatePhoneNumber(data.notAuthUrl)
  ) {
    return 'Valid phone for not autherized users is required';
  }
  if (
    data.notAuthLabel &&
    data.notAuthType === LINK_TYPES_VALUES.websiteUrl &&
    !validateRelativeURL(data.notAuthUrl)
  ) {
    return 'URL should be started from /';
  }
  if (
    data.notAuthLabel &&
    data.notAuthType === LINK_TYPES_VALUES.url &&
    !validateURL(data.notAuthUrl)
  ) {
    return 'Valid website url for not autherized users is required';
  }
};
