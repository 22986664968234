import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import cn from 'classnames';

import { S3_URL } from 'shared/consts';
import RichTextMarckup from 'shared/components/RichTextMarckup';

import LandingLink from '../LandingLink';

import { renderMultilineText } from '../utils';

import styles from './styles';

class CardSection extends React.PureComponent {
  render() {
    const { classes, item } = this.props;

    const TitleTag = item && item.titleHTag ? item.titleHTag : 'h2';

    return (
      <section className={classes.cartSection}>
        <div className={classes.cartImg}>
          <img src={`${S3_URL}${item.imageURL}`} alt={item.imageSEOAlt} />
        </div>
        <div className="view-content">
          <div className={cn(classes.cart, classes.flexColumn)}>
            <div>
              {item.title && (
                <TitleTag
                  className={cn(classes.blockTitle, classes.title)}
                  style={{ color: item.titleColor }}
                >
                  {renderMultilineText(item.title)}
                </TitleTag>
              )}
              {item.description && (
                <RichTextMarckup
                  value={item.description}
                  component={'p'}
                  className={classes.paragraph}
                />
              )}
            </div>
            {item.link &&
              item.link.url && (
                <LandingLink item={item.link} color={item.textColor} />
              )}
          </div>
        </div>
      </section>
    );
  }
}

CardSection.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    link: PropTypes.object,
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    cartBgColor: PropTypes.string,
    imageURL: PropTypes.string,
    imageSEOAlt: PropTypes.string,
    imagePosition: PropTypes.string,
  }),
};

export default injectSheet(styles)(CardSection);
