import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableActionCell from 'components/TableActionCell';
import TableHead from '@material-ui/core/TableHead';

import ActionHeading from 'components/ActionHeading';
import SmallButton from 'components/SmallButton';
import MenuItemsDropdown from 'components/MenuItemsDropdown';

class MenuItems extends React.PureComponent {
  state = {
    selectedMenuItem: null,
    selectedCategory: null,
  };

  onSelectMenuItem = (menuItem) => {
    this.setState({ selectedMenuItem: menuItem });
  };

  handleAddMenuItem = () => {
    const { selectedMenuItem } = this.state;
    const { onAddMenuItem } = this.props;
    onAddMenuItem(selectedMenuItem);
    this.setState({ selectedMenuItem: null });
  };

  render() {
    const { items, onDelete } = this.props;
    const { selectedMenuItem } = this.state;
    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.categoryName}</TableCell>
                  <TableActionCell
                    action={() => onDelete(item.id)}
                    icon="delete"
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <div style={{ marginTop: 40 }}>
          <ActionHeading>Add menu item</ActionHeading>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <MenuItemsDropdown
              value={selectedMenuItem}
              onChange={this.onSelectMenuItem}
              excludeIds={items.map(({ id }) => id)}
            />
            <SmallButton onClick={this.handleAddMenuItem} leftSpace>
              Add
            </SmallButton>
          </div>
        </div>
      </div>
    );
  }
}

MenuItems.propTypes = {
  items: PropTypes.array,
  onDelete: PropTypes.func.isRequired,
  onAddMenuItem: PropTypes.func.isRequired,
};

export default MenuItems;
