import { put, all } from 'redux-saga/effects';

import {
  GET_KICKBACKS_LIST_REQUEST,
  GET_KICKBACKS_LIST_SUCCESS,
  GET_KICKBACK_BY_ID_REQUEST,
  GET_KICKBACK_BY_ID_SUCCESS,
  GET_KICKBACK_HISTORY_REQUEST,
  GET_KICKBACK_HISTORY_SUCCESS,
  SAVE_KICKBACKS_LIST_REQUEST,
  SAVE_KICKBACKS_LIST_SUCCESS,
  SAVE_KICKBACKS_LIST_FAILURE,
  DELETE_KICKBACK_REQUEST,
  DELETE_KICKBACK_SUCCESS,
  GET_PUNCH_CARD_LIST_REQUEST,
  GET_PUNCH_CARD_LIST_SUCCESS,
  GET_PUNCH_CARD_BY_ID_REQUEST,
  GET_PUNCH_CARD_BY_ID_SUCCESS,
  SAVE_PUNCH_CARD_REQUEST,
  SAVE_PUNCH_CARD_SUCCESS,
  SAVE_PUNCH_CARD_FAILURE,
  SAVE_PUNCH_CARD_MENU_ITEM_REQUEST,
  SAVE_PUNCH_CARD_MENU_ITEM_SUCCESS,
  SAVE_PUNCH_CARD_MENU_ITEM_FAILURE,
  DELETE_PUNCH_CARD_MENU_ITEM_REQUEST,
  DELETE_PUNCH_CARD_MENU_ITEM_SUCCESS,
  GET_USER_PUNCH_CARDS_REQUEST,
  GET_USER_PUNCH_CARDS_SUCCESS,
  GET_USER_PUNCH_CARDS_FAILURE,
  GET_USER_STORE_CREDITS_POINTS_REQUEST,
  GET_USER_STORE_CREDITS_POINTS_SUCCESS,
  GET_USER_STORE_CREDITS_POINTS_FAILURE,
  SAVE_USER_STORE_CREDITS_POINTS_REQUEST,
  SAVE_USER_STORE_CREDITS_POINTS_SUCCESS,
  SAVE_USER_STORE_CREDITS_POINTS_FAILURE,
  GET_CREDITS_ANALYTICS_REQUEST,
  GET_CREDITS_ANALYTICS_SUCCESS,
  GET_CREDITS_ANALYTICS_FAILURE,
} from 'consts/loyalties';

import { calculatePage, calculateSort } from 'utils';
import {
  getMonthDateRange,
  formatDateTime,
  convertCurrentTimezoneToUTC,
  addYearsToDays,
} from 'utils/date';

import { DATETIME_FORMAT, SHORT_DATE_FORMAT } from 'consts';

import { getMenuItems } from 'sagas/menu-items/menu-items';
import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';

export function* getKickbacks({ sortName, sortDirection, page, pageLimit }) {
  const pageParam = pageLimit ? calculatePage(page, pageLimit) : '';
  const url = `/kickback-map?${calculateSort(
    sortDirection,
    sortName
  )}&${pageParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);

    const newAction = {
      type: GET_KICKBACKS_LIST_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getKickbacksSaga() {
  yield takeFirst(GET_KICKBACKS_LIST_REQUEST, getKickbacks);
}

function* getKickbackById({ id }) {
  const url = `/kickback-map/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);

    const newAction = {
      type: GET_KICKBACK_BY_ID_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getKickbackByIdSaga() {
  yield takeFirst(GET_KICKBACK_BY_ID_REQUEST, getKickbackById);
}

function* getUserLoyaltyInfo({ userId }) {
  const params = [];
  if (userId) params.push(`&userId=${userId}`);
  const url = `/users/${userId}/user-loyalty-info?${params.join('&')}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);

  return payload;
}

function* getKickbackHistory({
  userId,
  sortName,
  sortDirection,
  page,
  pageLimit,
}) {
  const pageParam = pageLimit ? calculatePage(page, pageLimit) : '';
  const userIdParam = userId ? `&userId=${userId}` : '';
  const url = `/kickback-history?${calculateSort(
    sortDirection,
    sortName
  )}&${pageParam}${userIdParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const kickbackHistory = yield apiRequest(url, options);
    const currentKickback = yield getUserLoyaltyInfo({ userId });

    let cycledItems = [];
    if (
      kickbackHistory &&
      kickbackHistory.items &&
      kickbackHistory.items.length
    ) {
      if (sortDirection === 'desc') {
        kickbackHistory.items = kickbackHistory.items.reverse();
      }
      cycledItems = kickbackHistory.items.reduce((acc, item) => {
        const prevItem = acc[acc.length - 1];
        let startDate;

        if (prevItem) startDate = prevItem.createDate;

        acc.push({
          id: item.id,
          date: startDate
            ? `${startDate} - ${formatDateTime(
                item.createDate,
                SHORT_DATE_FORMAT
              )}`
            : formatDateTime(item.createDate, SHORT_DATE_FORMAT),
          points: item.pointsLeft,
          storeCredits: item.earnedStoreCredits,
          createDate: formatDateTime(item.createDate, SHORT_DATE_FORMAT),
        });
        return acc;
      }, []);
    }

    const prevCycledDate = cycledItems[cycledItems.length - 1];

    cycledItems =
      sortDirection === 'desc' ? cycledItems.reverse() : cycledItems;

    if (currentKickback) {
      const currentItem = {
        id: 0,
        date: `${prevCycledDate ? `${prevCycledDate.createDate} - ` : ''} ${
          currentKickback.lastDayOfCurrentKickbackCycle
        } (current)`,
        points: currentKickback.points,
        storeCredits: currentKickback.storeCredits,
      };

      if (sortDirection === 'asc') {
        cycledItems.push(currentItem);
      } else if (sortDirection === 'desc') {
        cycledItems.unshift(currentItem);
      }
    }

    const payload = {
      ...kickbackHistory,
      totalResults: currentKickback
        ? kickbackHistory.totalResults + 1
        : kickbackHistory.totalResults,
      items: cycledItems,
    };

    const newAction = {
      type: GET_KICKBACK_HISTORY_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getKickbackHistorySaga() {
  yield takeFirst(GET_KICKBACK_HISTORY_REQUEST, getKickbackHistory);
}

function* saveKickback({ id, pointAmount, name, storeCreditAward }) {
  const url = id ? `/kickback-map/${id}` : '/kickback-map';
  const options = {
    method: id ? 'PUT' : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      pointAmount: parseInt(pointAmount) || 0,
      storeCreditAward: parseInt(storeCreditAward),
    }),
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SAVE_KICKBACKS_LIST_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    yield put({ type: SAVE_KICKBACKS_LIST_FAILURE, error });
    showErrorOnErrorResponse(error);
  }
}

function* saveKickbackSaga() {
  yield takeFirst(SAVE_KICKBACKS_LIST_REQUEST, saveKickback);
}

function* deleteKickback({ id }) {
  const url = `/kickback-map/${id}`;
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: DELETE_KICKBACK_SUCCESS,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* deleteKickbackSaga() {
  yield takeFirst(DELETE_KICKBACK_REQUEST, deleteKickback);
}

export function* getUserDiscount(userId) {
  const url = `/users/${userId}/discounts`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const userDiscountsResponse = yield apiRequest(url, options);
    let discount = 0;
    let deactivationDate = '';
    let discountId;
    if (userDiscountsResponse.items && userDiscountsResponse.items.length) {
      const [userDiscount] = userDiscountsResponse.items;
      discountId = userDiscount.discountId;
      const discountRule = yield apiRequest(
        `/discount-rules/${discountId}`,
        options
      );
      discount = discountRule.discount * 100;
      deactivationDate = discountRule.deactivationDate;
    }
    return { discount, discountDate: deactivationDate, discountId };
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getUserCompliments(userId) {
  const url = `/users/${userId}/compliments`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const userComplimentsResponse = yield apiRequest(url, options);
    let compliment = 0;
    let complimentId;
    if (userComplimentsResponse.items && userComplimentsResponse.items.length) {
      const userCompliment = userComplimentsResponse.items.find(
        (item) => item.orderId === 0
      );
      if (userCompliment) {
        complimentId = userCompliment.id;
        compliment = userCompliment.discount * 100;
      }
    }
    return { compliment, complimentId };
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getOrderCompliment(orderId) {
  const url = `/orders/${orderId}/compliments`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const response = yield apiRequest(url, options);
    return response;
  } catch (error) {
    if (error.message === 'Resource not found!') {
      return;
    }
    showErrorOnErrorResponse(error);
  }
}

function* saveDiscountRule({ discountId, discount, discountDate, name }) {
  const url = discountId ? `/discount-rules/${discountId}` : '/discount-rules';
  const options = {
    method: discountId ? 'PUT' : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      discount,
      activationDate: convertCurrentTimezoneToUTC(new Date(), DATETIME_FORMAT),
      deactivationDate: discountDate
        ? convertCurrentTimezoneToUTC(discountDate, DATETIME_FORMAT)
        : addYearsToDays({
            date: convertCurrentTimezoneToUTC(new Date()),
            years: 1,
            format: DATETIME_FORMAT,
          }),
    }),
  };
  const discountRule = yield apiRequest(url, options);
  return discountRule;
}

export function* saveUserDiscount({
  userId,
  discountId,
  discount,
  discountDate,
  name,
}) {
  try {
    if (discount === 0) {
      yield deleteUserDiscount({ userId, discountId });
      return { discount: 0, discountId: undefined };
    } else {
      const discountRule = yield saveDiscountRule({
        discountId,
        discount,
        discountDate,
        name,
      });
      if (!discountId) {
        discountId = discountRule.id;
        const discountOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            discountId: discountRule.id,
          }),
        };
        yield apiRequest(`/users/${userId}/discounts`, discountOptions);
      }
      return { discount, discountId };
    }
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* saveUserCompliment({ userId, compliment, complimentId }) {
  try {
    const url = complimentId
      ? `/users/${userId}/compliments/${complimentId}`
      : `/users/${userId}/compliments`;
    const options = {
      method: complimentId ? 'PATCH' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        discount: compliment,
      }),
    };
    const complimentPayload = yield apiRequest(url, options);
    return {
      compliment: complimentPayload.discount,
      complimentId: complimentPayload.id,
    };
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* deleteUserDiscount({ userId, discountId }) {
  try {
    if (!discountId) {
      return;
    }
    const url = `/users/${userId}/discounts/${discountId}`;
    const options = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };
    yield apiRequest(url, options);
    yield apiRequest(`/discount-rules/${discountId}`, options);
    return;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getCompanyDiscount(companyId) {
  const url = `/companies/${companyId}/discounts`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const companyDiscountsResponse = yield apiRequest(url, options);
    let discount = 0;
    let deactivationDate = '';
    let discountId;
    if (
      companyDiscountsResponse.items &&
      companyDiscountsResponse.items.length
    ) {
      const [companyDiscount] = companyDiscountsResponse.items;
      discountId = companyDiscount.discountId;
      const discountRule = yield apiRequest(
        `/discount-rules/${discountId}`,
        options
      );
      discount = discountRule.discount * 100;
      deactivationDate = discountRule.deactivationDate;
    }
    return { discount, discountDate: deactivationDate, discountId };
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getCompanyCompliments(companyId) {
  const url = `/companies/${companyId}/compliments`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const companyComplimentsResponse = yield apiRequest(url, options);
    let compliment = 0;
    let complimentId;
    if (
      companyComplimentsResponse.items &&
      companyComplimentsResponse.items.length
    ) {
      const companyCompliment = companyComplimentsResponse.items.find(
        (item) => item.orderId === 0
      );
      if (companyCompliment) {
        complimentId = companyCompliment.id;
        compliment = companyCompliment.discount * 100;
      }
    }
    return { compliment, complimentId };
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* saveCompanyDiscount({
  companyId,
  discountId,
  discount,
  discountDate,
  name,
}) {
  try {
    if (discount === 0) {
      yield deleteCompanyDiscount({ companyId, discountId });
      return { discount: 0, discountId: undefined };
    } else {
      const discountRule = yield saveDiscountRule({
        discountId,
        discount,
        discountDate,
        name,
      });
      if (!discountId) {
        discountId = discountRule.id;
        const discountOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            discountId: discountRule.id,
          }),
        };
        yield apiRequest(`/companies/${companyId}/discounts`, discountOptions);
      }
      return { discount, discountId };
    }
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* saveCompanyCompliment({
  companyId,
  compliment,
  complimentId,
}) {
  try {
    const url = complimentId
      ? `/companies/${companyId}/compliments/${complimentId}`
      : `/companies/${companyId}/compliments`;
    const options = {
      method: complimentId ? 'PATCH' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        discount: compliment,
      }),
    };
    const complimentPayload = yield apiRequest(url, options);
    return {
      compliment: complimentPayload.discount,
      complimentId: complimentPayload.id,
    };
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* deleteCompanyDiscount({ companyId, discountId }) {
  try {
    if (!discountId) {
      return;
    }
    const url = `/companies/${companyId}/discounts/${discountId}`;
    const options = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };
    yield apiRequest(url, options);
    yield apiRequest(`/discount-rules/${discountId}`, options);
    return;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getPunchCards({ ids, sortName, sortDirection, page, pageLimit }) {
  const idParam = ids && ids.length ? `&id=${ids.join(',')}` : '';
  const pageParam = pageLimit ? `&${calculatePage(page, pageLimit)}` : '';
  const url = `/punch-card-rules?${calculateSort(
    sortDirection,
    sortName
  )}${pageParam}${idParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);

    const newAction = {
      type: GET_PUNCH_CARD_LIST_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getPunchCardsSaga() {
  yield takeFirst(GET_PUNCH_CARD_LIST_REQUEST, getPunchCards);
}

function* getPunchCardById({ id }) {
  const idParam = id ? `?id=${id}` : '';
  const url = `/punch-card-rules${idParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const punchCardResponse = yield apiRequest(url, options);
    const item = punchCardResponse.items[0];

    const punchCardItems = yield apiRequest(
      `/punch-card-rules/${id}/menu-item-relations`,
      options
    );

    const menuItemsIds = punchCardItems.items.map((item) => item.menuItemId);

    let menuItems = null;
    if (menuItemsIds && menuItemsIds.length > 0) {
      menuItems = yield getMenuItems({ ids: menuItemsIds });
    }

    const newAction = {
      type: GET_PUNCH_CARD_BY_ID_SUCCESS,
      payload: {
        ...item,
        menuItems: menuItems && menuItems.items ? menuItems.items : [],
      },
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getPunchCardByIdSaga() {
  yield takeFirst(GET_PUNCH_CARD_BY_ID_REQUEST, getPunchCardById);
}

function* savePunchCard({
  id,
  name,
  activationDate,
  deactivationDate,
  amountForFreeMenuItem,
  description,
  image,
}) {
  const url = id ? `/punch-card-rules/${id}` : '/punch-card-rules';
  const options = {
    method: id ? 'PUT' : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      activationDate,
      deactivationDate,
      amountForFreeMenuItem,
      description,
      image,
    }),
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SAVE_PUNCH_CARD_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    yield put({ type: SAVE_PUNCH_CARD_FAILURE, error });
    showErrorOnErrorResponse(error);
  }
}

function* savePunchCardSaga() {
  yield takeFirst(SAVE_PUNCH_CARD_REQUEST, savePunchCard);
}

function* savePunchCardMenuItem({ id, menuItemId }) {
  const url = `/punch-card-rules/${id}/menu-item-relations`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      menuItemId,
    }),
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SAVE_PUNCH_CARD_MENU_ITEM_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    yield put({ type: SAVE_PUNCH_CARD_MENU_ITEM_FAILURE, error });
    showErrorOnErrorResponse(error);
  }
}

function* savePunchCardMenuItemSaga() {
  yield takeFirst(SAVE_PUNCH_CARD_MENU_ITEM_REQUEST, savePunchCardMenuItem);
}

function* deletePunchCardMenuItem({ id, menuItemId }) {
  const url = `/punch-card-rules/${id}/menu-item-relations/${menuItemId}`;
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: DELETE_PUNCH_CARD_MENU_ITEM_SUCCESS,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* deletePunchCardMenuItemSaga() {
  yield takeFirst(DELETE_PUNCH_CARD_MENU_ITEM_REQUEST, deletePunchCardMenuItem);
}

function* getUserPunchCards({
  userId,
  sortName,
  sortDirection,
  page,
  pageLimit,
}) {
  const pageParam = pageLimit ? calculatePage(page, pageLimit) : '';
  const url = `/users/${userId}/punch-cards?${calculateSort(
    sortDirection,
    sortName
  )}&${pageParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const userPunchCardsPayload = yield apiRequest(url, options);
    const punchCardRulesIds = userPunchCardsPayload.items.map(
      (item) => item.punchCardRuleId
    );
    let punchCards = { ...userPunchCardsPayload };
    if (punchCardRulesIds && punchCardRulesIds.length) {
      const punchCardRulesPayload = yield getPunchCards({
        ids: punchCardRulesIds,
      });
      const punchCardDictionary = punchCardRulesPayload.items.reduce(
        (acc, punchCard) => {
          acc[punchCard.id] = punchCard;
          return acc;
        },
        {}
      );
      punchCards.items = punchCards.items.map((item) => ({
        ...item,
        punchCard: punchCardDictionary[item.punchCardRuleId],
      }));
    }

    const payload = {
      ...punchCards,
    };

    const newAction = {
      type: GET_USER_PUNCH_CARDS_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
    yield put({ type: GET_USER_PUNCH_CARDS_FAILURE, error });
  }
}

function* getUserPunchCardsSaga() {
  yield takeFirst(GET_USER_PUNCH_CARDS_REQUEST, getUserPunchCards);
}

function* getUserStoreCreditsPoints({ userId }) {
  const url = `/users/${userId}/points`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);

    const newAction = {
      type: GET_USER_STORE_CREDITS_POINTS_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    /*
     * If user has no points, 404 response will be sended with such message.
     * Only for this hardcoded message we should return just empty object.
     */
    if (error.message === 'Resource not found(userPoints)!') {
      yield put({
        type: GET_USER_STORE_CREDITS_POINTS_SUCCESS,
        payload: {
          userId,
          points: 0,
          storeCredits: 0,
          kickbackDate: '',
        },
      });
    } else {
      showErrorOnErrorResponse(error);
      yield put({ type: GET_USER_STORE_CREDITS_POINTS_FAILURE, error });
    }
  }
}

function* getUserStoreCreditsPointsSaga() {
  yield takeFirst(
    GET_USER_STORE_CREDITS_POINTS_REQUEST,
    getUserStoreCreditsPoints
  );
}

function* saveUserStoreCreditsPoints({
  userId,
  points,
  storeCredits,
  kickbackDate,
}) {
  try {
    const url = `/users/${userId}/points`;
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        points,
        storeCredits,
        kickbackDate: convertCurrentTimezoneToUTC(
          kickbackDate,
          DATETIME_FORMAT
        ),
      }),
    };
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SAVE_USER_STORE_CREDITS_POINTS_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    yield put({ type: SAVE_USER_STORE_CREDITS_POINTS_FAILURE, error });
    showErrorOnErrorResponse(error);
  }
}

function* saveUserStoreCreditsPointsSaga() {
  yield takeFirst(
    SAVE_USER_STORE_CREDITS_POINTS_REQUEST,
    saveUserStoreCreditsPoints
  );
}

export function* saveUserPunchCards({ userPunchCardId, stampCardAmount }) {
  const url = `/punch-cards/${userPunchCardId}`;
  const options = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ stampCardAmount: parseInt(stampCardAmount, 10) }),
  };
  try {
    const payload = yield apiRequest(url, options);
    return payload;
  } catch (error) {
    throw error;
  }
}

function* getSpentStoreCredits({ startDate, endDate }) {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const periodRanges = getMonthDateRange(startDate, endDate);
  const payload = yield all(
    periodRanges.map(function*(range) {
      const url = `/loyalty-points/store-credits/spent?startPeriod=${
        range.startDate
      }&endPeriod=${range.endDate}`;
      const response = yield apiRequest(url, options);
      return {
        startDate: range.startDate,
        endDate: range.endDate,
        credits: response.spent,
      };
    })
  );
  return payload;
}

function* getTotalStoreCreditsAmount() {
  const url = '/loyalty-points/store-credits/total-amount';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);
  return payload;
}

function* getPointsInStoreCredits() {
  const url = '/loyalty-points/store-credits/kickback-reward';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const payload = yield apiRequest(url, options);
  return payload;
}

function* getStoreCreditsAnalytics({ startDate, endDate }) {
  try {
    const [
      rangePayload,
      totalStoreCreditsPayload,
      pointsInStoreCreditsPayload,
    ] = yield all([
      getSpentStoreCredits({ startDate, endDate }),
      getTotalStoreCreditsAmount(),
      getPointsInStoreCredits(),
    ]);
    const newAction = {
      type: GET_CREDITS_ANALYTICS_SUCCESS,
      payload: {
        rangeItems: rangePayload,
        totalStoreCredits: totalStoreCreditsPayload.totalAmount,
        pointsInStoreCredits: pointsInStoreCreditsPayload.total,
      },
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
    yield put({ type: GET_CREDITS_ANALYTICS_FAILURE, error });
  }
}

function* getStoreCreditsAnalyticsSaga() {
  yield takeFirst(GET_CREDITS_ANALYTICS_REQUEST, getStoreCreditsAnalytics);
}

export default combine([
  getKickbacksSaga,
  getKickbackByIdSaga,
  getKickbackHistorySaga,
  saveKickbackSaga,
  deleteKickbackSaga,
  getPunchCardsSaga,
  getPunchCardByIdSaga,
  savePunchCardSaga,
  savePunchCardMenuItemSaga,
  deletePunchCardMenuItemSaga,
  getUserPunchCardsSaga,
  getUserStoreCreditsPointsSaga,
  saveUserStoreCreditsPointsSaga,
  getStoreCreditsAnalyticsSaga,
]);
