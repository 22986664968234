import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import injectSheet from 'react-jss';
import { DateTimePicker } from 'material-ui-pickers';
import { parseISO } from 'date-fns';

import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

import PageHeading from 'components/PageHeading';
import PageHeadingTitle from 'components/PageHeadingTitle';
import Section from 'components/Section';
import OrderHistory from 'components/OrderHistory';
import { validateJSONString, validateOrgNumber } from 'utils/processors';

import { addYearsToDays, convertCurrentTimezoneToUTC } from 'utils/date';

import { MAX_SELECTED_DATE, SHORT_DATE_FORMAT } from 'consts';

import InvoiceAddress from './InvoiceAddress';
import Members from './Members';

import styles from './styles';

const stateFromProps = ({ initialData = {} }) => {
  const parsedInvoiceAddress = validateJSONString(
    initialData.invoicePhysicalAddress
  )
    ? JSON.parse(initialData.invoicePhysicalAddress)
    : {};
  return {
    name: initialData.name || '',
    organisationNumber: initialData.organisationNumber || '',
    contactPerson: initialData.contactPerson || '',
    street: parsedInvoiceAddress.street || '',
    zipCode: parsedInvoiceAddress.zipCode || '',
    city: parsedInvoiceAddress.city || '',
    email: initialData.invoiceDigitalAddress || '',
    organizationNumberError: '',
    showMergingPopup: false,
    searchedCompanies: [],
    discount: initialData.discount || 0,
    discountDate:
      initialData.discountDate ||
      addYearsToDays({
        date: convertCurrentTimezoneToUTC(new Date()),
        years: 1,
      }),
    compliment: parseInt(initialData.compliment, 10) || 0,
  };
};

class CompanyDetailsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleDateChange = (date) => {
    this.setState({ discountDate: date });
  };

  handleSubmit = () => {
    const { id, onSave, initialData = {} } = this.props;
    const { ...values } = this.state;
    const data = { ...initialData, ...values };
    const validateError = this.validateError(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      onSave({
        ...data,
        id,
        name: data.name,
        organisationNumber: data.organisationNumber,
        contactPerson: data.contactPerson,
        invoicePhysicalAddress: JSON.stringify({
          street: data.street,
          zipCode: data.zipCode,
          city: data.city,
        }),
        invoiceDigitalAddress: data.email,
      })
        .then(() => {
          toastr.success(
            'Success!',
            id ? 'Company has been updated' : 'New Company has been created'
          );
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  handleCheckOrganizationNumber = () => {
    const { onSearchOrganizationNumber } = this.props;
    const { organisationNumber } = this.state;
    if (validateOrgNumber(organisationNumber)) {
      onSearchOrganizationNumber(organisationNumber).then((result) => {
        this.setState({
          showMergingPopup: !!result.items.length,
          searchedCompanies:
            result.items && result.items.length ? result.items : [],
        });
      });
      this.setState({ organizationNumberError: '', organisationNumber });
    } else {
      this.setState({
        organizationNumberError: 'Organisation Number is not valid',
        searchedCompanies: [],
      });
    }
  };

  handleSelectCompany = (company) => {
    const companyAddress = validateJSONString(company.invoicePhysicalAddress)
      ? JSON.parse(company.invoicePhysicalAddress)
      : {};
    this.setState({
      showMergingPopup: false,
      name: company.name,
      organisationNumber: company.organisationNumber,
      contactPerson: company.contactPerson,
      street: companyAddress.street || '',
      zipCode: companyAddress.zipCode || '',
      city: companyAddress.city || '',
      email: company.invoiceDigitalAddress || '',
    });
  };

  handleEmptyOrganization = () => {
    this.setState({ showMergingPopup: false });
  };

  validateError = (data) => {
    if (!data.name) {
      return 'Name is required';
    }
    if (data.discount < 0 || isNaN(parseFloat(data.discount))) {
      return 'Fixed Discount should be greater than or equal 0';
    }
    if (data.compliment < 0 || isNaN(parseFloat(data.compliment))) {
      return 'Discount on next order should be greater than or equal 0';
    }
    if (
      !(data.organisationNumber && validateOrgNumber(data.organisationNumber))
    ) {
      return 'Valid Organisation Number is required';
    }
    if (!data.street) {
      return 'Valid Street is required';
    }
    if (!data.zipCode) {
      return 'Valid Zip code is required';
    }
    if (!data.city) {
      return 'Valid City is required';
    }
    if (!data.contactPerson) {
      return 'Valid Contact Person is required';
    }
    if (!data.email) {
      return 'Valid Invoicing Email is required';
    }
    if (
      new Date(data.discountDate).getTime() >
      new Date(MAX_SELECTED_DATE).getTime()
    ) {
      return 'Discount date is not valid.';
    }
  };

  render() {
    const { id, classes } = this.props;
    const {
      name,
      organisationNumber,
      contactPerson,
      street,
      zipCode,
      city,
      email,
      organizationNumberError,
      showMergingPopup,
      searchedCompanies,
      discount,
      discountDate,
      compliment,
    } = this.state;
    return (
      <React.Fragment>
        <PageHeading
          title={
            id ? (
              <PageHeadingTitle
                text="Company"
                highlightedText={name || 'No name'}
              />
            ) : (
              'New Company'
            )
          }
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        <div className={classes.formWrapper}>
          <div className={classes.formBlock}>
            <div>
              <Input
                value={organisationNumber}
                onChange={this.handleChange('organisationNumber')}
                placeholder="Organization Number"
                label="Organization Number"
                id="org-number"
                margin="normal"
                onBlur={this.handleCheckOrganizationNumber}
                style={{ width: 300 }}
                disabled={!!id}
                error={!!organizationNumberError}
              />
              {!!organizationNumberError && (
                <FormHelperText error={!!organizationNumberError}>
                  {organizationNumberError}
                </FormHelperText>
              )}
            </div>
            {id && <div className={classes.idWrapper}>ID: {id}</div>}
            <div>
              <Input
                value={name}
                onChange={this.handleChange('name')}
                placeholder="Company Name"
                label="Company Name"
                margin="normal"
                style={{ width: 300 }}
              />
            </div>
          </div>
          <div className={classes.formBlock}>
            <div className={classes.discountWrapper}>
              <InputLabel
                htmlFor="user-compliment"
                className={classes.discountLabel}
              >
                Discount on next order (in % effects the whole order but not
                delivery cost)
              </InputLabel>
              <Input
                id="user-compliment"
                value={compliment}
                type="number"
                onChange={this.handleChange('compliment')}
                placeholder="Discount on next order"
                margin="normal"
                style={{ width: '70px' }}
              />
            </div>
            <div className={classes.discountWrapper}>
              <InputLabel
                htmlFor="user-discount"
                className={classes.discountLabel}
              >
                Fixed discount (in % effects the whole order but not delivery
                cost)
              </InputLabel>
              <Input
                id="user-discount"
                value={discount}
                type="number"
                onChange={this.handleChange('discount')}
                placeholder="Fixed discount"
                margin="normal"
                style={{ width: '70px' }}
              />
              <div className={classes.discountDateWrapper}>
                Valid until:{' '}
                <DateTimePicker
                  className={classes.datePicker}
                  value={discountDate}
                  onChange={this.handleDateChange}
                  format={SHORT_DATE_FORMAT}
                  maxDate={parseISO(MAX_SELECTED_DATE)}
                />
              </div>
            </div>
          </div>
        </div>
        <Section heading="Invoice address">
          <InvoiceAddress
            contactPerson={contactPerson}
            street={street}
            zipCode={zipCode}
            city={city}
            email={email}
            handleChange={this.handleChange}
          />
        </Section>
        {id && (
          <Section>
            <Members companyId={id} />
          </Section>
        )}
        <Section heading="Order History">
          <OrderHistory companyId={id} getOrdersByDelivery />
        </Section>
        {!!(searchedCompanies && searchedCompanies.length) && (
          <Dialog
            open={showMergingPopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              There are other profiles with the same organisation number{' '}
              <b>{searchedCompanies[0].organisationNumber}</b>
              <br />
              <br />
              Would you like to auto-fill information from any of the following
              profiles?
              <ul className={classes.searchedCompaniesList}>
                {searchedCompanies.map((company) => (
                  <li
                    key={company.id}
                    className={classes.searchedCompaniesListItem}
                    onClick={() => this.handleSelectCompany(company)}
                  >
                    <span className={classes.companyName}>{company.name}</span>
                    <span className={classes.companyPerson}>
                      {company.contactPerson}
                    </span>
                  </li>
                ))}
              </ul>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleEmptyOrganization}
              >
                No, start with empty fields
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

CompanyDetailsPage.propTypes = {
  id: PropTypes.number,
  classes: PropTypes.object,
  initialData: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onSearchOrganizationNumber: PropTypes.func.isRequired,
};

export default injectSheet(styles)(CompanyDetailsPage);
