import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Input from '@material-ui/core/TextField';
import TableHead from '@material-ui/core/TableHead';

import PageHeading from 'components/PageHeading';
import TableSortCell from 'components/TableSortCell';
import TableActionCell from 'components/TableActionCell';
import TableCount from 'components/TableCount';
import SmallButton from 'components/SmallButton';
import PageHeadingButton from 'components/PageHeadingButton';
import Select from 'components/Select';

import { confirmAlert } from 'utils';
import { ITEM_STATUSES, ITEM_STATUSES_LABELS } from 'consts';

import CategoriesDropdown from './CategoriesDropdown';

const defaultCategory = { label: 'All', value: null };

const menuItemStatuses = Object.keys(ITEM_STATUSES_LABELS).map((key) => ({
  label: ITEM_STATUSES_LABELS[key],
  value: key,
}));
// Adding All status to start of an array
menuItemStatuses.unshift({ label: 'All', value: null });

class MenuItemsListPage extends React.PureComponent {
  state = {
    selectedCategory: this.props.selectedCategory || null,
    searchName: this.props.searchName || '',
    status: this.props.status || '',
  };

  onSelectCategory = ({ value }) => {
    this.setState({ selectedCategory: value });
  };

  onSearchNameChange = ({ target: { value } }) => {
    this.setState({ searchName: value });
  };

  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handlePage = (event, page) => {
    return this.props.onPage({ page });
  };

  handleSearch = () => {
    const { selectedCategory, searchName, status } = this.state;
    return this.props.onSearch({ selectedCategory, searchName, status });
  };

  handleDelete = (id) => {
    const { onDelete } = this.props;
    confirmAlert({ okCb: () => onDelete(id) });
  };

  onStatusChange = ({ value }) => {
    this.setState({ status: value });
  };

  handleRestoreItem = (item) => {
    const { onRestore } = this.props;
    confirmAlert({
      okCb: () => onRestore(item.id, item),
      message: 'Do you really want to restore this menu item?',
    });
  };

  render() {
    const { items, totalResults } = this.props.initialData;
    const { sortName, sortDirection, page, pageLimit } = this.props;
    const { selectedCategory, searchName, status } = this.state;

    return (
      <div>
        <PageHeading
          title="Menu items"
          right={
            <PageHeadingButton link="/menu-item-details">
              + New menu item
            </PageHeadingButton>
          }
        />
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Select
            options={menuItemStatuses}
            value={menuItemStatuses.find((i) => i.value === status)}
            onChange={this.onStatusChange}
            width={170}
            placeholder="All"
            label="Status"
          />
          <Input
            type="text"
            value={searchName}
            onChange={this.onSearchNameChange}
            placeholder="Sök"
            label="Search"
            style={{ paddingBottom: 4, marginRight: 20, marginLeft: 20 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <CategoriesDropdown
            defaultCategory={defaultCategory}
            value={selectedCategory}
            onChange={this.onSelectCategory}
          />
          <SmallButton onClick={this.handleSearch} leftSpace>
            Search
          </SmallButton>
        </div>
        <TableCount count={totalResults} text="items in current selection" />
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="Id"
                  sortName="id"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Name"
                  sortName="name"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell>Category</TableCell>
                <TableSortCell
                  title="Price"
                  sortName="price"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                  align="right"
                />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow
                  key={item.id}
                  style={{
                    backgroundColor:
                      item.status === ITEM_STATUSES.inactive
                        ? 'rgba(0, 0, 0, 0.07)'
                        : '',
                  }}
                >
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.categoryName}</TableCell>
                  <TableCell align="right">{item.price}</TableCell>
                  <TableActionCell
                    link={`/menu-item-details/${item.id}`}
                    icon="edit"
                  />
                  {item.status === ITEM_STATUSES.active ? (
                    <TableActionCell
                      action={() => this.handleDelete(item.id)}
                      icon="delete"
                    />
                  ) : (
                    <TableActionCell
                      action={() => this.handleRestoreItem(item)}
                      icon="restore"
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageLimit]}
            component="div"
            count={totalResults}
            rowsPerPage={pageLimit}
            page={page}
            onChangePage={this.handlePage}
          />
        </Paper>
      </div>
    );
  }
}

MenuItemsListPage.propTypes = {
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number.isRequired,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  selectedCategory: PropTypes.number,
  searchName: PropTypes.string,
  status: PropTypes.string,
  onSort: PropTypes.func.isRequired,
  onPage: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
};

export default MenuItemsListPage;
