import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';

import {
  ORDER_STATUSES_LABEL,
  ORDER_STATUSES,
  ORDER_TYPES,
} from 'consts/orders';

const CHANGE_ORDER_MAP = {
  draft: null,
  pending: null,
  readyForPayment: null,
  done: null,
  paid: [
    {
      value: ORDER_STATUSES.paid,
      label: ORDER_STATUSES_LABEL.paid,
    },
    {
      value: ORDER_STATUSES.inProduction,
      label: ORDER_STATUSES_LABEL.inProduction,
    },
    {
      value: ORDER_STATUSES.readyForShipment,
      label: ORDER_STATUSES_LABEL.readyForShipment,
    },
  ],
  inProduction: [
    {
      value: ORDER_STATUSES.inProduction,
      label: ORDER_STATUSES_LABEL.inProduction,
    },
    {
      value: ORDER_STATUSES.readyForShipment,
      label: ORDER_STATUSES_LABEL.readyForShipment,
    },
    {
      value: ORDER_STATUSES.paid,
      label: ORDER_STATUSES_LABEL.paid,
    },
  ],
  readyForShipment: [
    {
      value: ORDER_STATUSES.readyForShipment,
      label: ORDER_STATUSES_LABEL.readyForShipment,
    },
    {
      value: ORDER_STATUSES.inProduction,
      label: ORDER_STATUSES_LABEL.inProduction,
    },
  ],
  cancelled: null,
};

class OrderStatus extends React.PureComponent {
  render() {
    const { status, type, onStatusChange } = this.props;

    return (
      <React.Fragment>
        {status && CHANGE_ORDER_MAP[status] && type === ORDER_TYPES.delivery ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Select
              options={CHANGE_ORDER_MAP[status]}
              value={CHANGE_ORDER_MAP[status].find((i) => i.value === status)}
              onChange={onStatusChange}
              width={170}
              placeholder=""
              fontSize="13px"
            />
          </div>
        ) : (
          <span>{ORDER_STATUSES_LABEL[status]}</span>
        )}
      </React.Fragment>
    );
  }
}

OrderStatus.propTypes = {
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

export default OrderStatus;
