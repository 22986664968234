import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  branch,
  renderComponent,
} from 'recompose';
import { connect } from 'react-redux';

import { getMenuItems } from 'actions/menu-items';

import MenuItemsDropdown from './MenuItemsDropdown';
import DefaultDropdown from './DefaultDropdown';

const mapDispatchToProps = {
  onInit: getMenuItems,
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props) => {
  const { onInit, setInitialData } = props;
  return onInit({
    sortName: 'name',
    sortDirection: 'asc',
    getAllItems: true,
  }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  branch((props) => !props.initialData, renderComponent(DefaultDropdown))
)(MenuItemsDropdown);
