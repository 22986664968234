import apiRequest from 'sagas/utils/apiRequest';

import {
  setPaginationParams,
  updateOffsetParam,
  prepareURLRequest,
} from 'utils';

/**
 * Creates paging loading to retrieve all data.
 * @param {Object} { url, params, options } - Url to request, params for request url, additional options for request.
 * @return {Object} Response for request.
 */
export function* paginateRequest({ url, params, options, maxTotal } = {}) {
  try {
    if (params && params.length) {
      params = params.filter((item) => !!item);
    }
    const requestedUrl = prepareURLRequest(url, params);
    if (!requestedUrl) {
      return;
    }
    const response = yield apiRequest(requestedUrl, options);

    let pageOptions = {
      offset: response.offset,
      limit: response.limit,
      total: response.totalResults,
    };
    pageOptions = updateOffsetParam(pageOptions);
    while (pageOptions.offset < pageOptions.total) {
      if (maxTotal && maxTotal <= pageOptions.offset) {
        return response;
      }
      const paginationParams = setPaginationParams(pageOptions);
      const addPayload = yield apiRequest(
        prepareURLRequest(url, params.concat(paginationParams)),
        options
      );
      response.items = response.items.concat(addPayload.items);
      pageOptions = updateOffsetParam(pageOptions);
    }
    return response;
  } catch (error) {
    throw error;
  }
}
