import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_MENU_ITEMS_REQUEST,
  GET_MENU_ITEMS_SUCCESS,
  GET_MENU_ITEMS_FAILURE,
  GET_MENU_ITEMS_LIST_REQUEST,
  GET_MENU_ITEMS_LIST_SUCCESS,
  GET_MENU_ITEMS_LIST_FAILURE,
  GET_MENU_ITEM_BY_ID_REQUEST,
  GET_MENU_ITEM_BY_ID_SUCCESS,
  GET_MENU_ITEM_BY_ID_FAILURE,
  SAVE_MENU_ITEM_REQUEST,
  SAVE_MENU_ITEM_SUCCESS,
  SAVE_MENU_ITEM_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  GET_CATEGORY_MENU_ITEMS_REQUEST,
  GET_CATEGORY_MENU_ITEMS_SUCCESS,
  GET_CATEGORY_MENU_ITEMS_FAILURE,
  ADD_TO_RESTAURANT_REQUEST,
  ADD_TO_RESTAURANT_SUCCESS,
  ADD_TO_RESTAURANT_FAILURE,
  DELETE_FROM_RESTAURANT_REQUEST,
  DELETE_FROM_RESTAURANT_SUCCESS,
  DELETE_FROM_RESTAURANT_FAILURE,
  ADD_CATEGORY_TO_RESTAURANT_REQUEST,
  ADD_CATEGORY_TO_RESTAURANT_SUCCESS,
  ADD_CATEGORY_TO_RESTAURANT_FAILURE,
  REMOVE_CATEGORY_FROM_RESTAURANT_REQUEST,
  REMOVE_CATEGORY_FROM_RESTAURANT_SUCCESS,
  REMOVE_CATEGORY_FROM_RESTAURANT_FAILURE,
  CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_REQUEST,
  CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_SUCCESS,
  CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_FAILURE,
  GET_MENU_ATTRIBUTES_REQUEST,
  GET_MENU_ATTRIBUTES_SUCCESS,
  GET_MENU_ATTRIBUTES_FAILURE,
  DELETE_MENU_ITEM_REQUEST,
  DELETE_MENU_ITEM_SUCCESS,
  DELETE_MENU_ITEM_FAILURE,
  RESTORE_MENU_ITEM_REQUEST,
  RESTORE_MENU_ITEM_SUCCESS,
  RESTORE_MENU_ITEM_FAILURE,
} from 'consts/menu-items';

export function getMenuItems({
  sortName,
  sortDirection,
  page,
  pageLimit,
  selectedCategory,
  searchName,
  restaurantId,
  getAllItems,
  status,
  onlyAvailableForRestaurant,
}) {
  return {
    type: GET_MENU_ITEMS_REQUEST,
    sortName,
    sortDirection,
    page,
    pageLimit,
    status,
    selectedCategory,
    searchName,
    restaurantId,
    getAllItems,
    onlyAvailableForRestaurant,
    [WAIT_FOR_ACTION]: GET_MENU_ITEMS_SUCCESS,
    [ERROR_ACTION]: GET_MENU_ITEMS_FAILURE,
  };
}

export function getMenuItemsList({
  sortName,
  sortDirection,
  page,
  pageLimit,
  selectedCategory,
  searchName,
  restaurantId,
  getAllItems,
  status,
  onlyAvailableForRestaurant,
}) {
  return {
    type: GET_MENU_ITEMS_LIST_REQUEST,
    sortName,
    sortDirection,
    page,
    pageLimit,
    selectedCategory,
    searchName,
    restaurantId,
    getAllItems,
    status,
    onlyAvailableForRestaurant,
    [WAIT_FOR_ACTION]: GET_MENU_ITEMS_LIST_SUCCESS,
    [ERROR_ACTION]: GET_MENU_ITEMS_LIST_FAILURE,
  };
}

export function getMenuItemsAttributes() {
  return {
    type: GET_MENU_ATTRIBUTES_REQUEST,
    [WAIT_FOR_ACTION]: GET_MENU_ATTRIBUTES_SUCCESS,
    [ERROR_ACTION]: GET_MENU_ATTRIBUTES_FAILURE,
  };
}

export function getMenuItemById(id) {
  return {
    type: GET_MENU_ITEM_BY_ID_REQUEST,
    id,
    [WAIT_FOR_ACTION]: GET_MENU_ITEM_BY_ID_SUCCESS,
    [ERROR_ACTION]: GET_MENU_ITEM_BY_ID_FAILURE,
  };
}

export function saveMenuItem({
  id,
  sku,
  price,
  tax,
  categoryId,
  tags,
  name,
  ean,
  seoTitle,
  seoDescription,
  seoCoverImage,
  seoNoIndex,
  seoRedirectMenuItem,
  coverImgUrl,
  descriptionLong,
  descriptionShort,
  groups,
  groupsToDelete,
  nutrition,
  recommended,
  similar,
  restaurantIds,
  coverImg,
}) {
  return {
    type: SAVE_MENU_ITEM_REQUEST,
    id,
    sku,
    price,
    tax,
    categoryId,
    name,
    ean,
    seoTitle,
    seoDescription,
    seoCoverImage,
    seoNoIndex,
    seoRedirectMenuItem,
    coverImgUrl,
    descriptionLong,
    descriptionShort,
    tags,
    groups,
    groupsToDelete,
    nutrition,
    recommended,
    similar,
    restaurantIds,
    coverImg,
    [WAIT_FOR_ACTION]: SAVE_MENU_ITEM_SUCCESS,
    [ERROR_ACTION]: SAVE_MENU_ITEM_FAILURE,
  };
}

export function deleteMenuItem(id) {
  return {
    type: DELETE_MENU_ITEM_REQUEST,
    id,
    [WAIT_FOR_ACTION]: DELETE_MENU_ITEM_SUCCESS,
    [ERROR_ACTION]: DELETE_MENU_ITEM_FAILURE,
  };
}

export function updateCategory({ id, categoryId }) {
  return {
    type: UPDATE_CATEGORY_REQUEST,
    id,
    categoryId,
    [WAIT_FOR_ACTION]: UPDATE_CATEGORY_SUCCESS,
    [ERROR_ACTION]: UPDATE_CATEGORY_FAILURE,
  };
}

export function getCategoryMenuItems({
  sortName,
  sortDirection,
  selectedCategory,
}) {
  return {
    type: GET_CATEGORY_MENU_ITEMS_REQUEST,
    sortName,
    sortDirection,
    selectedCategory,
    [WAIT_FOR_ACTION]: GET_CATEGORY_MENU_ITEMS_SUCCESS,
    [ERROR_ACTION]: GET_CATEGORY_MENU_ITEMS_FAILURE,
  };
}

export function addToRestaurant({ id, restaurantId }) {
  return {
    type: ADD_TO_RESTAURANT_REQUEST,
    id,
    restaurantId,
    [WAIT_FOR_ACTION]: ADD_TO_RESTAURANT_SUCCESS,
    [ERROR_ACTION]: ADD_TO_RESTAURANT_FAILURE,
  };
}

export function deleteFromRestaurant({ id, restaurantId }) {
  return {
    type: DELETE_FROM_RESTAURANT_REQUEST,
    id,
    restaurantId,
    [WAIT_FOR_ACTION]: DELETE_FROM_RESTAURANT_SUCCESS,
    [ERROR_ACTION]: DELETE_FROM_RESTAURANT_FAILURE,
  };
}

export function changeMenuItemAttributeToRestaurant({
  id,
  restaurantId,
  attribute,
  attributeValue,
}) {
  return {
    type: CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_REQUEST,
    id,
    restaurantId,
    attribute,
    attributeValue,
    [WAIT_FOR_ACTION]: CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_SUCCESS,
    [ERROR_ACTION]: CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_FAILURE,
  };
}

export function addCategoryToRestaurant({ categoryId, restaurantId }) {
  return {
    type: ADD_CATEGORY_TO_RESTAURANT_REQUEST,
    categoryId,
    restaurantId,
    [WAIT_FOR_ACTION]: ADD_CATEGORY_TO_RESTAURANT_SUCCESS,
    [ERROR_ACTION]: ADD_CATEGORY_TO_RESTAURANT_FAILURE,
  };
}

export function removeCategoryFromRestaurant({ categoryId, restaurantId }) {
  return {
    type: REMOVE_CATEGORY_FROM_RESTAURANT_REQUEST,
    categoryId,
    restaurantId,
    [WAIT_FOR_ACTION]: REMOVE_CATEGORY_FROM_RESTAURANT_SUCCESS,
    [ERROR_ACTION]: REMOVE_CATEGORY_FROM_RESTAURANT_FAILURE,
  };
}

export function restoreMenuItem(id, item) {
  return {
    type: RESTORE_MENU_ITEM_REQUEST,
    id,
    item,
    [WAIT_FOR_ACTION]: RESTORE_MENU_ITEM_SUCCESS,
    [ERROR_ACTION]: RESTORE_MENU_ITEM_FAILURE,
  };
}
