import React from 'react';
import PropTypes from 'prop-types';

import { renderMultilineText } from '../../utils';

class ListSectionTitle extends React.PureComponent {
  render() {
    const { className, item } = this.props;

    const TitleTag = item && item.titleHTag ? item.titleHTag : 'h3';

    return (
      <TitleTag
        className={className}
        style={{
          color: item.titleColor,
          textAlign: item.titleAlign,
        }}
      >
        {renderMultilineText(item.title)}
      </TitleTag>
    );
  }
}

ListSectionTitle.propTypes = {
  className: PropTypes.string.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.object,
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
    backgroundColor: PropTypes.string,
  }),
};

export default ListSectionTitle;
