import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  defaultProps,
} from 'recompose';
import { connect } from 'react-redux';

import { getKickbacks, deleteKickback } from 'actions/loyalties';
import { showLoading, hideLoading } from 'actions/ui';
import { setFilters, clearFilters } from 'actions/redirect';

import { wrapWithSideEffect } from 'utils';
import { hideIfNoData } from 'utils/enhancers';

import KickbackListPage from './KickbackListPage';

const PAGING_LIMIT = 40;

const defaultParams = {
  sortName: 'name',
  sortDirection: 'asc',
};

const mapStateToProps = ({ redirect }) => ({
  filters: redirect.filters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit: (args) => dispatch(getKickbacks(args)),
  onDelete: (id) => dispatch(deleteKickback(id)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
  setFilters: (args) => dispatch(setFilters(ownProps.location, args)),
  clearFilters: () => dispatch(clearFilters()),
});

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', defaultParams.sortName),
  withState('sortDirection', 'setSortDirection', defaultParams.sortDirection),
  withState('page', 'setPage', 0),
  defaultProps({
    pageLimit: PAGING_LIMIT,
  })
);

const refresh = (props) => {
  const {
    sortName,
    sortDirection,
    onInit,
    setInitialData,
    page,
    pageLimit,
  } = props;
  return wrapWithProgress(props)(
    onInit({
      sortName,
      sortDirection,
      page,
      pageLimit,
    }).then(setInitialData)
  );
};

const handlers = withHandlers({
  onInit: (props) => () => {
    const { location, filters } = props;
    if (location && filters && location.pathname !== filters.url) {
      props.clearFilters();
      return refresh(props);
    }
    const { sortName, sortDirection } = props.filters;
    if (sortName) props.setSortName(sortName);
    if (sortDirection) props.setSortDirection(sortDirection);
    return refresh({
      ...props,
      sortName: sortName || defaultParams.sortName,
      sortDirection: sortDirection || defaultParams.sortDirection,
    });
  },
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    props.setFilters({ sortName, sortDirection, page: props.page });
    return refresh({ ...props, sortName, sortDirection });
  },
  onPage: (props) => ({ page }) => {
    props.setPage(page);
    props.setFilters({
      sortName: props.sortName,
      sortDirection: props.sortDirection,
      page,
    });
    return refresh({ ...props, page });
  },
  onDelete: (props) => (event) =>
    props.onDelete(event).then(() => refresh(props)),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(KickbackListPage);
