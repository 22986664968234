import React from 'react';
import Typography from '@material-ui/core/Typography';

class SectionHeading extends React.PureComponent {
  render() {
    return (
      <Typography
        variant="h6"
        color="secondary"
        style={{ marginTop: 0, marginBottom: 10 }}
        {...this.props}
      />
    );
  }
}

export default SectionHeading;
