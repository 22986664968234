import { all } from 'redux-saga/effects';

import { calculateSort, calculatePage, prepareURLRequest } from 'utils';
import {
  formatOrderDeliveryDateTime,
  formatOrderFiltersDate,
} from 'utils/date';

import apiRequest from 'sagas/utils/apiRequest';
import { getCustomers } from 'sagas/customers';
import { getOrderDeliveries } from 'sagas/orderDeliveries';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';
import { getLocationsRequest } from 'sagas/locations';

export function* getOrdersByDeliveryRequest({
  sortName,
  sortDirection,
  restaurantId,
  startTime,
  endTime,
  status,
  page,
  pageLimit,
  orderType,
  userId,
  companyId,
  orderId,
  getLocations = false,
}) {
  try {
    const params = [];
    if (orderId) params.push(`id=${orderId}`);
    if (restaurantId) params.push(`restaurantId=${restaurantId}`);
    if (status) params.push(`status=${status}`);
    if (orderType) params.push(`type=${orderType}`);
    if (userId) params.push(`userId=${userId}`);
    if (companyId) params.push(`companyId=${companyId}`);
    if (startTime && endTime)
      params.push(
        `deliveryTime=${formatOrderFiltersDate(
          startTime
        )},${formatOrderFiltersDate(endTime)}`
      );
    params.push(calculateSort(sortDirection, sortName));

    const url = '/orders-by-deliveries';
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let rawPayload;
    rawPayload = yield apiRequest(
      prepareURLRequest(
        url,
        pageLimit
          ? params.concat([`${calculatePage(page, pageLimit)}`])
          : params
      ),
      options
    );

    const usersIds = rawPayload.items.reduce((usersArr, order) => {
      if (usersArr.indexOf(order.userId) === -1) usersArr.push(order.userId);
      return usersArr;
    }, []);

    let users = [];

    if (usersIds.length > 0) {
      users = yield getCustomers({
        ids: usersIds,
        nonAnonymous: false,
      });
    } else {
      // Handle the case where there are no user IDs
      users = { items: [] };
    }

    const usersList = {};
    users.items.forEach((user) => {
      usersList[user.id] = user;
    });

    const locationIds =
      rawPayload.items && rawPayload.items.length > 0
        ? rawPayload.items
            .filter((item) => item.delivery && item.delivery.locationId > 0)
            .map((item) => item.delivery.locationId)
        : [];

    let locationsDict = {};
    if (getLocations && locationIds && locationIds.length) {
      const locations = yield getLocationsRequest({
        ids: locationIds,
      });
      locationsDict = locations.items.reduce((acc, location) => {
        acc[location.id] = location;
        return acc;
      }, {});
    }

    return {
      ...rawPayload,
      items: rawPayload.items.map((item) => {
        const location =
          item.delivery &&
          item.delivery.locationId &&
          locationsDict[item.delivery.locationId]
            ? locationsDict[item.delivery.locationId]
            : {};

        return {
          ...item,
          createDate: formatOrderDeliveryDateTime(item.createDate),
          updateDate: formatOrderDeliveryDateTime(item.updateDate),
          user: usersList[item.userId],
          location,
          deliveryAddress: location
            ? `${location.streetAddress || ''} ${location.postcode ||
                ''} ${location.city || ''}`
            : '',
        };
      }),
    };
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

/**
 * Process orders with delivery and user info.
 * @param {Object} { orderIds, orders, deliveryItems, getDelivery } - array of order ids, array of orders, array of order deliveries, should retrieve order deliveries if true.
 * @return {Object} Response for request.
 */
export function* processOrdersWithDeliveries({
  orderIds,
  orders,
  deliveryItems = [],
  getDelivery,
}) {
  let deliveries = deliveryItems;

  if (getDelivery) {
    const response = yield getOrderDeliveries({ orderIds });
    deliveries = response.items;
  }

  const deliveryDict = deliveries.reduce((acc, delivery) => {
    acc[delivery.orderId] = delivery;
    return acc;
  }, {});

  const usersIds =
    orders && orders.length > 0
      ? orders.reduce((usersArr, order) => {
          if (usersArr.indexOf(order.userId) === -1)
            usersArr.push(order.userId);
          return usersArr;
        }, [])
      : [];

  const users = yield getCustomers({
    ids: usersIds,
    nonAnonymous: false,
  });

  const usersList = {};
  users.items.forEach((user) => {
    usersList[user.id] = user;
  });

  const processedOrders =
    orders && orders.length > 0
      ? orders.map((order) => ({
          ...order,
          delivery: deliveryDict[order.id] || {},
          user: usersList[order.userId] || {},
        }))
      : [];

  return processedOrders;
}

/**
 * Retrieve pagination list of order.
 * @param {Object}
 * @return {Object} Response for request.
 */
export function* getOrdersRequest({
  sortName,
  sortDirection,
  restaurantId,
  ids,
  status,
  page,
  pageLimit,
  orderType,
  userId,
  orderId,
  createDate,
}) {
  try {
    const params = [];
    if (orderId) params.push(`id=${orderId}`);
    if (restaurantId) params.push(`restaurantId=${restaurantId}`);
    if (status) params.push(`status=${status}`);
    if (orderType) params.push(`type=${orderType}`);
    if (userId) params.push(`userId=${userId}`);
    if (ids && ids.length) params.push(`id=${ids.join(',')}`);
    if (createDate) params.push(`createDate=${createDate}`);
    params.push(calculateSort(sortDirection, sortName));

    const url = '/orders';
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const rawPayload = yield apiRequest(
      prepareURLRequest(
        url,
        pageLimit
          ? params.concat([`${calculatePage(page, pageLimit)}`])
          : params
      ),
      options
    );

    const orders =
      rawPayload.items && rawPayload.items.length > 0
        ? rawPayload.items.map((order) => ({
            ...order,
            createDate: formatOrderDeliveryDateTime(order.createDate),
            updateDate: formatOrderDeliveryDateTime(order.updateDate),
          }))
        : [];
    return {
      ...rawPayload,
      items: orders,
    };
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

/**
 * Retrieve orders by ids.
 * @param {Object} { ids } - orders ids joined with ","
 * @return {Object} Response object.
 */
export function* getOrdersByIds(ids, sortDirection, sortName) {
  try {
    const params = [];
    if (ids) params.push(`id=${ids}`);
    params.push(calculateSort('desc', 'deliveryTime'));
    const url = `/orders-by-deliveries?${calculateSort(
      sortDirection,
      sortName
    )}&id=${ids}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const payload = yield apiRequest(url, options);
    return payload;
  } catch (error) {
    return;
  }
}

/**
 * Retrieve menu items for list of orders.
 * @param {Object} { ids } - orders ids joined with ","
 * @return {Object} Return distionary by order id.
 */
export function* getOrderItems({ ids = '' }) {
  const responses = yield all(
    ids.split(',').map(function*(orderId) {
      try {
        const id = parseInt(orderId, 10);
        let url = `/orders/${id}/items`;
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const response = yield apiRequest(url, options);
        response.orderId = id;
        return response;
      } catch (error) {
        return;
        //todo
      }
    })
  );
  const resultItems = {};
  responses.forEach((res) => (resultItems[res.orderId] = res));
  return resultItems;
}

/**
 * Retrieve pickup number for order
 * @param {Number} orderId - order id
 * @return {Object} - Response object
 */
export function* getOrderPickupNumber(orderId) {
  try {
    const url = `/orders/${orderId}/pickup-number`;
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const payload = yield apiRequest(url, options);
    return payload;
  } catch (error) {
    return;
  }
}
