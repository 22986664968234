import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { toastr } from 'react-redux-toastr';

import TableSortCell from 'components/TableSortCell';
import TableActionCell from 'components/TableActionCell';
import ActionHeading from 'components/ActionHeading';
import SmallButton from 'components/SmallButton';

import { ITEM_STATUSES } from 'consts';

import { confirmAlert } from 'utils';

class TagList extends React.PureComponent {
  state = {
    name: '',
  };

  handleNameChange = ({ target: { value } }) => {
    this.setState({
      name: value,
    });
  };

  validateError = (data) => {
    if (!data.name) {
      return 'Tag name is required';
    }
  };

  handleSubmit = () => {
    const { id, onSubmit } = this.props;
    const data = {
      groupId: id,
      name: this.state.name,
      status: ITEM_STATUSES.active,
    };
    const validateError = this.validateError(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      onSubmit(data)
        .then(() => {
          this.setState({ name: '' });
          toastr.success('Success!', 'New Tag has been created');
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  handleDelete = (id) => {
    const { onDelete } = this.props;
    confirmAlert({
      okCb: () =>
        onDelete(id)
          .then(() => {
            toastr.success('Success!', 'Tag has been deleted');
          })
          .catch(() => {
            toastr.error('Error!', 'Something went wrong');
          }),
    });
  };

  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  render() {
    const { items } = this.props.initialData;
    const { sortName, sortDirection } = this.props;
    const { name } = this.state;
    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="Tag"
                  sortName="value"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.value}</TableCell>
                  <TableActionCell
                    icon="delete"
                    action={() => this.handleDelete(item.id)}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <div style={{ marginTop: 40 }}>
          <ActionHeading>Create new tag</ActionHeading>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Input
              type="text"
              value={name}
              onChange={this.handleNameChange}
              placeholder="Enter tag name"
              color="secondary"
            />
            <SmallButton onClick={this.handleSubmit} leftSpace>
              Create
            </SmallButton>
          </div>
        </div>
      </div>
    );
  }
}

TagList.propTypes = {
  id: PropTypes.number,
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TagList;
