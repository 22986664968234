import React from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/TextField';

class InvoiceAddress extends React.PureComponent {
  render() {
    const {
      contactPerson,
      street,
      zipCode,
      city,
      email,
      handleChange,
    } = this.props;
    return (
      <React.Fragment>
        <div>
          <Input
            value={street}
            onChange={handleChange('street')}
            placeholder="Street name and number"
            label="Street name and number"
            margin="normal"
            style={{ width: 300, marginRight: 24 }}
          />
          <Input
            value={contactPerson}
            onChange={handleChange('contactPerson')}
            placeholder="Contact person name"
            label="Contact person name"
            margin="normal"
            style={{ width: 300 }}
          />
        </div>
        <div>
          <Input
            value={zipCode}
            onChange={handleChange('zipCode')}
            placeholder="Zip code"
            label="Zip code"
            margin="normal"
            style={{ width: 300, marginRight: 24 }}
          />
          <Input
            value={email}
            onChange={handleChange('email')}
            placeholder="Invoicing email"
            label="Invoicing email"
            margin="normal"
            style={{ width: 300 }}
          />
        </div>
        <div>
          <Input
            value={city}
            onChange={handleChange('city')}
            placeholder="City"
            label="City"
            margin="normal"
            style={{ width: 300 }}
          />
        </div>
      </React.Fragment>
    );
  }
}

InvoiceAddress.propTypes = {
  contactPerson: PropTypes.string,
  street: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  email: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default InvoiceAddress;
