import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_GROUP_BY_ID_REQUEST,
  GET_GROUP_BY_ID_SUCCESS,
  SAVE_GROUP_REQUEST,
  SAVE_GROUP_SUCCESS,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
} from 'consts/groups';
import { ITEM_STATUSES } from 'consts';
import { calculateSort } from 'utils';

import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';

import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';

function* getGroups({ sortName, sortDirection }) {
  let url = `/groups?${calculateSort(sortDirection, sortName)}&status=${
    ITEM_STATUSES.active
  }`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: GET_GROUPS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getGroupsSaga() {
  yield takeFirst(GET_GROUPS_REQUEST, getGroups);
}

function* getGroupById({ id }) {
  let url = `/groups/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: GET_GROUP_BY_ID_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getGroupByIdSaga() {
  yield takeFirst(GET_GROUP_BY_ID_REQUEST, getGroupById);
}

function* saveGroup({ id, name, label, menuItems }) {
  const url = id ? `/groups/${id}` : '/groups';
  const options = {
    method: id ? 'PUT' : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, label, menuItems }),
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SAVE_GROUP_SUCCESS,
      payload,
    };
    yield put(newAction);
    if (!id) {
      yield put(push(`/group-details/${payload.id}`));
    }
  } catch (error) {
    const isDuplicatedLabel = error.message.includes(
      `1062 Duplicate entry 'other' for key 'UQ_GROUP_LABEL'`
    );
    if (isDuplicatedLabel) {
      showErrorOnErrorResponse({ message: 'Name should be unique' });
    } else {
      showErrorOnErrorResponse(error);
    }
  }
}

function* saveGroupSaga() {
  yield takeFirst(SAVE_GROUP_REQUEST, saveGroup);
}

function* deleteGroup({ id }) {
  const url = id ? `/groups/${id}` : '/groups';
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: DELETE_GROUP_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* deleteGroupSaga() {
  yield takeFirst(DELETE_GROUP_REQUEST, deleteGroup);
}

export default combine([
  getGroupsSaga,
  getGroupByIdSaga,
  saveGroupSaga,
  deleteGroupSaga,
]);
