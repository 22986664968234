import apiRequest from 'sagas/utils/apiRequest';

import { isFloat } from 'utils';

export function* getLocationsRequest({ ids }) {
  const idParam = ids && ids.length ? `id=${ids.join(',')}` : '';
  const url = `/locations?${idParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    return payload;
  } catch (error) {
    // TODO:
  }
}

export function* changeLocation({
  id,
  address,
  postcode,
  city,
  latitude,
  longitude,
}) {
  const url = `/locations/${id}`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      streetAddress: address,
      postcode,
      city,
      latitude,
      longitude,
    }),
  };
  try {
    const payload = yield apiRequest(url, options);
    return payload;
  } catch (error) {
    // TODO:
  }
}

/**
 * Update location info if calling with locationId parameter else create new location.
 * @param {Object} { locationId, streetAddress, city, postcode, latitude, longitude } - location info.
 * @return {Object} Response for request.
 */
export function* saveLocation({
  locationId,
  streetAddress,
  city,
  postcode,
  latitude,
  longitude,
} = {}) {
  const url = locationId ? `/locations/${locationId}` : '/locations';
  const options = {
    method: locationId ? 'PUT' : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      streetAddress,
      city,
      postcode,
      latitude: isFloat(latitude) ? parseFloat(latitude) : 0,
      longitude: isFloat(longitude) ? parseFloat(longitude) : 0,
    }),
  };
  const payload = yield apiRequest(url, options);
  return payload;
}
