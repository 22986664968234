import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';

const mapOption = (text) => ({
  label: text,
  value: text,
});

class OwnerDropdown extends React.PureComponent {
  handleChange = ({ value }) => {
    this.props.onChange(value);
  };

  render() {
    const { value, options } = this.props;
    return (
      <Select
        options={options.map(mapOption)}
        placeholder="Please select"
        value={value ? mapOption(value) : null}
        onChange={this.handleChange}
        label="Restaurant owner"
      />
    );
  }
}

OwnerDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default OwnerDropdown;
