import React from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import { DatePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core/styles';

import TimePicker from './TimePicker';
import DisabledTimePicker from './DisabledTimePicker';

import { getMaxDeliveryDate, getMinDeliveryDate } from 'utils/date';
import { isDisabledDay } from './utils';
import { DATE_MONTH_FORMAT } from 'consts';

const styles = () => ({
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formBlock: {
    flex: 1,
    '&:nth-child(1)': {
      marginRight: 30,
    },
    '&:nth-child(2)': {
      marginTop: 25,
    },
  },
  label: {
    fontSize: '0.75rem',
  },
  dateWrapper: {
    display: 'flex',
  },
});

class DeliveryInfo extends React.PureComponent {
  state = {
    date: this.props.deliveryTime,
  };

  //TODO
  getDisabledDays = (day) => {
    const { restaurant, minDeliveryTime } = this.props;

    return isDisabledDay({ day, restaurant, minTime: minDeliveryTime });
  };

  onDateChange = (value) => {
    this.setState({
      date: value,
    });
  };

  render() {
    const {
      classes,
      restaurant,
      onChangePrice,
      deliveryTime,
      deliveryPrice,
      onChangeTime,
      onChangeDate,
      selectedHour,
      selectedMinute,
      schedule,
      delivery,
      onChangeDeliveryAddress,
    } = this.props;

    return (
      <div className={classes.formWrapper}>
        <div className={classes.formBlock}>
          <div>
            <Input
              value={deliveryPrice}
              onChange={(e) => onChangePrice(e.target.value)}
              placeholder="Delivery price"
              label="Delivery price"
              type="number"
              margin="normal"
              style={{ width: 300 }}
            />
          </div>
          <div>
            <Input
              value={delivery.name}
              onChange={onChangeDeliveryAddress('name')}
              placeholder="Name"
              label="Name"
              margin="normal"
              style={{ width: 300 }}
            />
          </div>
          <div>
            <Input
              value={delivery.address}
              onChange={onChangeDeliveryAddress('address')}
              placeholder="Address"
              label="Address"
              margin="normal"
              style={{ width: 300 }}
            />
          </div>
          <div>
            <Input
              value={delivery.postcode}
              onChange={onChangeDeliveryAddress('postcode')}
              placeholder="Postcode"
              label="Zip Code"
              margin="normal"
              style={{ width: 300 }}
            />
          </div>
          <div>
            <Input
              value={delivery.notice}
              onChange={onChangeDeliveryAddress('notice')}
              placeholder="Add.info/code"
              label="Add.info/code"
              margin="normal"
              style={{ width: 300 }}
            />
          </div>
          <div>
            <Input
              value={delivery.phone}
              onChange={onChangeDeliveryAddress('phone')}
              placeholder="Phone"
              label="Phone"
              margin="normal"
              style={{ width: 300 }}
            />
          </div>
        </div>
        <div className={classes.formBlock}>
          <div style={{ marginTop: '-15px' }}>
            <div>
              <FormLabel className={classes.label}>
                Delivery time (Time zone: {restaurant.timeZone})
              </FormLabel>
            </div>
            <div className={classes.dateWrapper}>
              <DatePicker
                value={deliveryTime}
                format={DATE_MONTH_FORMAT}
                minDate={getMinDeliveryDate(restaurant.timeZone)}
                maxDate={getMaxDeliveryDate(restaurant.timeZone)}
                shouldDisableDate={this.getDisabledDays}
                onChange={onChangeDate}
              />
              {selectedHour ? (
                <TimePicker
                  restaurant={restaurant}
                  onTimeChange={onChangeTime}
                  schedule={schedule}
                  selectedMinute={selectedMinute}
                  selectedHour={selectedHour}
                />
              ) : (
                <DisabledTimePicker
                  selectedMinute={selectedMinute}
                  selectedHour={selectedHour}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeliveryInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  deliveryPrice: PropTypes.number,
  deliveryTime: PropTypes.instanceOf(Date),
  onChangePrice: PropTypes.func.isRequired,
  onChangeTime: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  minDeliveryTime: PropTypes.number.isRequired,
  restaurant: PropTypes.shape({
    timeZone: PropTypes.string,
  }).isRequired,
  selectedMinute: PropTypes.number,
  selectedHour: PropTypes.number,
  schedule: PropTypes.object.isRequired,
  delivery: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    postcode: PropTypes.string,
    notice: PropTypes.string,
  }),
  onChangeDeliveryAddress: PropTypes.func.isRequired,
};

export default withStyles(styles)(DeliveryInfo);
