import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { getCategoryById, saveCategory } from 'actions/categories';
import { showLoading, hideLoading } from 'actions/ui';

import { wrapWithSideEffect } from 'utils';
import { routerPassId, hideIfNoData } from 'utils/enhancers';

import CategoryDetailsPage from './CategoryDetailsPage';

const mapDispatchToProps = {
  onInit: getCategoryById,
  onSubmit: saveCategory,
  showLoading,
  hideLoading,
};

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props) => {
  const { id, onInit, setInitialData } = props;
  return id && wrapWithProgress(props)(onInit(id).then(setInitialData));
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSubmit: (props) => (event) =>
    props.onSubmit(event).then(() => refresh(props)),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
  componentWillReceiveProps(newProps) {
    if (newProps.id !== this.props.id) {
      this.props.onInit();
    }
  },
});

export default compose(
  routerPassId,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => props.id && !props.initialData)
)(CategoryDetailsPage);
