import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

class PageHeadingButton extends React.PureComponent {
  render() {
    const { link, ...rest } = this.props;
    return (
      <Link to={link}>
        <Button variant="contained" color="primary" size="large" {...rest} />
      </Link>
    );
  }
}

PageHeadingButton.propTypes = {
  link: PropTypes.string.isRequired,
};

export default PageHeadingButton;
