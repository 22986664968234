import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import DeliveryZoneDetailsPage from './DeliveryZoneDetailsPage';

import { saveDeliveryCost, getDeliveryCostById } from 'actions/settings';

import { routerPassId, hideIfNoData } from 'utils/enhancers';

const mapDispatchToProps = {
  onInit: getDeliveryCostById,
  onSubmitDeliveryCost: saveDeliveryCost,
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props) => {
  const { id, onInit, setInitialData } = props;
  return id && onInit(id).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
});

const retrieveData = lifecycle({
  componentDidMount() {
    const { id, onInit } = this.props;
    if (id) {
      onInit(id);
    }
  },
});

export default compose(
  routerPassId,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => props.id && !props.initialData)
)(DeliveryZoneDetailsPage);
