import React from 'react';
import PropTypes from 'prop-types';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import ListItemForm from '../ListItemForm';

import styles from './styles';

class ListItemDetails extends React.PureComponent {
  render() {
    const {
      classes,
      item,
      index,
      onChange,
      onChangeColor,
      onDelete,
      onChangeLink,
      onChangeSelect,
    } = this.props;

    return (
      <ExpansionPanel style={{ marginBottom: 10 }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          <Typography className={classes.itemType}>{index + 1}.</Typography>
          <div className={classes.headerTitle}>{item.title}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <div style={{ width: '100%' }}>
            <ListItemForm
              item={item}
              index={index}
              onChange={onChange}
              onChangeColor={onChangeColor}
              onChangeLink={onChangeLink}
              onChangeSelect={onChangeSelect}
            />
            <div className={classes.btnWrapper}>
              <Button color="primary" onClick={() => onDelete(item.id)}>
                Delete
              </Button>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

ListItemDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeColor: PropTypes.func.isRequired,
  onChangeLink: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListItemDetails);
