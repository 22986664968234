import React from 'react';
import PropTypes from 'prop-types';

import HourDropdown from 'components/HourDropdown';
import MinuteDropdown from 'components/MinuteDropdown';

const isFilled = ({ hour, minute }) =>
  Number.isInteger(hour) || Number.isInteger(minute);

class TimeControl extends React.PureComponent {
  onHourChange = ({ value }) => {
    const newValue = {
      ...(this.props.value || {}),
      hour: value,
    };
    this.props.onChange(isFilled(newValue) ? newValue : undefined);
  };

  onMinuteChange = ({ value }) => {
    const newValue = {
      ...(this.props.value || {}),
      minute: value,
    };
    this.props.onChange(isFilled(newValue) ? newValue : undefined);
  };

  render() {
    const { value = {} } = this.props;
    return (
      <React.Fragment>
        <HourDropdown value={value.hour} onChange={this.onHourChange} />
        <span style={{ marginLeft: 20 }} />
        <MinuteDropdown value={value.minute} onChange={this.onMinuteChange} />
      </React.Fragment>
    );
  }
}

TimeControl.propTypes = {
  value: PropTypes.shape({
    hour: PropTypes.number,
    minute: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
};

export default TimeControl;
