import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import TableSortCell from 'components/TableSortCell';
import TableActionCell from 'components/TableActionCell';

import { validateDeliveryZipCode } from 'utils/processors';

class ZipCodes extends React.PureComponent {
  state = {
    newZipCode: '',
    error: false,
    sortDirection: 'asc',
  };

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleSort = (sortName, sortDirection) => {
    this.setState({ sortDirection });
  };

  handleAddZipCode = () => {
    const { handleAddZipCode, zipCodes } = this.props;
    const { newZipCode } = this.state;
    const cleanCode = newZipCode.replace(/x/gim, '');
    const duplicatedMask = zipCodes.filter((code) => {
      const cCode = code.replace(/x/gim, '');
      return (
        cCode.match(new RegExp(`^${cleanCode}`, 'gim')) ||
        cleanCode.match(new RegExp(`^${cCode}`, 'gim'))
      );
    });
    if (
      validateDeliveryZipCode(newZipCode) &&
      !(duplicatedMask && duplicatedMask.length)
    ) {
      handleAddZipCode(newZipCode, () =>
        this.setState({ newZipCode: '', error: false })
      );
    } else {
      this.setState({ error: true });
    }
  };

  handleDelete = (zipCode) => {
    const { handleDeleteZipCode } = this.props;
    handleDeleteZipCode(zipCode);
  };

  render() {
    const { zipCodes } = this.props;
    const { newZipCode, error, sortDirection } = this.state;
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableSortCell
                title="Zip code"
                sortName="name"
                currentSortName="name"
                currentSortDirection={sortDirection}
                handleSort={this.handleSort}
              />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {(sortDirection === 'asc'
              ? zipCodes.sort()
              : zipCodes.reverse()
            ).map((zipCode, index) => (
              <TableRow key={zipCode}>
                <TableCell>{zipCode}</TableCell>
                <TableActionCell
                  action={() => this.handleDelete(zipCode, index)}
                  icon="delete"
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div>
            <Input
              value={newZipCode}
              onChange={this.handleChange('newZipCode')}
              placeholder="Zip Code"
              label="Add Zip code (X = wildcard)"
              margin="normal"
              style={{ width: 300 }}
              error={error}
            />
          </div>
          <Button
            onClick={this.handleAddZipCode}
            variant="contained"
            color="primary"
            style={{ marginTop: 30, marginLeft: 20 }}
          >
            Add
          </Button>
        </div>
      </div>
    );
  }
}

ZipCodes.propTypes = {
  zipCodes: PropTypes.arrayOf(PropTypes.string),
  handleAddZipCode: PropTypes.func.isRequired,
  handleDeleteZipCode: PropTypes.func.isRequired,
};

export default ZipCodes;
