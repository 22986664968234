const groupOrdersByComment = (orders) => {
  let noticelessSection = {
    items: [],
  };
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];

    noticelessSection = {
      items: [...noticelessSection.items, ...order.items],
    };
  }
  return [
    {
      ...noticelessSection,
      deliveryStart:
        orders && orders.length > 0 && orders[0] && orders[0].delivery
          ? orders[0].delivery.deliveryTime
          : '',
      deliveryEnd:
        orders &&
        orders.length > 0 &&
        orders[orders.length - 1] &&
        orders[orders.length - 1].delivery
          ? orders[orders.length - 1].delivery.deliveryTime
          : '',
    },
  ];
};

const groupItems = (items) => {
  let result = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item && item.menuItem) {
      const prevItem = result.find((r) => r.id === item.menuItem.id) || {};
      const newItem = {
        id: item.menuItem.id,
        name: item.menuItem.name,
        quantity: (prevItem.quantity || 0) + item.quantity,
        visible: item.menuItem.visible,
        order: item.menuItem.order,
        categoryName: item.menuItem.categoryName,
      };
      result = [...result.filter((r) => r.id !== newItem.id), newItem];
    }
  }
  return result.sort(function(a, b) {
    return a.quantity > b.quantity ? -1 : 1;
  });
};

const groupToSections = (orders) =>
  groupOrdersByComment(orders).map((group) => ({
    ...group,
    items: groupItems(group.items),
  }));

export default groupToSections;
