import { compose, mapProps } from 'recompose';

import Nutrition from './Nutrition';

const per100gLabels = [
  { key: 'Kcal', label: 'Kcal' },
  {
    key: 'Fett',
    label: 'Fett g',
    items: [{ key: 'Mättat fett', label: 'Varav mättat fett' }],
  },
  {
    key: 'Kolhydrater',
    label: 'Kolhydrater',
    items: [{ key: 'Sockerarter', label: 'Varav sockerarter' }],
  },
  { key: 'Protein', label: 'Protein' },
  { key: 'Salt', label: 'Salt' },
];

const mapPer100gProps = mapProps(({ per100g = [], ...rest }) => ({
  per100g: per100gLabels.map((item) => {
    const originalItem = per100g.find((p) => p.key === item.key);
    const value = (originalItem || {}).value || '';
    const items = item.items
      ? item.items.map((subitem) => {
          if (originalItem && originalItem.items) {
            const originalSubitem = originalItem.items.find(
              (p) => p.key === subitem.key
            );
            const value = (originalSubitem || {}).value || '';
            return { ...subitem, value };
          }
          return subitem;
        })
      : undefined;
    return { ...item, value, items };
  }),
  ...rest,
}));

export default compose(mapPer100gProps)(Nutrition);
