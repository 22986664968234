import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  defaultProps,
} from 'recompose';
import { connect } from 'react-redux';

import {
  getOrders,
  changeOrderStatus,
  sendManualOheicsOrder,
  changeMultipleOrdersStatus,
} from 'actions/orders';
import {
  getPaginatedOrdersForProductionList,
  getPaginatedOrdersForPackList,
} from 'actions/printList';
import { showLoading, hideLoading } from 'actions/ui';
import { setFilters, clearFilters } from 'actions/redirect';
import { getRestaurantsDictionary } from 'actions/restaurants';

import { wrapWithSideEffect } from 'utils';
import { getStartOfDay, getEndOfDay } from 'utils/date';
import { hideIfNoData } from 'utils/enhancers';

import OrdersTypeList from './OrdersTypeList';

import { ABLE_STATUSES_TO_PRINT, ORDER_TYPES } from 'consts/orders';

const PAGING_LIMIT = 100;

const START_TIME = () => getStartOfDay();

const END_TIME = () => getEndOfDay();

const STATUS = Object.keys(ABLE_STATUSES_TO_PRINT).join(',');

const defaultParams = {
  sortName: 'deliveryTime',
  sortDirection: 'desc',
  startTime: START_TIME,
  endTime: END_TIME,
  status: STATUS,
};

const mapStateToProps = ({ redirect }) => ({
  filters: redirect.filters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit: (args) => dispatch(getOrders(args)),
  onCancel: (args) => dispatch(changeOrderStatus(args)),
  onChangeStatus: (args) => dispatch(changeMultipleOrdersStatus(args)),
  onSendOrderOheics: (id) => dispatch(sendManualOheicsOrder(id)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
  setFilters: (args) => dispatch(setFilters(ownProps.location, args)),
  clearFilters: () => dispatch(clearFilters()),
  getProductionList: (args) =>
    dispatch(getPaginatedOrdersForProductionList(args)),
  getPackList: (args) => dispatch(getPaginatedOrdersForPackList(args)),
  getRestaurants: () => dispatch(getRestaurantsDictionary()),
  onChangeOrderStatus: (args) => dispatch(changeOrderStatus(args)),
});

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', defaultParams.sortName),
  withState('sortDirection', 'setSortDirection', defaultParams.sortDirection),
  withState('selectedRestaurant', 'setSelectedRestaurant', null),
  withState('startTime', 'setStartTime', defaultParams.startTime),
  withState('endTime', 'setEndTime', defaultParams.endTime),
  withState('status', 'setStatus'),
  withState('page', 'setPage', 0),
  withState('isRefresh', 'setRefreshValue', true),
  withState('userId', 'setUserId', null),
  withState('orderIdQuery', 'setOrderIdQuery', ''),
  withState('restaurants', 'setRestaurants', {}),
  defaultProps({
    pageLimit: PAGING_LIMIT,
  })
);

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const refresh = (props, setInitData = true) => {
  const {
    sortName,
    sortDirection,
    selectedRestaurant,
    startTime,
    endTime,
    status,
    page,
    type,
    pageLimit,
    onInit,
    setInitialData,
    userId,
    orderIdQuery,
  } = props;

  return wrapWithProgress(props)(
    onInit({
      sortName,
      sortDirection,
      restaurantId: selectedRestaurant,
      startTime,
      endTime,
      status: status || defaultParams.status,
      page,
      pageLimit,
      userId,
      orderId: orderIdQuery,
      orderType: type,
      getOrdersByDelivery: true,
      withCompany: true,
      withOheicsLogs: true,
      getLocations: type === 'delivery',
    }).then((data) => {
      if (setInitData) {
        setInitialData(data);
      } else {
        return data;
      }
    })
  );
};

const handlers = withHandlers({
  onInit: (props) => () => {
    props.setRefreshValue(true);
    const { location, filters } = props;
    if (location && filters && location.pathname !== filters.url) {
      props.clearFilters();
      return refresh(props);
    }
    const {
      sortName,
      sortDirection,
      selectedRestaurant,
      startTime,
      endTime,
      status,
      userId,
      orderIdQuery,
    } = props.filters;
    if (sortName) props.setSortName(sortName);
    if (sortDirection) props.setSortDirection(sortDirection);
    if (selectedRestaurant) props.setSelectedRestaurant(selectedRestaurant);
    if (startTime) props.setStartTime(startTime);
    if (endTime) props.setEndTime(endTime);
    if (status) props.setStatus(status);
    if (userId) props.setUserId(userId);
    if (orderIdQuery) props.setOrderIdQuery(orderIdQuery);
    return refresh({
      ...props,
      sortName: sortName || defaultParams.sortName,
      sortDirection: sortDirection || defaultParams.sortDirection,
      selectedRestaurant,
      startTime: startTime || defaultParams.startTime,
      endTime: endTime || defaultParams.endTime,
      status: status || defaultParams.status,
      userId: userId || '',
      orderIdQuery: orderIdQuery || '',
    });
  },
  onUpdate: (props) => ({ page }) => {
    props.setRefreshValue(false);
    props.setPage(page);
    return refresh(
      {
        ...props,
        page,
        getOrdersByDelivery: true,
      },
      false
    );
  },
  onFilter: (props) => ({
    selectedRestaurant,
    startTime,
    endTime,
    status,
    userId,
    orderIdQuery,
  }) => {
    props.setPage(0);
    props.setSelectedRestaurant(selectedRestaurant);
    props.setStartTime(startTime);
    props.setEndTime(endTime);
    props.setStatus(status);
    props.setRefreshValue(true);
    props.setUserId(userId);
    props.setOrderIdQuery(orderIdQuery);
    props.setFilters({
      sortName: props.sortName,
      sortDirection: props.sortDirection,
      selectedRestaurant,
      startTime,
      endTime,
      status: status || defaultParams.status,
      userId: userId || '',
      orderIdQuery: orderIdQuery || '',
    });
    return refresh({
      ...props,
      selectedRestaurant,
      startTime,
      endTime,
      status: status || defaultParams.status,
      page: 0,
      userId,
      orderIdQuery,
    });
  },
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setPage(0);
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    props.setRefreshValue(true);
    props.setFilters({
      sortName,
      sortDirection,
      selectedRestaurant: props.selectedRestaurant,
      startTime: props.startTime,
      endTime: props.endTime,
      status: props.status || defaultParams.status,
      userId: props.userId,
      orderIdQuery: props.orderIdQuery,
    });
    return refresh({
      ...props,
      sortName,
      sortDirection,
      page: 0,
    });
  },
  getProductionList: (props) => ({ ordersIds, orderIdsToChangeStatus }) => {
    return wrapWithProgress(props)(
      props
        .getProductionList({
          ...props,
          status: props.status || defaultParams.status,
          orderType: props.type,
          ordersIds,
          orderIdsToChangeStatus,
          restaurantId: props.selectedRestaurant,
          getOrdersByDelivery: true,
          userId: props.userId,
          orderId: props.orderIdQuery,
        })
        .then(() => refresh(props))
    );
  },
  getPackList: (props) => ({ ordersIds, orderIdsToChangeStatus }) => {
    return wrapWithProgress(props)(
      props
        .getPackList({
          ...props,
          status: props.status || defaultParams.status,
          orderType: props.type,
          ordersIds,
          orderIdsToChangeStatus,
          restaurantId: props.selectedRestaurant,
          getOrdersByDelivery: true,
          userId: props.userId,
          orderId: props.orderIdQuery,
        })
        .then(() => refresh(props))
    );
  },
  onCancel: (props) => (event) =>
    props.onCancel(event).then(() => refresh(props)),
  onChangeStatus: (props) => (event) =>
    props.onChangeStatus(event).then(() => refresh(props)),
  onSendOrderOheics: (props) => (event) => {
    const { onSendOrderOheics } = props;
    return wrapWithProgress(props)(
      onSendOrderOheics(event)
        .then(() => refresh(props))
        .catch(() => refresh(props))
    );
  },
  getRestaurants: (props) => () =>
    wrapWithProgress(props)(
      props.getRestaurants().then((data) => props.setRestaurants(data))
    ),
  onChangeOrderStatus: (props) => (event) => {
    return props.onChangeOrderStatus(event).then(() => {
      return refresh({ ...props });
    });
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    const { type, onInit, getRestaurants } = this.props;

    onInit().then(() => {
      if (type === ORDER_TYPES.pickUp) {
        getRestaurants();
      }
    });
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(OrdersTypeList);
