import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { toastr } from 'react-redux-toastr';

import { DEFAULT_TIMEZONE } from 'consts';
import { restaurantOwnerOptions } from 'consts/restaurants';

import PageHeading from 'components/PageHeading';
import PageHeadingTitle from 'components/PageHeadingTitle';
import Section from 'components/Section';
import ImageUpload from 'components/ImageUpload';
import { findAttributeValue, findAttributeJSONValue } from 'utils';

import TimezoneDropdown from './TimezoneDropdown';
import OwnerDropdown from './OwnerDropdown';
import OpeningHours from './OpeningHours';
import SpecialOpeningHours from './SpecialOpeningHours';
import MenuItems from './MenuItems';

const ownerNames = restaurantOwnerOptions.map((item) => item.name);
const defaultOwnerName = ownerNames[0];

const stateFromProps = ({ initialData = {} }) => ({
  name: initialData.name || '',
  nameVisible: findAttributeValue(initialData.attributes, 'nameVisible') || '',
  deliveryTimeout:
    findAttributeValue(initialData.attributes, 'deliveryTimeout') || 0,
  streetAddress: initialData.streetAddress || '',
  city: initialData.city || '',
  postcode: initialData.postcode || '',
  latitude: initialData.latitude || 0,
  longitude: initialData.longitude || 0,
  locationId: initialData.locationId || null,
  phoneNumber: findAttributeValue(initialData.attributes, 'phoneNumber') || '',
  coverImgUrl: findAttributeValue(initialData.attributes, 'coverImgUrl') || '',
  restaurantCode:
    findAttributeValue(initialData.attributes, 'restaurantCode') || '',
  timeZone:
    findAttributeValue(initialData.attributes, 'timeZone') || DEFAULT_TIMEZONE,
  availableForPickup: findAttributeValue(initialData.attributes, 'availableForPickup') === 'true' || false,
  availableForInfo: findAttributeValue(initialData.attributes, 'availableForInfo') === 'true' || false,
  owner:
    findAttributeValue(initialData.attributes, 'owner') || defaultOwnerName,
  openingHours:
    findAttributeJSONValue(initialData.attributes, 'openingHours') || [],
  specialOpeningHours:
    findAttributeJSONValue(initialData.attributes, 'specialOpeningHours') || [],
  coverFile: findAttributeValue(initialData.attributes, 'coverImgUrl') || '',
  deliveryRestaurant:
    findAttributeValue(initialData.attributes, 'deliveryRestaurant') ===
    'true' || false,
  seoTitle: findAttributeValue(initialData.attributes, 'seo-title') || '',
  seoDescription:
    findAttributeValue(initialData.attributes, 'seo-description') || '',
  seoCoverImage:
    findAttributeValue(initialData.attributes, 'seo-coverimage') || '',
  restaurantOrganizationNumber:
    findAttributeValue(
      initialData.attributes,
      'restaurantOrganizationNumber'
    ) || '',
});

const isOpeningHoursFilled = ({ hour, minute }) =>
  Number.isInteger(hour) && Number.isInteger(minute);

class RestaurantDetailsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.initialData.longitude !== this.state.longitude ||
      nextProps.initialData.latitude !== this.state.latitude
    ) {
      this.setState({
        longitude: nextProps.initialData.longitude,
        latitude: nextProps.initialData.latitude,
      });
    }
  }

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleOwnerChange = (value) => {
    const restaurantOwnerOption = restaurantOwnerOptions.find(
      (item) => item.name === value
    );
    const restaurantOrganizationNumber = restaurantOwnerOption
      ? restaurantOwnerOption.orgNumber
      : '';
    this.setState({
      owner: value,
      restaurantOrganizationNumber,
    });
  };

  handleTimeZoneChange = (value) => {
    this.setState({ timeZone: value });
  };

  handleOpeningHoursChange = (value) => {
    this.setState({ openingHours: value });
  };

  handleSpecialOpeningHoursChange = (value) => {
    this.setState({ specialOpeningHours: value });
  };

  handleAvailableForPickupChange = () => {
    this.setState({ availableForPickup: !this.state.availableForPickup });
  };

  handleAvailableForInfoChange = () => {
    this.setState({ availableForInfo: !this.state.availableForInfo });
  };

  handleDeliveryRestaurant = () => {
    this.setState({
      deliveryRestaurant: !this.state.deliveryRestaurant,
      deliveryTimeout: 0,
    });
  };

  validateError = (data) => {
    if (!data.name) {
      return 'Name is required';
    }
    if (!data.streetAddress) {
      return 'Street address is required';
    }
    if (!data.city) {
      return 'City is required';
    }
    if (!data.postcode) {
      return 'Postcode is required';
    }
    if (!data.phoneNumber) {
      return 'Phone number is required';
    }
    if (!data.restaurantCode) {
      return 'Restaurant Code is required';
    }
    if (
      !data.deliveryRestaurant &&
      (isNaN(parseInt(data.deliveryTimeout)) ||
        parseInt(data.deliveryTimeout) <= 0 ||
        parseInt(data.deliveryTimeout) > 15)
    ) {
      return 'Minimum time should be a number between 1 and 15';
    }
    if (!data.timeZone) {
      return 'Time Zone is required';
    }
    if (!data.owner) {
      return 'Restaurant owner is required';
    }
    if (data.openingHours && data.openingHours.length) {
      for (let i = 0; i < data.openingHours.length; i++) {
        const oh = data.openingHours[i];
        if (oh.opens && !isOpeningHoursFilled(oh.opens)) {
          return 'Opening hours are incorrect';
        }
        if (oh.closes && !isOpeningHoursFilled(oh.closes)) {
          return 'Opening hours are incorrect';
        }
        if ((oh.opens || oh.closes) && !(oh.opens && oh.closes)) {
          return 'Opening hours are incorrect';
        }
        if (
          oh.opens &&
          ((oh.opens.hour &&
            oh.closes.hour &&
            oh.opens.hour >= oh.closes.hour) ||
            (!oh.opens.hour && !oh.closes.hour))
        ) {
          return 'Closing hours are incorrect. Can`t be less than opening.';
        }
      }
    }
    if (data.specialOpeningHours && data.specialOpeningHours.length) {
      for (let i = 0; i < data.specialOpeningHours.length; i++) {
        const soh = data.specialOpeningHours[i];
        if (
          !soh.name ||
          !Number.isInteger(soh.month) ||
          !Number.isInteger(soh.day)
        ) {
          return 'Special opening hours are incorrect';
        }
        if (soh.opens && !isOpeningHoursFilled(soh.opens)) {
          return 'Special opening hours are incorrect';
        }
        if (soh.closes && !isOpeningHoursFilled(soh.closes)) {
          return 'Special opening hours are incorrect';
        }
        if ((soh.opens || soh.closes) && !(soh.opens && soh.closes)) {
          return 'Special opening hours are incorrect';
        }
      }
    }
  };

  handleSubmit = () => {
    const { id, onSubmit } = this.props;
    const data = { id, ...this.state };
    const validateError = this.validateError(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      onSubmit(data)
        .then(() => {
          toastr.success(
            'Success!',
            id
              ? 'Restaurant has been updated'
              : 'New Restaurant has been created'
          );
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  render() {
    const { id, initialData: restaurant } = this.props;
    const {
      name,
      nameVisible,
      streetAddress,
      city,
      postcode,
      latitude,
      longitude,
      phoneNumber,
      coverImgUrl,
      restaurantCode,
      timeZone,
      availableForPickup,
      availableForInfo,
      owner,
      openingHours,
      specialOpeningHours,
      deliveryRestaurant,
      seoTitle,
      seoDescription,
      seoCoverImage,
      restaurantOrganizationNumber,
      deliveryTimeout,
    } = this.state;
    return (
      <div>
        <PageHeading
          title={
            id ? (
              <PageHeadingTitle
                text="Restaurant"
                highlightedText={restaurant.name}
              />
            ) : (
              'New Restaurant'
            )
          }
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        <div>
          <Input
            value={name}
            onChange={this.handleChange('name')}
            placeholder="Name"
            label="Name"
            margin="normal"
            style={{ width: 360 }}
            disabled
          />
          <Input
            value={nameVisible}
            onChange={this.handleChange('nameVisible')}
            placeholder="Name Visible"
            label="Name Visible"
            margin="normal"
            style={{ marginLeft: 20, width: 360 }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Input
            value={streetAddress}
            onChange={this.handleChange('streetAddress')}
            placeholder="Street adress"
            label="Street adress"
            margin="normal"
            style={{ width: 240 }}
          />
          <Input
            value={city}
            onChange={this.handleChange('city')}
            placeholder="City"
            label="City"
            margin="normal"
            style={{ marginLeft: 20, width: 160 }}
          />
          <Input
            value={postcode}
            onChange={this.handleChange('postcode')}
            placeholder="Postcode"
            label="Postcode"
            margin="normal"
            style={{ marginLeft: 20, width: 160 }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Input
            value={latitude}
            onChange={this.handleChange('latitude')}
            placeholder="Latitude"
            label="Latitude"
            margin="normal"
            type="number"
            style={{ width: 130 }}
          />
          <Input
            value={longitude}
            onChange={this.handleChange('longitude')}
            placeholder="Longitude"
            label="Longitude"
            margin="normal"
            type="number"
            style={{ marginLeft: 20, width: 130 }}
          />
        </div>
        <div style={{ marginTop: 20, display: 'flex', alignItems: 'flex-end' }}>
          <Input
            value={phoneNumber}
            onChange={this.handleChange('phoneNumber')}
            placeholder="Phone number"
            label="Phone number"
            style={{
              paddingBottom: 4,
              marginRight: 20,
            }}
          />
          <Input
            value={restaurantCode}
            onChange={this.handleChange('restaurantCode')}
            placeholder="Restaurant Code"
            label="Restaurant Code"
            style={{
              width: 146,
              paddingBottom: 4,
              marginRight: 20,
            }}
          />
          <TimezoneDropdown
            value={timeZone}
            onChange={this.handleTimeZoneChange}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <ImageUpload
            image={coverImgUrl}
            onChange={this.handleChange('coverImgUrl')}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <OwnerDropdown
            value={owner}
            options={ownerNames}
            onChange={this.handleOwnerChange}
          />
          <Input
            value={restaurantOrganizationNumber}
            placeholder="Organization Number"
            label="Organization Number"
            style={{ width: 240, marginLeft: 20, paddingTop: 8 }}
            disabled={true}
          />
        </div>

        {!deliveryRestaurant && (
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={availableForPickup}
                  onChange={this.handleAvailableForPickupChange}
                  color="primary"
                />
              }
              label="Is Available for Pick-up"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={availableForInfo}
                  onChange={this.handleAvailableForInfoChange}
                  color="primary"
                />
              }
              label="Visible in Store list"
            />
          </div>
        )}

        {!deliveryRestaurant && (
          <div style={{ marginTop: 20 }}>
            <Input
              value={deliveryTimeout}
              onChange={this.handleChange('deliveryTimeout')}
              placeholder="Minimim time"
              label="Minimim time (minutes)"
              margin="normal"
              type="number"
              min={1}
              style={{ width: 250 }}
            />
          </div>
        )}
        <br />
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={deliveryRestaurant}
                onChange={this.handleDeliveryRestaurant}
                color="primary"
              />
            }
            label="Is Delivery Restaurant"
          />
        </div>
        <div>
          <Input
            value={seoTitle}
            onChange={this.handleChange('seoTitle')}
            placeholder="Seo Title"
            label="Seo Title"
            margin="normal"
            style={{ width: 300 }}
          />
        </div>
        <div>
          <Input
            value={seoDescription}
            onChange={this.handleChange('seoDescription')}
            placeholder="seo Description"
            label="Seo Description"
            margin="normal"
            style={{ width: 300 }}
          />
        </div>
        <div>
          <Input
            value={seoCoverImage}
            onChange={this.handleChange('seoCoverImage')}
            placeholder="Seo CoverImage"
            label="Seo CoverImage"
            margin="normal"
            style={{ width: 300 }}
          />
        </div>
        <Section
          heading="Opening hours"
          hint="Clear the time for a row if the restaurant is closed for that day"
        >
          <OpeningHours
            daysData={openingHours}
            onChange={this.handleOpeningHoursChange}
          />
        </Section>
        <Section heading="Special Opening hours">
          <SpecialOpeningHours
            daysData={specialOpeningHours}
            onChange={this.handleSpecialOpeningHoursChange}
          />
        </Section>
        {id && (
          <Section
            heading="Menu items"
            hint="What menu items should be available for this restaurant?"
          >
            <MenuItems restaurantId={id} />
          </Section>
        )}
      </div>
    );
  }
}

RestaurantDetailsPage.propTypes = {
  id: PropTypes.number,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default RestaurantDetailsPage;
