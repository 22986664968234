import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { Link } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import Button from '@material-ui/core/Button';

import styles from './styles';

class TableActionCell extends React.PureComponent {
  renderButton() {
    const { action, icon } = this.props;
    let IconComponent = EditIcon;
    if (icon === 'delete') {
      IconComponent = DeleteIcon;
    }
    if (icon === 'restore') {
      IconComponent = RestoreFromTrashIcon;
    }
    return (
      <Button color="secondary" {...(action ? { onClick: action } : {})}>
        <IconComponent />
      </Button>
    );
  }

  renderCellContent() {
    const { link } = this.props;
    return link ? (
      <Link to={link}>{this.renderButton()}</Link>
    ) : (
      this.renderButton()
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <TableCell className={classes.tableCell} padding="none" align="right">
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {this.renderCellContent()}
        </div>
      </TableCell>
    );
  }
}

TableActionCell.propTypes = {
  icon: PropTypes.oneOf(['edit', 'delete', 'restore']).isRequired,
  action: PropTypes.func,
  link: PropTypes.string,
  classes: PropTypes.object,
};

export default injectSheet(styles)(TableActionCell);
