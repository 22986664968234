import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  CHANGE_ORDER_STATUS_REQUEST,
  CHANGE_ORDER_STATUS_SUCCESS,
  CHANGE_ORDER_STATUS_FAILURE,
  CHANGE_MULTIPLE_ORDERS_STATUS_REQUEST,
  CHANGE_MULTIPLE_ORDERS_STATUS_SUCCESS,
  CHANGE_MULTIPLE_ORDERS_STATUS_FAILURE,
  SEND_MANUAL_OHEICS_ORDER_REQUEST,
  SEND_MANUAL_OHEICS_ORDER_SUCCESS,
  SEND_MANUAL_OHEICS_ORDER_FAILURE,
  GET_ORDER_DETAILS_REQUEST,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  UPDATE_ORDERS_REQUEST,
  UPDATE_ORDERS_SUCCESS,
  UPDATE_ORDERS_FAILURE,
} from 'consts/orders';

export function getOrders({
  sortName,
  sortDirection,
  restaurantId,
  startTime,
  endTime,
  status,
  page,
  pageLimit,
  orderType,
  userId,
  companyId,
  orderId,
  getOrdersByDelivery,
  withCompany,
  withOheicsLogs,
  getLocations,
}) {
  return {
    type: GET_ORDERS_REQUEST,
    sortName,
    sortDirection,
    restaurantId,
    startTime,
    endTime,
    status,
    page,
    pageLimit,
    orderType,
    userId,
    companyId,
    orderId,
    getOrdersByDelivery,
    withCompany,
    withOheicsLogs,
    getLocations,
    [WAIT_FOR_ACTION]: GET_ORDERS_SUCCESS,
    [ERROR_ACTION]: GET_ORDERS_FAILURE,
  };
}

export function updateOrder({ data, orderId }) {
  return {
    type: UPDATE_ORDERS_REQUEST,
    orderId,
    data,
    [WAIT_FOR_ACTION]: UPDATE_ORDERS_SUCCESS,
    [ERROR_ACTION]: UPDATE_ORDERS_FAILURE,
  };
}

export function changeOrderStatus({ id, status }) {
  return {
    type: CHANGE_ORDER_STATUS_REQUEST,
    id,
    status,
    [WAIT_FOR_ACTION]: CHANGE_ORDER_STATUS_SUCCESS,
    [ERROR_ACTION]: CHANGE_ORDER_STATUS_FAILURE,
  };
}

export function changeMultipleOrdersStatus({ ids, statusToChange }) {
  return {
    type: CHANGE_MULTIPLE_ORDERS_STATUS_REQUEST,
    ids,
    statusToChange,
    [WAIT_FOR_ACTION]: CHANGE_MULTIPLE_ORDERS_STATUS_SUCCESS,
    [ERROR_ACTION]: CHANGE_MULTIPLE_ORDERS_STATUS_FAILURE,
  };
}

export function sendManualOheicsOrder(orderId) {
  return {
    type: SEND_MANUAL_OHEICS_ORDER_REQUEST,
    orderId,
    [WAIT_FOR_ACTION]: SEND_MANUAL_OHEICS_ORDER_SUCCESS,
    [ERROR_ACTION]: SEND_MANUAL_OHEICS_ORDER_FAILURE,
  };
}

export function getOrderDetails(id) {
  return {
    type: GET_ORDER_DETAILS_REQUEST,
    id,
    [WAIT_FOR_ACTION]: GET_ORDER_DETAILS_SUCCESS,
    [ERROR_ACTION]: GET_ORDER_DETAILS_FAILURE,
  };
}

export function getOrder({ id, getRestaurant }) {
  return {
    type: GET_ORDER_REQUEST,
    id,
    getRestaurant,
    [WAIT_FOR_ACTION]: GET_ORDER_SUCCESS,
    [ERROR_ACTION]: GET_ORDER_FAILURE,
  };
}
