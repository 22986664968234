import { SHOW_LOADING, HIDE_LOADING } from 'consts/ui';

const initialState = {
  loading: false,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return { ...state, loading: true };
    }

    case HIDE_LOADING: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
};

export default uiReducer;
