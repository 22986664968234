import { put, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  GET_DELIVERY_COSTS_REQUEST,
  GET_DELIVERY_COSTS_SUCCESS,
  SAVE_DELIVERY_COSTS_REQUEST,
  SAVE_DELIVERY_COSTS_SUCCESS,
  GET_DELIVERY_COSTS_BY_ID_REQUEST,
  GET_DELIVERY_COSTS_BY_ID_SUCCESS,
  DELETE_DELIVERY_COSTS_REQUEST,
  DELETE_DELIVERY_COSTS_SUCCESS,
  GET_GENERAL_SETTINGS_REQUEST,
  GET_GENERAL_SETTINGS_SUCCESS,
  SAVE_GENERAL_SETTINGS_REQUEST,
  SAVE_GENERAL_SETTINGS_SUCCESS,
  SAVE_GENERAL_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  SAVE_SETTINGS_REQUEST,
  SAVE_SETTINGS_SUCCESS,
  SAVE_SETTINGS_FAILURE,
  GET_PRODUCTION_LIST_REQUEST,
  GET_PRODUCTION_LIST_SUCCESS,
  GET_PRODUCTION_LIST_FAILURE,
  PRODUCTION_LIST_SORTING_NAME,
  GET_PACK_LIST_REQUEST,
  GET_PACK_LIST_SUCCESS,
  GET_PACK_LIST_FAILURE,
  PACK_LIST_SORTING_NAME,
} from 'consts/settings';

import { ITEM_STATUSES } from 'consts';

import { calculateSort } from 'utils';

import { getMenuItems } from 'sagas/menu-items/menu-items';
import {
  getRestaurantsDafaultDeliveryMinTime,
  saveRestaurantsDefaultDeliveryMinTime,
} from 'sagas/restaurants';

import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';

export const prepareOrderListSortingSettings = (
  settingsResponse = {},
  menuItemsResponse
) => {
  const isNew = Object.keys(settingsResponse).length === 0;
  if (!(settingsResponse && settingsResponse.value)) {
    return {
      ...menuItemsResponse,
      items: menuItemsResponse.items.map((item) => ({
        id: item.id,
        categoryName: item.categoryName,
        name: item.name,
        visible: false,
      })),
      isNew,
    };
  }
  let items = [];
  if (isNew) {
    items = menuItemsResponse.items.map((item) => ({
      id: item.id,
      categoryName: item.categoryName,
      name: item.name,
      visible: false,
    }));
  } else {
    const settings = JSON.parse(settingsResponse.value);
    const settingsDict =
      settings && settings.length > 0
        ? settings.reduce((acc, item) => {
            acc[item.menuItemId] = item;
            return acc;
          }, {})
        : [];
    const allItems = menuItemsResponse.items.map((item) => ({
      id: item.id,
      categoryName: item.categoryName,
      name: item.name,
      visible: settingsDict[item.id] ? settingsDict[item.id].visible : false,
      index: settingsDict[item.id] ? settingsDict[item.id].index : undefined,
    }));
    const newAtems = allItems.filter((item) => typeof item.index !== 'number');
    const oldItems = allItems
      .filter((item) => typeof item.index === 'number')
      .sort((a, b) => a.index - b.index);

    items = [...oldItems, ...newAtems];
  }

  return {
    ...menuItemsResponse,
    items,
    isNew,
  };
};

export function* getDeliveryCosts({ sortDirection, sortName }) {
  let url = `/delivery-costs?${calculateSort(sortDirection, sortName)}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: GET_DELIVERY_COSTS_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    //todo
  }
}

function* getDeliveryCostsSaga() {
  yield takeFirst(GET_DELIVERY_COSTS_REQUEST, getDeliveryCosts);
}

export function* getDeliveryCostById({ id }) {
  let url = `/delivery-costs/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: GET_DELIVERY_COSTS_BY_ID_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    //todo
  }
}

function* getDeliveryCostByIdSaga() {
  yield takeFirst(GET_DELIVERY_COSTS_BY_ID_REQUEST, getDeliveryCostById);
}

function* saveDeliveryCost({
  id,
  zoneName,
  shippingPrice,
  tax,
  zipCodes,
  minimumTime,
}) {
  let url = id ? `/delivery-costs/${id}` : '/delivery-costs';
  const options = {
    method: id ? 'PUT' : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      zoneName,
      shippingPrice,
      tax,
      zipCodes,
      minimumTime: parseInt(minimumTime, 10),
    }),
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SAVE_DELIVERY_COSTS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* saveDeliveryCostSaga() {
  yield takeFirst(SAVE_DELIVERY_COSTS_REQUEST, saveDeliveryCost);
}

function* deleteDeliveryCost({ id }) {
  const url = `/delivery-costs/${id}`;
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: DELETE_DELIVERY_COSTS_SUCCESS,
      payload,
    };
    yield put(newAction);
    yield put(push('/settings'));
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* deleteDeliveryCostSaga() {
  yield takeFirst(DELETE_DELIVERY_COSTS_REQUEST, deleteDeliveryCost);
}

/**
 * Retrieve settings by name.
 * @param {Object} { name, subRequest } - setting dictionaries name, bool value to indicate if it is sub request
 * @return {Object} Response for request.
 */
export function* getSettings({
  name,
  subRequest = false,
  withReplace = false,
} = {}) {
  const url = `/setting-dictionaries/${name}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const response = yield apiRequest(url, options);
    const newAction = {
      type: GET_SETTINGS_SUCCESS,
      payload:
        response && response.value
          ? withReplace
            ? JSON.parse(response.value.replace(/\\n/g, '<br />'))
            : JSON.parse(response.value)
          : undefined,
    };
    yield put(newAction);
    return response;
  } catch (error) {
    if (
      error.errorCode === 0 &&
      error.message === 'Resource not found(settingDictionaries)!'
    ) {
      if (!subRequest) {
        yield put({
          type: GET_SETTINGS_SUCCESS,
          payload: {},
        });
      } else {
        return {};
      }
    } else {
      showErrorOnErrorResponse(error);
    }
  }
}

export function* getSettingsSaga() {
  yield takeFirst(GET_SETTINGS_REQUEST, getSettings);
}

export function* getGeneralSettings({ name } = {}) {
  try {
    const settingsResponse = yield getSettings({ name });
    const settings =
      settingsResponse && settingsResponse.value
        ? JSON.parse(settingsResponse.value)
        : undefined;
    const restaurantsDeliveryTimeout = yield getRestaurantsDafaultDeliveryMinTime();
    const payload = {
      settings,
      restaurantsDeliveryTimeout,
    };
    yield put({
      type: GET_GENERAL_SETTINGS_SUCCESS,
      payload,
    });
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getGeneralSettingsSaga() {
  yield takeFirst(GET_GENERAL_SETTINGS_REQUEST, getGeneralSettings);
}

/**
 * Update settings data by name if isNew parameter is false else create new settings.
 * @param {Object} { name, value, subRequest } - setting dictionaries name, value to update or create, bool value to indicate if it is a new settings
 * @return {Object} Response for request.
 */
export function* saveSettings({ name, value, isNew } = {}) {
  let url = isNew ? '/setting-dictionaries' : `/setting-dictionaries/${name}`;
  const body = { value };
  if (isNew) {
    body.name = name;
  }
  const options = {
    method: isNew ? 'POST' : 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SAVE_SETTINGS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    yield put({ type: SAVE_SETTINGS_FAILURE, error });
    showErrorOnErrorResponse(error);
  }
}

export function* saveSettingsSaga() {
  yield takeFirst(SAVE_SETTINGS_REQUEST, saveSettings);
}

/**
 * Retrieve menu items ordering for produnction list.
 * @return {Object} Response for request.
 */
export function* getProductionSortingList() {
  try {
    const [productionListSettings, menuItems] = yield all([
      getSettings({ name: PRODUCTION_LIST_SORTING_NAME }),
      getMenuItems({
        sortName: 'name',
        sortDirection: 'asc',
        getAllItems: true,
        status: ITEM_STATUSES.active,
      }),
    ]);
    const payload = prepareOrderListSortingSettings(
      productionListSettings,
      menuItems
    );
    const newAction = {
      type: GET_PRODUCTION_LIST_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    yield put({ type: GET_PRODUCTION_LIST_FAILURE, error });
    showErrorOnErrorResponse(error);
  }
}

export function* getProductionSortingListSaga() {
  yield takeFirst(GET_PRODUCTION_LIST_REQUEST, getProductionSortingList);
}

/**
 * Retrieve menu items ordering for pack list.
 * @return {Object} Response for request.
 */
export function* getPackSortingList() {
  try {
    const [packListSettings, menuItems] = yield all([
      getSettings({ name: PACK_LIST_SORTING_NAME }),
      getMenuItems({
        sortName: 'name',
        sortDirection: 'asc',
        getAllItems: true,
        status: ITEM_STATUSES.active,
      }),
    ]);
    const payload = prepareOrderListSortingSettings(
      packListSettings,
      menuItems
    );
    const newAction = {
      type: GET_PACK_LIST_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    yield put({ type: GET_PACK_LIST_FAILURE, error });
    showErrorOnErrorResponse(error);
  }
}

export function* getPackSortingListSaga() {
  yield takeFirst(GET_PACK_LIST_REQUEST, getPackSortingList);
}

export function* saveGeneralSettings({
  name,
  value,
  isNew,
  minTime,
  minTimeAttrId,
} = {}) {
  try {
    const settingsResponse = yield saveSettings({ name, value, isNew });
    const minTimeResponse = yield saveRestaurantsDefaultDeliveryMinTime({
      id: minTimeAttrId,
      value: minTime,
    });
    const newAction = {
      type: SAVE_GENERAL_SETTINGS_SUCCESS,
      payload: {
        settings: settingsResponse,
        minTime: minTimeResponse,
      },
    };
    yield put(newAction);
  } catch (error) {
    yield put({ type: SAVE_GENERAL_SETTINGS_FAILURE, error });
    showErrorOnErrorResponse(error);
  }
}

export function* saveGeneralSettingsSaga() {
  yield takeFirst(SAVE_GENERAL_SETTINGS_REQUEST, saveGeneralSettings);
}

export default combine([
  getDeliveryCostsSaga,
  saveDeliveryCostSaga,
  deleteDeliveryCostSaga,
  getDeliveryCostByIdSaga,
  getGeneralSettingsSaga,
  getSettingsSaga,
  saveSettingsSaga,
  saveGeneralSettingsSaga,
  getProductionSortingListSaga,
  getPackSortingListSaga,
]);
