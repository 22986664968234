const styles = {
  headItemsWrapper: {
    display: 'flex',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  headItem: {
    padding: '20px 24px',
    fontSize: '0.75rem',
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  orderCell: {
    width: 32,
  },
  cell: {
    padding: '5px 24px',
  },
  menuItemsList: {
    listStyle: 'none',
  },
  menuItemsListItem: {
    padding: 0,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    transition: 'background-color .3s',
  },
  menuItemsListItemWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItemsListItemHover: {
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
  },
  nameCell: {
    flex: 1,
  },
  linkCell: {
    flex: 1,
  },
  input: {
    maxWidth: 300,
    width: '100%',
  },
  btnWrapper: {
    padding: '5px 24px',
  },
  deleteCell: {
    width: 96,
  },
  editCell: {
    width: 72,
    paddingRight: 0,
  },
  description: {
    paddingLeft: 72,
    marginBottom: 10,
    color: '#838383',
  },
};

export default styles;
