export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

export const GET_CATEGORY_BY_ID_REQUEST = 'GET_CATEGORY_BY_ID_REQUEST';
export const GET_CATEGORY_BY_ID_SUCCESS = 'GET_CATEGORY_BY_ID_SUCCESS';
export const GET_CATEGORY_BY_ID_FAILURE = 'GET_CATEGORY_BY_ID_FAILURE';

export const SAVE_CATEGORY_REQUEST = 'SAVE_CATEGORY_REQUEST';
export const SAVE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS';
export const SAVE_CATEGORY_FAILURE = 'SAVE_CATEGORY_FAILURE';

export const GET_CATEGORIES_BY_PARENTID_REQUEST =
  'GET_CATEGORIES_BY_PARENTID_REQUEST';
export const GET_CATEGORIES_BY_PARENTID_SUCCESS =
  'GET_CATEGORIES_BY_PARENTID_SUCCESS';
export const GET_CATEGORIES_BY_PARENTID_FAILURE =
  'GET_CATEGORIES_BY_PARENTID_FAILURE';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const GET_SUBCATEGORY_CANDIDATS_REQUEST =
  'GET_SUBCATEGORY_CANDIDATS_REQUEST';
export const GET_SUBCATEGORY_CANDIDATS_SUCCESS =
  'GET_SUBCATEGORY_CANDIDATS_SUCCESS';
export const GET_SUBCATEGORY_CANDIDATS_FAILURE =
  'GET_SUBCATEGORY_CANDIDATS_FAILURE';

export const ADD_SUBCATEGORY_REQUEST = 'ADD_SUBCATEGORY_REQUEST';
export const ADD_SUBCATEGORY_SUCCESS = 'ADD_SUBCATEGORY_SUCCESS';
export const ADD_SUBCATEGORY_FAILURE = 'ADD_SUBCATEGORY_FAILURE';

export const REMOVE_SUBCATEGORY_REQUEST = 'REMOVE_SUBCATEGORY_REQUEST';
export const REMOVE_SUBCATEGORY_SUCCESS = 'REMOVE_SUBCATEGORY_SUCCESS';
export const REMOVE_SUBCATEGORY_FAILURE = 'REMOVE_SUBCATEGORY_FAILURE';
