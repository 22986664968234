import React from 'react';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';

import { DateTimePicker } from 'material-ui-pickers';

import { MAX_SELECTED_DATE } from 'consts';

class CustomDateTimePicker extends React.PureComponent {
  render() {
    return (
      <DateTimePicker
        ampm={false}
        maxDate={parseISO(MAX_SELECTED_DATE)}
        {...this.props}
      />
    );
  }
}

CustomDateTimePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
};

export default CustomDateTimePicker;
