import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import Button from '@material-ui/core/Button';

import styles from '../ToolbarButtonsBar/styles';

class ToolbarHistoryBar extends PureComponent {
  handleClick = ({ currentTarget: { value } }) => {
    this.props.onChange(value);
  };

  render() {
    const {
      config,
      currentState: { undoDisabled, redoDisabled },
      classes,
      className,
    } = this.props;

    return (
      <div>
        {config.options.map((type) => {
          const Icon = config[type].icon;

          return (
            <Button
              className={className}
              key={type}
              aria-label={type}
              value={type}
              onClick={this.handleClick}
              disabled={
                (type === 'undo' && undoDisabled) ||
                (type === 'redo' && redoDisabled)
              }
              classes={{ root: classes.toolbarButon }}
            >
              {typeof Icon === 'function' ? (
                <Icon />
              ) : (
                <img src={Icon} alt={type} />
              )}
            </Button>
          );
        })}
      </div>
    );
  }
}

ToolbarHistoryBar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.object,
  config: PropTypes.object,
  currentState: PropTypes.object,
  onChange: PropTypes.func,
};

export default compose(injectSheet(styles))(ToolbarHistoryBar);
