import { put, all, throttle } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_BY_ID_REQUEST,
  GET_CUSTOMER_BY_ID_SUCCESS,
  SAVE_CUSTOMER_REQUEST,
  SAVE_CUSTOMER_SUCCESS,
  SAVE_CUSTOMER_FAILURE,
  CHANGE_CUSTOMER_STATUS_REQUEST,
  CHANGE_CUSTOMER_STATUS_SUCCESS,
  GET_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
  GET_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
  GET_CUSTOMER_DELIVERY_REQUEST,
  GET_CUSTOMER_DELIVERY_SUCCESS,
  CHANGE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
  CHANGE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
  SAVE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
  SAVE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
  SAVE_CUSTOMER_DELIVERY_ADDRESSES_FAILURE,
  CLEAR_USER_REQUEST,
  CLEAR_USER_SUCCESS,
  SEARCH_CUSTOMER_REQUEST,
} from 'consts/customers';
import {
  calculatePage,
  calculateSort,
  updateOffsetParam,
  setPaginationParams,
  prepareURLRequest,
} from 'utils';

import { takeFirst, combine } from 'sagas/utils/effects';
import {
  getLocationsRequest,
  changeLocation,
  saveLocation,
} from 'sagas/locations';
import {
  getUserDiscount,
  getUserCompliments,
  saveUserDiscount,
  saveUserCompliment,
  deleteUserDiscount,
  saveUserPunchCards,
} from 'sagas/loyalties';
import apiRequest from 'sagas/utils/apiRequest';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';

/**
 * Retrieve customers list.
 * @param {Object} { sortName, sortDirection, ids, status, email, phone, page, pageLimit, nonAnonymous, getAllItems, isAction } - restaurant id.
 * @return {Object} Response for request.
 */
export function* getCustomers({
  sortName,
  sortDirection,
  ids,
  status,
  email,
  phone,
  firstName,
  lastName,
  page,
  pageLimit,
  nonAnonymous = true,
  getAllItems = false,
  isAction = true,
}) {
  const params = [];
  if (ids && ids.length) params.push(`id=${ids.join(',')}`);
  if (pageLimit) params.push(`${calculatePage(page, pageLimit)}`);
  if (status) params.push(`status=${status}`);
  if (email) params.push(`email=(like%7C${email})`);
  if (phone) params.push(`phone=(like%7C${phone.replace(/\+/g, '')})`);
  if (lastName) params.push(`lastName=(like%7C${lastName})`);
  if (firstName) params.push(`firstName=(like%7C${firstName})`);
  // PNCM-65 Display only non anonymous customers
  if (nonAnonymous) params.push('nonAnonymous=true');
  params.push(calculateSort(sortDirection, sortName));
  const base = '/users';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const rawPayload = yield apiRequest(
      prepareURLRequest(base, params),
      options
    );
    if (getAllItems) {
      let pageOptions = {
        offset: rawPayload.offset,
        limit: rawPayload.limit,
        total: rawPayload.totalResults,
      };
      pageOptions = updateOffsetParam(pageOptions);
      while (pageOptions.offset < pageOptions.total) {
        const newPageParams = setPaginationParams(pageOptions);
        const addPayload = yield apiRequest(
          prepareURLRequest(base, params.concat(newPageParams)),
          options
        );
        rawPayload.items = rawPayload.items.concat(addPayload.items);
        pageOptions = updateOffsetParam(pageOptions);
      }
    }
    const newAction = {
      type: GET_CUSTOMERS_SUCCESS,
      payload: rawPayload,
    };
    if (isAction) {
      yield put(newAction);
    }
    return rawPayload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getCustomersSaga() {
  yield takeFirst(GET_CUSTOMERS_REQUEST, getCustomers);
}

/**
 * Retrieve customer info by user id.
 * @param {Object} { id } - user id.
 * @return {Object} Response for requests.
 */
export function* getCustomerById({ id, withLoyalty = true }) {
  const url = `/users/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const rawPayload = yield apiRequest(url, options);
    const userDiscount = withLoyalty ? yield getUserDiscount(id) : {};
    const userCompliments = withLoyalty ? yield getUserCompliments(id) : {};
    const { discount, discountDate, discountId } = userDiscount;
    const { compliment, complimentId } = userCompliments;
    const payload = {
      ...rawPayload,
      discount,
      discountDate,
      compliment,
      discountId,
      complimentId,
    };
    const newAction = {
      type: GET_CUSTOMER_BY_ID_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getCustomerByIdSaga() {
  yield takeFirst(GET_CUSTOMER_BY_ID_REQUEST, getCustomerById);
}

/**
 * Update customer info if calling with id parameter else create new user.
 * @param {Object} - customer info.
 * @return {Object} Response for request.
 */
export function* saveCustomer({
  id,
  firstName,
  lastName,
  email,
  phone,
  status,
  discount,
  discountId,
  discountDate,
  compliment,
  complimentId,
  punchCardsToChange,
} = {}) {
  const url = id ? `/users/${id}` : '/users';
  const options = {
    method: id ? 'PUT' : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      phone,
      status,
    }),
  };
  try {
    if (punchCardsToChange && punchCardsToChange.length) {
      yield all(
        punchCardsToChange.map((punchCard) =>
          saveUserPunchCards({
            userPunchCardId: punchCard.id,
            stampCardAmount: punchCard.stampCardAmount,
          })
        )
      );
    }
    const payload = yield apiRequest(url, options);
    const discountNumber = parseInt(discount) || 0;
    let newDiscount = 0;
    let newDiscountId;
    if (discountNumber >= 0) {
      // Save or update discount
      const discountName = `${
        payload.firstName
          ? `${payload.firstName.toUpperCase()}-${payload.lastName.toUpperCase()}`
          : payload.id
      }-DISCOUNT`;
      let response = yield saveUserDiscount({
        userId: payload.id,
        discountId,
        discountDate,
        discount: parseFloat((discountNumber / 100).toFixed(2)),
        name: discountName,
      });
      if (response) {
        newDiscount = response.discount;
        newDiscountId = response.discountId;
      }
    } else if (discountId) {
      // delete discount in other case
      yield deleteUserDiscount({ userId: payload.id, discountId });
    }
    const complimentNumber = parseInt(compliment) || 0;
    const complimentPayload = yield saveUserCompliment({
      userId: payload.id,
      compliment: parseFloat((complimentNumber / 100).toFixed(2)),
      complimentId,
    });
    const newAction = {
      type: SAVE_CUSTOMER_SUCCESS,
      payload: {
        ...payload,
        discount: newDiscount,
        discountId: newDiscountId,
        compliment: complimentPayload.compliment,
        complimentId: complimentPayload.complimentId,
      },
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
    yield put({ type: SAVE_CUSTOMER_FAILURE, error });
  }
}

export function* saveCustomerSaga() {
  yield takeFirst(SAVE_CUSTOMER_REQUEST, saveCustomer);
}

/**
 * Change customer status
 * @param {Object} { id, status } - user id, status to change.
 * @return {Object} Response for request.
 */
export function* changeCustomerStatus({ id, status }) {
  const url =
    status === 'blocked'
      ? `/users/${id}/status/blocked`
      : `/users/${id}/status/active`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: CHANGE_CUSTOMER_STATUS_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* changeCustomerStatusSaga() {
  yield takeFirst(CHANGE_CUSTOMER_STATUS_REQUEST, changeCustomerStatus);
}

/**
 * Retrieve customer delivery addresses by user id.
 * @param {Object} { userId } - user id.
 * @return {Object} Response for requests.
 */
export function* getCustomerDeliveryAddresses({ userId }) {
  const userIdParam = userId ? `userId=${userId}` : '';
  const url = `/users-for-delivery?${userIdParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const deliveryAddreses = yield apiRequest(url, options);
    const items = yield prepareDeliveryAddresses(deliveryAddreses.items);
    const newAction = {
      type: GET_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
      payload: {
        ...deliveryAddreses,
        items,
      },
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

/**
 * Prepare delivery adresses with location info.
 * @param {Array} deliveryAddreses - array of delivery addresses.
 * @return {Array} Array of delivery addresses with location info.
 */
export function* prepareDeliveryAddresses(deliveryAddreses = []) {
  const idsArr = deliveryAddreses.map((address) => address.locationId);
  const locations = yield getLocationsRequest({ ids: idsArr });
  const locationDict = locations.items.reduce((result, cur) => {
    result[cur.id] = cur;
    return result;
  }, {});
  return deliveryAddreses.map((address) => ({
    ...address,
    location: locationDict[address.locationId],
  }));
}

export function* getCustomerDeliveryAddressesSaga() {
  yield takeFirst(
    GET_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
    getCustomerDeliveryAddresses
  );
}

/**
 * Retrieve customer delivery address by delivery id.
 * @param {Object} { deliveryId } - id of delivery address.
 * @return {Object} Response for requests.
 */
export function* getCustomerDelivery({ deliveryId }) {
  const url = `/users-for-delivery/${deliveryId}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const deliveryAddreses = yield apiRequest(url, options);
    const [payload] = yield prepareDeliveryAddresses([deliveryAddreses]);
    const newAction = {
      type: GET_CUSTOMER_DELIVERY_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getCustomerDeliverySaga() {
  yield takeFirst(GET_CUSTOMER_DELIVERY_REQUEST, getCustomerDelivery);
}

/**
 * Change customer delivery address by delivery id.
 * @param {Object} - delivery info.
 * @return {Object} Response for requests.
 */
export function* changeCustomerDeliveryAddress({
  id,
  userId,
  name,
  address,
  postcode,
  phone,
  notice,
  locationId,
  city,
  latitude,
  longitude,
}) {
  const url = `/users-for-delivery/${id}`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      name,
      phone,
      notice,
      locationId,
    }),
  };
  try {
    const payload = yield apiRequest(url, options);
    yield changeLocation({
      id: locationId,
      address,
      postcode,
      city,
      latitude,
      longitude,
    });
    const newAction = {
      type: CHANGE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* changeCustomerDeliveryAddressSaga() {
  yield takeFirst(
    CHANGE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
    changeCustomerDeliveryAddress
  );
}

function* getDeliveryCostsByZipCode(zipCode) {
  const url = `/delivery-costs/by-zipcode/${zipCode.replace(/\s/g, '')}`;
  try {
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const payload = yield apiRequest(url, options);
    return payload;
  } catch (error) {
    if (error.errorCode === 1201) {
      return undefined;
    }
  }
}

export function* saveCustomerDeliveryAddress({
  userId,
  name,
  address,
  postcode,
  phone,
  notice,
  city,
  latitude,
  longitude,
}) {
  const url = '/users-for-delivery';
  try {
    // Checking if such delivery cost for zipcode exists
    const deliveryCost = yield getDeliveryCostsByZipCode(postcode);
    if (!deliveryCost) {
      const error = 'Can not deliver to this zip code.';
      yield put({
        type: SAVE_CUSTOMER_DELIVERY_ADDRESSES_FAILURE,
        error,
      });
      showErrorOnErrorResponse({
        message: error,
      });
      return;
    }
    const location = yield saveLocation({
      streetAddress: address,
      postcode,
      city,
      latitude,
      longitude,
    });
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userId,
        name,
        phone,
        notice,
        locationId: location.id,
      }),
    };
    const delivery = yield apiRequest(url, options);
    const payload = {
      ...delivery,
      location,
      deliveryCost,
    };
    const newAction = {
      type: SAVE_CUSTOMER_DELIVERY_ADDRESSES_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* saveCustomerDeliveryAddressSaga() {
  yield takeFirst(
    SAVE_CUSTOMER_DELIVERY_ADDRESSES_REQUEST,
    saveCustomerDeliveryAddress
  );
}

/**
 * Clear customer info by user id.
 * @param {Object} { id } - user id.
 * @return {Object} Response for requests.
 */
export function* clearCustomer({ id }) {
  const url = `/users/${id}`;
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: CLEAR_USER_SUCCESS,
      payload,
    };
    yield put(newAction);
    yield put(push('/customers'));
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* clearCustomerSaga() {
  yield takeFirst(CLEAR_USER_REQUEST, clearCustomer);
}

function* searchCustomersSaga() {
  yield throttle(500, SEARCH_CUSTOMER_REQUEST, getCustomers);
}

export default combine([
  getCustomersSaga,
  getCustomerByIdSaga,
  saveCustomerSaga,
  changeCustomerStatusSaga,
  getCustomerDeliveryAddressesSaga,
  getCustomerDeliverySaga,
  changeCustomerDeliveryAddressSaga,
  saveCustomerDeliveryAddressSaga,
  clearCustomerSaga,
  searchCustomersSaga,
]);
