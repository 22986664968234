import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { DateTimePicker } from 'material-ui-pickers';
import { parseISO } from 'date-fns';

import { toastr } from 'react-redux-toastr';

import PageHeading from 'components/PageHeading';
import PageHeadingTitle from 'components/PageHeadingTitle';
import Section from 'components/Section';
import OrderHistory from 'components/OrderHistory';

import {
  validateEmail,
  validatePhoneNumber,
  normalizePhoneNumber,
} from 'utils/processors';
import { addYearsToDays, convertCurrentTimezoneToUTC } from 'utils/date';

import { MAX_SELECTED_DATE, SHORT_DATE_FORMAT } from 'consts';

import StampCards from './StampCards';
import DeliveryAddresses from './DeliveryAddresses';
import KickbackHistory from './KickbackHistory';
import PointsStoreCredits from './PointsStoreCredits';
import CompaniesList from './CompaniesList';

import { confirmAlert } from 'utils';

const stateFromProps = ({ initialData = {} }) => ({
  name: `${initialData.firstName || ''} ${initialData.lastName || ''}`.trim(),
  email: initialData.email || '',
  phone: initialData.phone || '',
  status: initialData.status || '',
  discount: initialData.discount || 0,
  discountDate:
    initialData.discountDate ||
    addYearsToDays({ date: convertCurrentTimezoneToUTC(new Date()), years: 1 }),
  compliment: parseInt(initialData.compliment, 10) || 0,
  punchCardsToChange: [],
});

const styles = () => ({
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formBlock: {
    flex: 1,
    '&:nth-child(1)': {
      marginRight: 30,
    },
  },
  discountWrapper: {
    marginTop: 16,
  },
  discountLabel: {
    fontSize: '0.9rem',
    display: 'block',
  },
  discountDateWrapper: {
    display: 'inline-block',
    paddingTop: 20,
    marginLeft: 16,
  },
  datePicker: {
    marginLeft: 12,
    marginTop: '-3px',
  },
});

class CustomersDetailsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  handleStatusChange = (event) => {
    const { id, onChangeCustomerStatus } = this.props;
    const newStatus = event.target.checked ? 'blocked' : 'active';
    onChangeCustomerStatus({ id, status: newStatus });
    this.setState({ status: newStatus });
  };

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleChangeStampCardAmount = (punchCardToChange) => {
    const { punchCardsToChange } = this.state;
    this.setState({
      punchCardsToChange: [
        ...punchCardsToChange.filter(
          (item) => item.id !== punchCardToChange.id
        ),
        { ...punchCardToChange },
      ],
    });
  };

  handleClearUser = () => {
    const { id, onClearCustomer } = this.props;
    confirmAlert({
      okCb: () =>
        onClearCustomer(id)
          .then(() => {
            toastr.success('Success!', 'Customer has been cleared');
          })
          .catch(() => {
            toastr.error('Error!', 'Something went wrong');
          }),
    });
  };

  handleSubmit = () => {
    const { id, initialData = {}, onSubmit } = this.props;
    const { ...values } = this.state;
    const data = { ...initialData, ...values };
    const validateError = this.validateError(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      const [firstName, lastName = ''] = data.name.split(' ');
      onSubmit({
        ...data,
        id,
        firstName,
        lastName,
        name: undefined,
        phone: data.phone ? normalizePhoneNumber(data.phone) : '',
      })
        .then(() => {
          toastr.success(
            'Success!',
            id ? 'Customer has been updated' : 'New Customer has been created'
          );
          this.setState({ punchCardsToChange: [] });
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
          this.setState({ punchCardsToChange: [] });
        });
    }
  };

  handleDateChange = (date) => {
    this.setState({ discountDate: date });
  };

  validateError = (data) => {
    if (data.email.length && !validateEmail(data.email)) {
      return 'Valid email is requried';
    }
    if (data.phone.length && !validatePhoneNumber(data.phone)) {
      return 'Valid phone is requried';
    }
    if (data.discount < 0 || isNaN(parseFloat(data.discount))) {
      return 'Fixed Discount should be greater than or equal 0';
    }
    if (data.compliment < 0 || isNaN(parseFloat(data.compliment))) {
      return 'Discount on next order should be greater than or equal 0';
    }
    if (
      new Date(data.discountDate).getTime() >
      new Date(MAX_SELECTED_DATE).getTime()
    ) {
      return 'Discount date is not valid.';
    }
  };

  render() {
    const { id, classes } = this.props;
    const {
      name,
      email,
      phone,
      status,
      discount,
      discountDate,
      compliment,
    } = this.state;
    return (
      <div>
        <PageHeading
          title={
            id ? (
              <PageHeadingTitle
                text="Customer"
                highlightedText={name || 'No name'}
              />
            ) : (
              'New Customer'
            )
          }
          right={
            <div>
              <Button
                id="gdpr-cleaning-button"
                onClick={this.handleClearUser}
                variant="contained"
                color="primary"
                size="large"
                style={{ marginRight: 24 }}
              >
                GDPR clearing
              </Button>
              <Button
                id="save-button"
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                size="large"
              >
                Save
              </Button>
            </div>
          }
        />
        <div className={classes.formWrapper}>
          <div className={classes.formBlock}>
            <div>
              <Input
                value={name}
                onChange={this.handleChange('name')}
                placeholder="Name"
                label="Name"
                margin="normal"
                style={{ width: '100%' }}
              />
            </div>
            <div>
              <Input
                value={email}
                type="email"
                onChange={this.handleChange('email')}
                placeholder="Email"
                label="Email"
                margin="normal"
                style={{ width: '100%' }}
              />
            </div>
            <div>
              <Input
                value={phone}
                type="tel"
                onChange={this.handleChange('phone')}
                placeholder="Phone"
                label="Phone"
                margin="normal"
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div className={classes.formBlock}>
            <FormControlLabel
              label="Blocked user"
              control={
                <React.Fragment>
                  <Switch
                    checked={status !== 'active'}
                    onChange={this.handleStatusChange}
                    color="primary"
                  />
                </React.Fragment>
              }
            />
            <div>This will block the user from logging in to account</div>
            <div className={classes.discountWrapper}>
              <InputLabel
                htmlFor="user-compliment"
                className={classes.discountLabel}
              >
                Discount on next order (in % effects the whole order but not
                delivery cost)
              </InputLabel>
              <Input
                id="user-compliment"
                value={compliment}
                type="number"
                onChange={this.handleChange('compliment')}
                placeholder="Discount on next order"
                margin="normal"
                style={{ width: '70px' }}
              />
            </div>
            <div className={classes.discountWrapper}>
              <InputLabel
                htmlFor="user-discount"
                className={classes.discountLabel}
              >
                Fixed discount (in % effects the whole order but not delivery
                cost)
              </InputLabel>
              <Input
                id="user-discount"
                value={discount}
                type="number"
                onChange={this.handleChange('discount')}
                placeholder="Fixed discount"
                margin="normal"
                style={{ width: '70px' }}
              />
              <div className={classes.discountDateWrapper}>
                Valid until:{' '}
                <DateTimePicker
                  className={classes.datePicker}
                  value={discountDate}
                  onChange={this.handleDateChange}
                  format={SHORT_DATE_FORMAT}
                  maxDate={parseISO(MAX_SELECTED_DATE)}
                />
              </div>
            </div>
          </div>
        </div>
        <Section heading="Delivery addresses">
          <DeliveryAddresses userId={id} />
        </Section>
        {id && (
          <Section heading="Companies">
            <CompaniesList userId={id} />
          </Section>
        )}
        <Section heading="Stamp cards">
          <StampCards userId={id} onChange={this.handleChangeStampCardAmount} />
        </Section>
        <Section heading="Points & Store credit">
          <PointsStoreCredits userId={id} />
        </Section>
        <Section heading="Kickback history">
          <KickbackHistory userId={id} />
        </Section>
        <Section heading="Order History">
          <OrderHistory userId={id} />
        </Section>
      </div>
    );
  }
}

CustomersDetailsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onChangeCustomerStatus: PropTypes.func.isRequired,
  onClearCustomer: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomersDetailsPage);
