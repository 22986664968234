import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import Input from '@material-ui/core/TextField';

import PageHeading from 'components/PageHeading';
import TableSortCell from 'components/TableSortCell';
import TableCount from 'components/TableCount';
import TableActionCell from 'components/TableActionCell';
import SmallButton from 'components/SmallButton';
import NoData from 'components/NoData';
import CustomerName from 'components/CustomerName';
import StatusDropdown from 'components/StatusDropdown';

import { FIELDS_TO_SEARCH_CUSTOMERS, STATUSES } from 'consts/customers';
import Select from 'components/Select';

class CustomersListPage extends React.PureComponent {
  state = {
    status: this.props.status || null,
    search: this.props.search || '',
    searchParam: this.props.searchParam || FIELDS_TO_SEARCH_CUSTOMERS[0],
  };

  onStatusChange = (status) => {
    this.setState({ status });
  };

  onSearchParamChange = (searchParam) => {
    this.setState({ searchParam });
  };

  onSearchChange = ({ target: { value } }) => {
    this.setState({ search: value });
  };

  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handlePage = (event, page) => {
    return this.props.onPage({ page });
  };

  handleSearch = () => {
    return this.props.onSearch(this.state);
  };

  render() {
    const { items, totalResults } = this.props.initialData;
    const { sortName, sortDirection, pageLimit, page } = this.props;
    const { status, search, searchParam } = this.state;

    return (
      <React.Fragment>
        <PageHeading title="Customers" />
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div style={{ display: 'inline-block', marginRight: 20 }}>
            <Select
              options={FIELDS_TO_SEARCH_CUSTOMERS}
              placeholder="Field to search"
              value={searchParam}
              onChange={this.onSearchParamChange}
              label="Field to search"
            />
          </div>
          <Input
            type="text"
            value={search}
            onChange={this.onSearchChange}
            placeholder=""
            label="Search"
            style={{ paddingBottom: 4, marginRight: 20 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <StatusDropdown
            value={status}
            onChange={this.onStatusChange}
            options={STATUSES}
          />
          <SmallButton onClick={this.handleSearch} leftSpace>
            Search
          </SmallButton>
        </div>
        <TableCount count={totalResults} text="items in current selection" />
        <Paper style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="ID"
                  sortName="id"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Name"
                  sortName="firstName"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Email"
                  sortName="email"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Phone number"
                  sortName="phone"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Status"
                  sortName="status"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    <CustomerName item={item} />
                  </TableCell>
                  <TableCell>
                    {item.email || <NoData>No email</NoData>}
                  </TableCell>
                  <TableCell>
                    {item.phone || <NoData>No phone number</NoData>}
                  </TableCell>
                  <TableCell>
                    <span style={{ textTransform: 'capitalize' }}>
                      {item.status}
                    </span>
                  </TableCell>
                  <TableActionCell
                    link={`/customer-details/${item.id}`}
                    icon="edit"
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageLimit]}
            component="div"
            count={totalResults}
            rowsPerPage={pageLimit}
            page={page}
            onChangePage={this.handlePage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

CustomersListPage.propTypes = {
  classes: PropTypes.object,
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onPage: PropTypes.func.isRequired,
  status: PropTypes.string,
  search: PropTypes.string,
  searchParam: PropTypes.object,
  onSearch: PropTypes.func.isRequired,
};

export default CustomersListPage;
