import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_REQUEST,
  GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_SUCCESS,
  GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_FAILURE,
  GET_PAGINATER_ORDERS_FOR_PACK_LIST_REQUEST,
  GET_PAGINATER_ORDERS_FOR_PACK_LIST_SUCCESS,
  GET_PAGINATER_ORDERS_FOR_PACK_LIST_FAILURE,
  GET_PRODUCTION_ORDERS_REQUEST,
  GET_PRODUCTION_ORDERS_SUCCESS,
  GET_PRODUCTION_ORDERS_FAILURE,
  GET_PACK_ORDERS_REQUEST,
  GET_PACK_ORDERS_SUCCESS,
  GET_PACK_ORDERS_FAILURE,
  GET_ORDERS_COUNT_FOR_PRINT_REQUEST,
  GET_ORDERS_COUNT_FOR_PRINT_SUCCESS,
  GET_ORDERS_COUNT_FOR_PRINT_FAILURE,
} from 'consts/printList';

export function getOrdersCountForPrint({
  restaurantId,
  startTime,
  endTime,
  status,
  orderType,
  userId,
  orderId,
  getOrdersByDelivery,
  getAll,
}) {
  return {
    type: GET_ORDERS_COUNT_FOR_PRINT_REQUEST,
    restaurantId,
    startTime,
    endTime,
    status,
    orderType,
    userId,
    orderId,
    getAll,
    getOrdersByDelivery,
    [WAIT_FOR_ACTION]: GET_ORDERS_COUNT_FOR_PRINT_SUCCESS,
    [ERROR_ACTION]: GET_ORDERS_COUNT_FOR_PRINT_FAILURE,
  };
}

export function getPaginatedOrdersForProductionList({
  restaurantId,
  startTime,
  endTime,
  status,
  orderType,
  userId,
  orderId,
  ordersIds,
  orderIdsToChangeStatus,
  getOrdersByDelivery,
}) {
  return {
    type: GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_REQUEST,
    restaurantId,
    startTime,
    endTime,
    status,
    orderType,
    userId,
    orderId,
    ordersIds,
    orderIdsToChangeStatus,
    getOrdersByDelivery,
    [WAIT_FOR_ACTION]: GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_SUCCESS,
    [ERROR_ACTION]: GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_FAILURE,
  };
}

export function getPaginatedOrdersForPackList({
  restaurantId,
  startTime,
  endTime,
  status,
  orderType,
  userId,
  orderId,
  ordersIds,
  orderIdsToChangeStatus,
  getOrdersByDelivery,
}) {
  return {
    type: GET_PAGINATER_ORDERS_FOR_PACK_LIST_REQUEST,
    restaurantId,
    startTime,
    endTime,
    status,
    orderType,
    userId,
    orderId,
    ordersIds,
    orderIdsToChangeStatus,
    getOrdersByDelivery,
    [WAIT_FOR_ACTION]: GET_PAGINATER_ORDERS_FOR_PACK_LIST_SUCCESS,
    [ERROR_ACTION]: GET_PAGINATER_ORDERS_FOR_PACK_LIST_FAILURE,
  };
}

export function getProductionOrders({ ids }) {
  return {
    type: GET_PRODUCTION_ORDERS_REQUEST,
    ids,
    [WAIT_FOR_ACTION]: GET_PRODUCTION_ORDERS_SUCCESS,
    [ERROR_ACTION]: GET_PRODUCTION_ORDERS_FAILURE,
  };
}

export function getPackOrders({ ids }) {
  return {
    type: GET_PACK_ORDERS_REQUEST,
    ids,
    [WAIT_FOR_ACTION]: GET_PACK_ORDERS_SUCCESS,
    [ERROR_ACTION]: GET_PACK_ORDERS_FAILURE,
  };
}
