import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class LoadingOverlay extends React.PureComponent {
  render() {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 9999,
          backgroundColor: 'rgba(63,81,181,0.3)',
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default LoadingOverlay;
