import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { toastr } from 'react-redux-toastr';

import ActionHeading from 'components/ActionHeading';
import TableSortCell from 'components/TableSortCell';
import SmallButton from 'components/SmallButton';

import { ITEM_STATUSES } from 'consts';

import MenuItemsDropdown from './MenuItemsDropdown';

class MenuItems extends React.PureComponent {
  state = {
    selectedMenuItem: null,
  };

  onSelectMenuItem = (menuItem) => {
    this.setState({ selectedMenuItem: menuItem });
  };

  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handleSubmit = () => {
    const { selectedMenuItem } = this.state;
    if (selectedMenuItem) {
      const { category, onSubmit } = this.props;
      onSubmit({ id: selectedMenuItem.id, categoryId: category.id })
        .then(() => {
          this.setState({ selectedMenuItem: null });
          toastr.success('Success!', 'Menu item has been added');
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  render() {
    const { items } = this.props.initialData;
    const { sortName, sortDirection, category } = this.props;
    const { selectedMenuItem } = this.state;
    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="Name"
                  sortName="name"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                {!category.parentId && (
                  <TableSortCell
                    title="Category"
                    sortName="js:categoryName"
                    currentSortName={sortName}
                    currentSortDirection={sortDirection}
                    handleSort={this.handleSort}
                  />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow
                  key={item.id}
                  style={{
                    backgroundColor:
                      item.status === ITEM_STATUSES.inactive
                        ? 'rgba(0, 0, 0, 0.07)'
                        : '',
                  }}
                >
                  <TableCell>{item.name}</TableCell>
                  {!category.parentId && (
                    <TableCell>{item.categoryName}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <div style={{ marginTop: 40 }}>
          <ActionHeading>Add menu item</ActionHeading>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <MenuItemsDropdown
              categoryId={category.id}
              value={selectedMenuItem}
              onChange={this.onSelectMenuItem}
              excludeIds={items.map(({ id }) => id)}
            />
            <SmallButton onClick={this.handleSubmit} leftSpace>
              Add
            </SmallButton>
          </div>
        </div>
      </div>
    );
  }
}

MenuItems.propTypes = {
  category: PropTypes.object,
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MenuItems;
