import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';

import green from '@material-ui/core/colors/green';

import Routes from 'routes';
import LoadingOverlay from 'components/LoadingOverlay';
import { TOAST_TIME_HIDE } from 'consts';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: green[400],
      main: '#114a2c',
      dark: green[900],
      contrastText: '#fff',
    },
    secondary: {
      light: green[400],
      main: '#114a2c',
      dark: green[900],
      contrastText: '#fff',
    },
  },
  props: {
    MuiTableCell: {
      padding: 'dense',
    },
  },
  overrides: {
    MuiTableSortLabel: {
      root: {
        fontWeight: 600,
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px 10px 4px 10px',
      },
      paddingDense: {
        paddingRight: 10,
      },
    },
    // override menu section styles
    MuiDrawer: {
      paper: {
        backgroundColor: '#e3edc6',
        '& *:not(h1)': { color: 'rgba(0, 0, 0, 0.87)' },
      },
    },
  },
});

class Root extends React.Component {
  render() {
    const { store, history, persistor } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Provider store={store}>
            <React.Fragment>
              <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                  <Routes />
                </ConnectedRouter>
              </PersistGate>
              <ReduxToastr
                timeOut={TOAST_TIME_HIDE}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                removeOnHover={true}
                removeOnHoverTimeOut={TOAST_TIME_HIDE}
                closeOnToastrClick
              />
              <LoadingOverlay />
            </React.Fragment>
          </Provider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

Root.propTypes = {
  /**
   * store object
   */
  store: PropTypes.object.isRequired,
  /**
   * persistor object
   */
  persistor: PropTypes.object.isRequired,
  /**
   * browser history object
   */
  history: PropTypes.object.isRequired,
};

export default Root;
