import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';

const mapOption = (item) => ({
  label: item.name,
  value: item.id,
});

class MenuItemsDropdown extends React.PureComponent {
  handleChange = ({ value }) => {
    this.props.onChange(value);
  };

  render() {
    const { items, selected, disabled } = this.props;
    const value = items.find((i) => i.id === selected);
    return (
      <Select
        options={items.map(mapOption)}
        placeholder="Select item"
        disabled={disabled}
        value={value ? mapOption(value) : null}
        onChange={this.handleChange}
      />
    );
  }
}

MenuItemsDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default MenuItemsDropdown;
