import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { getTagGroupById } from 'actions/tags';
import { showLoading, hideLoading } from 'actions/ui';

import { wrapWithSideEffect } from 'utils';
import { routerPassId, hideIfNoData } from 'utils/enhancers';

import TagGroupsListPage from './TagGroupDetailsPage';

const mapDispatchToProps = {
  onInit: getTagGroupById,
  showLoading,
  hideLoading,
};

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props) => {
  const { id, onInit, setInitialData } = props;
  return id && wrapWithProgress(props)(onInit(id).then(setInitialData));
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  routerPassId,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(TagGroupsListPage);
