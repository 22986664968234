import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import cn from 'classnames';

import LandingLink from '../LandingLink';
import ListSectionTitle from './ListSectionTitle';

import { renderMultilineText } from '../utils';

import styles from './styles';

class ListSection extends React.PureComponent {
  render() {
    const { classes, items } = this.props;

    return (
      <div className="view-content">
        <section className={classes.list}>
          {items &&
            items.length > 0 &&
            items.map((item) => (
              <div
                key={item.id}
                className={cn(classes.listItem, classes.flexColumn)}
                style={{
                  backgroundColor: item.backgroundColor,
                  color: item.textColor,
                  textAlign: item.textAlign,
                }}
              >
                <div>
                  {item.title && (
                    <ListSectionTitle
                      className={classes.blockTitle}
                      item={item}
                    />
                  )}
                  {item.description && (
                    <p className={classes.paragraph}>
                      {renderMultilineText(item.description)}
                    </p>
                  )}
                </div>
                {item.link && item.link.url && (
                  <LandingLink item={item.link} color={item.textColor} />
                )}
              </div>
            ))}
        </section>
      </div>
    );
  }
}

ListSection.propTypes = {
  classes: PropTypes.object.isRequired,
  itemsPerRow: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.object,
      textColor: PropTypes.string,
      titleColor: PropTypes.string,
      backgroundColor: PropTypes.string,
    })
  ),
};

export default injectSheet(styles)(ListSection);
