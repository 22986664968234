import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function SingleValue(props) {
  return (
    <Typography
      style={{
        fontSize: props.selectProps.fontSize ? props.selectProps.fontSize : 16,
      }}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
