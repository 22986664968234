import { styleConsts } from 'shared/styles/consts';
import { tablet, laptop, laptopMiddle } from 'shared/styles/breakpoints';
import { generalStyles } from '../generalStyles';

const styles = {
  paragraph: {
    fontSize: 13,
    lineHeight: '1.38',
  },
  cart: {
    maxWidth: 485,
    margin: [-88, 'auto', 0],
    padding: 24,
    color: (props) => props.item.textColor,
    textAlign: (props) => props.item.textAlign,
    backgroundColor: (props) => props.item.cartBgColor,
    position: 'relative',
    zIndex: 1,
    boxSizing: 'border-box',
  },
  title: {
    marginBottom: 16,
    textAlign: (props) => props.item.titleAlign,
  },
  blockTitle: {
    extend: generalStyles.blockTitle,
  },
  flexColumn: {},
  cartSection: {},
  cartImg: {},
  [`@media (min-width: ${tablet})`]: {
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    paragraph: {
      fontSize: 17,
      lineHeight: '1.41',
    },
    cartSection: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 80,
    },
    title: {
      marginBottom: 24,
    },
    cartImg: {
      backgroundColor: (props) => props.item.backgroundColor,
      display: 'flex',
      justifyContent: (props) =>
        props.item.imagePosition === 'right' ? 'flex-end' : 'flex-start',
      '& > img': {
        width: '79.5%',
        display: 'block',
      },
    },
    cart: {
      marginTop: -175,
      padding: 40,
    },
  },
  [`@media (min-width: ${laptop})`]: {
    cartSection: {
      marginBottom: 250,
      '& .view-content': {
        height: '100%',
        position: 'relative',
      },
    },
    cart: {
      width: '100%',
      minHeight: 485,
      position: 'absolute',
      right: (props) =>
        props.item.imagePosition === 'right' ? 'auto' : styleConsts.pagePadding,
      left: (props) =>
        props.item.imagePosition === 'right' ? styleConsts.pagePadding : 'auto',
      bottom: -90,
    },
  },
  [`@media (min-width: ${laptopMiddle})`]: {
    cartImg: {
      '& > img': {
        width: `calc(50% + ${styleConsts.wrapperWidth}px / 2 - 230px)`,
      },
    },
  },
};

export default styles;
