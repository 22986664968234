import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { routerPassId, hideIfNoData } from 'utils/enhancers';

import { wrapWithSideEffect } from 'utils';

import {
  getCustomerDelivery,
  changeCustomerDeliveryAddress,
} from 'actions/customers';

import CustomerDeliveryDetailsPage from './CustomerDeliveryDetailsPage';

const mapDispatchToProps = {
  onInit: getCustomerDelivery,
  onSubmit: changeCustomerDeliveryAddress,
};

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('userId', 'setUserId')
);

const refresh = (props) => {
  const { id, onInit, setInitialData, setUserId } = props;
  setUserId(parseInt(props.match.params.userId, 10));
  return id && onInit(id).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSubmit: (props) => (event) => {
    const { onSubmit } = props;
    return wrapWithProgress(props)(
      onSubmit(event).then(() =>
        props.history.push(`/customer-details/${props.userId}`)
      )
    );
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  routerPassId,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(CustomerDeliveryDetailsPage);
