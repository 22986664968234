import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/TextField';

class Nutrition extends React.PureComponent {
  onTotalChange = ({ target: { value } }) => {
    this.props.onChange({
      total: value,
      per100g: this.props.per100g,
    });
  };

  onPer100gChange = ({ target: { value } }, key) => {
    const { total, per100g, onChange } = this.props;
    onChange({
      total,
      per100g: per100g.map((p) => (p.key === key ? { ...p, value } : p)),
    });
  };

  onPer100gSubitemChange = ({ target: { value } }, itemKey, key) => {
    const { total, per100g, onChange } = this.props;
    onChange({
      total,
      per100g: per100g.map(
        (p) =>
          p.key === itemKey
            ? {
                ...p,
                items: p.items.map(
                  (subitem) =>
                    subitem.key === key ? { ...subitem, value } : subitem
                ),
              }
            : p
      ),
    });
  };

  render() {
    const { total = '', per100g, inactive } = this.props;
    return (
      <div>
        <Typography
          variant="h6"
          color="inherit"
          style={{ marginTop: 20, fontSize: 12, fontWeight: 'bold' }}
        >
          Nutritional value per 100 g
        </Typography>
        {per100g.map((item) => (
          <React.Fragment key={item.key}>
            <div style={{ marginTop: 4 }}>
              <span style={{ display: 'inline-block', width: 200 }}>
                {item.label}
              </span>
              <Input
                value={item.value || ''}
                onChange={(e) => this.onPer100gChange(e, item.key)}
                margin="none"
                disabled={inactive}
                inputProps={{
                  style: { padding: '4px', fontSize: 12, textAlign: 'right' },
                }}
              />
            </div>
            {item.items &&
              item.items.map((subitem) => (
                <div key={subitem.key} style={{ marginTop: 4, marginLeft: 20 }}>
                  <span style={{ display: 'inline-block', width: 180 }}>
                    {subitem.label}
                  </span>
                  <Input
                    value={subitem.value || ''}
                    onChange={(e) =>
                      this.onPer100gSubitemChange(e, item.key, subitem.key)
                    }
                    margin="none"
                    disabled={inactive}
                    inputProps={{
                      style: {
                        padding: '4px',
                        fontSize: 12,
                        textAlign: 'right',
                      },
                    }}
                  />
                </div>
              ))}
          </React.Fragment>
        ))}
        <div style={{ marginTop: 14 }}>
          <span
            style={{
              display: 'inline-block',
              width: 200,
              fontSize: 12,
              fontWeight: 'bold',
            }}
          >
            Portion size g
          </span>
          <Input
            value={total}
            onChange={this.onTotalChange}
            margin="none"
            disabled={inactive}
            inputProps={{
              style: { padding: '4px', fontSize: 12, textAlign: 'right' },
            }}
          />
        </div>
      </div>
    );
  }
}

Nutrition.propTypes = {
  total: PropTypes.string,
  inactive: PropTypes.bool,
  per100g: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
  onChange: PropTypes.func.isRequired,
};

export default Nutrition;
