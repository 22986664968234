import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { toastr } from 'react-redux-toastr';

import TableSortCell from 'components/TableSortCell';
import TableActionCell from 'components/TableActionCell';
import ActionHeading from 'components/ActionHeading';
import SmallButton from 'components/SmallButton';

import CandidatsDropdown from './CandidatsDropdown';

class SubcategoryList extends React.PureComponent {
  state = {
    selectedCategory: null,
    dropDownKey: 1,
  };

  onSelectCategory = (value) => {
    this.setState({ selectedCategory: value });
  };

  handleDelete = (item) => {
    const { onDelete } = this.props;
    onDelete(item)
      .then(() => {
        toastr.success('Success!', 'Subcategory has been deleted');
      })
      .catch(() => {
        toastr.error('Error!', 'Something went wrong');
      });
  };

  handleSubmit = () => {
    const { parentId, onSubmit } = this.props;
    const { selectedCategory } = this.state;
    if (selectedCategory) {
      const data = {
        categoryId: parentId,
        subcategory: this.state.selectedCategory,
      };
      onSubmit(data)
        .then(() => {
          this.setState({
            selectedCategory: null,
            dropDownKey: this.state.dropDownKey + 1,
          });
          toastr.success('Success!', 'Subcategory has been added');
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  render() {
    const { items } = this.props.initialData;
    const { sortName, sortDirection, parentId } = this.props;
    const { selectedCategory, dropDownKey } = this.state;
    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="Name"
                  sortName="name"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableActionCell
                    action={() => this.handleDelete(item)}
                    icon="delete"
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <div style={{ marginTop: 40 }}>
          <ActionHeading>Add subcategory</ActionHeading>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <CandidatsDropdown
              key={dropDownKey}
              categoryId={parentId}
              value={selectedCategory}
              onChange={this.onSelectCategory}
            />
            <SmallButton onClick={this.handleSubmit} leftSpace>
              Add
            </SmallButton>
          </div>
        </div>
      </div>
    );
  }
}

SubcategoryList.propTypes = {
  parentId: PropTypes.number,
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SubcategoryList;
