import React from 'react';
import PropTypes from 'prop-types';

import Autosuggest from 'react-autosuggest';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import InputComponent from './InputComponent';
import Suggestion from './Suggestion';

import styles from './styles';

class IntegrationAutosuggest extends React.Component {
  render() {
    const {
      classes,
      label,
      placeholder,
      value,
      suggestions,
      handleSuggestionsFetchRequested,
      handleSuggestionsClearRequested,
      getSuggestionValue,
      onChange,
      onSuggestionSelected,
    } = this.props;

    return (
      <div className={classes.root}>
        <Autosuggest
          renderInputComponent={InputComponent}
          suggestions={suggestions}
          onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={handleSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={onSuggestionSelected}
          renderSuggestion={Suggestion}
          inputProps={{
            classes,
            label: label,
            placeholder: placeholder,
            value: value,
            onChange: onChange,
            InputLabelProps: {
              shrink: true,
            },
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={(options) => (
            <Paper
              {...options.containerProps}
              square
              style={{ maxHeight: 200, overflow: 'auto' }}
            >
              {options.children}
            </Paper>
          )}
        />
        <div />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  handleSuggestionsFetchRequested: PropTypes.func.isRequired,
  handleSuggestionsClearRequested: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(IntegrationAutosuggest);
