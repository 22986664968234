import { DEFAULT_PAGE_LIMIT } from 'consts';
import { ORDERS_PER_PRINT_PAGE } from 'consts/printList';

import { chunkArray } from 'utils/processors';

/**
 * Prepare sort parameters.
 * @param {String} sortDirection - Direction for sorting.
 * @param {String} sortName - Name of field to sort.
 * @return {String} Url parameters for sorting.
 */
export const calculateSort = (sortDirection, sortName) => {
  if (!sortName || sortName.startsWith('js:')) {
    return '';
  }
  return `orderBy${sortDirection === 'asc' ? 'Asc' : 'Desc'}=${sortName}`;
};

/**
 * Prepare pagination parameters with offset and limit.
 * @param {String|Number} page - number of page.
 * @param {String|Number} pageLimit - number of items per page.
 * @return {String} Url parameters for pagination.
 */
export const calculatePage = (page, pageLimit) => {
  if (!(pageLimit && page >= 0 && pageLimit > 0)) {
    return '';
  }
  const offset = page * pageLimit;
  return `offset=${offset}&limit=${pageLimit}`;
};

/**
 * Finds attribute object in array of attributes.
 * @param {Array} attributes - array of attributes.
 * @param {String} label - name of parameter to search.
 * @return {object} Searched attribute object.
 */
export const findAttributeValue = (attributes, label) =>
  attributes && attributes.length
    ? (attributes.find((attr) => attr.label === label) || {}).value
    : undefined;

/**
 * Finds and parse JSON attribute object in array of attributes.
 * @param {Array} attributes - array of attributes.
 * @param {String} label - name of parameter to search.
 * @return {object} Searched attribute object.
 */
export const findAttributeJSONValue = (attributes, label) => {
  const rawValue = findAttributeValue(attributes, label);
  if (rawValue) {
    try {
      return JSON.parse(rawValue);
    } catch (e) {
      if (process.env.NODE_ENV !== 'test') console.log(e, rawValue); //eslint-disable-line
    }
  }
};

/**
 * Finds available restaurant ids.
 * @param {Array} attributes - array of attributes.
 * @return {Array} Array of restaurant ids.
 */
export const findAvailableRestaurantIds = (attributes) =>
  attributes && attributes.length
    ? attributes
        .filter((attr) => attr.label === 'available' && attr.value === 'true')
        .map((a) => a.restaurantId)
        .filter((a) => !!a)
    : [];

/**
 * Converts number to two digits format.
 * @param {Number} n - number to convert.
 * @return {String} New number.
 */
export function twoDigFormat(n) {
  return n > 9 ? '' + n : '0' + (n || '0');
}

/**
 * Wraps promise with sideeffect to settimeout.
 * @param {Function} sideEffect - Sideeffect function.
 * @param {Number} delay - Delay of settimeout.
 * @param {Promise} promise.
 * @return {Promise}.
 */
export const wrapWithSideEffect = (sideEffect, delay = 0) => (promise) => {
  const timer = setTimeout(sideEffect, delay);
  return promise.finally(() => {
    clearTimeout(timer);
  });
};

/**
 * Prepare offset pagination parameters.
 * @param {Object} options - Request options.
 * @return {Object}.
 */
export const updateOffsetParam = (options = {}) => {
  return {
    ...options,
    offset:
      options.limit === 0 || !options.limit
        ? DEFAULT_PAGE_LIMIT
        : (options.offset || 0) + options.limit,
  };
};

/**
 * Prepare direct pagination parameters.
 * @param {Object} options - Request options.
 * @return {Object}.
 */
export const setPaginationParams = (options = {}) => {
  const { offset, limit } = options;
  return offset ? `offset=${offset}&limit=${limit}` : '';
};

/**
 * Check if passed argument is float.
 * @param {any} n - Value to check.
 * @return {Boolean} Returns true if value is float.
 */
export const isFloat = (n) => {
  return !isNaN(n) && n.toString().indexOf('.') !== -1;
};

/**
 * Shows confirmation message and run functions callback if such provided.
 * @param {Function} okCb - Function callback that would run on confirmation.
 * @param {Function} cancelCb - Function callback that would run on cancelling.
 * @param {String} message - Message that will be displayed in confirmation window.
 */
export const confirmAlert = ({
  okCb,
  cancelCb,
  message = 'Do you really want to delete this?',
} = {}) => {
  const response = window.confirm(message);
  if (response) {
    okCb && okCb();
  } else {
    cancelCb && cancelCb();
  }
};

/**
 * Add parameters to url.
 * @param {String} url - Base url.
 * @param {Array} params - Array of parameters for url.
 * @return {String} Url with parameters.
 */
export const prepareURLRequest = (url, params) => {
  if (params && params.length) {
    params = params.filter((item) => !!item);
  }
  return params && params.length ? `${url}?${params.join('&')}` : url;
};

export const openProductionPrintList = (ids, params) => {
  const chankedArray = chunkArray(ids, ORDERS_PER_PRINT_PAGE);

  chankedArray.forEach((element, index) => {
    const pageParams =
      chankedArray.length > 1
        ? `page=${index + 1}&pages=${chankedArray.length}&`
        : '';
    window.open(
      `print/production?${params}${pageParams}ids=${element.join(',')}`,
      '_blank'
    );
  });
};

export const openPackPrintList = (ids, params) => {
  const chankedArray = chunkArray(ids, ORDERS_PER_PRINT_PAGE);

  chankedArray.forEach((element, index) => {
    const pageParams =
      chankedArray.length > 1
        ? `page=${index + 1}&pages=${chankedArray.length}`
        : '';
    window.open(
      `print/pack?${pageParams}${params}&ids=${element.join(',')}`,
      '_blank'
    );
  });
};
