import {
  validatePhoneNumber,
  validateZipCode,
  normalizePhoneNumber,
} from 'utils/processors';
import { prepareDeliveryDate } from 'utils/date';
import { ORDER_TYPES, ORDER_STATUSES } from 'consts/orders';
import { USER_STATUSES } from 'consts/customers';

export function changeSelectedGroupsCount(selectedItem, changedValue) {
  const { item, selectedGroupItems } = selectedItem;

  if (!(selectedGroupItems && selectedGroupItems.length > 0)) {
    return [];
  }

  return selectedGroupItems.map((group) => {
    const newGroup = {
      ...group,
      values: group.values.map((val) => {
        if (val.isDefault) {
          const newNumberOfItems = val.numberOfItems + changedValue;
          return {
            ...val,
            numberOfItems: newNumberOfItems > 0 ? newNumberOfItems : 0,
          };
        }
        return val;
      }),
    };
    const defaultSelectedValue = newGroup.values.find((val) => val.isDefault);
    if (!defaultSelectedValue && changedValue > 0) {
      const currentGroup = item.groups.find((gr) => gr.id === group.groupId);
      const defaultItem = currentGroup.items.find((it) => it.isDefault);
      if (defaultItem) {
        newGroup.values.push({
          ...defaultItem,
        });
      }
    }
    return newGroup;
  });
}

/*
 * Prepare order items for order updating
 */
export function prepareOrderItems(menuItems = []) {
  const orderItems = [];
  menuItems.forEach((menuItem) => {
    orderItems.push({
      menuItemId: menuItem.item.id,
      quantity: menuItem.numberOfItems,
    });
    if (menuItem.selectedGroupItems && menuItem.selectedGroupItems.length) {
      menuItem.selectedGroupItems.forEach((group) => {
        group.values.forEach((groupItem) => {
          orderItems.push({
            menuItemId: groupItem.id,
            quantity: groupItem.numberOfItems,
            parentMenuItemId: groupItem.parentMenuItemId,
            relationshipIdentifier: group.groupId,
            relationshipType: 'group',
          });
        });
      });
    }
  });
  return orderItems;
}

export function prepareDataToSubmit(data, initialData) {
  // set compliment to null if it was not changed
  const compliment =
    initialData.compliment === data.compliment
      ? null
      : parseInt(data.compliment, 10);
  return {
    delivery: {
      contactPerson: data.delivery.name,
      deliveryPhone: data.delivery.phone
        ? normalizePhoneNumber(data.delivery.phone)
        : '',
      deliveryTime: prepareDeliveryDate({
        date: data.deliveryTime,
        hours: data.selectedHour,
        minutes: data.selectedMinute,
        timeZone: initialData.restaurant.timeZone,
      }),
      locationId: data.delivery.locationId,
      notice: data.delivery.notice,
      deliveryPrice: data.deliveryPrice,
    },
    order: {
      notice: initialData.notice,
      orderItems: prepareOrderItems(data.orderItems),
    },
    discount: {
      compliment,
    },
    comment: data.comment,
  };
}

export function validateError(data, initialData) {
  if (initialData.type !== ORDER_TYPES.delivery) {
    return 'The order can not be changed for such order type';
  }
  if (initialData.status === ORDER_STATUSES.done) {
    return 'The order can not be changed for "done" order status';
  }
  if (initialData.status === ORDER_STATUSES.cancelled) {
    return 'The order can not be changed for "cancelled" order status';
  }
  if (initialData.totalAmount === 0) {
    return 'The order can not be changed for total amout 0';
  }
  if (data.deliveryPrice < 0 || isNaN(parseFloat(data.deliveryPrice))) {
    return 'Delivery price should be greater than or equal 0';
  }
  if (
    data.compliment < 0 ||
    isNaN(parseFloat(data.compliment)) ||
    data.compliment > 100
  ) {
    return 'Compliment value should be in percentage range for 0 to 100';
  }
  if (!validatePhoneNumber(data.delivery.phone)) {
    return 'Valid Phone Number is required';
  }
  if (!validateZipCode(data.delivery.postcode)) {
    return 'Valid ZipCode is required';
  }
  if (!data.deliveryTime || data.selectedHour == null) {
    return 'Valid delivery time is required';
  }
  if (
    initialData &&
    initialData.user &&
    initialData.user.status === USER_STATUSES.deleted
  ) {
    return 'User has been deleted!';
  }
}
