import { compose } from 'recompose';
import { connect } from 'react-redux';

import { hideIfNoData } from 'utils/enhancers';

import LoadingOverlay from './LoadingOverlay';

const mapStateToProps = ({ ui }) => ({
  show: ui.loading,
});

export default compose(
  connect(mapStateToProps),
  hideIfNoData((props) => !props.show)
)(LoadingOverlay);
