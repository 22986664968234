import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';

class CandidatsDropdown extends React.PureComponent {
  render() {
    const { items } = this.props.initialData;
    const { value, onChange } = this.props;
    return (
      <Select
        options={items.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        placeholder="Select category"
        value={value}
        onChange={onChange}
      />
    );
  }
}

CandidatsDropdown.propTypes = {
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default CandidatsDropdown;
