/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { Editor } from 'react-draft-wysiwyg';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { withTheme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';

import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatStrikethroughIcon from '@material-ui/icons/FormatStrikethrough';

import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import FormatClearIcon from '@material-ui/icons/FormatClear';
import ColorizeIcon from '@material-ui/icons/Colorize';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';

import ToolbarButtonsBar from './ToolbarButtonsBar';
import ToolbarBbockTypeBar from './ToolbarBbockTypeBar';
import ToolbarLinkBar from './ToolbarLinkBar';
import ToolbarHistoryBar from './ToolbarHistoryBar';
import ToolbarColorBar from './ToolbarColorBar';

import styles from './styles';
import buttonStyles from './ToolbarButtonsBar/styles';

const getCurrentFormatingState = (state) =>
  Object.entries(state)
    .map(([key, value]) => (value ? key : false))
    .filter(Boolean);

const getCurrentListState = (state) => (state.listType ? [state.listType] : []);
const getCurrentAlignmentState = (state) =>
  state.textAlignment ? [state.textAlignment] : [];

const getCurrentFontSizeState = (state) =>
  state.fontSize ? state.fontSize : '';
const getCurrentBlockTypeState = (state) =>
  state.blockType ? state.blockType : '';

const toolbarConfig = (classes, openedModalType, openModalbyType) => ({
  options: [
    'inline',
    'blockType',
    'fontSize',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'remove',
    'history',
  ],
  inline: {
    component: ({ currentState, ...props }: { currentState: Object }) => (
      <ToolbarButtonsBar
        {...props}
        currentState={getCurrentFormatingState(currentState)}
      />
    ),
    options: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      // 'monospace',
      'superscript',
      'subscript',
    ],
    bold: { icon: <FormatBoldIcon /> },
    italic: { icon: <FormatItalicIcon /> },
    underline: { icon: <FormatUnderlinedIcon /> },
    strikethrough: { icon: <FormatStrikethroughIcon /> },
    superscript: {
      icon: (
        <span
          style={{
            fontWeight: 'bolder',
            fontSize: 20,
            lineHeight: 'initial',
          }}
        >
          X
          <span
            style={{
              verticalAlign: 'super',
              fontSize: 10,
            }}
          >
            2
          </span>
        </span>
      ),
    },
    subscript: {
      icon: (
        <span
          style={{
            fontWeight: 'bolder',
            fontSize: 20,
            lineHeight: '29px',
          }}
        >
          X
          <span
            style={{
              verticalAlign: 'sub',
              fontSize: 10,
            }}
          >
            2
          </span>
        </span>
      ),
    },

    // monospace: { icon: monospace, className: undefined },
  },
  blockType: {
    options: ['Normal', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
    component: ({ currentState, ...props }: { currentState: Object }) => (
      <ToolbarBbockTypeBar
        {...props}
        currentState={getCurrentBlockTypeState(currentState)}
        className={classes.toolbarBlockTypeSelect}
      />
    ),
  },
  fontSize: {
    component: ({ currentState, ...props }: { currentState: Object }) => (
      <ToolbarBbockTypeBar
        {...props}
        currentState={getCurrentFontSizeState(currentState)}
        className={classes.toolbarFontSizeSelect}
      />
    ),
  },
  list: {
    component: ({ currentState, ...props }: { currentState: Object }) => (
      <ToolbarButtonsBar
        {...props}
        currentState={getCurrentListState(currentState)}
      />
    ),
    options: [
      'unordered',
      'ordered',
      //  'indent', 'outdent'
    ],
    unordered: { icon: <FormatListBulletedIcon /> },
    ordered: { icon: <FormatListNumberedIcon /> },
    //   indent: { icon: indent, className: undefined },
    //   outdent: { icon: outdent, className: undefined },
  },
  textAlign: {
    component: ({ currentState, ...props }: { currentState: Object }) => (
      <ToolbarButtonsBar
        {...props}
        currentState={getCurrentAlignmentState(currentState)}
      />
    ),
    left: { icon: <FormatAlignLeftIcon /> },
    center: { icon: <FormatAlignCenterIcon /> },
    right: { icon: <FormatAlignRightIcon /> },
    justify: { icon: <FormatAlignJustifyIcon /> },
  },
  colorPicker: {
    icon: ColorizeIcon,
    component: (props) => (
      <ToolbarColorBar
        {...props}
        openedModalType={openedModalType}
        openModalbyType={openModalbyType}
      />
    ),
  },
  link: {
    component: (props) => (
      <ToolbarLinkBar
        {...props}
        openedModalType={openedModalType}
        openModalbyType={openModalbyType}
      />
    ),
    link: { icon: <LinkIcon /> },
    unlink: { icon: <LinkOffIcon /> },
  },
  remove: {
    component: ({ onChange }: { onChange: Object }) => (
      <Button onClick={onChange} classes={{ root: classes.toolbarButon }}>
        <FormatClearIcon />
      </Button>
    ),
  },
  history: {
    component: ToolbarHistoryBar,
    undo: { icon: UndoIcon },
    redo: { icon: RedoIcon },
  },
});

class RichTextEditor extends PureComponent {
  state = {
    openedModalType: '',
  };

  openModalbyType = (type) => this.setState({ openedModalType: type });

  render() {
    const { classes, ...props } = this.props;
    const { openedModalType } = this.state;
    return (
      <TextField
        className={classes.textField}
        placeholder="Description"
        label="Description"
        InputProps={{
          inputComponent: () => (
            <Editor
              toolbar={toolbarConfig(
                classes,
                openedModalType,
                this.openModalbyType
              )}
              toolbarClassName={classes.editorToolbarWrapper}
              editorClassName={classes.editorInputWrapper}
              wrapperClassName={classes.editorWrapper}
              {...props}
            />
          ),
        }}
      />
    );
  }
}

RichTextEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(
  withTheme(),
  injectSheet({ ...styles, ...buttonStyles })
)(RichTextEditor);
