import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';

import TableSortCell from 'components/TableSortCell';
import TableActionCell from 'components/TableActionCell';

import { convertFromUTCToTimeZone } from 'utils/date';
import { DEFAULT_TIMEZONE } from 'consts';

class CompaniesList extends React.PureComponent {
  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handlePage = (event, page) => {
    return this.props.onPage({ page });
  };

  render() {
    const { sortName, sortDirection, page, pageLimit, onDelete } = this.props;
    const { items, totalResults } = this.props.initialData;

    return (
      <React.Fragment>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="Company Id"
                  sortName="companyId"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell>Name</TableCell>
                <TableCell>Org number</TableCell>
                <TableSortCell
                  title="Approve date"
                  sortName="updateDate"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.companyId}</TableCell>
                  <TableCell>{item.company ? item.company.name : ''}</TableCell>
                  <TableCell>
                    {item.company ? item.company.organisationNumber : ''}
                  </TableCell>
                  <TableCell>
                    {item.updateDate
                      ? convertFromUTCToTimeZone(
                          item.updateDate,
                          DEFAULT_TIMEZONE
                        )
                      : ''}
                  </TableCell>
                  <TableActionCell
                    action={() => onDelete(item.id)}
                    icon="delete"
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageLimit]}
            component="div"
            count={totalResults}
            rowsPerPage={pageLimit}
            page={page}
            onChangePage={this.handlePage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

CompaniesList.propTypes = {
  userId: PropTypes.number.isRequired,
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number.isRequired,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onPage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CompaniesList;
