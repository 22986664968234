import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import PageHeading from 'components/PageHeading';
import PageHeadingTitle from 'components/PageHeadingTitle';
import Section from 'components/Section';

import SubcategoryList from './SubcategoryList';
import MenuItems from './MenuItems';

import { findAttributeValue } from 'utils';

const stateFromProps = ({ initialData = {} }) => {
  const seoNoIndexAttr = findAttributeValue(
    initialData.attributes,
    'seoNoindex'
  );
  const seoTitleAttr = findAttributeValue(initialData.attributes, 'seoTitle');
  const seoDescription = findAttributeValue(
    initialData.attributes,
    'seoDescription'
  );
  return {
    name: initialData.name || '',
    label: initialData.label || '',
    seoTitle: !initialData.parentId && seoTitleAttr ? seoTitleAttr : '',
    seoDescription:
      !initialData.parentId && seoDescription ? seoDescription : '',
    seoNoIndex:
      !initialData.parentId && seoNoIndexAttr && seoNoIndexAttr === 'true'
        ? true
        : false,
  };
};
class CategoryDetailsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  onSEONoIndexCheck = ({ target: { checked } }) => {
    this.setState({ seoNoIndex: checked });
  };

  validateError = (data) => {
    if (!data.name) {
      return 'Name is required';
    }
  };

  handleSubmit = () => {
    const { id, onSubmit } = this.props;
    const data = { id, ...this.state };
    const validateError = this.validateError(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      onSubmit(data)
        .then(() => {
          toastr.success(
            'Success!',
            id ? 'Category has been updated' : 'New Category has been created'
          );
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  render() {
    const { id, initialData: category } = this.props;
    const { name, seoTitle, seoDescription, seoNoIndex } = this.state;
    return (
      <div>
        <PageHeading
          title={
            id ? (
              <PageHeadingTitle
                text="Category"
                highlightedText={category.name}
              />
            ) : (
              'New Category'
            )
          }
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        <Input
          value={name}
          onChange={this.handleChange('name')}
          placeholder="Name"
          label="Name"
          margin="normal"
        />
        {category &&
          !category.parentId && (
            <React.Fragment>
              <div>
                <Input
                  value={seoTitle}
                  onChange={this.handleChange('seoTitle')}
                  placeholder="Seo Title"
                  label="Seo Title"
                  margin="normal"
                  style={{ width: 300 }}
                />
              </div>
              <div>
                <Input
                  value={seoDescription}
                  onChange={this.handleChange('seoDescription')}
                  placeholder="seo Description"
                  label="Seo Description"
                  margin="normal"
                  style={{ width: 300 }}
                />
              </div>
              <div>
                <Checkbox
                  style={{ paddingLeft: 0 }}
                  checked={seoNoIndex}
                  onChange={this.onSEONoIndexCheck}
                />
                Enable a noindex-tag for category
              </div>
            </React.Fragment>
          )}
        {id && (
          <Section heading="Subcategories">
            {category.parentId ? (
              <p style={{ marginTop: 10, color: '#666' }}>
                Category is already a subcategory and can not have its own
                subcategories
              </p>
            ) : (
              <SubcategoryList parentId={id} />
            )}
          </Section>
        )}
        {id && (
          <Section heading="Menu items">
            <MenuItems category={category} />
          </Section>
        )}
      </div>
    );
  }
}

CategoryDetailsPage.propTypes = {
  id: PropTypes.number,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default CategoryDetailsPage;
