import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { preAuth, auth } from 'actions/auth';
import { showLoading, hideLoading } from 'actions/ui';

import LoginPage from './LoginPage';

import { wrapWithSideEffect } from 'utils';

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const mapDispatchToProps = {
  onGetSmsCode: preAuth,
  onSubmit: auth,
  showLoading,
  hideLoading,
};

const handlers = withHandlers({
  onSubmit: (props) => (event) => {
    const { onSubmit } = props;
    return wrapWithProgress(props)(onSubmit(event));
  },
  onGetSmsCode: (props) => (event) => {
    const { onGetSmsCode } = props;
    return wrapWithProgress(props)(onGetSmsCode(event));
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  handlers
)(LoginPage);
