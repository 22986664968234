import { toastr } from 'react-redux-toastr';
import { ERROR_TOAST_TIME_HIDE } from 'consts';

export function showErrorOnErrorResponse(error) {
  let errorMessage = 'Something went wrong';
  if (error.errors && error.errors.length) {
    errorMessage = '';
    error.errors.forEach(([fieldError]) => {
      errorMessage = `${fieldError.field.toUpperCase()} - ${fieldError.error}`;
      toastr.error('Error!', errorMessage, {
        timeOut: ERROR_TOAST_TIME_HIDE,
      });
    });
    return;
  } else if (error.message) {
    errorMessage = error.message;
  }
  toastr.error('Error!', errorMessage, { timeOut: ERROR_TOAST_TIME_HIDE });
}
