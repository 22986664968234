import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import Checkbox from '@material-ui/core/Checkbox';
import { toastr } from 'react-redux-toastr';

import TableSortCell from 'components/TableSortCell';
import TableActionCell from 'components/TableActionCell';
import ActionHeading from 'components/ActionHeading';
import SmallButton from 'components/SmallButton';
import SectionHint from 'components/SectionHint';

import { MENU_ITEMS_ATTRIBUTES } from 'consts/menu-items';

import MenuItemsDropdown from 'components/MenuItemsDropdown';
import CategoriesDropdown from './CategoriesDropdown';

class MenuItems extends React.PureComponent {
  state = {
    selectedMenuItem: null,
    selectedCategory: null,
  };

  onSelectMenuItem = (menuItem) => {
    this.setState({ selectedMenuItem: menuItem });
  };

  onSelectCategory = (selectedCategory) => {
    this.setState({ selectedCategory });
  };

  handleDelete = (item) => {
    this.props
      .onDelete(item)
      .then(() => {
        toastr.success('Success!', 'Menu item has been deleted');
        this.setState({ selectedMenuItem: null });
      })
      .catch(() => {
        toastr.error('Error!', 'Something went wrong');
      });
  };

  handleChangeMenuItemAttribute = (
    item,
    attribute,
    { target: { checked } }
  ) => {
    const {
      onChangeMenuItemAttribute,
      menuItemsAttributes,
      restaurantId,
    } = this.props;
    onChangeMenuItemAttribute({
      id: item.id,
      restaurantId,
      attribute: menuItemsAttributes.find((attr) => attr.label === attribute),
      attributeValue: `${checked}`,
    });
  };

  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handlePage = (event, page) => {
    return this.props.onPage({ page });
  };

  handleSubmit = () => {
    const { selectedMenuItem } = this.state;
    if (selectedMenuItem) {
      this.props
        .onAdd(selectedMenuItem)
        .then(() => {
          toastr.success('Success!', 'Menu item has been added');
          this.setState({ selectedMenuItem: null });
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  handleSubmitCategory = () => {
    const { selectedCategory } = this.state;
    if (selectedCategory) {
      this.props
        .onAddCategory({ categoryId: selectedCategory.id })
        .then(() => {
          toastr.success('Success!', 'Category menu items have been added');
          this.setState({ selectedCategory: null });
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  handleRemoveCategory = () => {
    const { selectedCategory } = this.state;
    if (selectedCategory) {
      this.props
        .onRemoveCategory({ categoryId: selectedCategory.id })
        .then(() => {
          toastr.success('Success!', 'Category menu items have been removed');
          this.setState({ selectedCategory: null });
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  isPopular = (item) => {
    const { restaurantId } = this.props;
    return Boolean(
      item.attributes.find(
        (attr) =>
          attr.label === 'popular' &&
          attr.restaurantId === restaurantId &&
          attr.value === 'true'
      )
    );
  };

  isRecommended = (item) => {
    const { restaurantId } = this.props;
    return Boolean(
      item.attributes.find(
        (attr) =>
          attr.label === 'recommendedOnCheckout' &&
          attr.restaurantId === restaurantId &&
          attr.value === 'true'
      )
    );
  };

  isOutOfStock = (item) => {
    const { restaurantId } = this.props;
    return Boolean(
      item.attributes.find(
        (attr) =>
          attr.label === 'outOfStock' &&
          attr.restaurantId === restaurantId &&
          attr.value === 'true'
      )
    );
  };

  render() {
    const { sortName, sortDirection, page, pageLimit } = this.props;
    const { items, totalResults } = this.props.initialData;
    const { selectedMenuItem, selectedCategory } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="Name"
                  sortName="name"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell>Category</TableCell>
                <TableCell padding="checkbox">Popular</TableCell>
                <TableCell padding="checkbox">Feat. checkout</TableCell>
                <TableCell padding="checkbox">Out of stock</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.categoryName}</TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={this.isPopular(item)}
                      onChange={(e) =>
                        this.handleChangeMenuItemAttribute(
                          item,
                          MENU_ITEMS_ATTRIBUTES.popular,
                          e
                        )
                      }
                    />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={this.isRecommended(item)}
                      onChange={(e) =>
                        this.handleChangeMenuItemAttribute(
                          item,
                          MENU_ITEMS_ATTRIBUTES.recommendedOnCheckout,
                          e
                        )
                      }
                    />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={this.isOutOfStock(item)}
                      onChange={(e) =>
                        this.handleChangeMenuItemAttribute(
                          item,
                          MENU_ITEMS_ATTRIBUTES.outOfStock,
                          e
                        )
                      }
                    />
                  </TableCell>
                  <TableActionCell
                    action={() => this.handleDelete(item)}
                    icon="delete"
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageLimit]}
            component="div"
            count={totalResults}
            rowsPerPage={pageLimit}
            page={page}
            onChangePage={this.handlePage}
          />
        </Paper>
        <div style={{ marginTop: 40 }}>
          <ActionHeading>Add menu item</ActionHeading>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <MenuItemsDropdown
              value={selectedMenuItem}
              onChange={this.onSelectMenuItem}
              excludeIds={items.map(({ id }) => id)}
            />
            <SmallButton
              onClick={this.handleSubmit}
              leftSpace
              id="add-menu-item-button"
            >
              Add
            </SmallButton>
          </div>
        </div>
        <div style={{ marginTop: 40 }}>
          <ActionHeading>Add whole category</ActionHeading>
          <SectionHint>You can add individual items later on</SectionHint>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <CategoriesDropdown
              value={selectedCategory}
              onChange={this.onSelectCategory}
            />
            <SmallButton
              onClick={this.handleSubmitCategory}
              leftSpace
              id="add-category-button"
            >
              Add
            </SmallButton>
            <SmallButton
              onClick={this.handleRemoveCategory}
              leftSpace
              id="remove-category-button"
            >
              Remove
            </SmallButton>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MenuItems.propTypes = {
  restaurantId: PropTypes.number.isRequired,
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number.isRequired,
  }).isRequired,
  menuItemsAttributes: PropTypes.array,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onPage: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeMenuItemAttribute: PropTypes.func.isRequired,
  onAddCategory: PropTypes.func.isRequired,
  onRemoveCategory: PropTypes.func.isRequired,
};

export default MenuItems;
