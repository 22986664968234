import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  defaultProps,
} from 'recompose';
import { connect } from 'react-redux';

import {
  getMenuItems,
  addToRestaurant,
  deleteFromRestaurant,
  addCategoryToRestaurant,
  removeCategoryFromRestaurant,
  changeMenuItemAttributeToRestaurant,
  getMenuItemsAttributes,
} from 'actions/menu-items';
import { showLoading, hideLoading } from 'actions/ui';
import { wrapWithSideEffect } from 'utils';
import { hideIfNoData } from 'utils/enhancers';

import MenuItems from './MenuItems';

const PAGING_LIMIT = 20;

const mapDispatchToProps = {
  onInit: getMenuItems,
  onGetMenuItemsAttributes: getMenuItemsAttributes,
  onAdd: addToRestaurant,
  onDelete: deleteFromRestaurant,
  onChangeMenuItemAttribute: changeMenuItemAttributeToRestaurant,
  onAddCategory: addCategoryToRestaurant,
  onRemoveCategory: removeCategoryFromRestaurant,
  showLoading,
  hideLoading,
};

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const defaultData = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', 'name'),
  withState('sortDirection', 'setSortDirection', 'asc'),
  withState('page', 'setPage', 0),
  withState('menuItemsAttributes', 'setMenuItemsAttributes'),
  defaultProps({
    pageLimit: PAGING_LIMIT,
  })
);

const refresh = (props) => {
  const {
    sortName,
    sortDirection,
    page,
    pageLimit,
    onInit,
    setInitialData,
    restaurantId,
  } = props;
  return onInit({
    sortName,
    sortDirection,
    page,
    pageLimit,
    restaurantId,
  }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onGetMenuItemsAttributes: (props) => () => {
    props.onGetMenuItemsAttributes().then(props.setMenuItemsAttributes);
  },
  onAdd: (props) => ({ id }) => {
    const { restaurantId, onAdd } = props;
    props.setPage(0);
    return wrapWithProgress(props)(
      onAdd({ restaurantId, id }).then(() => refresh(props))
    );
  },
  onDelete: (props) => ({ id }) => {
    const { restaurantId, onDelete } = props;
    props.setPage(0);
    return wrapWithProgress(props)(
      onDelete({ restaurantId, id }).then(() => refresh(props))
    );
  },
  onChangeMenuItemAttribute: (props) => (params) => {
    const { onChangeMenuItemAttribute } = props;
    return wrapWithProgress(props)(
      onChangeMenuItemAttribute(params).then(() => refresh(props))
    );
  },
  onAddCategory: (props) => ({ categoryId }) => {
    const { restaurantId, onAddCategory } = props;
    props.setPage(0);
    return wrapWithProgress(props)(
      onAddCategory({ restaurantId, categoryId }).then(() => refresh(props))
    );
  },
  onRemoveCategory: (props) => ({ categoryId }) => {
    const { restaurantId, onRemoveCategory } = props;
    props.setPage(0);
    return wrapWithProgress(props)(
      onRemoveCategory({ restaurantId, categoryId }).then(() => refresh(props))
    );
  },
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setPage(0);
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    return refresh({ ...props, sortName, sortDirection, page: 0 });
  },
  onPage: (props) => ({ page }) => {
    props.setPage(page);
    return refresh({ ...props, page });
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
    this.props.onGetMenuItemsAttributes();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  defaultData,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(MenuItems);
