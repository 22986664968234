import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import ui from './ui';
import auth from './auth';
import redirect from './redirect';

const history = createBrowserHistory();

const rootReducer = combineReducers({
  toastr,
  router: connectRouter(history),
  ui,
  auth,
  redirect,
});

export default rootReducer;
