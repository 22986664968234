import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';

import TableSortCell from 'components/TableSortCell';

class KickbackHistory extends React.PureComponent {
  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handlePage = (event, page) => {
    return this.props.onPage({ page });
  };

  render() {
    const { items, totalResults } = this.props.initialData;
    const { sortName, sortDirection, pageLimit, page } = this.props;
    return (
      <React.Fragment>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="Period"
                  sortName="createDate"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Points gathered"
                  sortName="pointsLeft"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Store credits earned"
                  sortName="earnedStoreCredits"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.date}</TableCell>
                  <TableCell>{item.points}</TableCell>
                  <TableCell>{item.storeCredits}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageLimit]}
            component="div"
            count={totalResults}
            rowsPerPage={pageLimit}
            page={page}
            onChangePage={this.handlePage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

KickbackHistory.propTypes = {
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number.isRequired,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  onPage: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default KickbackHistory;
