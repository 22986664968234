import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import TableActionCell from 'components/TableActionCell';
import TableSortCell from 'components/TableSortCell';
import SmallButton from 'components/SmallButton';

import { confirmAlert } from 'utils';

class Delivery extends React.PureComponent {
  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handleDelete = (id) => {
    const { onDelete } = this.props;
    confirmAlert({ okCb: () => onDelete(id) });
  };

  render() {
    const { items } = this.props.initialData;
    const { sortName, sortDirection } = this.props;
    return (
      <div>
        <Link to="/settings/delivery">
          <SmallButton style={{ float: 'right', cursor: 'pointer' }}>
            + New Zone
          </SmallButton>
        </Link>
        <Table>
          <TableHead>
            <TableRow>
              <TableSortCell
                title="Delivery zone"
                sortName="name"
                currentSortName={sortName}
                currentSortDirection={sortDirection}
                handleSort={this.handleSort}
              />
              <TableSortCell
                title="Price"
                sortName="price"
                currentSortName={sortName}
                currentSortDirection={sortDirection}
                handleSort={this.handleSort}
              />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.zoneName}</TableCell>
                <TableCell>{item.shippingPrice}</TableCell>
                <TableActionCell
                  link={`/settings/delivery/${item.id}`}
                  icon="edit"
                />
                <TableActionCell
                  action={() => this.handleDelete(item.id)}
                  icon="delete"
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

Delivery.propTypes = {
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
};

export default Delivery;
