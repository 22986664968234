import React from 'react';

import RestaurantsDropdown from './RestaurantsDropdown';

class DefaultDropdown extends React.PureComponent {
  render() {
    return (
      <RestaurantsDropdown
        {...this.props}
        initialData={{ items: [] }}
        value={null}
        onChange={() => {}}
      />
    );
  }
}

export default DefaultDropdown;
