import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';

import CustomDialog from 'components/CustomDialog';
import OrderDetails from 'components/OrderDetails';
import TableSortCell from 'components/TableSortCell';

import { ORDER_TYPES_LABEL } from 'consts/orders';
import { formatOrderDeliveryDateTime } from 'utils/date';

class OrderHistory extends React.PureComponent {
  state = { openOrderDetails: false, orderId: null };

  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handlePage = (event, page) => {
    return this.props.onPage({ page });
  };

  handleCloseOrderDetails = () => {
    this.setState({ openOrderDetails: false, orderId: null });
  };

  handleShowOrderDetails = (id) => {
    this.setState({ openOrderDetails: true, orderId: id });
  };

  render() {
    const { sortName, sortDirection, page, pageLimit } = this.props;
    const { items, totalResults } = this.props.initialData;
    const { openOrderDetails, orderId } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="Order nr."
                  sortName="id"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Date"
                  sortName="createDate"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell>Delivery Date</TableCell>
                <TableSortCell
                  title="Type"
                  sortName="type"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Status"
                  sortName="status"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Number of items"
                  sortName="numberOfItems"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Cost summary"
                  sortName="totalAmount"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow
                  key={item.id}
                  hover
                  style={{
                    transition: 'background-color .3s',
                    cursor: 'pointer',
                  }}
                  onClick={() => this.handleShowOrderDetails(item.id)}
                >
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.createDate}</TableCell>
                  <TableCell>
                    {item.delivery && item.delivery.deliveryTime
                      ? formatOrderDeliveryDateTime(item.delivery.deliveryTime)
                      : ''}
                  </TableCell>
                  <TableCell>{ORDER_TYPES_LABEL[item.type]}</TableCell>
                  <TableCell style={{ textTransform: 'capitalize' }}>
                    {item.status}
                  </TableCell>
                  <TableCell align="right">{item.numberOfItems}</TableCell>
                  <TableCell align="right">{item.totalAmount} kr</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageLimit]}
            component="div"
            count={totalResults}
            rowsPerPage={pageLimit}
            page={page}
            onChangePage={this.handlePage}
          />
        </Paper>
        <CustomDialog
          open={openOrderDetails}
          onClose={this.handleCloseOrderDetails}
        >
          <OrderDetails orderId={orderId} />
        </CustomDialog>
      </React.Fragment>
    );
  }
}

OrderHistory.propTypes = {
  userId: PropTypes.number,
  companyId: PropTypes.number,
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number.isRequired,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onPage: PropTypes.func.isRequired,
};

export default OrderHistory;
