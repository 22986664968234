import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import { PACK_LIST_SORTING_NAME } from 'consts/settings';
import MenuItemsSortList from 'components/MenuItemsSortList';

class ProductionListSortingPage extends React.PureComponent {
  handleSubmit = (items) => {
    const { onSubmit, initialData } = this.props;
    const dataToSend = {
      isNew: initialData.isNew,
      name: PACK_LIST_SORTING_NAME,
      value: JSON.stringify(
        items.map((item, index) => ({
          menuItemId: item.id,
          index,
          visible: item.visible,
        }))
      ),
    };
    onSubmit(dataToSend)
      .then(() => {
        toastr.success('Success!', 'Sorting list has been updated');
      })
      .catch(() => {
        toastr.error('Error!', 'Something went wrong');
      });
  };

  render() {
    const { items } = this.props.initialData;
    return (
      <Fragment>
        <MenuItemsSortList
          type="pack"
          items={items}
          onSubmit={this.handleSubmit}
        />
      </Fragment>
    );
  }
}

ProductionListSortingPage.propTypes = {
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProductionListSortingPage;
