import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Select from 'components/Select';
import { convertHours } from 'utils/restaurant';

const styles = () => ({
  wrapper: {
    marginTop: '-4px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  col: {
    '&:first-child': {
      margin: '0px 15px',
    },
  },
});

class DisabledTimePicker extends React.PureComponent {
  render() {
    const { classes, selectedHour, selectedMinute } = this.props;

    return (
      <div className={classes.wrapper}>
        <div className={classes.col}>
          <Select
            options={[]}
            placeholder={selectedHour >= 0 ? convertHours(selectedHour) : '--'}
            width={70}
            value={null}
            isDisabled
          />
        </div>
        <div className={classes.col}>
          <Select
            options={[]}
            placeholder={
              selectedMinute >= 0 ? convertHours(selectedMinute) : '--'
            }
            width={70}
            value={null}
            isDisabled
          />
        </div>
      </div>
    );
  }
}

DisabledTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedHour: PropTypes.number,
  selectedMinute: PropTypes.number,
};

export default withStyles(styles)(DisabledTimePicker);
