import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import PageHeading from 'components/PageHeading';
import TableSortCell from 'components/TableSortCell';
import TableActionCell from 'components/TableActionCell';
import TableCount from 'components/TableCount';

class TagGroupsListPage extends React.PureComponent {
  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  render() {
    const { items } = this.props.initialData;
    const { sortName, sortDirection } = this.props;
    return (
      <div>
        <PageHeading title="Tags" />
        <TableCount count={items.length} />
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="Name"
                  sortName="name"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <span style={{ textTransform: 'capitalize' }}>
                      {item.name}
                    </span>
                  </TableCell>
                  <TableActionCell
                    link={`/tag-details/${item.id}`}
                    icon="edit"
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

TagGroupsListPage.propTypes = {
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default TagGroupsListPage;
