import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import ActionHeading from 'components/ActionHeading';
import SmallButton from 'components/SmallButton';
import UsersAutosuggest from 'components/UsersAutosuggest';

import {
  PENDING_MEMBER_STATUS,
  ACTIVE_MEMBER_STATUS,
} from 'consts/userRegistry';

import MembersTable from './MembersTable';

class Members extends React.PureComponent {
  state = {
    searchQuery: '',
    memberToAdd: {},
  };

  handleSearchQueryChange = (event, { newValue }) => {
    this.setState({
      searchQuery: newValue,
    });
  };

  handleSubmit = () => {
    const { memberToAdd } = this.state;
    const { onSubmitMember, companyId, members } = this.props;
    const isApproved = (members || []).find(
      (member) =>
        member.id === memberToAdd.id &&
        member.userRegistry &&
        member.userRegistry.status === ACTIVE_MEMBER_STATUS
    );
    this.setState({ memberToAdd: {}, searchQuery: '' });
    if (isApproved) {
      toastr.error('Error!', 'User has already been approved!');
      return;
    }
    onSubmitMember({ userId: memberToAdd.id, companyId });
  };

  handleAddMember = (item) => {
    this.setState({ memberToAdd: item });
  };

  handleDeclineMember = (item) => {
    const { onDeclineMember } = this.props;
    onDeclineMember(item.userRegistry.id);
  };

  handleApprove = (item) => {
    const { onApproveMember } = this.props;
    onApproveMember(item.userRegistry.id);
  };

  handleApproveAll = () => {
    const { onApproveAll, members } = this.props;
    const pendingMembersIds = members
      .filter(
        (user) =>
          user.userRegistry &&
          user.userRegistry.status === PENDING_MEMBER_STATUS
      )
      .map((member) => member.userRegistry.id);
    onApproveAll(pendingMembersIds);
  };

  onChangeCustomer = (item) => {
    this.setState({
      memberToAdd: item,
    });
  };

  onSearchParamChange = () => {
    this.setState({ searchQuery: '', memberToAdd: {} });
  };

  render() {
    const { memberToAdd, searchQuery } = this.state;
    const { members } = this.props;

    return (
      <React.Fragment>
        <div>
          <MembersTable
            items={members.filter(
              (user) =>
                user.userRegistry &&
                user.userRegistry.status === ACTIVE_MEMBER_STATUS
            )}
            isPending={false}
            handleDelete={this.handleDeclineMember}
          />
          <div style={{ marginTop: 40, marginBottom: 40 }}>
            <ActionHeading>Add member</ActionHeading>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <UsersAutosuggest
                query={searchQuery || ''}
                onQueryChange={this.handleSearchQueryChange}
                onChange={this.onChangeCustomer}
                onSearchParamChange={this.onSearchParamChange}
              />
              <SmallButton
                onClick={this.handleSubmit}
                disabled={!(memberToAdd && memberToAdd.id)}
                leftSpace
              >
                Add
              </SmallButton>
            </div>
          </div>
          <MembersTable
            items={members.filter(
              (user) =>
                user.userRegistry &&
                user.userRegistry.status === PENDING_MEMBER_STATUS
            )}
            isPending={true}
            handleDelete={this.handleDeclineMember}
            handleApprove={this.handleApprove}
            handleApproveAll={this.handleApproveAll}
          />
        </div>
      </React.Fragment>
    );
  }
}

Members.propTypes = {
  members: PropTypes.array,
  companyId: PropTypes.number.isRequired,
  onSubmitMember: PropTypes.func.isRequired,
  onDeclineMember: PropTypes.func.isRequired,
  onApproveMember: PropTypes.func.isRequired,
  onApproveAll: PropTypes.func.isRequired,
};

export default Members;
