import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { getTagsByGroupId, saveTag, deleteTag } from 'actions/tags';
import { hideIfNoData } from 'utils/enhancers';

import TagList from './TagList';

const mapDispatchToProps = {
  onInit: getTagsByGroupId,
  onSubmit: saveTag,
  onDelete: deleteTag,
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', 'value'),
  withState('sortDirection', 'setSortDirection', 'asc')
);

const refresh = (props) => {
  const { id, sortName, sortDirection, onInit, setInitialData } = props;
  return onInit({ sortName, sortDirection, groupId: id }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSubmit: (props) => (event) =>
    props.onSubmit(event).then(() => refresh(props)),
  onDelete: (props) => (event) =>
    props.onDelete(event).then(() => refresh(props)),
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    return refresh({ ...props, sortName, sortDirection });
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => props.id && !props.initialData)
)(TagList);
