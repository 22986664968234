import {
  OPENING_HOURS_LABEL,
  SPECIAL_OPENING_HOURS_LABEL,
} from 'consts/restaurants';
import { format } from 'date-fns';
import { getStartOfDay } from 'utils/date';
import { SHORT_DATE_FORMAT, DAYS_FOR_MAPING } from 'consts';

export function getOpeningHoursList(item) {
  if (!item.attributes.length) {
    return;
  }

  const openingHours = getScheduleHours(item.attributes, OPENING_HOURS_LABEL);

  if (!openingHours) {
    return;
  }

  const opHours = openingHours.map((item) => ({
    weekDay: item.weekDay,
    ...setSchedule(item),
  }));

  return sortOpeningHoursByDay(opHours, DAYS_FOR_MAPING);
}

function getScheduleHours(items, label) {
  const attribute = items.find((item) => item.label === label);
  return attribute ? JSON.parse(attribute.value) : null;
}

function setSchedule(schedule) {
  return schedule && schedule.opens
    ? {
        openHour: setHour(schedule.opens),
        closeHour: setHour(schedule.closes),
      }
    : {
        isClosed: true,
      };
}

function setHour(item) {
  return `${convertHours(item.hour)}:${convertHours(item.minute)}`;
}

export function convertHours(item) {
  return ('0' + item).slice(-2);
}

// TODO: check for time zone
export function getSpecialHoursList(item, timeZone) {
  if (!item.attributes.length) {
    return;
  }

  const specialOpeningHours = getScheduleHours(
    item.attributes,
    SPECIAL_OPENING_HOURS_LABEL
  );

  if (!specialOpeningHours) {
    return;
  }

  const items = specialOpeningHours
    .filter(
      (item) =>
        !item.year ||
        (item.year &&
          new Date(item.year, item.month, item.day) >=
            getStartOfDay(new Date()))
    )
    .map((item) => ({
      name: item.name,
      date: item.year
        ? format(new Date(item.year, item.month, item.day), SHORT_DATE_FORMAT)
        : format(
            new Date(new Date().getFullYear(), item.month, item.day),
            SHORT_DATE_FORMAT
          ),
      ...setSchedule(item),
    }));

  return items;
}

export function sortOpeningHoursByDay(items, days) {
  const newArray = [];

  if (!items) {
    return [];
  }

  days.forEach((day) => {
    const item = items.find((el) => el.weekDay === day);
    item
      ? newArray.push({ ...item })
      : newArray.push({
          weekDay: day,
          isClosed: true,
        });
  });

  return newArray;
}
