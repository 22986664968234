import {
  renderNothing,
  branch,
  setDisplayName,
  compose,
  mapProps,
} from 'recompose';

export const hideIfNoData = (hasNoData) =>
  compose(
    setDisplayName('HideIfNoDataEnhancer'),
    branch(hasNoData, renderNothing)
  );

export const routerPassId = mapProps((props) => {
  const id = (props.match.params || {}).id;
  return {
    id: id ? Number(id) : undefined,
    ...props,
  };
});

function parseQuery(queryString) {
  let query = {};
  let pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export const routerPassQuery = mapProps((props) => {
  const query = parseQuery(props.location.search);
  return {
    ...query,
    ...props,
  };
});

export const routerPassNotNumberId = mapProps((props) => {
  const id = (props.match.params || {}).id;
  return {
    id,
    ...props,
  };
});
