import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Checkbox from '@material-ui/core/Checkbox';

import TableSortCell from 'components/TableSortCell';

class Restaurants extends React.PureComponent {
  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  render() {
    const {
      sortName,
      sortDirection,
      value,
      onCheck,
      onCheckAll,
      checkAll,
      inactive,
    } = this.props;
    const { items } = this.props.initialData;
    return (
      <Paper>
        {!inactive && (
          <div style={{ padding: '16px 12px 8px 0', textAlign: 'right' }}>
            Check All
            <Checkbox
              checked={checkAll}
              onChange={(e) => onCheckAll(e, items.map((item) => item.id))}
            />
          </div>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableSortCell
                title="Name"
                sortName="name"
                currentSortName={sortName}
                currentSortDirection={sortDirection}
                handleSort={this.handleSort}
              />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell padding="checkbox" align="right">
                  <Checkbox
                    disabled={inactive}
                    checked={value.includes(item.id)}
                    onChange={(e) => onCheck(item.id, e)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

Restaurants.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  checkAll: PropTypes.bool.isRequired,
  inactive: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
  onCheckAll: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default Restaurants;
