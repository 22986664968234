import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import ActionHeading from 'components/ActionHeading';
import TableActionCell from 'components/TableActionCell';
import SmallButton from 'components/SmallButton';

import TagsDropdown from './TagsDropdown';

class Tags extends React.PureComponent {
  state = {
    selectedTag: null,
  };

  onSelectTag = (tag) => {
    this.setState({ selectedTag: tag });
  };

  handleSubmit = () => {
    const { selectedTag } = this.state;
    if (selectedTag) {
      this.props.onAdd(selectedTag);
      this.setState({ selectedTag: null });
    }
  };

  render() {
    const { items, onDelete, inactive } = this.props;
    const { selectedTag } = this.state;

    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                {!inactive && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.value}</TableCell>
                  {!inactive && (
                    <TableActionCell
                      action={() => onDelete(item.id)}
                      icon="delete"
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {!inactive && (
          <div style={{ marginTop: 40 }}>
            <ActionHeading>Add tag</ActionHeading>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <TagsDropdown
                value={selectedTag}
                onChange={this.onSelectTag}
                excludeIds={items.map(({ id }) => id)}
              />
              <SmallButton
                onClick={this.handleSubmit}
                leftSpace
                id="add-tag-button"
              >
                Add
              </SmallButton>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Tags.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.array.isRequired,
  inactive: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Tags;
