export const GET_COMPANY_MEMBERS_REQUEST = 'GET_COMPANY_MEMBERS_REQUEST';
export const GET_COMPANY_MEMBERS_SUCCESS = 'GET_COMPANY_MEMBERS_SUCCESS';
export const GET_COMPANY_MEMBERS_FAILURE = 'GET_COMPANY_MEMBERS_FAILURE';

export const ADD_MEMBER_TO_COMPANY_REQUEST = 'ADD_MEMBER_TO_COMPANY_REQUEST';
export const ADD_MEMBER_TO_COMPANY_SUCCESS = 'ADD_MEMBER_TO_COMPANY_SUCCESS';
export const ADD_MEMBER_TO_COMPANY_FAILURE = 'ADD_MEMBER_TO_COMPANY_FAILURE';

export const APPROVE_COMPANY_MEMBER_REQUEST = 'APPROVE_COMPANY_MEMBER_REQUEST';
export const APPROVE_COMPANY_MEMBER_SUCCESS = 'APPROVE_COMPANY_MEMBER_SUCCESS';
export const APPROVE_COMPANY_MEMBER_FAILURE = 'APPROVE_COMPANY_MEMBER_FAILURE';

export const APPROVE_ALL_MEMBERS_REQUEST = 'APPROVE_ALL_MEMBERS_REQUEST';
export const APPROVE_ALL_MEMBERS_SUCCESS = 'APPROVE_ALL_MEMBERS_SUCCESS';
export const APPROVE_ALL_MEMBERS_FAILURE = 'APPROVE_ALL_MEMBERS_FAILURE';

export const DECLINE_COMPANY_MEMBER_REQUEST = 'DECLINE_COMPANY_MEMBER_REQUEST';
export const DECLINE_COMPANY_MEMBER_SUCCESS = 'DECLINE_COMPANY_MEMBER_SUCCESS';
export const DECLINE_COMPANY_MEMBER_FAILURE = 'DECLINE_COMPANY_MEMBER_FAILURE';

export const GET_USER_COMPANIES_REQUEST = 'GET_USER_COMPANIES_REQUEST';
export const GET_USER_COMPANIES_SUCCESS = 'GET_USER_COMPANIES_SUCCESS';
export const GET_USER_COMPANIES_FAILURE = 'GET_USER_COMPANIES_FAILURE';

export const PENDING_MEMBER_STATUS = 'draft';
export const ACTIVE_MEMBER_STATUS = 'approved';
export const DECLINED_MEMBER_STATUS = 'declined';
