import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import Input from '@material-ui/core/TextField';

import injectSheet from 'react-jss';

import TableSortCell from 'components/TableSortCell';

const styles = {
  stampCardAmountInput: {
    margin: 0,
  },
  freeItemsAmount: {
    display: 'inline-block',
    paddingTop: 6,
    paddingLeft: 8,
  },
};

const stateFromProps = ({ initialData = {} }) => ({
  items: initialData.items || [],
});

class StampCards extends React.PureComponent {
  state = stateFromProps(this.props);

  componentWillReceiveProps;

  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handlePage = (event, page) => {
    return this.props.onPage({ page });
  };

  handleChangeAmount = ({ target: { value } }, index, id) => {
    const { onChange } = this.props;
    const { items } = this.state;
    this.setState(
      {
        items: items.map((item) => ({
          ...item,
          stampCardAmount: item.id === id ? value : item.stampCardAmount,
        })),
      },
      () => {
        const changedItem = this.state.items.find((item) => item.id === id);
        onChange(changedItem);
      }
    );
  };

  render() {
    const { sortName, sortDirection, page, pageLimit, classes } = this.props;
    const { totalResults } = this.props.initialData;
    const { items } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableSortCell
                  title="Stamps right now"
                  sortName="stampCardAmount"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Products claimed"
                  sortName="usedPunchCards"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>{item.punchCard.name}</TableCell>
                  <TableCell>
                    <Input
                      className={classes.stampCardAmountInput}
                      value={item.stampCardAmount}
                      type="number"
                      onChange={(e) =>
                        this.handleChangeAmount(e, index, item.id)
                      }
                      placeholder="Stamp card amount"
                      margin="normal"
                      style={{ width: 40 }}
                    />{' '}
                    <span className={classes.freeItemsAmount}>
                      of {item.punchCard.amountForFreeMenuItem}
                    </span>
                  </TableCell>
                  <TableCell>{item.usedPunchCards}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageLimit]}
            component="div"
            count={totalResults}
            rowsPerPage={pageLimit}
            page={page}
            onChangePage={this.handlePage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

StampCards.propTypes = {
  classes: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number.isRequired,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onPage: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default injectSheet(styles)(StampCards);
