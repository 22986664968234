import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import TableActionCell from 'components/TableActionCell';

class DeliveryAddresses extends React.PureComponent {
  render() {
    const { userId } = this.props;
    const { items } = this.props.initialData;
    return (
      <React.Fragment>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Zip Code</TableCell>
                <TableCell>ad.info/code</TableCell>
                <TableCell>Telephonenumber</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.location.streetAddress}</TableCell>
                  <TableCell>{item.name || '-'}</TableCell>
                  <TableCell>{item.location.postcode || '-'}</TableCell>
                  <TableCell>{item.notice || '-'}</TableCell>
                  <TableCell>{item.phone || '-'}</TableCell>
                  <TableActionCell
                    link={`/customer-details/${userId}/${item.id}`}
                    icon="edit"
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  }
}

DeliveryAddresses.propTypes = {
  userId: PropTypes.number.isRequired,
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number.isRequired,
  }).isRequired,
};

export default DeliveryAddresses;
