export const GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_REQUEST =
  'GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_REQUEST';
export const GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_SUCCESS =
  'GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_SECCESS';
export const GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_FAILURE =
  'GET_PAGINATER_ORDERS_FOR_PRODUCTION_LIST_FAILURE';

export const GET_PAGINATER_ORDERS_FOR_PACK_LIST_REQUEST =
  'GET_PAGINATER_ORDERS_FOR_PACK_LIST_REQUEST';
export const GET_PAGINATER_ORDERS_FOR_PACK_LIST_SUCCESS =
  'GET_PAGINATER_ORDERS_FOR_PACK_LIST_SECCESS';
export const GET_PAGINATER_ORDERS_FOR_PACK_LIST_FAILURE =
  'GET_PAGINATER_ORDERS_FOR_PACK_LIST_FAILURE';

export const GET_PRODUCTION_ORDERS_REQUEST = 'GET_PRODUCTION_ORDERS_REQUEST';
export const GET_PRODUCTION_ORDERS_SUCCESS = 'GET_PRODUCTION_ORDERS_SUCCESS';
export const GET_PRODUCTION_ORDERS_FAILURE = 'GET_PRODUCTION_ORDERS_FAILURE';

export const GET_PACK_ORDERS_REQUEST = 'GET_PACK_ORDERS_REQUEST';
export const GET_PACK_ORDERS_SUCCESS = 'GET_PACK_ORDERS_SUCCESS';
export const GET_PACK_ORDERS_FAILURE = 'GET_PACK_ORDERS_FAILURE';

export const GET_ORDERS_COUNT_FOR_PRINT_REQUEST =
  'GET_ORDERS_COUNT_FOR_PRINT_REQUEST';
export const GET_ORDERS_COUNT_FOR_PRINT_SUCCESS =
  'GET_ORDERS_COUNT_FOR_PRINT_SUCCESS';
export const GET_ORDERS_COUNT_FOR_PRINT_FAILURE =
  'GET_ORDERS_COUNT_FOR_PRINT_FAILURE';

export const ORDERS_PER_PRINT_PAGE = 100;
export const MAX_ORDERS_COUNT_FOR_PRINT_LIST = 500;
