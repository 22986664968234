export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

export const GET_GROUP_BY_ID_REQUEST = 'GET_GROUP_BY_ID_REQUEST';
export const GET_GROUP_BY_ID_SUCCESS = 'GET_GROUP_BY_ID_SUCCESS';
export const GET_GROUP_BY_ID_FAILURE = 'GET_GROUP_BY_ID_FAILURE';

export const SAVE_GROUP_REQUEST = 'SAVE_GROUP_REQUEST';
export const SAVE_GROUP_SUCCESS = 'SAVE_GROUP_SUCCESS';
export const SAVE_GROUP_FAILURE = 'SAVE_GROUP_FAILURE';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
