import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORY_BY_ID_REQUEST,
  GET_CATEGORY_BY_ID_SUCCESS,
  SAVE_CATEGORY_REQUEST,
  SAVE_CATEGORY_SUCCESS,
  GET_CATEGORIES_BY_PARENTID_REQUEST,
  GET_CATEGORIES_BY_PARENTID_SUCCESS,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  GET_SUBCATEGORY_CANDIDATS_REQUEST,
  GET_SUBCATEGORY_CANDIDATS_SUCCESS,
  ADD_SUBCATEGORY_REQUEST,
  ADD_SUBCATEGORY_SUCCESS,
  REMOVE_SUBCATEGORY_REQUEST,
  REMOVE_SUBCATEGORY_SUCCESS,
} from 'consts/categories';
import { ITEM_STATUSES } from 'consts';
import { calculateSort } from 'utils';
import { fixCategoriesPayload, normalizeLabel } from 'utils/processors';

import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';
import { getCategoryAttributes } from 'sagas/attributes';

import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';

/**
 * Retrieve categories list.
 * @param {Object} { sortName, sortDirection } - name of field to sort, direction for sorting.
 * @return {Object} Response for request.
 */
export function* getCategories({ sortName = '', sortDirection = '' } = {}) {
  let url = `/categories?${calculateSort(sortDirection, sortName)}&status=${
    ITEM_STATUSES.active
  }`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const rawPayload = yield apiRequest(url, options);
    const payload = fixCategoriesPayload(rawPayload, {
      sortName,
      sortDirection,
    });
    const newAction = {
      type: GET_CATEGORIES_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getCategoriesSaga() {
  yield takeFirst(GET_CATEGORIES_REQUEST, getCategories);
}

/**
 * Retrieve category by id.
 * @param {number} id - category id.
 * @return {Object} Response for request.
 */
export function* getCategoryById({ id }) {
  let url = `/categories/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: GET_CATEGORY_BY_ID_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getCategoryByIdSaga() {
  yield takeFirst(GET_CATEGORY_BY_ID_REQUEST, getCategoryById);
}

export function prepareAttributes(attributes, values = {}) {
  return Object.keys(values).map((label) => ({
    attributeId: (attributes.find((a) => a.label === label) || {}).id,
    value: values[label] ? values[label] : '',
  }));
}

/**
 * if id update category object unless create new category.
 * @param {Object} {id, name, label} - category id, params to update.
 * @return {Object} Response for request.
 */
export function* saveCategory({
  id,
  name,
  label,
  seoTitle,
  seoDescription,
  seoNoIndex,
}) {
  const url = id ? `/categories/${id}` : '/categories';
  try {
    const categoryAttributes = yield getCategoryAttributes();
    const stringAttributes = prepareAttributes(categoryAttributes, {
      seoTitle,
      seoDescription,
    });
    const seoNoIndexAttr = categoryAttributes.find(
      (a) => a.label === 'seoNoindex'
    );
    const attributes = [
      ...stringAttributes,
      { attributeId: seoNoIndexAttr.id, value: seoNoIndex ? 'true' : 'false' },
    ];
    let parentId = 0;
    // adding normilized label based on category name
    // should only works if category is new
    if (!id && !label) {
      label = normalizeLabel(name);
    }
    if (id) {
      const oldCategory = yield getCategoryById({ id });
      parentId = oldCategory.parentId;
    }
    const options = {
      method: id ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, parentId, label, attributes }),
    };
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SAVE_CATEGORY_SUCCESS,
      payload,
    };
    yield put(newAction);
    if (!id) {
      yield put(push(`/category-details/${payload.id}`));
    }
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* saveCategorySaga() {
  yield takeFirst(SAVE_CATEGORY_REQUEST, saveCategory);
}

/**
 * Retrieve categories list by categories parentId.
 * @param {Object} {parentId, sortName, sortDirection} - categories parent id, name of field to sort, direction for sorting.
 * @return {Object} Response for request.
 */
export function* getCategoriesByParentId({
  parentId,
  sortName,
  sortDirection,
}) {
  let url = `/categories?parentId=${parentId}&${calculateSort(
    sortDirection,
    sortName
  )}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const hotfixPayload = {
      ...payload,
      items: payload.items.filter((i) => i.parentId === parentId),
    };
    const newAction = {
      type: GET_CATEGORIES_BY_PARENTID_SUCCESS,
      payload: hotfixPayload,
    };
    yield put(newAction);
    return hotfixPayload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getCategoriesByParentIdSaga() {
  yield takeFirst(GET_CATEGORIES_BY_PARENTID_REQUEST, getCategoriesByParentId);
}

/**
 * Delete category by id.
 * @param {number} id - category id.
 */
export function* deleteCategory({ id }) {
  const url = `/categories/${id}`;
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: DELETE_CATEGORY_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* deleteCategorySaga() {
  yield takeFirst(DELETE_CATEGORY_REQUEST, deleteCategory);
}

export function* getSubcategoryCandidats({ categoryId }) {
  let url = `/categories?${calculateSort('asc', 'name')}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const hotfixPayload = {
      ...payload,
      items: payload.items.filter(
        (i) =>
          i.id !== categoryId &&
          i.parentId === 0 &&
          !payload.items.map((j) => j.parentId).includes(i.id)
      ),
    };
    const newAction = {
      type: GET_SUBCATEGORY_CANDIDATS_SUCCESS,
      payload: hotfixPayload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* getSubcategoryCandidatsSaga() {
  yield takeFirst(GET_SUBCATEGORY_CANDIDATS_REQUEST, getSubcategoryCandidats);
}

/**
 * Create new subcategory.
 * @param {Object} {categoryId, subcategory}
 */
export function* addSubcategory({ categoryId, subcategory }) {
  const url = `/categories/${subcategory.value}`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      parentId: categoryId,
      name: subcategory.label,
      label: normalizeLabel(subcategory.label),
    }),
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: ADD_SUBCATEGORY_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* addSubcategorySaga() {
  yield takeFirst(ADD_SUBCATEGORY_REQUEST, addSubcategory);
}

/**
 * Delete subcategory by id.
 * @param {Object} subcategory
 */
export function* removeSubcategory({ subcategory }) {
  const url = `/categories/${subcategory.id}`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      parentId: null,
      name: subcategory.label,
      label: normalizeLabel(subcategory.label),
    }),
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: REMOVE_SUBCATEGORY_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export function* removeSubcategorySaga() {
  yield takeFirst(REMOVE_SUBCATEGORY_REQUEST, removeSubcategory);
}

export default combine([
  getCategoriesSaga,
  getCategoryByIdSaga,
  saveCategorySaga,
  getCategoriesByParentIdSaga,
  deleteCategorySaga,
  getSubcategoryCandidatsSaga,
  addSubcategorySaga,
  removeSubcategorySaga,
]);
