import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { formatOrderDeliveryDateTime } from 'utils/date';

import styles from './styles';

class OheicsOrdersErrorDialog extends React.Component {
  render() {
    const { open, onClose, classes, order, onSubmit } = this.props;
    return (
      <Dialog onClose={onClose} aria-labelledby="custom-dialog" open={open}>
        <DialogTitle className={classes.root}>
          Error!
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.content}>
            {order.oheicsErrors && order.oheicsErrors.length > 0 ? (
              <ul>
                {order.oheicsErrors.map((error) => (
                  <li key={error.id} style={{ padding: '4px 0' }}>
                    {formatOrderDeliveryDateTime(error.createDate)}:{' '}
                    {error.errorMessage}
                  </li>
                ))}
              </ul>
            ) : (
              <div>No Data</div>
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={onSubmit} color="primary">
            Retry sync with Oheics
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

OheicsOrdersErrorDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  order: PropTypes.shape({
    oheicsErrors: PropTypes.array,
    id: PropTypes.number.isRequired,
  }).isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(OheicsOrdersErrorDialog);
