const styles = {
  popover: {
    position: 'absolute',
    zIndex: 2,
  },
  cover: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  wrapper: {
    padding: '8px 0',
  },
  label: {
    marginRight: 15,
    marginBottom: 5,
  },
};

export default styles;
