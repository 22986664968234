import { refreshToken } from 'sagas/auth';

import { apiRequest } from './apiNotSecureRequest';

function* apiRequestWithTokenRefresh(url, options) {
  try {
    const payload = yield apiRequest(url, options);
    return payload;
  } catch (error) {
    if (error && error.status_code === 401) {
      const refreshPayload = yield refreshToken();
      if (refreshPayload) {
        const payload = yield apiRequest(url, options);
        return payload;
      } else {
        const error = { message: 'Could not refresh token' };
        throw error;
      }
    } else {
      throw error;
    }
  }
}

export default apiRequestWithTokenRefresh;
