import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_RESTAURANTS_REQUEST,
  GET_RESTAURANTS_SUCCESS,
  GET_RESTAURANTS_FAILURE,
  GET_RESTAURANT_BY_ID_REQUEST,
  GET_RESTAURANT_BY_ID_SUCCESS,
  GET_RESTAURANT_BY_ID_FAILURE,
  SAVE_RESTAURANT_REQUEST,
  SAVE_RESTAURANT_SUCCESS,
  SAVE_RESTAURANT_FAILURE,
  SYNC_OHEICS_RESTAURANT_REQUEST,
  SYNC_OHEICS_RESTAURANT_SUCCESS,
  SYNC_OHEICS_RESTAURANT_FAILURE,
  GET_RESTAURANTS_DICTIONARY_REQUEST,
  GET_RESTAURANTS_DICTIONARY_SUCCESS,
  GET_RESTAURANTS_DICTIONARY_FAILURE,
} from 'consts/restaurants';

export function getRestaurants({ ids, sortName, sortDirection }) {
  return {
    type: GET_RESTAURANTS_REQUEST,
    ids,
    sortName,
    sortDirection,
    [WAIT_FOR_ACTION]: GET_RESTAURANTS_SUCCESS,
    [ERROR_ACTION]: GET_RESTAURANTS_FAILURE,
  };
}

export function getRestaurantsDictionary(ids) {
  return {
    type: GET_RESTAURANTS_DICTIONARY_REQUEST,
    ids,
    [WAIT_FOR_ACTION]: GET_RESTAURANTS_DICTIONARY_SUCCESS,
    [ERROR_ACTION]: GET_RESTAURANTS_DICTIONARY_FAILURE,
  };
}

export function getRestaurantById(id) {
  return {
    type: GET_RESTAURANT_BY_ID_REQUEST,
    id,
    [WAIT_FOR_ACTION]: GET_RESTAURANT_BY_ID_SUCCESS,
    [ERROR_ACTION]: GET_RESTAURANT_BY_ID_FAILURE,
  };
}

export function saveRestaurant({
  id,
  name,
  locationId,
  streetAddress,
  city,
  postcode,
  latitude,
  longitude,
  phoneNumber,
  coverImgUrl,
  restaurantCode,
  timeZone,
  availableForPickup,
  availableForInfo,
  owner,
  openingHours,
  specialOpeningHours,
  nameVisible,
  deliveryRestaurant,
  seoTitle,
  seoDescription,
  seoCoverImage,
  restaurantOrganizationNumber,
  deliveryTimeout,
}) {
  return {
    type: SAVE_RESTAURANT_REQUEST,
    id,
    name,
    locationId,
    streetAddress,
    city,
    postcode,
    latitude,
    longitude,
    phoneNumber,
    coverImgUrl,
    restaurantCode,
    timeZone,
    owner,
    availableForPickup,
    availableForInfo,
    openingHours,
    specialOpeningHours,
    nameVisible,
    deliveryRestaurant,
    seoTitle,
    seoDescription,
    seoCoverImage,
    restaurantOrganizationNumber,
    deliveryTimeout,
    [WAIT_FOR_ACTION]: SAVE_RESTAURANT_SUCCESS,
    [ERROR_ACTION]: SAVE_RESTAURANT_FAILURE,
  };
}

export function syncRestaurants() {
  return {
    type: SYNC_OHEICS_RESTAURANT_REQUEST,
    [WAIT_FOR_ACTION]: SYNC_OHEICS_RESTAURANT_SUCCESS,
    [ERROR_ACTION]: SYNC_OHEICS_RESTAURANT_FAILURE,
  };
}
