import {
  compose,
  mapProps,
  lifecycle,
  withHandlers,
  withState,
} from 'recompose';
import { connect } from 'react-redux';

import { getProductionOrders } from 'actions/printList';
import { getRestaurants } from 'actions/restaurants';
import { routerPassQuery, hideIfNoData } from 'utils/enhancers';

import ProductionListPage from './ProductionListPage';
import groupToSections from './utils';

const mapDispatchToProps = {
  onInit: getProductionOrders,
  onGetRestaurants: getRestaurants,
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('selectedRestaurants', 'setSelectedRestaurants')
);

const refresh = (props) => {
  const { ids, onInit, setInitialData } = props;

  return onInit({ ids }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onGetRestaurants: (props) => () => {
    if (props.restaurantId) {
      props.onGetRestaurants({ ids: props.restaurantId }).then((data) => {
        props.setSelectedRestaurants(data.items);
      });
    }
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
    this.props.onGetRestaurants();
  },
});

const mapInitialData = mapProps(({ initialData, ...rest }) => ({
  sections: groupToSections(initialData.items),
  numberOfOrders: initialData.items.length,
  ...rest,
}));

export default compose(
  routerPassQuery,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData),
  mapInitialData
)(ProductionListPage);
