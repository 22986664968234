import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

class PageHeadingTitle extends React.PureComponent {
  render() {
    const { text, highlightedText } = this.props;
    return (
      <React.Fragment>
        {text}:
        <Typography
          component="span"
          style={{
            fontSize: 'inherit',
            display: 'inline-block',
            marginLeft: 10,
            color: 'black',
          }}
        >
          {highlightedText}
        </Typography>
      </React.Fragment>
    );
  }
}

PageHeadingTitle.propTypes = {
  text: PropTypes.string.isRequired,
  highlightedText: PropTypes.string.isRequired,
};

export default PageHeadingTitle;
