import React from 'react';

import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import styles from './styles';

const RichTextMarckup = ({
  value,
  component: Component = 'p',
  className,
  classes,
}) => {
  if (typeof value === 'string') {
    return <Component className={className || ''}>{value}</Component>;
  } else if (value instanceof Object) {
    try {
      let options = {
        inlineStyles: {
          BOLD: { element: 'b', attributes: { className: classes.bold } },
          ITALIC: {
            attributes: { className: classes.italic },
          },
        },

        inlineStyleFn: (styles) => {
          const color = styles
            .filter((value) => value.startsWith('color-'))
            .first();
          const fontSize = styles
            .filter((value) => value.startsWith('fontsize-'))
            .first();

          const isSuperscript = styles
            .filter((value) => value.includes('SUPERSCRIPT'))
            .first();
          const isSubscript = styles
            .filter((value) => value.includes('SUBSCRIPT'))
            .first();

          if (color || fontSize || isSuperscript || isSubscript) {
            return {
              element: 'span',
              style: {
                ...(color && { color: color.replace('color-', '') }),
                ...(fontSize && {
                  fontSize: fontSize.replace('fontsize-', ''),
                }),
                ...(isSuperscript && {
                  verticalAlign: 'super',
                  fontSize: 10,
                }),
                ...(isSubscript && {
                  verticalAlign: 'sub',
                  fontSize: 10,
                }),
              },
            };
          }
        },
        blockStyleFn: (block) => {
          const color = block.getData().get('color');
          const textAlign = block.getData().get('text-align');
          return {
            ...((color || textAlign) && {
              style: {
                ...(color && { color }),
                ...(textAlign && { textAlign }),
              },
            }),
          };
        },
      };

      return (
        <Component
          className={`${classes.reachTextContainer}${
            className ? ` ${className}` : ''
          }`}
          dangerouslySetInnerHTML={{
            __html: stateToHTML(convertFromRaw(value), options),
          }}
        />
      );
    } catch (error) {
      console.warn("Can't read article", error);
    }
  }
  return null;
};

RichTextMarckup.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  className: PropTypes.string,
};

export default injectSheet(styles)(RichTextMarckup);
