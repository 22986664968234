import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { toastr } from 'react-redux-toastr';

import TimeControl from 'components/TimeControl';
import SmallButton from 'components/SmallButton';

const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const findDayData = (weekDay, daysData) => ({
  ...(daysData.find((dd) => dd.weekDay === weekDay) || {
    opens: undefined,
    closes: undefined,
  }),
  weekDay,
});

const isFilled = ({ opens, closes }) => Boolean(opens) || Boolean(closes);

class OpeningHours extends React.PureComponent {
  state = {
    copyingWeekDay: null,
  };

  onCopy = (weekDay) => {
    this.setState({ copyingWeekDay: weekDay });
  };

  onCancelCopy = () => {
    this.setState({ copyingWeekDay: null });
  };

  onPaste = (weekDay) => {
    const { daysData } = this.props;
    const { copyingWeekDay } = this.state;
    const selectedDay =
      daysData.find((dd) => dd.weekDay === copyingWeekDay) || {};
    const newSelectedDay = { ...selectedDay, weekDay };
    const otherDays = daysData.filter((dd) => dd.weekDay !== weekDay);
    const newValue = isFilled(newSelectedDay)
      ? [...otherDays, newSelectedDay]
      : otherDays;
    this.props.onChange(newValue);
  };

  onOpenTimeChange = (weekDay, time) => {
    const { daysData } = this.props;
    const selectedDay = daysData.find((dd) => dd.weekDay === weekDay) || {};
    const otherDays = daysData.filter((dd) => dd.weekDay !== weekDay);
    const newSelectedDay = {
      ...selectedDay,
      opens: time,
      weekDay,
    };
    const newValue = isFilled(newSelectedDay)
      ? [...otherDays, newSelectedDay]
      : otherDays;
    this.props.onChange(newValue);
  };

  onCloseTimeChange = (weekDay, time) => {
    const { daysData } = this.props;
    const day = daysData.find((item) => item.weekDay === weekDay);
    if (
      day &&
      day.opens &&
      ((day.opens.hour <= 0 && time.hour <= 0) ||
        (day.opens.hour && time.hour <= day.opens.hour))
    ) {
      toastr.error('Error!', 'Close time should be more than opened time');
      return;
    }
    const selectedDay = daysData.find((dd) => dd.weekDay === weekDay) || {};
    const otherDays = daysData.filter((dd) => dd.weekDay !== weekDay);
    const newSelectedDay = {
      ...selectedDay,
      closes: time,
      weekDay,
    };
    const newValue = isFilled(newSelectedDay)
      ? [...otherDays, newSelectedDay]
      : otherDays;
    this.props.onChange(newValue);
  };

  render() {
    const { daysData } = this.props;
    const dataList = weekDays.map((weekDay) => findDayData(weekDay, daysData));
    const { copyingWeekDay } = this.state;
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Week day</TableCell>
              <TableCell>Opens</TableCell>
              <TableCell>Closes</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((item) => {
              return (
                <TableRow key={item.weekDay}>
                  <TableCell>{item.weekDay}</TableCell>
                  <TableCell>
                    <TimeControl
                      value={item.opens}
                      onChange={(time) =>
                        this.onOpenTimeChange(item.weekDay, time)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TimeControl
                      value={item.closes}
                      onChange={(time) =>
                        this.onCloseTimeChange(item.weekDay, time)
                      }
                    />
                  </TableCell>
                  <TableCell align="right">
                    {copyingWeekDay && (
                      <React.Fragment>
                        {copyingWeekDay === item.weekDay ? (
                          <SmallButton onClick={() => this.onCancelCopy()}>
                            Stop
                          </SmallButton>
                        ) : (
                          <SmallButton
                            onClick={() => this.onPaste(item.weekDay)}
                          >
                            Paste
                          </SmallButton>
                        )}
                      </React.Fragment>
                    )}
                    {!copyingWeekDay && (
                      <SmallButton onClick={() => this.onCopy(item.weekDay)}>
                        Copy
                      </SmallButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const timeShape = PropTypes.shape({
  hour: PropTypes.number,
  minute: PropTypes.number,
});

OpeningHours.propTypes = {
  classes: PropTypes.object,
  daysData: PropTypes.arrayOf(
    PropTypes.shape({
      weekDay: PropTypes.string,
      opens: timeShape,
      closes: timeShape,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

OpeningHours.defaultProps = {
  daysData: [],
};

export default OpeningHours;
