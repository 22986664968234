import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import List from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const links = [
  {
    type: 'link',
    text: 'Delivery',
    to: '/delivery-orders',
    selected: (props) => props.location.pathname.startsWith('/delivery-orders'),
  },
  {
    type: 'link',
    text: 'Pick-up',
    to: '/pickup-orders',
    selected: (props) => props.location.pathname.startsWith('/pickup-orders'),
  },
  {
    type: 'group',
    text: 'Ecom content',
    links: [
      {
        text: 'Restaurants',
        to: '/restaurants',
        selected: (props) => props.location.pathname.startsWith('/restaurant'),
      },
      {
        text: 'Menu items',
        to: '/menu-items',
        selected: (props) => props.location.pathname.startsWith('/menu-item'),
      },
      {
        text: 'Groups',
        to: '/groups',
        selected: (props) => props.location.pathname.startsWith('/group'),
      },
      {
        text: 'Categories',
        to: '/categories',
        selected: (props) => props.location.pathname.startsWith('/categor'),
      },
      {
        text: 'Tags',
        to: '/tags',
        selected: (props) => props.location.pathname.startsWith('/tag'),
      },
    ],
  },
  {
    type: 'link',
    text: 'Orders',
    to: '/orders',
    selected: (props) => props.location.pathname.startsWith('/order'),
  },
  {
    type: 'link',
    text: 'Customers',
    to: '/customers',
    selected: (props) => props.location.pathname.startsWith('/customer'),
  },
  {
    type: 'link',
    text: 'Companies',
    to: '/companies',
    selected: (props) => props.location.pathname.startsWith('/compan'),
  },
  {
    type: 'group',
    text: 'Loyalty',
    links: [
      {
        text: 'Kickbacks',
        to: '/kickbacks',
        selected: (props) => props.location.pathname.startsWith('/kickback'),
      },
      {
        text: 'Stamp Cards',
        to: '/stampcards',
        selected: (props) => props.location.pathname.startsWith('/stampcards'),
      },
      {
        text: 'Store Credits',
        to: '/store-credits',
        selected: (props) =>
          props.location.pathname.startsWith('/store-credits'),
      },
    ],
  },
  {
    type: 'group',
    text: 'Dynamic content',
    links: [
      {
        text: 'Header content',
        to: '/header-content',
        selected: (props) =>
          props.location.pathname.startsWith('/header-content'),
      },
      {
        text: 'Footer menu',
        to: '/footer-menu',
        selected: (props) => props.location.pathname.startsWith('/footer-menu'),
      },
      {
        text: 'Footer partners list',
        to: '/footer-partners-list',
        selected: (props) =>
          props.location.pathname.startsWith('/footer-partners-list'),
      },
      {
        text: 'Footer social icons',
        to: '/footer-social-icons',
        selected: (props) =>
          props.location.pathname.startsWith('/footer-social-icons'),
      },
      {
        text: 'Landing page',
        to: '/landing-content',
        selected: (props) =>
          props.location.pathname.startsWith('/landing-content'),
      },
    ],
  },
  {
    type: 'group',
    text: 'Settings',
    links: [
      {
        text: 'Production list sorting',
        to: '/production-list',
        selected: (props) =>
          props.location.pathname.startsWith('/production-list'),
      },
      {
        text: 'Pack list sorting',
        to: '/pack-list',
        selected: (props) => props.location.pathname.startsWith('/pack-list'),
      },
      {
        text: 'General',
        to: '/settings',
        selected: (props) => props.location.pathname.startsWith('/settings'),
      },
    ],
  },
];

const findSelectedGroup = (props) => {
  const groups = links.filter(({ type }) => type === 'group');
  const selectedGroup = groups.find((g) => {
    return g.links.find((l) => l.selected(props));
  });
  return selectedGroup;
};

class Links extends React.PureComponent {
  constructor(props) {
    super(props);
    const selectedGroup = findSelectedGroup(props);
    this.state = { selectedGroup: selectedGroup ? selectedGroup.text : null };
  }

  onSelectGroup = (text) => {
    const { selectedGroup } = this.state;
    this.setState({
      selectedGroup: selectedGroup === text ? null : text,
    });
  };

  renderLink = (item, divider, button) => {
    const { onClearFilters } = this.props;

    return (
      <Link
        key={item.to}
        to={item.to}
        style={{ cursor: 'pointer' }}
        onClick={onClearFilters}
      >
        <MuiListItem
          button={button}
          divider={divider}
          selected={item.selected(this.props)}
        >
          <MuiListItemText primary={item.text} />
        </MuiListItem>
      </Link>
    );
  };

  render() {
    const { selectedGroup } = this.state;
    const pageSelectedGroup = findSelectedGroup(this.props);

    return (
      <List disablePadding>
        {links.map((item) => {
          if (item.type === 'link') {
            return this.renderLink(item, true, true);
          } else {
            const selected =
              pageSelectedGroup && pageSelectedGroup.text === item.text;
            return (
              <React.Fragment key={item.text}>
                <MuiListItem
                  button
                  divider
                  selected={selected}
                  onClick={() => this.onSelectGroup(item.text)}
                >
                  <MuiListItemText primary={item.text} />
                  {selectedGroup === item.text ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </MuiListItem>
                <Collapse
                  in={selectedGroup === item.text}
                  timeout={0}
                  unmountOnExit
                >
                  <List component="div" style={{ paddingLeft: 8 }}>
                    {item.links.map((link, index) =>
                      this.renderLink(link, index === item.links.length - 1)
                    )}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          }
        })}
      </List>
    );
  }
}

Links.propTypes = {
  onClearFilters: PropTypes.func.isRequired,
};

export default withRouter(Links);
