import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import Input from '@material-ui/core/TextField';

import Select from 'components/Select';
import PageHeading from 'components/PageHeading';
import PageHeadingButton from 'components/PageHeadingButton';
import TableSortCell from 'components/TableSortCell';
import TableCount from 'components/TableCount';
import TableActionCell from 'components/TableActionCell';
import NoData from 'components/NoData';

const SEARCH_DELAY = 500;
const FIELDS_TO_SEARCH = [
  {
    label: 'ID',
    value: 'id',
  },
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Org Number',
    value: 'organisationNumber',
  },
];

class CompaniesListPage extends React.PureComponent {
  state = {
    search: this.props.search || '',
    searchParam: this.props.searchParam
      ? FIELDS_TO_SEARCH.find((item) => item.value === this.props.searchParam)
      : FIELDS_TO_SEARCH[0],
  };

  timer;

  onSearchChange = ({ target: { value } }) => {
    this.setState({ search: value }, () => {
      clearTimeout(this.timer);
      this.timer = undefined;
      this.timer = setTimeout(() => {
        this.props.onSearch(this.state);
      }, SEARCH_DELAY);
    });
  };

  handleSearchParamChange = (searchParam) => {
    this.setState({ searchParam });
  };

  handleSort = (sortName, sortDirection) => {
    return this.props.onSort({ sortName, sortDirection });
  };

  handlePage = (event, page) => {
    return this.props.onPage({ page });
  };

  render() {
    const { items, totalResults } = this.props.initialData;
    const { sortName, sortDirection, pageLimit, page } = this.props;
    const { search, searchParam } = this.state;
    return (
      <React.Fragment>
        <PageHeading
          title="Companies"
          right={
            <PageHeadingButton link="/company-details">
              + New company
            </PageHeadingButton>
          }
        />
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div style={{ display: 'inline-block', marginRight: 48 }}>
            <Select
              options={FIELDS_TO_SEARCH}
              placeholder="Field to search"
              value={searchParam}
              onChange={this.handleSearchParamChange}
              label="Field to search"
            />
          </div>
          <Input
            type="text"
            value={search}
            onChange={this.onSearchChange}
            placeholder="Search"
            label="Search"
            style={{ paddingBottom: 4, marginRight: 20 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <TableCount count={totalResults} text="items in current selection" />
        <Paper style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableSortCell
                  title="ID"
                  sortName="id"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Name"
                  sortName="name"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableSortCell
                  title="Org number"
                  sortName="organisationNumber"
                  currentSortName={sortName}
                  currentSortDirection={sortDirection}
                  handleSort={this.handleSort}
                />
                <TableCell>Pending users</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.name || <NoData>No name</NoData>}</TableCell>
                  <TableCell>
                    {item.organisationNumber || <NoData>No Org number</NoData>}
                  </TableCell>
                  <TableCell>{item.pendingUsers}</TableCell>
                  <TableActionCell
                    link={`/company-details/${item.id}`}
                    icon="edit"
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageLimit]}
            component="div"
            count={totalResults}
            rowsPerPage={pageLimit}
            page={page}
            onChangePage={this.handlePage}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

CompaniesListPage.propTypes = {
  initialData: PropTypes.shape({
    items: PropTypes.array,
    totalResults: PropTypes.number,
  }).isRequired,
  sortName: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onPage: PropTypes.func.isRequired,
  search: PropTypes.string,
  searchParam: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

export default CompaniesListPage;
