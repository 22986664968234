import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import DynamicLink from 'shared/components/DynamicLink';

import styles from './styles';

class LandingLink extends React.PureComponent {
  render() {
    const { classes, item, color } = this.props;

    return (
      <div className={classes.linkWrapper}>
        <DynamicLink className={classes.link} item={item}>
          <span className={classes.arrow}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="12"
              viewBox="0 0 7 12"
            >
              <path
                fill="none"
                fillRule="nonzero"
                stroke={color}
                strokeWidth="2"
                d="M.875 11.4L6.125 6 .875.6"
              />
            </svg>
          </span>
        </DynamicLink>
      </div>
    );
  }
}

LandingLink.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};

export default injectSheet(styles)(LandingLink);
