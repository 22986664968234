import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';

const mapOption = (item) => ({
  label: item.name,
  value: item.id,
});

class CategoriesDropdown extends React.PureComponent {
  handleChange = ({ value }) => {
    const { items } = this.props.initialData;
    const category = items.find((i) => i.id === value);
    this.props.onChange(category);
  };

  render() {
    const { items } = this.props.initialData;
    const { value } = this.props;
    return (
      <Select
        options={items.map(mapOption)}
        placeholder="Select category"
        value={value ? mapOption(value) : null}
        onChange={this.handleChange}
      />
    );
  }
}

CategoriesDropdown.propTypes = {
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default CategoriesDropdown;
