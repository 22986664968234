import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import randomstring from 'randomstring';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';

import EditLinkModal from 'components/EditLinkModal';
import PageHeading from 'components/PageHeading';
import Section from 'components/Section';
import ImageUpload from 'components/ImageUpload';

import { reorder } from 'utils/processors';

import styles from './styles';

class FooterMenuLinks extends React.PureComponent {
  state = {
    items: this.props.items,
    logo: this.props.logo || {},
    license: this.props.license || '',
    modalShown: false,
    selectedItem: null,
  };

  handleOpenModal = (item) => {
    this.setState({
      modalShown: true,
      selectedItem: item,
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalShown: false,
      selectedItem: null,
    });
  };

  reorder = (sourceIndex, destinationIndex, cb) => {
    const { items } = this.state;
    const newItems = reorder(items, sourceIndex, destinationIndex);

    this.setState({ items: newItems }, cb && cb());
  };

  handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    this.reorder(result.source.index, result.destination.index);
  };

  handleDelete = (index) => {
    const { items } = this.state;
    const newItems = items.filter((value, i) => i !== index);
    this.setState({
      items: newItems,
    });
  };

  handleEditItem = (id, newItem) => {
    const { items } = this.state;
    const newItems = items.map(
      (item) =>
        item.id === id
          ? {
              ...item,
              ...newItem,
            }
          : item
    );
    this.setState({
      items: newItems,
    });
  };

  handleAddItem = (newItem) => {
    const { items } = this.state;

    this.setState({
      items: [...items, { ...newItem, id: randomstring.generate(7) }],
    });
  };

  handleSaveItem = (data, id) => {
    const { items } = this.state;
    const itemToSave = (items || []).find((link) => link.id === id);

    if (itemToSave) {
      this.handleEditItem(id, data);
    } else {
      this.handleAddItem(data);
    }
  };

  handleLogoChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      logo: {
        ...this.state.logo,
        [fieldName]: value,
      },
    });
  };

  handleLicenseChange = ({ target: { value: license } }) =>
    this.setState({
      license,
    });

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { items, logo, license } = this.state;

    onSubmit({
      linksList: items.map((item, index) => ({
        ...item,
        index,
      })),
      logo,
      license,
    });
  };

  render() {
    const { classes } = this.props;
    const { items, logo, license, modalShown, selectedItem } = this.state;

    return (
      <React.Fragment>
        <PageHeading
          title="Footer menu"
          right={
            <div>
              <Button
                onClick={this.handleOpenModal}
                variant="contained"
                size="large"
                color="primary"
              >
                + New item
              </Button>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                size="large"
                style={{ marginLeft: 24 }}
              >
                Save
              </Button>
            </div>
          }
        />
        {items && items.length > 0 ? 'Drag and drop items to change order' : ''}
        {items &&
          items.length > 0 && (
            <Paper style={{ marginTop: 15 }}>
              <div className={classes.headItemsWrapper}>
                <div className={cn(classes.headItem, classes.orderCell)} />
                <div className={cn(classes.headItem, classes.nameCell)}>
                  Link label
                </div>
                <div className={cn(classes.headItem, classes.linkCell)}>
                  Link url
                </div>
                <div className={cn(classes.headItem, classes.editCell)} />
                <div className={cn(classes.headItem, classes.deleteCell)} />
              </div>
              <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <ul
                      className={cn(classes.menuItemsList)}
                      ref={provided.innerRef}
                    >
                      {items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <li
                              className={cn(
                                classes.menuItemsListItem,
                                snapshot.isDragging &&
                                  classes.menuItemsListItemHover
                              )}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                className={cn(classes.cell, classes.orderCell)}
                              >
                                {index + 1}
                              </div>
                              <div
                                className={cn(classes.cell, classes.nameCell)}
                              >
                                {item.label}
                              </div>
                              <div
                                className={cn(classes.cell, classes.nameCell)}
                              >
                                {item.url}
                              </div>
                              <div
                                className={cn(classes.cell, classes.editCell)}
                                style={{ paddingRight: 0 }}
                              >
                                <IconButton
                                  aria-label="Edit"
                                  color="primary"
                                  onClick={() => this.handleOpenModal(item)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </div>
                              <div
                                className={cn(classes.cell, classes.deleteCell)}
                              >
                                <IconButton
                                  aria-label="Delete"
                                  color="primary"
                                  onClick={() => this.handleDelete(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </Paper>
          )}
        <Section heading="Footer logo">
          <div className={classes.imagePreviewBg}>
            <ImageUpload
              image={logo.imageURL || ''}
              onChange={this.handleLogoChange('imageURL')}
              placeholder="Desktop logo URL"
              label="Desktop logo URL"
            />
          </div>
          <div style={{ width: 360, marginRight: 20 }}>
            <Input
              value={logo.imageSEOAlt || ''}
              type="string"
              onChange={this.handleLogoChange('imageSEOAlt')}
              label="SEO alt text"
              margin="normal"
              style={{ width: 360 }}
            />
          </div>
        </Section>
        <Section heading="Copyright license">
          <div style={{ width: 360, marginRight: 20 }}>
            <Input
              value={license || ''}
              type="string"
              onChange={this.handleLicenseChange}
              label="License"
              margin="normal"
              style={{ width: 360 }}
            />
          </div>
        </Section>
        <EditLinkModal
          open={modalShown}
          item={selectedItem}
          key={selectedItem}
          onClose={this.handleCloseModal}
          onSave={this.handleSaveItem}
        />
      </React.Fragment>
    );
  }
}

FooterMenuLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  logo: PropTypes.shape({
    imageURL: PropTypes.string,
    imageSEOAlt: PropTypes.string,
  }),
  license: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(FooterMenuLinks);
