import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import { ChromePicker } from 'react-color';

import buttonsStyles from '../ToolbarButtonsBar/styles';
import styles from './styles';

class ToolbarLinkBar extends PureComponent {
  state = {
    color: this.props.currentState ? this.props.currentState.color : '',
  };

  handleChange = (color) => {
    this.setState({ color: color.hex });
  };

  handleClose = () => {
    const { color = '' } = this.props.currentState;
    if (this.state.color !== color) {
      this.props.onChange('color', this.state.color);
    }

    this.props.openModalbyType('');
  };

  handleOpen = () => {
    this.props.openModalbyType('colorPicker');
  };

  render() {
    const { config, openedModalType, classes, className } = this.props;

    const Icon = config.icon;
    return (
      <div>
        <Button
          classes={{ root: classes.toolbarButon }}
          className={className}
          onClick={this.handleOpen}
        >
          {typeof Icon === 'function' ? (
            <Icon />
          ) : (
            <img src={Icon} alt="Color" />
          )}
        </Button>

        {openedModalType === 'colorPicker' ? (
          <div className={classes.popover}>
            <div className={classes.cover} onClick={this.handleClose} />
            <ChromePicker
              color={this.state.color}
              disableAlpha
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ToolbarLinkBar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.object,
  config: PropTypes.object,
  currentState: PropTypes.object,
  onChange: PropTypes.func,
  openedModalType: PropTypes.string,
  openModalbyType: PropTypes.func,
};

export default injectSheet({ ...buttonsStyles, ...styles })(ToolbarLinkBar);
