export function calculateFreeItems(items, countLimit) {
  let freeItems = countLimit;
  const selectedItems = items.filter((it) => it.selected);
  const freeItemsDict = {};
  if (selectedItems && selectedItems.length) {
    selectedItems.forEach((item) => {
      freeItemsDict[item.id] =
        freeItems > item.numberOfItems ? item.numberOfItems : freeItems;
      freeItems -= freeItemsDict[item.id];
    });
  }
  items.filter((it) => !it.selected).forEach((item) => {
    freeItemsDict[item.id] =
      (freeItemsDict[item.id] || 0) + freeItems > item.numberOfItems
        ? item.numberOfItems
        : freeItems;
    freeItems -= freeItemsDict[item.id];
  });
  return freeItemsDict;
}

export function processDescriptionFreeItems(countLimit, freeItems, item) {
  let str = '';
  if (freeItems[item.id] && countLimit >= freeItems[item.id]) {
    str += `${freeItems[item.id]} ${
      freeItems[item.id] === 1 ? 'Vald' : 'Valda'
    }`;
  }
  if (freeItems[item.id] && item.numberOfItems > freeItems[item.id]) {
    str += `, +${item.price * (item.numberOfItems - freeItems[item.id])} kr`;
  }
  if (!freeItems[item.id]) {
    str += `+${item.price * (item.numberOfItems || 1)} kr`;
  }
  return str;
}

export function calculateMenuItemPrice({
  item,
  numberOfItems,
  selectedGroupItems,
}) {
  let price = toFixedFloat(item.price * numberOfItems);
  if (item.groups && selectedGroupItems) {
    price = selectedGroupItems.reduce((acc, group) => {
      let totalLimit = group.countLimit * numberOfItems;
      const groupPrice = group.values
        .sort((a, b) => b.price - a.price)
        .reduce((groupAcc, selectedValue) => {
          let groupItemPrice = 0;
          if (selectedValue.numberOfItems >= totalLimit) {
            groupItemPrice =
              (selectedValue.numberOfItems - totalLimit) * selectedValue.price;
            totalLimit = 0;
          } else {
            groupItemPrice = 0;
            totalLimit -= selectedValue.numberOfItems;
          }
          return groupAcc + groupItemPrice;
        }, 0);
      return acc + groupPrice;
    }, price);
  }
  return toFixedFloat(price);
}

function toFixedFloat(number) {
  return parseFloat(number.toFixed(2));
}

export function calculateTotalMenuItemsPrice(items) {
  return items
    ? items.reduce(
        (acc, { item, numberOfItems, selectedGroupItems }) =>
          acc +
          calculateMenuItemPrice({ item, numberOfItems, selectedGroupItems }),
        0
      )
    : 0;
}
