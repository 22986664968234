const styles = {
  textField: {
    marginTop: 8,

    '& > label': {
      transform: 'translate(0, 1.5px) scale(0.75)',
      transformOrigin: ['top', 'left'],
    },
  },

  editorWrapper: {
    marginTop: 8,
  },
  editorToolbarWrapper: {
    margin: [0, -8, 0, -8],
    padding: 0,
    border: 'none',

    '& > *': {
      margin: [0, 8, 8, 8],
      color: (props) =>
        props.theme ? props.theme.palette.text.hint : 'rgba(0,0,0,0.38)',
    },
  },
  editorInputWrapper: {
    marginTop: -4,
  },
  toolbarBlockTypeSelect: {
    maxWidth: 112,
    width: '100%',
  },
  toolbarFontSizeSelect: {
    maxWidth: 64,
    width: '100%',
  },
};

export default styles;
