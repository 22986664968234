import 'babel-polyfill';
import 'custom-event-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import promiseFinally from 'promise.prototype.finally';
import 'scroll-restoration-polyfill';

import Root from 'components/Root';
import configureStore, { history } from 'store/configureStore';

import 'styles/reset.css';
import 'styles/main.css';
import 'styles/print.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

promiseFinally.shim();
window.history.scrollRestoration = 'manual';

const { store, persistor } = configureStore();

ReactDOM.render(
  <Root store={store} history={history} persistor={persistor} />,
  document.getElementById('root')
);
