export const styleConsts = {
  wrapperWidth: 1200,
  listWidthTablet: 588,
  coverImgMbSize: 290,
  coverImgTabletSize: 350,
  coverImgDeskSize: 400,
  mainBorderRadius: '4px',
  pagePadding: 20,
  blockMargin: 24,
  blockMarginSmall: 16,
  sectionMargin: 64,
  headerMbHeight: 60,
  headerDeskHeight: 80,
  subheaderMbHeight: 56,
  subheaderDeskHeight: 60,
  textColor: '#1d1d1b',
  secondTextColor: '#838383',
  foodItemHoverColor: 'rgba(255, 255, 255, 0.6)',
  mobilePageBg: '#f5f4f2',
  red: '#e65653',
  blue: '#c3edef',
  pink: '#f5e3e6',
  yellow: '#f7f3bd',
  green: '#a9cd71',
  errorColor: '#e65653',
  darkGreen: '#114a2c',
  lightGreen: '#e6f4bf',
  lightYellow: '#f7f3bd',
  inputBgColor: '#f5f4f2',
  pageMobileBg: '#f2f2f2',
  sectionShadow: '0 1px 0 0 rgba(0, 0, 0, 0.1)',
  mainBorderColor: '#e6e6e6',
  mainBorder: '1px solid #e6e6e6',
  inputBorder: '2px solid #f5f4f2',
  placeholderColor: 'rgba(0, 0, 0, 0.2)',
  authBgColor: '#f7f6f2',
  footerBgColor: '#f5f4f2',
  dropdownBoxShadow:
    '0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 4px 6px 0 rgba(0, 0, 0, 0.11)',
};
