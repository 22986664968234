import { put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  PRE_AUTH_REQUEST,
  PRE_AUTH_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  LOGOUT,
} from 'consts/auth';

import { apiNotSecureRequest } from 'sagas/utils/apiNotSecureRequest';

import { combine, takeFirst } from './utils/effects';

function* tokenRequest(data) {
  const url = '/sign-in';
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data || {}),
  };
  const payload = yield apiNotSecureRequest(url, options);
  return payload;
}

function* preAuth({ phone }) {
  const url = '/password';
  try {
    const tokenPayload = yield tokenRequest();
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenPayload.accessToken}`,
      },
      body: JSON.stringify({ phone, type: 'signIn' }),
    };
    const payload = yield apiNotSecureRequest(url, options);
    const newAction = {
      type: PRE_AUTH_SUCCESS,
      payload,
    };
    yield put(newAction);
  } catch (error) {
    yield put({
      type: AUTH_FAILURE,
      error,
    });
    return null;
  }
}

function* preAuthSaga() {
  yield takeFirst(PRE_AUTH_REQUEST, preAuth);
}

function* checkPermissions(token) {
  const url = `/users/${token.userId}`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.accessToken}`,
    },
  };
  const payload = yield apiNotSecureRequest(url, options);
  if (payload.roles && payload.roles.find((r) => r.label === 'cm')) {
    return payload;
  } else {
    const error = { message: "User doesn't have permissions" };
    throw error;
  }
}

function* auth({ phone, smsCode }) {
  try {
    const payload = yield tokenRequest({ phone, code: smsCode });
    yield checkPermissions(payload);
    yield put({ type: AUTH_SUCCESS, payload });
    yield put(push('/'));
    return payload;
  } catch (error) {
    yield put({
      type: AUTH_FAILURE,
      error,
    });
    return null;
  }
}

function* authSaga() {
  yield takeFirst(AUTH_REQUEST, auth);
}

const tokenSelector = (state) => state.auth.token;

function* refreshTokenError(error) {
  yield put({ type: LOGOUT });
  yield put({ type: REFRESH_TOKEN_FAILURE, error });
  return null;
}

export function* refreshToken() {
  const token = yield select(tokenSelector);
  if (!token) {
    return yield refreshTokenError({ error: 'No token specified' });
  }
  try {
    const url = `/refresh-token`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.refreshToken}`,
      },
    };
    const payload = yield apiNotSecureRequest(url, options);
    yield put({
      type: REFRESH_TOKEN_SUCCESS,
      payload,
    });
    return payload;
  } catch (error) {
    return yield refreshTokenError(error);
  }
}

export default combine([authSaga, preAuthSaga]);
