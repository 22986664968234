import n from 'normalize-strings';

import { API_ROOT } from 'consts';
import { MENU_ITEMS_ATTRIBUTES } from 'consts/menu-items';

const jsSortPrefix = 'js:';

/**
 * Sort payload items by sortName with values of type string.
 * @param {Object} payload - Request payload.
 * @param {String} sortDirection - Direction for sorting.
 * @param {String} sortName - Name of field to sort. Should start from 'js:'
 * @return {Object} Sorted payload items.
 */
export const sortPayloadItems = (payload, { sortName, sortDirection }) => {
  const fieldName = sortName ? sortName.substring(jsSortPrefix.length) : '';
  return {
    ...payload,
    items:
      payload && payload.items && payload.items.length > 0
        ? payload.items.sort(function(a, b) {
            const multiplier = sortDirection === 'asc' ? 1 : -1;
            return (
              multiplier *
              (a[fieldName] || '')
                .toString()
                .localeCompare((b[fieldName] || '').toString())
            );
          })
        : [],
  };
};

/**
 * Prepare categories payload.
 * @param {Object} payload - Request payload.
 * @param {Object} options - Options for sorting payload.
 * @return {Object}.
 */
export const fixCategoriesPayload = (payload, options) => {
  const result = {
    ...payload,
    items:
      payload && payload.items && payload.items.length > 0
        ? payload.items.map((c) => ({
            ...c,
            parentName: (payload.items.find((p) => p.id === c.parentId) || {})
              .name,
          }))
        : [],
  };
  if (
    options &&
    options.sortName &&
    options.sortName.startsWith(jsSortPrefix)
  ) {
    return sortPayloadItems(result, options);
  }
  return result;
};

/**
 * Prepare menu items payload.
 * @param {Object} payload - Request payload.
 * @param {Array} categories - Array of categories ids.
 * @param {Array} attributes - Array of attributes ids.
 * @param {Object} options - Options for sorting payload.
 * @return {Object}.
 */
export const fixMenuItemsPayload = (
  payload,
  categories,
  attributes,
  options
) => {
  let result = {
    ...payload,
    items:
      payload && payload.items && payload.items.length > 0
        ? payload.items.map((mi) => ({
            ...mi,
            categoryName:
              categories && categories.length > 0
                ? (categories.find((c) => c.id === mi.categoryId) || {}).name
                : '',
            attributes:
              mi && mi.attributes && mi.attributes.length > 0
                ? mi.attributes.map((attr) => ({
                    ...attr,
                    label:
                      attributes && attributes.length > 0
                        ? (
                            attributes.find((a) => a.id === attr.attributeId) ||
                            {}
                          ).label
                        : '',
                  }))
                : [],
          }))
        : [],
  };
  if (
    options &&
    options.sortName &&
    options.sortName.startsWith(jsSortPrefix)
  ) {
    result = sortPayloadItems(result, options);
  }
  if (options && options.onlyAvailableForRestaurant && options.restaurantId) {
    result = {
      ...result,
      items: filterMenuItemsByAvailability(result.items, options.restaurantId),
    };
  }
  return result;
};

const filterMenuItemsByAvailability = (menuItems, restaurantId) => {
  return menuItems.filter((item) => {
    const available = item.attributes.find(
      (attr) =>
        attr.restaurantId === restaurantId &&
        attr.label === MENU_ITEMS_ATTRIBUTES.available &&
        attr.value === 'true'
    );
    const outOfStock = item.attributes.find(
      (attr) =>
        attr.restaurantId === restaurantId &&
        attr.label === MENU_ITEMS_ATTRIBUTES.outOfStock &&
        attr.value === 'true'
    );
    return available && !outOfStock;
  });
};

/**
 * Prepare restaurants payload.
 * @param {Object} payload - Request payload.
 * @param {Object} options - Options for sorting payload.
 * @return {Object}.
 */
export const fixRestaurantsPayload = (payload, options) => {
  if (
    options &&
    options.sortName &&
    options.sortName.startsWith(jsSortPrefix)
  ) {
    return sortPayloadItems(payload, options);
  }
  return payload;
};

/**
 * Check if passed value is valid by Luhn algorith.
 * @param {String} value - Value to check.
 * @return {Boolean} Returns true if value is valid.
 */
export const luhnAlgorithValidation = (value) => {
  if (!value) return false;

  return (
    value
      .toString()
      .split('')
      .reverse()
      .map((x) => parseInt(x, 10))
      .map((x, idx) => (idx % 2 ? x * 2 : x))
      .map((x) => (x > 9 ? (x % 10) + 1 : x))
      .reduce((accum, x) => (accum += x)) %
    10 ===
    0
  );
};

/**
 * Check if passed email is valid.
 * @param {String} email - Value to check.
 * @return {Boolean} Returns true if value is valid.
 */
export function validateEmail(email) {
  if (!email) {
    return false;
  }
  // The Official Standard: RFC 5322
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * Check if passed phone number is valid.
 * @param {String} phone - Value to check.
 * @return {Boolean} Returns true if value is valid.
 */
export function validatePhoneNumber(phone) {
  if (!phone) {
    return false;
  }
  const re = /^\+?\d{6,14}$/im;
  return re.test(phone.replace(/\s/g, ''));
}

export function normalizePhoneNumber(number) {
  return number.substring(0, 2) === '07'
    ? number.replace(/07/, '+467')
    : number;
}

/**
 * Check if passed zipcode is valid.
 * @param {String} zipCode - Value to check.
 * @return {Boolean} Returns true if value is valid.
 */
export function validateZipCode(zipCode) {
  if (!zipCode) {
    return false;
  }
  const re = /^\d{5}$/im;
  return re.test(zipCode.toString().replace(/\s/g, ''));
}

/**
 * Check if passed organization number is valid.
 * @param {String} orgNumber - Value to check.
 * @return {Boolean} Returns true if value is valid.
 */
export function validateOrgNumber(orgNumber) {
  if (!orgNumber) return false;

  const re = /^\d{10,12}$/im;
  return (
    re.test(orgNumber.replace(/\s/g, '')) && luhnAlgorithValidation(orgNumber)
  );
}

/**
 * Normalize string.
 * @param {String} label - string to convert.
 * @return {String}
 */
export const normalizeLabel = (label) => {
  if (!label) return '';

  return n(label)
    .split(' ')
    .map((str) => str.toLowerCase())
    .map((str, index) => {
      if (index === 0) return str;
      return capitilizeString(str);
    })
    .join(' ')
    .replace(/(\s|&|!|@|#|\$|%|^)+/g, '');
};

/**
 * Capitalize string.
 * @param {String} str - string to convert.
 * @return {String} Capitalized string.
 */
export const capitilizeString = (str) => {
  if (!str) {
    return '';
  }
  const newStr = str.toString();
  return newStr.charAt(0).toUpperCase() + newStr.slice(1);
};

/**
 * Check if passed zipcode is valid.
 * @param {String} zipCode - Value to check.
 * @return {Boolean} Returns true if value is valid.
 */
export function validateDeliveryZipCode(zipCode) {
  if (!zipCode) return false;
  if (zipCode.length > 5) return false;

  const re = /^[0-9]{1,5}X{0,4}$/gim;
  const result = re.test(zipCode);
  return result;
}

/**
 * Check if passed string is valid json.
 * @param {String} string - Value to check.
 * @return {Boolean}
 */
export const validateJSONString = (string) => {
  if (!string) return false;
  const reg = new RegExp(/^[\],:{}\s]*$/gim);
  return reg.test(
    string
      .replace(/\\["\\/bfnrtu]/g, '@')
      .replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
        ']'
      )
      .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
  );
};

/**
 * Make request url.
 * @param {String} url - Url to request
 * @return {string}
 */
export function processUrl(url) {
  if (!url) {
    return '';
  }
  return url.startsWith('/') ? `${API_ROOT}${url}` : url;
}

export function chunkArray(array, chunkSize) {
  const tempArray = [];

  for (let index = 0; index < array.length; index += chunkSize) {
    const myChunk = array.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }

  return tempArray;
}

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export function validateURL(url) {
  if (!url) {
    return false;
  }
  const re = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  return re.test(url);
}

export function validateRelativeURL(url) {
  if (!url) {
    return false;
  }
  const re = /\/(\w{0,})/gi;
  return re.test(url);
}
