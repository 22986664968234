const styles = {
  description: {
    color: '#4a4a4a',
    fontSize: 24,
    fontWeight: 'bold',
  },
  imagePreviewBg: {
    '& .filepond--image-preview': {
      background: 'rgba(255,255,255,0.7)',
    },
  },
  cardItem: {
    marginTop: 15,
    padding: '15px 25px 20px',
    position: 'relative',
    overflow: 'unset',
  },
  cartContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  deleteBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 2,
  },
};

export default styles;
