import React from 'react';
import { Redirect } from 'react-router-dom';

import TagGroupsListPage from 'views/TagGroupsListPage';
import TagGroupDetailsPage from 'views/TagGroupDetailsPage';
import CategoriesListPage from 'views/CategoriesListPage';
import CategoryDetailsPage from 'views/CategoryDetailsPage';
import RestaurantsListPage from 'views/RestaurantsListPage';
import RestaurantDetailsPage from 'views/RestaurantDetailsPage';
import MenuItemsListPage from 'views/MenuItemsListPage';
import MenuItemDetailsPage from 'views/MenuItemDetailsPage';
import GroupsListPage from 'views/GroupsListPage';
import GroupDetailsPage from 'views/GroupDetailsPage';
import OrdersListPage from 'views/OrdersListPage';
import DeliveryOrdersListPage from 'views/DeliveryOrdersListPage';
import CustomersListPage from 'views/CustomersListPage';
import CustomerDetailsPage from 'views/CustomerDetailsPage';
import CustomerDeliveryDetailsPage from 'views/CustomerDeliveryDetailsPage';
import ProductionListPage from 'views/ProductionListPage';
import PackListPage from 'views/PackListPage';
import LoginPage from 'views/LoginPage';
import SettingsPage from 'views/SettingsPage';
import DeliveryZoneDetailsPage from 'views/DeliveryZoneDetailsPage';
import CompaniesListPage from 'views/CompaniesListPage';
import CompanyDetailsPage from 'views/CompanyDetailsPage';
import KickbackListPage from 'views/KickbackListPage';
import KickbackDetailsPage from 'views/KickbackDetailsPage';
import StampCardsListPage from 'views/StampCardsListPage';
import StampCardDetailsPage from 'views/StampCardDetailsPage';
import ProductionListSortingPage from 'views/ProductionListSortingPage';
import PackListSortingPage from 'views/PackListSortingPage';
import StoreCreditsPage from 'views/StoreCreditsPage';
import PickupOrdersListPage from 'views/PickupOrdersListPage';
import OrderDetailsPage from 'views/OrderDetailsPage';
import FooterDynamicSocialIcons from 'views/DynamicContent/FooterDynamicSocialIcons';
import FooterDynamicPartnersList from 'views/DynamicContent/FooterDynamicPartnersList';
import FooterDynamicMenu from 'views/DynamicContent/FooterDynamicMenu';
import HeaderDynamicContent from 'views/DynamicContent/HeaderDynamicContent';
import LandingSettings from 'views/DynamicContent/LandingSettings';
import ArticleSectionDetails from 'views/DynamicContent/ArticleSectionDetails';
import CartSectionDetails from 'views/DynamicContent/CartSectionDetails';
import ListSectionDetails from 'views/DynamicContent/ListSectionDetails';

import BaseLayout from 'components/BaseLayout';
import PrintLayout from 'components/PrintLayout';

const routeConfig = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/orders" />, //eslint-disable-line
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/print',
    component: PrintLayout,
    secure: true,
    routes: [
      {
        path: '/print/production',
        component: ProductionListPage,
      },
      {
        path: '/print/pack',
        component: PackListPage,
      },
    ],
  },
  {
    path: '/',
    component: BaseLayout,
    secure: true,
    routes: [
      {
        path: '/tags',
        component: TagGroupsListPage,
      },
      {
        path: '/tag-details/:id?',
        component: TagGroupDetailsPage,
      },
      {
        path: '/categories',
        component: CategoriesListPage,
      },
      {
        path: '/category-details/:id?',
        component: CategoryDetailsPage,
      },
      {
        path: '/restaurants',
        component: RestaurantsListPage,
      },
      {
        path: '/restaurant-details/:id?',
        component: RestaurantDetailsPage,
      },
      {
        path: '/menu-items',
        component: MenuItemsListPage,
      },
      {
        path: '/menu-item-details/:id?',
        component: MenuItemDetailsPage,
      },
      {
        path: '/groups',
        component: GroupsListPage,
      },
      {
        path: '/group-details/:id?',
        component: GroupDetailsPage,
      },
      {
        path: '/orders',
        component: OrdersListPage,
      },
      {
        path: '/order-details/:id?',
        component: OrderDetailsPage,
        exact: true,
      },
      {
        path: '/delivery-orders',
        component: DeliveryOrdersListPage,
      },
      {
        path: '/pickup-orders',
        component: PickupOrdersListPage,
      },
      {
        path: '/customers',
        component: CustomersListPage,
      },
      {
        path: '/companies',
        component: CompaniesListPage,
      },
      {
        path: '/company-details/:id?',
        component: CompanyDetailsPage,
        exact: true,
      },
      {
        path: '/customer-details/:id',
        component: CustomerDetailsPage,
        exact: true,
      },
      {
        path: '/customer-details/:userId/:id',
        component: CustomerDeliveryDetailsPage,
        exact: true,
      },
      {
        path: '/settings',
        exact: true,
        component: SettingsPage,
      },
      {
        path: '/settings/delivery',
        exact: true,
        component: DeliveryZoneDetailsPage,
      },
      {
        path: '/settings/delivery/:id',
        exact: true,
        component: DeliveryZoneDetailsPage,
      },
      {
        path: '/footer-menu',
        exact: true,
        component: FooterDynamicMenu,
      },
      {
        path: '/footer-social-icons',
        exact: true,
        component: FooterDynamicSocialIcons,
      },
      {
        path: '/footer-partners-list',
        exact: true,
        component: FooterDynamicPartnersList,
      },
      {
        path: '/landing-content',
        exact: true,
        component: LandingSettings,
      },
      {
        path: '/header-content',
        exact: true,
        component: HeaderDynamicContent,
      },
      {
        path: '/article-section-details/:id?',
        exact: true,
        component: ArticleSectionDetails,
      },
      {
        path: '/cart-section-details/:id?',
        exact: true,
        component: CartSectionDetails,
      },
      {
        path: '/list-section-details/:id?',
        exact: true,
        component: ListSectionDetails,
      },
      {
        path: '/kickbacks',
        exact: true,
        component: KickbackListPage,
      },
      {
        path: '/kickback-details/:id?',
        exact: true,
        component: KickbackDetailsPage,
      },
      {
        path: '/stampcards',
        exact: true,
        component: StampCardsListPage,
      },
      {
        path: '/stampcards-details/:id?',
        exact: true,
        component: StampCardDetailsPage,
      },
      {
        path: '/production-list',
        exact: true,
        component: ProductionListSortingPage,
      },
      {
        path: '/pack-list',
        exact: true,
        component: PackListSortingPage,
      },
      {
        path: '/store-credits',
        exact: true,
        component: StoreCreditsPage,
      },
    ],
  },
];

export default routeConfig;
