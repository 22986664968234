import React from 'react';
import PropTypes from 'prop-types';
import randomstring from 'randomstring';
import { toastr } from 'react-redux-toastr';

import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import PreviewModal from 'shared/components/LandingPage/PreviewModal';

import PageHeading from 'components/PageHeading';
import ImageUpload from 'components/ImageUpload';
import Select from 'components/Select';
import ColorPicker from 'components/ColorPicker';
import CustomLinkSection from 'components/CustomLinkSection';
import RichTextEditor from 'components/RichTextEditor';
import {
  createEditorStateObj,
  getRawEditorState,
} from 'components/RichTextEditor/utils';

import {
  IMAGE_POSITIONS,
  LANDING_SETTINGS_DATA_NAME,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_TEXT_COLOR,
  TEXT_ALIGN,
  H_TAGS,
} from 'consts/settings';

import { LANDING_SECTION_TYPES } from 'shared/consts/settings';

import { validateArticle } from './utils';

const stateFromProps = ({ item = {} }) => {
  return {
    title: item.title || '',
    titleHTag: item.titleHTag || H_TAGS[1].value,
    description: createEditorStateObj(item.description),
    link: item.link || {},
    imageURL: item.imageURL || '',
    imageSEOAlt: item.imageSEOAlt || '',
    imagePosition: item.imagePosition || IMAGE_POSITIONS[0].value,
    textAlign: item.textAlign || TEXT_ALIGN[0].value,
    titleAlign: item.titleAlign || TEXT_ALIGN[0].value,
    backgroundColor: item.backgroundColor || DEFAULT_BACKGROUND_COLOR,
    cartBgColor: item.cartBgColor || DEFAULT_BACKGROUND_COLOR,
    textColor: item.textColor || DEFAULT_TEXT_COLOR,
    titleColor: item.titleColor || DEFAULT_TEXT_COLOR,
    withBackgroundColor: item.withBackgroundColor || false,
    previewOpened: false,
  };
};

class ArticleForm extends React.PureComponent {
  state = stateFromProps(this.props);

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleDescriptionChange = (description) => {
    this.setState({
      description,
    });
  };

  handleCheckboxChange = ({ target: { checked } }) => {
    this.setState({
      withBackgroundColor: checked,
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    });
  };

  handleSelectChange = (fieldName) => ({ value }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleChangeColor = (fieldName) => (color) => {
    this.setState({
      [fieldName]: color.hex,
    });
  };

  handleSaveLink = (data) => {
    this.setState({
      link: data,
    });
  };

  get preview() {
    const { id, type } = this.props;
    const { description, ...data } = this.state;

    const descriptionToSend = getRawEditorState(description);
    const dataToPreview = id
      ? {
          type,
          description: descriptionToSend,
          ...data,
        }
      : {
          type,
          id: randomstring.generate(7),
          description: descriptionToSend,
          ...data,
        };

    return dataToPreview;
  }

  handlePreview = () =>
    this.setState((prevState) => ({
      previewOpened: !prevState.previewOpened,
    }));

  handleSubmit = () => {
    const { onSubmit, initialData, id, type } = this.props;
    const { description, ...data } = this.state;
    const items = initialData && initialData.items ? initialData.items : [];
    const validateError = validateArticle(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      const descriptionToSend = getRawEditorState(description);
      const valueToSend = id
        ? items.map(
            (item) =>
              item.id === id
                ? {
                    ...item,
                    description: descriptionToSend,
                    ...data,
                  }
                : item
          )
        : items.concat({
            type,
            id: randomstring.generate(7),
            description: descriptionToSend,
            ...data,
          });
      const dataToSend = {
        isNew: !Object.keys(initialData || {}).length,
        name: LANDING_SETTINGS_DATA_NAME,
        value: JSON.stringify({ ...initialData, items: valueToSend }),
      };
      onSubmit(dataToSend)
        .then(() => {
          toastr.success('Success!', 'Content has been saved');
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  render() {
    const { previewOpened, ...data } = this.state;
    const { id, type } = this.props;

    return (
      <React.Fragment>
        <PageHeading
          title={id ? `${type} section` : `New ${type} section`}
          right={
            <React.Fragment>
              <Button
                onClick={this.handlePreview}
                variant="contained"
                size="large"
                style={{ marginLeft: 'auto' }}
              >
                Preview
              </Button>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                size="large"
                style={{ marginLeft: 24 }}
              >
                Save
              </Button>
            </React.Fragment>
          }
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            value={data.title}
            onChange={this.handleChange('title')}
            multiline
            rowsMax="4"
            placeholder="Title"
            label="Title"
            margin="normal"
            style={{ width: 600, marginRight: 25 }}
          />
          <div>
            <InputLabel style={{ fontSize: 12, position: 'relative', top: 4 }}>
              H-tag
            </InputLabel>
            <div>
              <Select
                options={H_TAGS}
                value={H_TAGS.find((i) => i.value === data.titleHTag)}
                onChange={this.handleSelectChange('titleHTag')}
                width={170}
                placeholder="H-tag"
              />
            </div>
          </div>
        </div>
        <div>
          <RichTextEditor
            editorState={data.description}
            onEditorStateChange={this.handleDescriptionChange}
          />
        </div>
        <div>
          <ImageUpload
            image={data.imageURL}
            onChange={this.handleChange('imageURL')}
            placeholder="Image URL"
            label="Image URL"
          />
        </div>
        <div>
          <Input
            value={data.imageSEOAlt}
            type="string"
            onChange={this.handleChange('imageSEOAlt')}
            placeholder="Image alt text"
            label="Image alt text"
            margin="normal"
            style={{ width: 300 }}
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, position: 'relative', top: 4 }}>
            Image position
          </InputLabel>
          <div>
            <Select
              options={IMAGE_POSITIONS}
              value={IMAGE_POSITIONS.find(
                (i) => i.value === data.imagePosition
              )}
              onChange={this.handleSelectChange('imagePosition')}
              width={170}
              placeholder="Image position"
            />
          </div>
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, position: 'relative', top: 4 }}>
            Text align
          </InputLabel>
          <div>
            <Select
              options={TEXT_ALIGN}
              value={TEXT_ALIGN.find((i) => i.value === data.textAlign)}
              onChange={this.handleSelectChange('textAlign')}
              width={170}
              placeholder="Text align"
            />
          </div>
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, position: 'relative', top: 4 }}>
            Title text align
          </InputLabel>
          <div>
            <Select
              options={TEXT_ALIGN}
              value={TEXT_ALIGN.find((i) => i.value === data.titleAlign)}
              onChange={this.handleSelectChange('titleAlign')}
              width={170}
              placeholder="Title align"
            />
          </div>
        </div>
        {type === LANDING_SECTION_TYPES.article ? (
          <React.Fragment>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.withBackgroundColor}
                    onChange={this.handleCheckboxChange}
                    value="withBackgroundColor"
                  />
                }
                label="Article section with background color."
              />
            </div>
            {data.withBackgroundColor && (
              <div>
                <ColorPicker
                  label="Background color"
                  color={data.backgroundColor}
                  onChange={this.handleChangeColor('backgroundColor')}
                />
              </div>
            )}
          </React.Fragment>
        ) : (
          <div>
            <ColorPicker
              label="Background color"
              color={data.backgroundColor}
              onChange={this.handleChangeColor('backgroundColor')}
            />
          </div>
        )}
        {type === LANDING_SECTION_TYPES.cart && (
          <div>
            <ColorPicker
              label="Cart background color"
              color={data.cartBgColor}
              onChange={this.handleChangeColor('cartBgColor')}
            />
          </div>
        )}
        <div>
          <ColorPicker
            label="Text color"
            color={data.textColor}
            onChange={this.handleChangeColor('textColor')}
          />
        </div>
        <div>
          <ColorPicker
            label="Title color"
            color={data.titleColor}
            onChange={this.handleChangeColor('titleColor')}
          />
        </div>
        <CustomLinkSection item={data.link} onSave={this.handleSaveLink} />
        <PreviewModal
          open={previewOpened}
          onClose={this.handlePreview}
          data={this.preview}
          type={this.props.type}
        />
      </React.Fragment>
    );
  }
}

ArticleForm.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  type: PropTypes.string.isRequired,
};

export default ArticleForm;
