import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';

class StatusDropdown extends React.PureComponent {
  handleChange = ({ value }) => {
    this.props.onChange(value);
  };

  render() {
    const { value, options } = this.props;
    return (
      <Select
        options={options}
        placeholder="All"
        value={value ? options.find((v) => v.value === value) : null}
        onChange={this.handleChange}
        label="Status"
      />
    );
  }
}

StatusDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default StatusDropdown;
