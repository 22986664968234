import { tablet, laptop } from 'shared/styles/breakpoints';

const styles = {
  linkWrapper: {
    marginTop: 16,
  },
  link: {
    paddingRight: 20,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: '1.5',
    letterSpacing: '-0.1px',
    display: 'inline-block',
    position: 'relative',
    '&:hover $arrow': {
      right: -10,
    },
  },
  arrow: {
    width: 12,
    height: 12,
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    transition: 'right 0.225s ease',
  },
  [`@media (min-width: ${tablet})`]: {
    link: {
      paddingRight: 25,
      fontSize: 22,
      lineHeight: '1.45',
      fontWeight: 'normal',
      letterSpacing: '-0.1px',
    },
    linkWrapper: {
      marginTop: 35,
    },
  },
  [`@media (min-width: ${laptop})`]: {
    linkWrapper: {
      marginTop: 55,
    },
  },
};

export default styles;
