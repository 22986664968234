import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_DELIVERY_COSTS_REQUEST,
  GET_DELIVERY_COSTS_SUCCESS,
  GET_DELIVERY_COSTS_FAILURE,
  GET_DELIVERY_COSTS_BY_ID_REQUEST,
  GET_DELIVERY_COSTS_BY_ID_SUCCESS,
  GET_DELIVERY_COSTS_BY_ID_FAILURE,
  SAVE_DELIVERY_COSTS_REQUEST,
  SAVE_DELIVERY_COSTS_SUCCESS,
  SAVE_DELIVERY_COSTS_FAILURE,
  DELETE_DELIVERY_COSTS_REQUEST,
  DELETE_DELIVERY_COSTS_SUCCESS,
  DELETE_DELIVERY_COSTS_FAILURE,
  GET_GENERAL_SETTINGS_REQUEST,
  GET_GENERAL_SETTINGS_SUCCESS,
  GET_GENERAL_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  SAVE_SETTINGS_REQUEST,
  SAVE_SETTINGS_SUCCESS,
  SAVE_SETTINGS_FAILURE,
  GET_PRODUCTION_LIST_REQUEST,
  GET_PRODUCTION_LIST_SUCCESS,
  GET_PRODUCTION_LIST_FAILURE,
  GET_PACK_LIST_REQUEST,
  GET_PACK_LIST_SUCCESS,
  GET_PACK_LIST_FAILURE,
  SAVE_GENERAL_SETTINGS_REQUEST,
  SAVE_GENERAL_SETTINGS_SUCCESS,
  SAVE_GENERAL_SETTINGS_FAILURE,
} from 'consts/settings';

export function getDeliveryCosts({ sortName, sortDirection }) {
  return {
    type: GET_DELIVERY_COSTS_REQUEST,
    sortName,
    sortDirection,
    [WAIT_FOR_ACTION]: GET_DELIVERY_COSTS_SUCCESS,
    [ERROR_ACTION]: GET_DELIVERY_COSTS_FAILURE,
  };
}

export function getDeliveryCostById(id) {
  return {
    type: GET_DELIVERY_COSTS_BY_ID_REQUEST,
    id,
    [WAIT_FOR_ACTION]: GET_DELIVERY_COSTS_BY_ID_SUCCESS,
    [ERROR_ACTION]: GET_DELIVERY_COSTS_BY_ID_FAILURE,
  };
}

export function saveDeliveryCost({
  id,
  zoneName,
  shippingPrice,
  tax,
  zipCodes,
  minimumTime,
}) {
  return {
    type: SAVE_DELIVERY_COSTS_REQUEST,
    id,
    zoneName,
    shippingPrice,
    tax,
    zipCodes,
    minimumTime,
    [WAIT_FOR_ACTION]: SAVE_DELIVERY_COSTS_SUCCESS,
    [ERROR_ACTION]: SAVE_DELIVERY_COSTS_FAILURE,
  };
}

export function deleteDeliveryCost(id) {
  return {
    type: DELETE_DELIVERY_COSTS_REQUEST,
    id,
    [WAIT_FOR_ACTION]: DELETE_DELIVERY_COSTS_SUCCESS,
    [ERROR_ACTION]: DELETE_DELIVERY_COSTS_FAILURE,
  };
}

export function getSettings({ name, withReplace }) {
  return {
    type: GET_SETTINGS_REQUEST,
    name,
    withReplace,
    [WAIT_FOR_ACTION]: GET_SETTINGS_SUCCESS,
    [ERROR_ACTION]: GET_SETTINGS_FAILURE,
  };
}

export function getGeneralSettings({ name }) {
  return {
    type: GET_GENERAL_SETTINGS_REQUEST,
    name,
    [WAIT_FOR_ACTION]: GET_GENERAL_SETTINGS_SUCCESS,
    [ERROR_ACTION]: GET_GENERAL_SETTINGS_FAILURE,
  };
}

export function saveSettings({ name, value, isNew }) {
  return {
    type: SAVE_SETTINGS_REQUEST,
    name,
    value,
    isNew,
    [WAIT_FOR_ACTION]: SAVE_SETTINGS_SUCCESS,
    [ERROR_ACTION]: SAVE_SETTINGS_FAILURE,
  };
}

export function saveGeneralSettings({
  name,
  value,
  isNew,
  minTime,
  minTimeAttrId,
}) {
  return {
    type: SAVE_GENERAL_SETTINGS_REQUEST,
    name,
    value,
    isNew,
    minTimeAttrId,
    minTime,
    [WAIT_FOR_ACTION]: SAVE_GENERAL_SETTINGS_SUCCESS,
    [ERROR_ACTION]: SAVE_GENERAL_SETTINGS_FAILURE,
  };
}

export function getProductionSortingList() {
  return {
    type: GET_PRODUCTION_LIST_REQUEST,
    [WAIT_FOR_ACTION]: GET_PRODUCTION_LIST_SUCCESS,
    [ERROR_ACTION]: GET_PRODUCTION_LIST_FAILURE,
  };
}

export function getPackSortingList() {
  return {
    type: GET_PACK_LIST_REQUEST,
    [WAIT_FOR_ACTION]: GET_PACK_LIST_SUCCESS,
    [ERROR_ACTION]: GET_PACK_LIST_FAILURE,
  };
}
