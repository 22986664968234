import randomstring from 'randomstring';

export function generateListItems(items) {
  return (items || [])
    .map((item) => ({
      ...item,
      id: item.id || randomstring.generate(7),
    }))
    .sort((a, b) => a.index - b.index);
}

export function validateListItem(data) {
  if (!data.title && !data.description) {
    return 'Title or description are required';
  }
  if (data.linkLabel && !data.linkURL) {
    return 'Valid link URL is required';
  }
  if (data.linkURL && !data.linkLabel) {
    return 'Link label is required';
  }
}

export function validateList(items, itemsPerRow) {
  if (items && items.length) {
    if (
      itemsPerRow < 1 ||
      isNaN(parseFloat(itemsPerRow)) ||
      itemsPerRow > items.length
    ) {
      return 'Items per row value should be greater than 0 and less than list items count';
    }
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!item.title && !item.description) {
        return `Title or description are required for ${i + 1} list item`;
      }
      if (item.linkLabel && !item.linkURL) {
        return `Valid link URL is required for ${i + 1} list item`;
      }
      if (item.linkURL && !item.linkLabel) {
        return `Link label is required for ${i + 1} list item`;
      }
    }
  } else {
    return 'Should be at least one item';
  }
}
