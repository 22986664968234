export const GET_KICKBACKS_LIST_REQUEST = 'GET_KICKBACKS_LIST_REQUEST';
export const GET_KICKBACKS_LIST_SUCCESS = 'GET_KICKBACKS_LIST_SUCCESS';
export const GET_KICKBACKS_LIST_FAILURE = 'GET_KICKBACKS_LIST_FAILURE';

export const GET_KICKBACK_BY_ID_REQUEST = 'GET_KICKBACK_BY_ID_REQUEST';
export const GET_KICKBACK_BY_ID_SUCCESS = 'GET_KICKBACK_BY_ID_SUCCESS';
export const GET_KICKBACK_BY_ID_FAILURE = 'GET_KICKBACK_BY_ID_FAILURE';

export const GET_KICKBACK_HISTORY_REQUEST = 'GET_KICKBACK_HISTORY_REQUEST';
export const GET_KICKBACK_HISTORY_SUCCESS = 'GET_KICKBACK_HISTORY_SUCCESS';
export const GET_KICKBACK_HISTORY_FAILURE = 'GET_KICKBACK_HISTORY_FAILURE';

export const SAVE_KICKBACKS_LIST_REQUEST = 'SAVE_KICKBACKS_LIST_REQUEST';
export const SAVE_KICKBACKS_LIST_SUCCESS = 'SAVE_KICKBACKS_LIST_SUCCESS';
export const SAVE_KICKBACKS_LIST_FAILURE = 'SAVE_KICKBACKS_LIST_FAILURE';

export const DELETE_KICKBACK_REQUEST = 'DELETE_KICKBACK_REQUEST';
export const DELETE_KICKBACK_SUCCESS = 'DELETE_KICKBACK_SUCCESS';
export const DELETE_KICKBACK_FAILURE = 'DELETE_KICKBACK_FAILURE';

export const GET_PUNCH_CARD_LIST_REQUEST = 'GET_PUNCH_CARD_LIST_REQUEST';
export const GET_PUNCH_CARD_LIST_SUCCESS = 'GET_PUNCH_CARD_LIST_SUCCESS';
export const GET_PUNCH_CARD_LIST_FAILURE = 'GET_PUNCH_CARD_LIST_FAILURE';

export const GET_PUNCH_CARD_BY_ID_REQUEST = 'GET_PUNCH_CARD_BY_ID_REQUEST';
export const GET_PUNCH_CARD_BY_ID_SUCCESS = 'GET_PUNCH_CARD_BY_ID_SUCCESS';
export const GET_PUNCH_CARD_BY_ID_FAILURE = 'GET_PUNCH_CARD_BY_ID_FAILURE';

export const SAVE_PUNCH_CARD_REQUEST = 'SAVE_PUNCH_CARD_REQUEST';
export const SAVE_PUNCH_CARD_SUCCESS = 'SAVE_PUNCH_CARD_SUCCESS';
export const SAVE_PUNCH_CARD_FAILURE = 'SAVE_PUNCH_CARD_FAILURE';

export const SAVE_PUNCH_CARD_MENU_ITEM_REQUEST =
  'SAVE_PUNCH_CARD_MENU_ITEM_REQUEST';
export const SAVE_PUNCH_CARD_MENU_ITEM_SUCCESS =
  'SAVE_PUNCH_CARD_MENU_ITEM_SUCCESS';
export const SAVE_PUNCH_CARD_MENU_ITEM_FAILURE =
  'SAVE_PUNCH_CARD_MENU_ITEM_FAILURE';

export const DELETE_PUNCH_CARD_MENU_ITEM_REQUEST =
  'DELETE_PUNCH_CARD_MENU_ITEM_REQUEST';
export const DELETE_PUNCH_CARD_MENU_ITEM_SUCCESS =
  'DELETE_PUNCH_CARD_MENU_ITEM_SUCCESS';
export const DELETE_PUNCH_CARD_MENU_ITEM_FAILURE =
  'DELETE_PUNCH_CARD_MENU_ITEM_FAILURE';

export const GET_USER_PUNCH_CARDS_REQUEST = 'GET_USER_PUNCH_CARDS_REQUEST';
export const GET_USER_PUNCH_CARDS_SUCCESS = 'GET_USER_PUNCH_CARDS_SUCCESS';
export const GET_USER_PUNCH_CARDS_FAILURE = 'GET_USER_PUNCH_CARDS_FAILURE';

export const GET_USER_STORE_CREDITS_POINTS_REQUEST =
  'GET_USER_STORE_CREDITS_POINTS_REQUEST';
export const GET_USER_STORE_CREDITS_POINTS_SUCCESS =
  'GET_USER_STORE_CREDITS_POINTS_SUCCESS';
export const GET_USER_STORE_CREDITS_POINTS_FAILURE =
  'GET_USER_STORE_CREDITS_POINTS_FAILURE';

export const SAVE_USER_STORE_CREDITS_POINTS_REQUEST =
  'SAVE_USER_STORE_CREDITS_POINTS_REQUEST';
export const SAVE_USER_STORE_CREDITS_POINTS_SUCCESS =
  'SAVE_USER_STORE_CREDITS_POINTS_SUCCESS';
export const SAVE_USER_STORE_CREDITS_POINTS_FAILURE =
  'SAVE_USER_STORE_CREDITS_POINTS_FAILURE';

export const GET_CREDITS_ANALYTICS_REQUEST = 'GET_CREDITS_ANALYTICS_REQUEST';
export const GET_CREDITS_ANALYTICS_SUCCESS = 'GET_CREDITS_ANALYTICS_SUCCESS';
export const GET_CREDITS_ANALYTICS_FAILURE = 'GET_CREDITS_ANALYTICS_FAILURE';

export const STAMP_CARD_FREE_ITEMS_AMOUNT = 3;
export const ANALYTICS_STORE_CREDITS_START_DATE = '2019-03-01';
