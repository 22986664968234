import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import {
  getCategoriesByParentId,
  removeSubcategory,
  addSubcategory,
} from 'actions/categories';
import { hideIfNoData } from 'utils/enhancers';

import SubcategoryList from './SubcategoryList';

const mapDispatchToProps = {
  onInit: getCategoriesByParentId,
  onDelete: removeSubcategory,
  onSubmit: addSubcategory,
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', 'name'),
  withState('sortDirection', 'setSortDirection', 'asc')
);

const refresh = (props) => {
  const { parentId, sortName, sortDirection, onInit, setInitialData } = props;
  return onInit({ sortName, sortDirection, parentId }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onDelete: (props) => (event) =>
    props.onDelete(event).then(() => refresh(props)),
  onSubmit: (props) => (event) =>
    props.onSubmit(event).then(() => refresh(props)),
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    return refresh({ ...props, sortName, sortDirection });
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => props.parentId && !props.initialData)
)(SubcategoryList);
