import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import DiscountDetails from './DiscountDetails';

import { ORDER_TYPES_LABEL, ORDER_TYPES, ORDER_STATUSES } from 'consts/orders';
import { NAME_VISIBLE } from 'consts/restaurants';
import { DATETIME_FORMAT_WO_SECS } from 'consts';

import { USER_STATUSES } from 'consts/customers';
import { formatOrderDeliveryDateTime } from 'utils/date';
import { findAttributeValue } from 'utils';

const fixedLabel = { width: 120, marginRight: 15, flexShrink: 0 };
const fixedLabelRight = { width: 150, marginRight: 15 };
const fixedSection = {
  display: 'flex',
  width: '50%',
  marginBottom: 5,
};
const fullSection = { ...fixedSection, width: '100%' };
const rightAlign = { textAlign: 'right' };
const titleWrapper = { display: 'flex', alignItems: 'center' };

class OrderDetails extends React.PureComponent {
  isPosibleEditOrder = (order) => {
    return !!(
      order.type === ORDER_TYPES.delivery &&
      order.status !== ORDER_STATUSES.done &&
      order.status !== ORDER_STATUSES.cancelled &&
      order.totalAmount !== 0 &&
      order.user &&
      order.user.status !== USER_STATUSES.deleted
    );
  };

  getTotalPriceForMenuItem = (item) => {
    let totalPrice = 0;
    if (item.quantityOfFree) {
      totalPrice = (item.quantity - item.quantityOfFree) * item.defaultPrice;
    } else {
      totalPrice = item.quantity * item.defaultPrice;
    }
    return totalPrice.toFixed(2);
  };

  render() {
    const order = this.props.initialData;

    const restaurantVisibleName =
      order.restaurant && order.restaurant.attributes
        ? findAttributeValue(order.restaurant.attributes, NAME_VISIBLE)
        : '';

    return (
      <div style={{ minWidth: 530 }}>
        <div style={titleWrapper}>
          <h1 className="print-page-heading" style={{ marginBottom: 15 }}>
            Order #{order.id}
          </h1>
          {this.isPosibleEditOrder(order) && (
            <Link to={`/order-details/${order.id}`}>
              <IconButton
                aria-label="Edit"
                color="primary"
                style={{ marginBottom: 15 }}
              >
                <EditIcon />
              </IconButton>
            </Link>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '50%', marginRight: 20 }}>
            <div style={fullSection}>
              <label className="print-heading" style={fixedLabel}>
                Order type:
              </label>
              <p>{ORDER_TYPES_LABEL[order.type]}</p>
            </div>
            {order.restaurant && (
              <div style={fullSection}>
                <label className="print-heading" style={fixedLabel}>
                  Restaurant:
                </label>
                <p style={{ wordBreak: 'break-word' }}>
                  {restaurantVisibleName
                    ? restaurantVisibleName
                    : order.restaurant.name}
                </p>
              </div>
            )}
            {order.delivery &&
              order.delivery.deliveryTime &&
              (order.type === ORDER_TYPES.delivery ||
                order.type === ORDER_TYPES.pickUp) && (
                <div style={fullSection}>
                  <label className="print-heading" style={fixedLabel}>
                    Delivery Date:
                  </label>
                  <p>
                    {formatOrderDeliveryDateTime(
                      order.delivery.deliveryTime,
                      DATETIME_FORMAT_WO_SECS
                    )}
                  </p>
                </div>
              )}
            {order.type === ORDER_TYPES.pickUp &&
              order.pickupNumber && (
                <div style={fullSection}>
                  <label className="print-heading" style={fixedLabel}>
                    Pickup Number:
                  </label>
                  <p>{order.pickupNumber.number}</p>
                </div>
              )}
            {order.type === ORDER_TYPES.delivery &&
              order.delivery &&
              order.delivery.deliveryAddress && (
                <div style={fullSection}>
                  <label className="print-heading" style={fixedLabel}>
                    Delivery Address:
                  </label>
                  <p>{order.delivery.deliveryAddress}</p>
                </div>
              )}
            {order.notice && (
              <div style={fullSection}>
                <label className="print-heading" style={fixedLabel}>
                  Сomment:
                </label>
                <p>{order.notice}</p>
              </div>
            )}
            {order.attributes &&
              order.attributes.commentToEditOrder && (
                <div style={fullSection}>
                  <label className="print-heading" style={fixedLabel}>
                    Comment from CM:
                  </label>
                  <p>{order.attributes.commentToEditOrder}</p>
                </div>
              )}
          </div>
          <div style={{ width: '50%' }}>
            <div style={fullSection}>
              <label className="print-heading" style={fixedLabelRight}>
                Gross total amount:
              </label>
              <p>{order.subtotalAmount} kr</p>
            </div>
            {!!order.deliveryPrice && (
              <div style={fullSection}>
                <label className="print-heading" style={fixedLabelRight}>
                  Delivery price:
                </label>
                <p>{order.deliveryPrice} kr</p>
              </div>
            )}
            <div style={fullSection}>
              <label className="print-heading" style={fixedLabelRight}>
                Discount amount:
              </label>
              <div>
                <p>{order.discountAmount} kr</p>
              </div>
            </div>
            {!!order.discountAmount &&
              order.attributes && (
                <div style={fullSection}>
                  <label className="print-heading" style={fixedLabelRight}>
                    Discount details:
                  </label>
                  <DiscountDetails attributes={order.attributes} />
                </div>
              )}
            <div style={fullSection}>
              <label className="print-heading" style={fixedLabelRight}>
                Net total amount:
              </label>
              <p>{order.totalAmount} kr</p>
            </div>
          </div>
        </div>
        {order.orderItems &&
          order.orderItems.length > 0 && (
            <table className="print-table" style={{ marginTop: 20 }}>
              <thead>
                <tr>
                  <th>Product</th>
                  <th style={rightAlign}>Quantity</th>
                  <th style={rightAlign}>Price</th>
                  <th style={rightAlign}>Total</th>
                </tr>
              </thead>
              <tbody>
                {order.orderItems.map((item) => (
                  <tr key={item.id}>
                    <td>
                      {item.quantityOfFree > 0
                        ? `${item.name} (${item.quantityOfFree} free ${
                            item.quantityOfFree === 1 ? 'item' : 'items'
                          })`
                        : item.name}
                    </td>
                    <td style={rightAlign}>{item.quantity}</td>
                    <td style={rightAlign}>{item.defaultPrice}</td>
                    <td style={rightAlign}>
                      {this.getTotalPriceForMenuItem(item)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
      </div>
    );
  }
}

OrderDetails.propTypes = {
  initialData: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default OrderDetails;
