import { createStore, compose, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import createReduxWaitForMiddleware from 'redux-wait-for-action';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from 'reducers';
import sagas from 'sagas';

import scrollMiddleware from './middlewares/scrollMiddleware';
import patchReduxWaitForMiddleware from './middlewares/patchReduxWaitForMiddleware';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'panini_cms_v1.1.11', //change this to clear all user data after the build
  storage,
  whitelist: ['auth', 'redirect'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools;

const configureStore = (initialState) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    sagaMiddleware,
    patchReduxWaitForMiddleware,
    createReduxWaitForMiddleware(),
    scrollMiddleware,
    routerMiddleware(history),
  ];

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);

  return { store, persistor };
};

export default configureStore;
