const styles = {
  itemWrapper: {
    width: '100%',
    padding: '5px 0px',
    display: 'flex',
  },
  itemKey: {
    width: 150,
    paddingRight: 15,
    flexShrink: 0,
  },
  image: {
    maxWidth: '100%',
    maxHeight: 300,
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 10,
  },
};

export default styles;
