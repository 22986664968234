import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import {
  getPunchCardById,
  savePunchCard,
  savePunchCardMenuItem,
  deletePunchCardMenuItem,
} from 'actions/loyalties';
import { showLoading, hideLoading } from 'actions/ui';

import { routerPassId, hideIfNoData } from 'utils/enhancers';
import { wrapWithSideEffect } from 'utils';

import StampCardDetailsPage from './StampCardDetailsPage';

const mapDispatchToProps = {
  onInit: getPunchCardById,
  onSave: savePunchCard,
  onAddMenuItem: savePunchCardMenuItem,
  onDeleteMenuItem: deletePunchCardMenuItem,
  showLoading,
  hideLoading,
};

const state = compose(withState('initialData', 'setInitialData'));

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const refresh = (props) => {
  const { id, onInit, setInitialData } = props;
  return wrapWithProgress(props)(onInit(id).then(setInitialData));
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSave: (props) => (event) => {
    return wrapWithProgress(props)(
      props.onSave(event).then((data) => {
        if (!props.id) {
          props.history.push(`/stampcards-details/${data.id}`);
        }
        refresh({ ...props, id: data.id });
      })
    );
  },
  onAddMenuItem: (props) => (event) => {
    return wrapWithProgress(props)(
      props.onAddMenuItem(event).then(() => refresh({ ...props }))
    );
  },
  onDeleteMenuItem: (props) => (event) => {
    return wrapWithProgress(props)(
      props.onDeleteMenuItem(event).then(() => refresh({ ...props }))
    );
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    const { id, onInit } = this.props;
    if (id) {
      onInit(id);
    }
  },
});

export default compose(
  routerPassId,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => props.id && !props.initialData)
)(StampCardDetailsPage);
