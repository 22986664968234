import React from 'react';
import PropTypes from 'prop-types';
import randomstring from 'randomstring';
import { toastr } from 'react-redux-toastr';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Input from '@material-ui/core/TextField';
import PageHeading from 'components/PageHeading';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import PreviewModal from 'shared/components/LandingPage/PreviewModal';

import SmallButton from 'components/SmallButton';

import ListItemForm from './ListItemForm';
import ListItemDetails from './ListItemDetails';

import {
  LANDING_SETTINGS_DATA_NAME,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_TEXT_COLOR,
  TEXT_ALIGN,
  H_TAGS,
} from 'consts/settings';
import { LANDING_SECTION_TYPES } from 'shared/consts/settings';

import { reorder } from 'utils/processors';
import { generateListItems, validateListItem, validateList } from './utils';

const emptyNewItem = {
  title: '',
  titleHTag: H_TAGS[2].value,
  description: '',
  link: null,
  backgroundColor: DEFAULT_BACKGROUND_COLOR,
  textAlign: TEXT_ALIGN[0].value,
  titleAlign: TEXT_ALIGN[0].value,
  textColor: DEFAULT_TEXT_COLOR,
  titleColor: DEFAULT_TEXT_COLOR,
};

const stateFromProps = ({ initialData = [], id }) => {
  const data = id
    ? initialData && initialData.items
      ? initialData.items.find((item) => item.id === id)
      : {}
    : {};
  return {
    itemsPerRow: data.itemsPerRow || 1,
    items: generateListItems(data.items),
    newItem: emptyNewItem,
    previewOpened: false,
  };
};

class ListSectionDetails extends React.PureComponent {
  state = stateFromProps(this.props);

  reorder = (sourceIndex, destinationIndex, cb) => {
    const { items } = this.state;
    const newItems = reorder(items, sourceIndex, destinationIndex);

    this.setState({ items: newItems }, cb && cb());
  };

  handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    this.reorder(result.source.index, result.destination.index);
  };

  handleItemsPerRowChange = ({ target: { value } }) => {
    this.setState({
      itemsPerRow: value,
    });
  };

  handleNewItemChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      newItem: {
        ...this.state.newItem,
        [fieldName]: value,
      },
    });
  };

  handleNewItemChangeColor = (fieldName) => (color) => {
    this.setState({
      newItem: {
        ...this.state.newItem,
        [fieldName]: color.hex,
      },
    });
  };

  handleNewItemSelectChange = (fieldName) => ({ value }) => {
    this.setState({
      newItem: {
        ...this.state.newItem,
        [fieldName]: value,
      },
    });
  };

  handleNewItemLinkChange = () => (data) => {
    this.setState({
      newItem: {
        ...this.state.newItem,
        link: data,
      },
    });
  };

  onChangeHelper = (fieldName, index, value) => {
    const { items } = this.state;
    const newItems = items.map(
      (item, i) =>
        i === index
          ? {
              ...item,
              [fieldName]: value,
            }
          : item
    );
    this.setState({
      items: newItems,
    });
  };

  handleChange = (fieldName, index) => ({ target: { value } }) => {
    this.onChangeHelper(fieldName, index, value);
  };

  handleSelectChange = (fieldName, index) => ({ value }) => {
    this.onChangeHelper(fieldName, index, value);
  };

  handleChangeColor = (fieldName, index) => (color) => {
    this.onChangeHelper(fieldName, index, color.hex);
  };

  handleLinkChange = (index) => (data) => {
    this.onChangeHelper('link', index, data);
  };

  handleAddItem = () => {
    const { newItem, items } = this.state;
    const validateError = validateListItem(newItem);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      this.setState({
        items: [...items, { ...newItem, id: randomstring.generate(7) }],
        newItem: emptyNewItem,
      });
    }
  };

  handleDelete = (index) => {
    const { items } = this.state;
    const newItems = items.filter((value, i) => i !== index);
    this.setState({
      items: newItems,
    });
  };

  handleSubmit = () => {
    const { onSubmit, initialData, id } = this.props;
    const { items, itemsPerRow } = this.state;
    const oldData = initialData && initialData.items ? initialData.items : [];
    const validateError = validateList(items, itemsPerRow);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      const valueToSend = id
        ? oldData.map(
            (item) =>
              item.id === id
                ? {
                    ...item,
                    items,
                    itemsPerRow,
                  }
                : item
          )
        : oldData.concat({
            type: LANDING_SECTION_TYPES.list,
            id: randomstring.generate(7),
            items,
            itemsPerRow,
          });
      const dataToSend = {
        isNew: !Object.keys(initialData || {}).length,
        name: LANDING_SETTINGS_DATA_NAME,
        value: JSON.stringify({ ...initialData, items: valueToSend }),
      };
      onSubmit(dataToSend)
        .then(() => {
          toastr.success('Success!', 'Settings has been saved');
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  handlePreview = () =>
    this.setState((prevState) => ({
      previewOpened: !prevState.previewOpened,
    }));

  render() {
    const { id } = this.props;
    const { itemsPerRow, items, newItem, previewOpened } = this.state;

    return (
      <React.Fragment>
        <PageHeading
          title={id ? `List section` : `New list section`}
          right={
            <React.Fragment>
              <Button
                onClick={this.handlePreview}
                variant="contained"
                size="large"
                style={{ marginLeft: 'auto' }}
              >
                Preview
              </Button>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                size="large"
                style={{ marginLeft: 24 }}
              >
                Save
              </Button>
            </React.Fragment>
          }
        />
        <div>
          <Input
            value={itemsPerRow}
            onChange={this.handleItemsPerRowChange}
            type="number"
            placeholder="Items per row"
            label="Items per row"
            margin="normal"
            style={{ width: 300 }}
          />
        </div>
        {items &&
          items.length > 0 && (
            <React.Fragment>
              <Typography
                variant="body2"
                component="p"
                style={{ marginTop: 20 }}
              >
                Drag and drop items to change order
              </Typography>
              <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div ref={provided.innerRef} style={{ marginTop: 40 }}>
                      {items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ListItemDetails
                                index={index}
                                item={item}
                                onChange={this.handleChange}
                                onChangeColor={this.handleChangeColor}
                                onChangeLink={this.handleLinkChange}
                                onChangeSelect={this.handleSelectChange}
                                onDelete={() => this.handleDelete(index)}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </React.Fragment>
          )}
        <Paper style={{ padding: 24, marginTop: 40 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="secondary" variant="h5">
              Add new item
            </Typography>
            <SmallButton
              style={{ cursor: 'pointer' }}
              onClick={this.handleAddItem}
            >
              Add
            </SmallButton>
          </div>
          <ListItemForm
            item={newItem}
            onChange={this.handleNewItemChange}
            onChangeColor={this.handleNewItemChangeColor}
            onChangeLink={this.handleNewItemLinkChange}
            onChangeSelect={this.handleNewItemSelectChange}
          />
        </Paper>
        <PreviewModal
          open={previewOpened}
          onClose={this.handlePreview}
          data={{ items, itemsPerRow }}
          type={LANDING_SECTION_TYPES.list}
        />
      </React.Fragment>
    );
  }
}

ListSectionDetails.propTypes = {
  classes: PropTypes.object,
  initialData: PropTypes.object,
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default ListSectionDetails;
