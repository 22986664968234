import React from 'react';
import PropTypes from 'prop-types';

class PrintLayout extends React.PureComponent {
  render() {
    return <div className="print-root">{this.props.children}</div>;
  }
}

PrintLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrintLayout;
