import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';

import NoOptionsMessage from './NoOptionsMessage';
import Control from './Control';
import Option from './Option';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';
import MultiValue from './MultiValue';
import Menu from './Menu';
import styles from './styles';

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class IntegrationReactSelect extends React.Component {
  render() {
    const {
      classes,
      theme,
      width,
      options,
      placeholder,
      value,
      onChange,
      label,
      disabled,
      fontSize,
    } = this.props;

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    const selectValue =
      (options || []).find(
        (o) => o === value || (o && value && o.value === value.value)
      ) || null;

    return (
      <div className={classes.root} style={{ width: width || '' }}>
        <NoSsr>
          {label && (
            <InputLabel style={{ fontSize: 12, position: 'relative', top: 4 }}>
              {label}
            </InputLabel>
          )}
          <Select
            classes={classes}
            // menuPortalTarget={document.getElementById('root')}
            styles={selectStyles}
            options={options}
            components={components}
            value={selectValue}
            onChange={onChange}
            placeholder={placeholder}
            isDisabled={disabled}
            fontSize={fontSize}
          />
          <div className={classes.divider} style={{ height: 4 }} />
        </NoSsr>
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  fontSize: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
