const styles = {
  wrapper: {
    padding: 24,
    display: 'flex',
    flexWrap: 'wrap',
  },
  sideBlock: {
    flex: 1,
    '&:nth-child(1)': {
      marginRight: 30,
    },
  },
  balanceRowWrapper: {
    marginRight: 16,
    '&:first-child': {
      marginTop: 16,
      marginBottom: 52,
    },
  },
  balancePoints: {
    float: 'right',
  },
};

export default styles;
