import { parseISO, addMinutes } from 'date-fns';
import {
  getMinOpenTime,
  getMaxCloseTime,
  timeToDate,
  convertToFormatedRestaurantTimeZone,
  isToday,
} from 'utils/date';
import { TIME_SLOT_VALUE } from 'consts';

export const isSpecialDay = (item, date) => {
  if (!item.date) {
    return false;
  }

  const itemDate = parseISO(item.date);

  return (
    date.getFullYear() === itemDate.getFullYear() &&
    date.getMonth() === itemDate.getMonth() &&
    date.getDate() === itemDate.getDate()
  );
};

export function getSchedule(day, restaurant) {
  const { openingHours, specialOpeningHours } = restaurant;

  const simpleDay = openingHours.find((item, index) => day.getDay() === index);
  const specialDay =
    specialOpeningHours &&
    specialOpeningHours.find((item) => isSpecialDay(item, day));
  return specialDay ? specialDay : simpleDay;
}

export function getTimeSlots(schedule) {
  let timeSlots = [];
  let openHour =
    schedule.openHour instanceof Date
      ? schedule.openHour
      : parseISO(schedule.openHour);
  const closeHour =
    schedule.closeHour instanceof Date
      ? schedule.closeHour
      : parseISO(schedule.closeHour);

  while (openHour <= closeHour) {
    timeSlots.push(openHour);
    openHour = addMinutes(openHour, TIME_SLOT_VALUE);
  }

  return timeSlots;
}

export function getFirstAvailableTimeSlot({ date, schedule }) {
  const timeSlots = getTimeSlots(schedule);
  const firstTimeSlot = timeSlots.find((tS) => tS >= date);
  return firstTimeSlot;
}

export function modifiSchedule({ day, minTime, restaurant }) {
  const timeZone = restaurant.timeZone;
  let modifiedSchedule = {};
  const schedule = getSchedule(day, restaurant);
  if (!schedule) {
    return;
  }
  const openHour = timeToDate(schedule.openHour, timeZone);
  const closeHour = timeToDate(schedule.closeHour, timeZone);
  const minOpenTime = getMinOpenTime(
    minTime,
    convertToFormatedRestaurantTimeZone(timeZone)
  );

  if (isToday(day, timeZone)) {
    modifiedSchedule.openHour =
      minOpenTime > openHour
        ? getFirstAvailableTimeSlot({
            date: minOpenTime,
            schedule: {
              openHour,
              closeHour,
            },
          })
        : getMinOpenTime(minTime, openHour);
    modifiedSchedule.closeHour = getMaxCloseTime(closeHour);
  } else {
    modifiedSchedule.openHour = getMinOpenTime(minTime, openHour);
    modifiedSchedule.closeHour = getMaxCloseTime(closeHour);
  }
  return modifiedSchedule;
}

export function isDisabledDay({ day, restaurant, minTime }) {
  const timeZone = restaurant.timeZone;
  const schedule = getSchedule(day, restaurant);

  if (schedule.isClosed) {
    return true;
  }

  const openHour = timeToDate(schedule.openHour, timeZone);
  const closeHour = timeToDate(schedule.closeHour, timeZone);

  if (isToday(day, timeZone) && !schedule.isClosed) {
    const firstAvailableTimeSlot = getFirstAvailableTimeSlot({
      date: getMinOpenTime(
        minTime,
        convertToFormatedRestaurantTimeZone(timeZone)
      ),
      schedule: {
        openHour,
        closeHour,
      },
    });

    return (
      !firstAvailableTimeSlot ||
      firstAvailableTimeSlot > getMaxCloseTime(closeHour)
    );
  } else if (!schedule.isClosed) {
    return getMinOpenTime(minTime, openHour) > getMaxCloseTime(closeHour);
  }

  return schedule.isClosed;
}
