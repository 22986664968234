import React from 'react';
import PropTypes from 'prop-types';

import ArticleForm from 'components/ArticleForm';

import { LANDING_SECTION_TYPES } from 'shared/consts/settings';

class ArticleSectionDetails extends React.PureComponent {
  render() {
    const { id, onSubmit, initialData } = this.props;
    const item = id
      ? initialData && initialData.items
        ? initialData.items.find((item) => item.id === id)
        : {}
      : {};

    return (
      <ArticleForm
        item={item || {}}
        id={id}
        onSubmit={onSubmit}
        initialData={initialData}
        type={LANDING_SECTION_TYPES.article}
      />
    );
  }
}

ArticleSectionDetails.propTypes = {
  classes: PropTypes.object,
  initialData: PropTypes.object,
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default ArticleSectionDetails;
