export const mobileMiddle = '375px';
export const mobileLarge = '425px';
export const tablet = '768px';
export const laptop = '1024px';
export const laptopMiddle = '1200px';
export const laptopLarge = '1440px';

export const tabletHeight = '576px';
export const laptopHeight = '768px';
export const laptopMiddleHeight = '960px';
