import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

class PageHeading extends React.PureComponent {
  render() {
    const { title, right } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h4" color="primary">
            {title}
          </Typography>
          {right || null}
        </div>
        <Divider style={{ margin: '40px 0' }} />
      </React.Fragment>
    );
  }
}

PageHeading.propTypes = {
  title: PropTypes.any.isRequired, //eslint-disable-line
  right: PropTypes.node,
};

export default PageHeading;
