import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  PRE_AUTH_REQUEST,
  PRE_AUTH_SUCCESS,
  LOGOUT,
} from 'consts/auth';

export function auth({ phone, smsCode }) {
  return {
    type: AUTH_REQUEST,
    phone,
    smsCode,
    [WAIT_FOR_ACTION]: AUTH_SUCCESS,
    [ERROR_ACTION]: AUTH_FAILURE,
  };
}

export function preAuth({ phone }) {
  return {
    type: PRE_AUTH_REQUEST,
    phone,
    [WAIT_FOR_ACTION]: PRE_AUTH_SUCCESS,
    [ERROR_ACTION]: AUTH_FAILURE,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}
