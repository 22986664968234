import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { getCustomerDeliveryAddresses } from 'actions/customers';
import { hideIfNoData } from 'utils/enhancers';

import DeliveryAddresses from './DeliveryAddresses';

const mapDispatchToProps = {
  onInit: getCustomerDeliveryAddresses,
};

const state = compose(withState('initialData', 'setInitialData'));

const refresh = (props) => {
  const { userId, onInit, setInitialData } = props;
  return onInit({
    userId,
  }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(DeliveryAddresses);
