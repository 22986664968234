import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  defaultProps,
} from 'recompose';
import { connect } from 'react-redux';

import { getCustomers } from 'actions/customers';
import { showLoading, hideLoading } from 'actions/ui';
import { setFilters, clearFilters } from 'actions/redirect';

import { wrapWithSideEffect } from 'utils';
import { hideIfNoData } from 'utils/enhancers';

import { FIELDS_TO_SEARCH_CUSTOMERS } from 'consts/customers';

import CustomersListPage from './CustomersListPage';

const PAGING_LIMIT = 40;

const defaultParams = {
  sortName: 'id',
  sortDirection: 'desc',
  searchParam: FIELDS_TO_SEARCH_CUSTOMERS[0],
};

const mapStateToProps = ({ redirect }) => ({
  filters: redirect.filters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit: (args) => dispatch(getCustomers(args)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
  setFilters: (args) => dispatch(setFilters(ownProps.location, args)),
  clearFilters: () => dispatch(clearFilters()),
});

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const state = compose(
  withState('initialData', 'setInitialData'),
  withState('sortName', 'setSortName', defaultParams.sortName),
  withState('sortDirection', 'setSortDirection', defaultParams.sortDirection),
  withState('status', 'setStatus', null),
  withState('search', 'setSearch', null),
  withState('searchParam', 'setSearchParam', defaultParams.searchParam),
  withState('page', 'setPage', 0),
  defaultProps({
    pageLimit: PAGING_LIMIT,
  })
);

const refresh = (props) => {
  const {
    sortName,
    sortDirection,
    status,
    search,
    searchParam,
    page,
    pageLimit,
    onInit,
    setInitialData,
  } = props;
  return wrapWithProgress(props)(
    onInit({
      sortName,
      sortDirection,
      status,
      [searchParam.value]: searchParam.value === 'ids' ? [search] : search,
      page,
      pageLimit,
      nonAnonymous: true,
    }).then(setInitialData)
  );
};

const handlers = withHandlers({
  onInit: (props) => () => {
    const { location, filters } = props;
    if (location && filters && location.pathname !== filters.url) {
      props.clearFilters();
      return refresh(props);
    }
    const {
      sortName,
      sortDirection,
      status,
      search,
      searchParam,
      page,
    } = props.filters;
    if (sortName) props.setSortName(sortName);
    if (sortDirection) props.setSortDirection(sortDirection);
    if (status) props.setStatus(status);
    if (search) props.setSearch(search);
    if (searchParam) props.setSearchParam(searchParam);
    if (page) props.setPage(page);
    return refresh({
      ...props,
      sortName: sortName || defaultParams.sortName,
      sortDirection: sortDirection || defaultParams.sortDirection,
      status,
      search,
      searchParam: searchParam || defaultParams.searchParam,
      page: page || 0,
    });
  },
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setPage(0);
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    props.setFilters({
      sortName,
      sortDirection,
      status: props.status,
      search: props.search,
      searchParam: props.searchParam,
      page: 0,
    });
    return refresh({
      ...props,
      sortName,
      sortDirection,
      page: 0,
      search: props.search,
      searchParam: props.searchParam,
    });
  },
  onPage: (props) => ({ page }) => {
    props.setPage(page);
    props.setFilters({
      sortName: props.sortName,
      sortDirection: props.sortDirection,
      status: props.status,
      search: props.search,
      searchParam: props.searchParam,
      page,
    });
    return refresh({
      ...props,
      page,
      search: props.search,
      searchParam: props.searchParam,
    });
  },
  onSearch: (props) => ({ status, search, searchParam }) => {
    props.setPage(0);
    props.setStatus(status);
    props.setSearch(search);
    props.setSearchParam(searchParam);
    props.setFilters({
      sortName: props.sortName,
      sortDirection: props.sortDirection,
      status,
      search,
      searchParam,
      page: 0,
    });
    return refresh({
      ...props,
      status,
      search,
      searchParam,
      page: 0,
    });
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(CustomersListPage);
