import { put } from 'redux-saga/effects';

import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';

import {
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_PDF_REQUEST,
  UPLOAD_PDF_SUCCESS,
} from 'consts/upload';
import { UPLOAD_URL } from 'consts';

function* uploadFile({ payload, uploadUrl, filename, contentType }) {
  const url = `/${uploadUrl}/${filename}`;
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': contentType,
    },
    body: payload,
  };
  const response = yield apiRequest(url, options);
  return response;
}

function* uploadImage({ payload, filename }) {
  const fileType = payload['type'];
  const validImageTypes = [
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
  ];
  if (!validImageTypes.includes(fileType)) {
    return;
  }
  const response = yield uploadFile({
    payload,
    uploadUrl: UPLOAD_URL,
    filename,
    contentType: fileType,
  });
  const newAction = {
    type: UPLOAD_IMAGE_SUCCESS,
    response,
  };
  yield put(newAction);
}

function* uploadImageSaga() {
  yield takeFirst(UPLOAD_IMAGE_REQUEST, uploadImage);
}

function* uploadPDF({ payload, filename }) {
  const response = yield uploadFile({
    payload,
    uploadUrl: UPLOAD_URL,
    filename,
    contentType: 'application/octet-stream',
  });

  const newAction = {
    type: UPLOAD_PDF_SUCCESS,
    response,
  };
  yield put(newAction);
}

function* uploadPDFSaga() {
  yield takeFirst(UPLOAD_PDF_REQUEST, uploadPDF);
}

export default combine([uploadImageSaga, uploadPDFSaga]);
