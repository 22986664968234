import React from 'react';
import PropTypes from 'prop-types';

class DiscountDetails extends React.PureComponent {
  render() {
    const { attributes } = this.props;
    return (
      <div>
        {!!attributes.userDiscount && (
          <p>{attributes.userDiscount} kr - user discount</p>
        )}
        {!!attributes.companyDiscount && (
          <p>{attributes.companyDiscount} kr - company discount</p>
        )}
        {!!attributes.complimentDiscount && (
          <p>{attributes.complimentDiscount} kr - compliment</p>
        )}
        {!!attributes.punchCardDiscount && (
          <p>{attributes.punchCardDiscount} kr - punch card discount</p>
        )}
        {!!attributes.storeCreditsDiscount && (
          <p>{attributes.storeCreditsDiscount} kr - store credits discount</p>
        )}
        {!!attributes.posDiscount && (
          <p>{attributes.posDiscount} kr - POS discount</p>
        )}
      </div>
    );
  }
}

DiscountDetails.propTypes = {
  attributes: PropTypes.shape({
    userDiscount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    companyDiscount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    complimentDiscount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    punchCardDiscount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    storeCreditsDiscount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    posDiscount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default DiscountDetails;
