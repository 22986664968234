import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';
import { twoDigFormat } from 'utils';

const formatSelectValue = (value) =>
  Number.isInteger(value)
    ? {
        label: twoDigFormat(value),
        value,
      }
    : null;

class MinuteDropdown extends React.PureComponent {
  minutes = new Array(60 / this.props.fraction)
    .fill(0)
    .map((v, i) => i * this.props.fraction);

  render() {
    const { value, onChange } = this.props;
    const options = [
      {
        label: '-',
      },
      ...this.minutes.map(formatSelectValue),
    ];
    return (
      <Select
        options={options}
        width={70}
        placeholder="-"
        value={formatSelectValue(value)}
        onChange={onChange}
      />
    );
  }
}

MinuteDropdown.propTypes = {
  fraction: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

MinuteDropdown.defaultProps = {
  fraction: 30,
};

export default MinuteDropdown;
