import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import randomstring from 'randomstring';
import { toastr } from 'react-redux-toastr';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import SmallButton from 'components/SmallButton';
import Section from 'components/Section';
import ImageUpload from 'components/ImageUpload';
import PageHeading from 'components/PageHeading';

import { validateImageItem, validateImagesList } from '../utils';
import { reorder } from 'utils/processors';

import styles from './styles';

class FooterImagesList extends React.PureComponent {
  state = {
    items: this.props.items,
    newItem: {},
  };

  reorder = (sourceIndex, destinationIndex, cb) => {
    const { items } = this.state;
    const newItems = reorder(items, sourceIndex, destinationIndex);

    this.setState({ items: newItems }, cb && cb());
  };

  handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    this.reorder(result.source.index, result.destination.index);
  };

  onDelete = (index) => {
    const { items } = this.state;
    const newItems = items.filter((value, i) => i !== index);
    this.setState({
      items: newItems,
    });
  };

  handleChange = (fieldName, index, value) => {
    const { items } = this.state;
    const newItems = items.map(
      (item, i) =>
        i === index
          ? {
              ...item,
              [fieldName]: value,
            }
          : item
    );
    this.setState({
      items: newItems,
    });
  };

  onAddItem = () => {
    const { newItem, items } = this.state;
    const validateError = validateImageItem(newItem);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      this.setState({
        items: [...items, { ...newItem, id: randomstring.generate(7) }],
        newItem: {},
      });
    }
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { items } = this.state;
    const validateError = validateImagesList(items);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      onSubmit({
        imagesList: items.map((item, index) => ({
          ...item,
          index,
        })),
      });
    }
  };

  handleNewItemChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      newItem: {
        ...this.state.newItem,
        [fieldName]: value,
      },
    });
  };

  render() {
    const { classes } = this.props;
    const { items, newItem } = this.state;

    return (
      <React.Fragment>
        <PageHeading
          title="Footer partners list"
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        {items && items.length > 0 ? 'Drag and drop items to change order' : ''}
        {items &&
          items.length > 0 && (
            <DragDropContext onDragEnd={this.handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <ul className={classes.list} ref={provided.innerRef}>
                    {items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            className={cn(
                              classes.listItem,
                              snapshot.isDragging &&
                                classes.menuItemsListItemHover
                            )}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Card className={classes.cardItem}>
                              <IconButton
                                className={classes.deleteBtn}
                                aria-label="Delete"
                                color="primary"
                                onClick={() => this.onDelete(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <CardContent className={classes.cartContent}>
                                <div className={classes.imagePreviewBg}>
                                  <ImageUpload
                                    image={item.imageURL}
                                    onChange={(e) =>
                                      this.handleChange(
                                        'imageURL',
                                        index,
                                        e.target.value
                                      )
                                    }
                                    placeholder="Image URL"
                                    label="Image URL"
                                  />
                                </div>
                                <div>
                                  <Input
                                    value={item.imageSEOAlt}
                                    onChange={(e) =>
                                      this.handleChange(
                                        'imageSEOAlt',
                                        index,
                                        e.target.value
                                      )
                                    }
                                    placeholder="SEO alt"
                                    label="SEO alt"
                                    margin="normal"
                                    style={{ width: 360 }}
                                  />
                                </div>
                                <div>
                                  <Input
                                    value={item.imageWebsite}
                                    onChange={(e) =>
                                      this.handleChange(
                                        'imageWebsite',
                                        index,
                                        e.target.value
                                      )
                                    }
                                    placeholder="Website"
                                    label="Website"
                                    margin="normal"
                                    style={{ width: 360 }}
                                  />
                                </div>
                              </CardContent>
                            </Card>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          )}
        <Section heading="Add new item">
          <div>
            <div className={classes.imagePreviewBg}>
              <ImageUpload
                image={newItem.imageURL || ''}
                onChange={this.handleNewItemChange('imageURL')}
                placeholder="Image URL"
                label="Image URL"
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <div style={{ width: 360, marginRight: 20 }}>
                <Input
                  value={newItem.imageSEOAlt || ''}
                  type="string"
                  onChange={this.handleNewItemChange('imageSEOAlt')}
                  label="SEO alt text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  style={{ width: 360 }}
                />
              </div>
              <div style={{ width: 360, marginRight: 20 }}>
                <Input
                  value={newItem.imageWebsite || ''}
                  type="string"
                  onChange={this.handleNewItemChange('imageWebsite')}
                  label="Website"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  style={{ width: 360 }}
                />
              </div>
              <SmallButton
                onClick={this.onAddItem}
                leftSpace
                id="add-group-button"
                style={{ marginBottom: 8 }}
              >
                Add
              </SmallButton>
            </div>
          </div>
        </Section>
      </React.Fragment>
    );
  }
}

FooterImagesList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imageURL: PropTypes.string,
      imageSEOAlt: PropTypes.string,
      imageWebsite: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(FooterImagesList);
