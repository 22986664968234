import { SET_FILTERS, CLEAR_FILTER } from 'consts/redirect';
import { LOGOUT } from 'consts/auth';

import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  filters: {},
  previousLocation: null,
  currentLocation: null,
};

const redirectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.filters,
          url: action.location ? action.location.pathname : null,
        },
      };
    }

    case LOGOUT:
    case CLEAR_FILTER: {
      return initialState;
    }

    // clear filters on page refresh
    case LOCATION_CHANGE: {
      const oldLocation = state.currentLocation;
      const newLocation = action.payload.location
        ? action.payload.location.pathname
        : null;
      return {
        ...state,
        previousLocation: oldLocation,
        currentLocation: newLocation,
        filters: oldLocation === newLocation ? {} : state.filters,
      };
    }

    default:
      return state;
  }
};

export default redirectReducer;
