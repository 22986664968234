import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

class SecureRoute extends React.Component {
  render() {
    const { token, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!token) {
            return <Redirect to="/login" />;
          }
          return rest.render(props);
        }}
      />
    );
  }
}

SecureRoute.propTypes = {
  token: PropTypes.object,
};

export default SecureRoute;
