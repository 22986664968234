import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  defaultProps,
} from 'recompose';
import { connect } from 'react-redux';

import {
  getMenuItemsList,
  deleteMenuItem,
  restoreMenuItem,
} from 'actions/menu-items';
import { showLoading, hideLoading } from 'actions/ui';
import { setFilters, clearFilters } from 'actions/redirect';

import { wrapWithSideEffect } from 'utils';
import { hideIfNoData } from 'utils/enhancers';

import { ITEM_STATUSES } from 'consts';

import MenuItemsListPage from './MenuItemsListPage';

const PAGING_LIMIT = 20;

const defaultParams = {
  sortName: 'name',
  sortDirection: 'asc',
};
const ALL = `${ITEM_STATUSES.active},${ITEM_STATUSES.inactive}`;

const mapStateToProps = ({ redirect }) => ({
  filters: redirect.filters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit: (args) => dispatch(getMenuItemsList(args)),
  onDelete: (id) => dispatch(deleteMenuItem(id)),
  onRestore: (id, item) => dispatch(restoreMenuItem(id, item)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
  setFilters: (args) => dispatch(setFilters(ownProps.location, args)),
  clearFilters: () => dispatch(clearFilters()),
});

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const defaultData = compose(
  withState('initialData', 'setInitialData'),
  withState('selectedCategory', 'setSelectedCategory', null),
  withState('searchName', 'setSearchName', null),
  withState('sortName', 'setSortName', defaultParams.sortName),
  withState('sortDirection', 'setSortDirection', defaultParams.sortDirection),
  withState('status', 'setStatus', ITEM_STATUSES.active),
  withState('page', 'setPage', 0),
  defaultProps({
    pageLimit: PAGING_LIMIT,
  })
);

const refresh = (props) => {
  const {
    sortName,
    sortDirection,
    page,
    pageLimit,
    selectedCategory,
    searchName,
    status,
    onInit,
    setInitialData,
  } = props;
  return wrapWithProgress(props)(
    onInit({
      sortName,
      sortDirection,
      page,
      pageLimit,
      selectedCategory,
      searchName,
      status,
    }).then(setInitialData)
  );
};

const handlers = withHandlers({
  onInit: (props) => () => {
    const { location, filters } = props;
    if (location && filters && location.pathname !== filters.url) {
      props.clearFilters();
      return refresh(props);
    }
    const {
      sortName,
      sortDirection,
      selectedCategory,
      searchName,
      page,
      status,
    } = props.filters;
    if (sortName) props.setSortName(sortName);
    if (sortDirection) props.setSortDirection(sortDirection);
    if (selectedCategory) props.setSelectedCategory(selectedCategory);
    if (searchName) props.setSearchName(searchName);
    if (page) props.setPage(page);
    props.setStatus(status);
    return refresh({
      ...props,
      sortName: sortName || defaultParams.sortName,
      sortDirection: sortDirection || defaultParams.sortDirection,
      selectedCategory,
      searchName,
      page: page || 0,
      status: status ? status : ALL,
    });
  },
  onSort: (props) => ({ sortName, sortDirection }) => {
    props.setPage(0);
    props.setSortName(sortName);
    props.setSortDirection(sortDirection);
    props.setFilters({
      sortName,
      sortDirection,
      searchName: props.searchName,
      selectedCategory: props.selectedCategory,
      page: 0,
      status: props.status,
    });
    return refresh({ ...props, sortName, sortDirection, page: 0 });
  },
  onPage: (props) => ({ page }) => {
    props.setPage(page);
    props.setFilters({
      sortName: props.sortName,
      sortDirection: props.sortDirection,
      searchName: props.searchName,
      selectedCategory: props.selectedCategory,
      page,
      status: props.status,
    });
    return refresh({ ...props, page });
  },
  onSearch: (props) => ({ selectedCategory, searchName, status }) => {
    props.setPage(0);
    props.setSelectedCategory(selectedCategory);
    props.setSearchName(searchName);
    props.setStatus(status);
    props.setFilters({
      sortName: props.sortName,
      sortDirection: props.sortDirection,
      searchName,
      selectedCategory,
      page: props.page,
      status,
    });
    return refresh({ ...props, selectedCategory, searchName, page: 0, status });
  },
  onDelete: (props) => (event) =>
    props.onDelete(event).then(() => refresh(props)),
  onRestore: (props) => (id, item) =>
    props.onRestore(id, item).then(() => refresh(props)),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  defaultData,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(MenuItemsListPage);
