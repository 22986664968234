import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import { toastr } from 'react-redux-toastr';

import {
  DEFAULT_MINIMUM_DELIVERY_TIME,
  DEFAULT_DELIVERY_TAX,
} from 'consts/settings';

import CurrencyInput from 'components/CurrencyInput';
import PageHeading from 'components/PageHeading';
import Section from 'components/Section';

import ZipCodes from './ZipCodes';

const styles = () => ({
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

const stateFromProps = ({ initialData = {} }) => ({
  zoneName: initialData.zoneName || '',
  shippingPrice: initialData.shippingPrice || 0,
  tax: parseInt(
    (initialData.tax || initialData.tax === 0
      ? initialData.tax
      : DEFAULT_DELIVERY_TAX) * 100
  ),
  zipCodes: initialData.zipCodes || '',
  minimumTime: initialData.minimumTime || DEFAULT_MINIMUM_DELIVERY_TIME,
});

class DeliveryZoneDetailsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleSubmit = () => {
    const { onSubmitDeliveryCost, id, history } = this.props;
    const { ...data } = this.state;
    const validateError = this.validateError(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      const processCodes = data.zipCodes
        .split(',')
        .map((code) => code.replace(/\s/gim, '').replace(/X/gim, ''))
        .join(',');
      onSubmitDeliveryCost({
        ...data,
        id,
        zipCodes: processCodes,
        tax: data.tax / 100,
      }).then((data) => {
        if (!id && data.id) {
          history.push(`/settings/delivery/${data.id}`);
        }
      });
    }
  };

  handleAddZipCode = (newZipCode, cb) => {
    const { zipCodes } = this.state;
    let newZipCodes = [];
    if (zipCodes.length) {
      newZipCodes = zipCodes.split(',');
      newZipCodes.push(newZipCode);
    } else {
      newZipCodes.push(newZipCode);
    }
    this.setState({ zipCodes: newZipCodes.join(',') });
    cb && cb();
  };

  handleDeleteZipCode = (zipCodeToDelete) => {
    const { zipCodes } = this.state;
    const newZipCodes = zipCodes
      .split(',')
      .filter((zipCode) => zipCode !== zipCodeToDelete);
    this.setState({ zipCodes: newZipCodes.join(',') });
  };

  validateError = (data) => {
    if (!data.zoneName) {
      return 'Name is required';
    }
    if (!data.zipCodes) {
      return 'Zip Codes is required';
    }
    if (
      (parseInt(data.tax) !== 0 && !parseInt(data.tax)) ||
      data.tax < 0 ||
      data.tax > 100
    ) {
      return 'Tax is not valid';
    }
    if (
      data.minimumTime < DEFAULT_MINIMUM_DELIVERY_TIME ||
      isNaN(parseInt(data.minimumTime, 10))
    ) {
      return `Minimum time should be valid number and be more than ${DEFAULT_MINIMUM_DELIVERY_TIME} minutes`;
    }
  };

  render() {
    const { classes } = this.props;
    const { zoneName, shippingPrice, tax, zipCodes, minimumTime } = this.state;
    return (
      <React.Fragment>
        <PageHeading
          title="Delivery zone"
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        <div className={classes.formWrapper}>
          <div className={classes.formBlock}>
            <div>
              <Input
                value={zoneName}
                onChange={this.handleChange('zoneName')}
                placeholder="Name"
                label="Name"
                margin="normal"
                style={{ width: 300 }}
              />
            </div>
            <div>
              <CurrencyInput
                value={shippingPrice}
                onChange={this.handleChange('shippingPrice')}
                placeholder="Price"
                label="Price (including tax)"
                margin="normal"
                style={{ width: 160 }}
              />
              <Input
                value={tax}
                onChange={this.handleChange('tax')}
                type="number"
                placeholder="SKU"
                label="Tax, %"
                margin="normal"
                inputProps={{
                  style: { textAlign: 'right' },
                }}
                style={{ marginLeft: 20, width: 120 }}
              />
            </div>
            <div>
              <Input
                type="number"
                value={minimumTime}
                onChange={this.handleChange('minimumTime')}
                placeholder="Minimum time"
                label="Minimum time (minutes)"
                margin="normal"
                style={{ width: 300 }}
              />
            </div>
          </div>
        </div>
        <Section heading={`Zip codes in ${zoneName}`}>
          <ZipCodes
            zipCodes={zipCodes.length ? zipCodes.split(',') : []}
            handleAddZipCode={this.handleAddZipCode}
            handleDeleteZipCode={this.handleDeleteZipCode}
          />
        </Section>
      </React.Fragment>
    );
  }
}

DeliveryZoneDetailsPage.propTypes = {
  classes: PropTypes.object,
  id: PropTypes.number,
  onSubmitDeliveryCost: PropTypes.func.isRequired,
};

export default withStyles(styles)(DeliveryZoneDetailsPage);
