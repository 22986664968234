import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

class TableSortCell extends React.PureComponent {
  render() {
    const {
      title,
      sortName,
      currentSortName,
      currentSortDirection,
      handleSort,
      ...restProps
    } = this.props;
    const active = currentSortName === sortName;
    return (
      <TableCell {...restProps}>
        <TableSortLabel
          active={active}
          direction={currentSortDirection}
          onClick={() =>
            handleSort(
              sortName,
              active && currentSortDirection === 'asc' ? 'desc' : 'asc'
            )
          }
        >
          {title}
        </TableSortLabel>
      </TableCell>
    );
  }
}

TableSortCell.propTypes = {
  title: PropTypes.string.isRequired,
  sortName: PropTypes.string.isRequired,
  currentSortName: PropTypes.string.isRequired,
  currentSortDirection: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired,
};

export default TableSortCell;
