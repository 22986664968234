import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Input from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TableHead from '@material-ui/core/TableHead';
import { InlineDatePicker } from 'material-ui-pickers';
import { getStartOfDay, isDateBefore } from 'utils/date';

import TimeControl from 'components/TimeControl';
import YearDropdown from 'components/YearDropdown';
import TableActionCell from 'components/TableActionCell';
import SmallButton from 'components/SmallButton';

import { DATE_MONTH_FORMAT } from 'consts';

const formDate = (dayData) => {
  const { year, month, day } = dayData;
  const selectedYear = year || new Date().getFullYear();
  if (Number.isInteger(month) && Number.isInteger(day)) {
    return new Date(selectedYear, month, day);
  } else {
    return null;
  }
};

const datepickerRestrictions = (dayData) => {
  const year = dayData.year || new Date().getFullYear();
  return {
    minDate: new Date(year, 0, 1),
    maxDate: new Date(year, 11, 31),
  };
};

const isPastDate = (dayData) => {
  const { year, month, day } = dayData;
  if (
    Number.isInteger(year) &&
    Number.isInteger(month) &&
    Number.isInteger(day)
  ) {
    const date = getStartOfDay({ date: new Date(year, month, day) });
    const now = getStartOfDay();
    return isDateBefore(date, now);
  }
  return false;
};

class SpecialOpeningHours extends React.PureComponent {
  state = {
    showPast: false,
  };

  triggerShowPast = () => {
    this.setState({
      showPast: !this.state.showPast,
    });
  };

  onFieldChange = (index, changedData) => {
    const { daysData } = this.props;
    const selectedDay = daysData[index];
    const newSelectedDay = {
      ...selectedDay,
      ...changedData,
    };
    const newValue = Object.assign([...daysData], { [index]: newSelectedDay });
    this.props.onChange(newValue);
  };

  onNameChange = (index, value) => {
    this.onFieldChange(index, { name: value });
  };
  onYearChange = (index, value) => {
    this.onFieldChange(index, { year: value });
  };
  onDateChange = (index, value) => {
    const month = value.getMonth();
    const day = value.getDate();
    this.onFieldChange(index, { month, day });
  };
  onOpenTimeChange = (index, time) => {
    this.onFieldChange(index, { opens: time });
  };
  onCloseTimeChange = (index, time) => {
    this.onFieldChange(index, { closes: time });
  };

  onAddNew = () => {
    const { daysData } = this.props;
    const newItem = {};
    const newValue = [...daysData, newItem];
    this.props.onChange(newValue);
  };
  onDelete = (index) => {
    const { daysData } = this.props;
    const otherDays = daysData.filter((dd, i) => i !== index);
    this.props.onChange(otherDays);
  };

  render() {
    const { daysData } = this.props;
    const { showPast } = this.state;
    return (
      <React.Fragment>
        <FormControlLabel
          control={
            <Switch
              checked={showPast}
              onChange={this.triggerShowPast}
              color="primary"
            />
          }
          label="Show past"
        />
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Year/Every year</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Opens</TableCell>
                <TableCell>Closes</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {daysData.map((item, index) => {
                if (!showPast && isPastDate(item)) {
                  return null;
                }
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Input
                        value={item.name || ''}
                        onChange={(e) =>
                          this.onNameChange(index, e.target.value)
                        }
                        placeholder="Name"
                      />
                    </TableCell>
                    <TableCell>
                      <YearDropdown
                        value={item.year}
                        onChange={({ value }) =>
                          this.onYearChange(index, value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <InlineDatePicker
                        onlyCalendar
                        value={formDate(item)}
                        emptyLabel="-"
                        format={DATE_MONTH_FORMAT}
                        onChange={(value) => this.onDateChange(index, value)}
                        {...datepickerRestrictions(item)}
                        style={{ width: 120 }}
                      />
                    </TableCell>
                    <TableCell>
                      <TimeControl
                        value={item.opens}
                        onChange={(time) => this.onOpenTimeChange(index, time)}
                      />
                    </TableCell>
                    <TableCell>
                      <TimeControl
                        value={item.closes}
                        onChange={(time) => this.onCloseTimeChange(index, time)}
                      />
                    </TableCell>
                    <TableActionCell
                      action={() => this.onDelete(index)}
                      icon="delete"
                    />
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell component="th" scope="row">
                  <SmallButton
                    onClick={this.onAddNew}
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    + Add new
                  </SmallButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  }
}

const timeShape = PropTypes.shape({
  hour: PropTypes.number,
  minute: PropTypes.number,
});

SpecialOpeningHours.propTypes = {
  daysData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      year: PropTypes.number,
      month: PropTypes.number,
      day: PropTypes.number,
      opens: timeShape,
      closes: timeShape,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

SpecialOpeningHours.defaultProps = {
  daysData: [],
};

export default SpecialOpeningHours;
