import React from 'react';

import FooterSettings from 'components/FooterSettings';

class FooterDynamicPartnersList extends React.PureComponent {
  render() {
    return <FooterSettings settingsType="partners" />;
  }
}

export default FooterDynamicPartnersList;
