import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SecureRoute from './SecureRoute';

import routeConfig from './routeConfig';

class Routes extends React.Component {
  renderRoute = ({ routes, secure, ...cfg }) => {
    const RouteComponent = secure ? SecureRoute : Route;
    if (routes) {
      const Component = cfg.component;
      return (
        <RouteComponent
          key={cfg.path}
          {...cfg}
          component={null}
          render={() => <Component>{routes.map(this.renderRoute)}</Component>}
        />
      );
    } else {
      return <RouteComponent key={cfg.path} {...cfg} />;
    }
  };

  render() {
    return <Switch>{routeConfig.map(this.renderRoute)}</Switch>;
  }
}

export default Routes;
