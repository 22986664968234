import { put } from 'redux-saga/effects';

import {
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  SAVE_COMPANY_REQUEST,
  SAVE_COMPANY_SUCCESS,
  SEARCH_STRICT_ORGANIZATION_NUMBER_REQUEST,
  SEARCH_STRICT_ORGANIZATION_NUMBER_SUCCESS,
} from 'consts/companies';
import { PENDING_MEMBER_STATUS } from 'consts/userRegistry';

import { calculatePage, calculateSort, prepareURLRequest } from 'utils';

import {
  getCompanyDiscount,
  getCompanyCompliments,
  saveCompanyDiscount,
  saveCompanyCompliment,
  deleteCompanyDiscount,
} from 'sagas/loyalties';
import { getCompanyUsers } from 'sagas/userRegistry';
import { takeFirst, combine } from 'sagas/utils/effects';
import apiRequest from 'sagas/utils/apiRequest';
import { showErrorOnErrorResponse } from 'sagas/utils/errorHandler';
import { paginateRequest } from 'sagas/utils';

export function* getCompanies({
  sortName,
  sortDirection,
  ids,
  search,
  searchParam,
  page,
  pageLimit,
  getAllItems = false,
}) {
  const params = [];
  if (ids && ids.length) params.push(`id=${ids.join(',')}`);
  if (search && searchParam) params.push(`&${searchParam}=(like%7C${search})`);
  params.push(calculateSort(sortDirection, sortName));

  const base = '/companies';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    let rawPayload;
    if (getAllItems) {
      rawPayload = yield paginateRequest({ url: base, params, options });
    } else {
      rawPayload = yield apiRequest(
        prepareURLRequest(
          base,
          pageLimit
            ? params.concat([`${calculatePage(page, pageLimit)}`])
            : params
        ),
        options
      );
    }
    const companyIds = rawPayload.items.map((c) => c.id);
    const users = yield getCompanyUsers({ companyIds });

    const payload = {
      ...rawPayload,
      items: rawPayload.items.map((company) => {
        const companyUsers = users.filter(
          (user) =>
            user.userRegistry.companyId === company.id &&
            user.userRegistry.status === PENDING_MEMBER_STATUS
        );
        return {
          ...company,
          pendingUsers: companyUsers.length,
        };
      }),
    };

    const newAction = {
      type: GET_COMPANIES_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getCompaniesSaga() {
  yield takeFirst(GET_COMPANIES_REQUEST, getCompanies);
}

function* getCompanyById({ id }) {
  const url = `/companies/${id}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const { discount, discountDate, discountId } = yield getCompanyDiscount(id);
    const { compliment, complimentId } = yield getCompanyCompliments(id);
    const newAction = {
      type: GET_COMPANY_SUCCESS,
      payload: {
        ...payload,
        discount,
        discountDate,
        compliment,
        discountId,
        complimentId,
      },
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* getCompanyByIdSaga() {
  yield takeFirst(GET_COMPANY_REQUEST, getCompanyById);
}

function* saveCompany({
  id,
  name,
  organisationNumber,
  contactPerson,
  invoicePhysicalAddress,
  invoiceDigitalAddress,
  discount,
  discountId,
  discountDate,
  compliment,
  complimentId,
}) {
  const url = id ? `/companies/${id}` : '/companies';
  const options = {
    method: id ? 'PUT' : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      organisationNumber,
      contactPerson,
      invoicePhysicalAddress,
      invoiceDigitalAddress,
    }),
  };
  try {
    const payload = yield apiRequest(url, options);
    const discountNumber = parseInt(discount) || 0;
    let newDiscount = 0;
    let newDiscountId;
    if (discountNumber >= 0) {
      // Save or update discount
      let response = yield saveCompanyDiscount({
        companyId: payload.id,
        discountId,
        discountDate,
        discount: parseFloat((discountNumber / 100).toFixed(2)),
        name: `${payload.name.toUpperCase()}-DISCOUNT`,
      });
      newDiscount = response.newDiscount;
      newDiscountId = response.newDiscountId;
    } else if (discountId) {
      yield deleteCompanyDiscount({ companyId: payload.id, discountId });
      // delete discount in other case
    }
    const complimentNumber = parseInt(compliment) || 0;
    const complimentPayload = yield saveCompanyCompliment({
      companyId: payload.id,
      compliment: parseFloat((complimentNumber / 100).toFixed(2)),
      complimentId,
    });
    const newAction = {
      type: SAVE_COMPANY_SUCCESS,
      payload: {
        ...payload,
        discount: newDiscount,
        discountId: newDiscountId,
        compliment: complimentPayload.compliment,
        complimentId: complimentPayload.complimentId,
      },
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* saveCompanySaga() {
  yield takeFirst(SAVE_COMPANY_REQUEST, saveCompany);
}

function* searchByOrganNumber({ organisationNumber }) {
  const searchParam = `organisationNumber=${organisationNumber}`;
  const url = `/companies?${searchParam}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(url, options);
    const newAction = {
      type: SEARCH_STRICT_ORGANIZATION_NUMBER_SUCCESS,
      payload,
    };
    yield put(newAction);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

function* searchByOrganNumberSaga() {
  yield takeFirst(
    SEARCH_STRICT_ORGANIZATION_NUMBER_REQUEST,
    searchByOrganNumber
  );
}

export function* getCompaniesRequest(ids) {
  const params = [];
  if (ids && ids.length) params.push(`id=${ids.join(',')}`);

  const base = '/companies';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const payload = yield apiRequest(prepareURLRequest(base, params), options);
    return payload;
  } catch (error) {
    showErrorOnErrorResponse(error);
  }
}

export default combine([
  getCompaniesSaga,
  getCompanyByIdSaga,
  saveCompanySaga,
  searchByOrganNumberSaga,
]);
