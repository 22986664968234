import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { normalizePhoneNumber } from 'utils/processors';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class LoginPage extends React.PureComponent {
  state = {
    phone: '',
    smsCode: '',
    step: 'smsCode', //['smsCode', 'submit']
  };

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleSubmit = () => {
    const { step, phone, smsCode } = this.state;
    const { onSubmit, onGetSmsCode } = this.props;
    const normalizedPhone = normalizePhoneNumber(phone);
    if (step === 'smsCode') {
      return onGetSmsCode({ phone: normalizedPhone })
        .then((data) => {
          this.setState({ step: 'submit' });
          toastr.success('Success!', 'You should receive SMS code');
          return data;
        })
        .catch((error) => {
          const msg =
            error && error.message ? error.message : 'Something went wrong';
          toastr.error('Error!', msg);
        });
    } else {
      return onSubmit({ phone: normalizedPhone, smsCode }).catch((error) => {
        const msg =
          error && error.message ? error.message : 'Something went wrong';
        toastr.error('Error!', msg);
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { phone, smsCode, step } = this.state;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <div style={{ textAlign: 'center', marginTop: 40 }}>
          <img src="/img/logo_text.svg" alt="logo" />
          <div style={{ marginTop: 10 }}>
            <img src="/img/logo.svg" alt="logo" />
          </div>
        </div>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
            {step === 'smsCode' ? (
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="phone">Phone number</InputLabel>
                <Input
                  id="phone"
                  name="phone"
                  autoComplete="phone"
                  value={phone || ''}
                  onChange={this.handleChange('phone')}
                  autoFocus
                />
              </FormControl>
            ) : (
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="phone">SMS code</InputLabel>
                <Input
                  id="smsCode"
                  name="smsCode"
                  value={smsCode || ''}
                  onChange={this.handleChange('smsCode')}
                  autoFocus
                />
              </FormControl>
            )}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSubmit}
            >
              {step === 'smsCode' ? 'Submit' : 'Sign in'}
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onGetSmsCode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(LoginPage);
