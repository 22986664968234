import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { version } from '../../../package.json';
import { DEFAULT_TIMEZONE } from 'consts';

import Links from './Links';

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: 'flex',
    marginTop: '-20px',
    position: 'relative',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.overrides.MuiDrawer.paper.background,
  },
  title: {
    ...theme.overrides.MuiDrawer.title,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  contentContainer: {
    padding: 40,
    maxWidth: 1300,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

class BaseLayout extends React.PureComponent {
  render() {
    const { classes, onLogout, onClearFilters } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <div
            style={{
              display: 'flex',
              padding: 20,
              alignItems: 'center',
              height: 120,
              boxSizing: 'border-box',
            }}
          >
            <img src="/img/logo.svg" alt="logo" />
            <Typography
              style={{
                fontSize: 28,
                marginLeft: 20,
              }}
              component="h1"
              color="inherit"
            >
              Admin
            </Typography>
          </div>
          <Divider />
          <Links onClearFilters={onClearFilters} />
          <button
            style={{
              alignSelf: 'flex-start',
              marginTop: 40,
              marginLeft: 20,
              textDecoration: 'underline',
            }}
            onClick={onLogout}
          >
            Sign out
          </button>
          <div
            style={{
              position: 'absolute',
              bottom: 20,
              left: 20,
            }}
          >
            <p>Timezone: {DEFAULT_TIMEZONE}</p>
            <p>Version: {version}</p>
          </div>
        </Drawer>
        <div style={{ width: '100%' }}>
          <div className={classes.contentContainer}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

BaseLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onLogout: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
};

export default withStyles(styles)(BaseLayout);
