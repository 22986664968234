import React from 'react';

import OrdersTypeList from 'components/OrdersTypeList';

import { ORDER_TYPES } from 'consts/orders';

class DeliveryOrdersListPage extends React.PureComponent {
  render() {
    return <OrdersTypeList type={ORDER_TYPES.delivery} {...this.props} />;
  }
}

export default DeliveryOrdersListPage;
