import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_TAG_GROUPS_REQUEST,
  GET_TAG_GROUPS_SUCCESS,
  GET_TAG_GROUPS_FAILURE,
  GET_TAG_GROUP_BY_ID_REQUEST,
  GET_TAG_GROUP_BY_ID_SUCCESS,
  GET_TAG_GROUP_BY_ID_FAILURE,
  GET_TAGS_BY_GROUPID_REQUEST,
  GET_TAGS_BY_GROUPID_SUCCESS,
  GET_TAGS_BY_GROUPID_FAILURE,
  SAVE_TAG_REQUEST,
  SAVE_TAG_SUCCESS,
  SAVE_TAG_FAILURE,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
} from 'consts/tags';

export function getTagGroups({ sortName, sortDirection }) {
  return {
    type: GET_TAG_GROUPS_REQUEST,
    sortName,
    sortDirection,
    [WAIT_FOR_ACTION]: GET_TAG_GROUPS_SUCCESS,
    [ERROR_ACTION]: GET_TAG_GROUPS_FAILURE,
  };
}

export function getTagGroupById(id) {
  return {
    type: GET_TAG_GROUP_BY_ID_REQUEST,
    id,
    [WAIT_FOR_ACTION]: GET_TAG_GROUP_BY_ID_SUCCESS,
    [ERROR_ACTION]: GET_TAG_GROUP_BY_ID_FAILURE,
  };
}

export function getTagsByGroupId({ groupId, sortName, sortDirection }) {
  return {
    type: GET_TAGS_BY_GROUPID_REQUEST,
    groupId,
    sortName,
    sortDirection,
    [WAIT_FOR_ACTION]: GET_TAGS_BY_GROUPID_SUCCESS,
    [ERROR_ACTION]: GET_TAGS_BY_GROUPID_FAILURE,
  };
}

export function getTags({ sortName, sortDirection }) {
  return {
    type: GET_TAGS_REQUEST,
    sortName,
    sortDirection,
    [WAIT_FOR_ACTION]: GET_TAGS_SUCCESS,
    [ERROR_ACTION]: GET_TAGS_FAILURE,
  };
}

export function saveTag({ groupId, name, status }) {
  return {
    type: SAVE_TAG_REQUEST,
    groupId,
    name,
    status,
    [WAIT_FOR_ACTION]: SAVE_TAG_SUCCESS,
    [ERROR_ACTION]: SAVE_TAG_FAILURE,
  };
}

export function deleteTag(id) {
  return {
    type: DELETE_TAG_REQUEST,
    id,
    [WAIT_FOR_ACTION]: DELETE_TAG_SUCCESS,
    [ERROR_ACTION]: DELETE_TAG_FAILURE,
  };
}
