import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';

class CategoriesDropdown extends React.PureComponent {
  render() {
    const { items } = this.props.initialData;
    const { value, disabled, onChange } = this.props;
    const selectItems = items.map((item) => ({
      label: item.name,
      value: item.id,
    }));
    return (
      <Select
        options={selectItems}
        value={selectItems.find((i) => i.value === value)}
        onChange={onChange}
        placeholder="Select Category"
        label="Category"
        disabled={disabled}
      />
    );
  }
}

CategoriesDropdown.propTypes = {
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CategoriesDropdown;
