import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';

class RestaurantsDropdown extends React.PureComponent {
  render() {
    const { items } = this.props.initialData;
    const { value, defaultOption, onChange } = this.props;
    const selectItems = [
      defaultOption,
      ...items.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    ];
    return (
      <Select
        options={selectItems}
        value={selectItems.find((i) => i.value === value)}
        onChange={onChange}
        placeholder={defaultOption.label}
        width={300}
        label="Restaurant"
      />
    );
  }
}

RestaurantsDropdown.propTypes = {
  initialData: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  defaultOption: PropTypes.object.isRequired,
};

export default RestaurantsDropdown;
