import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import { hideIfNoData } from 'utils/enhancers';
import { wrapWithSideEffect } from 'utils';

import { showLoading, hideLoading } from 'actions/ui';
import { getOrderDetails } from 'actions/orders';

import OrderDetails from './OrderDetails';

const mapDispatchToProps = {
  onInit: getOrderDetails,
  showLoading,
  hideLoading,
};

const state = compose(withState('initialData', 'setInitialData'));

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const refresh = (props) => {
  const { orderId, onInit, setInitialData } = props;
  return wrapWithProgress(props)(onInit(orderId).then(setInitialData));
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(OrderDetails);
