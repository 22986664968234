import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { searchCustomers } from 'actions/customers';

import UsersAutosuggest from './UsersAutosuggest';

const mapDispatchToProps = {
  onInit: searchCustomers,
};

const handlers = withHandlers({
  onSearch: (props) => ({ email, phone, lastName, firstName, ids }) => {
    return props.onInit({
      status: 'active',
      email,
      phone,
      lastName,
      firstName,
      ids,
      nonAnonymous: true,
    });
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  handlers
)(UsersAutosuggest);
