import React from 'react';
import { Link } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';

class NewSectionButton extends React.PureComponent {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <React.Fragment>
        <Button
          aria-controls="content-sections-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          size="large"
          onClick={this.handleClick}
        >
          + New section
        </Button>
        <Menu
          id="sections-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>
            <Link to="/article-section-details">Article section</Link>
          </MenuItem>
          <MenuItem onClick={this.handleClose}>
            <Link to="/cart-section-details">Cart section</Link>
          </MenuItem>
          <MenuItem onClick={this.handleClose}>
            <Link to="/list-section-details">List section</Link>
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default NewSectionButton;
