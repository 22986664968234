import 'whatwg-fetch';

function checkContentType(response, type) {
  const contentType = response.headers.get('content-type');
  return contentType && contentType.startsWith(type);
}

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  if (checkContentType(response, 'application/json')) {
    return response.json().catch(() => {
      return {};
    });
  }
  if (checkContentType(response, 'text/plain')) {
    return response.text();
  }
  return null;
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (checkContentType(response, 'application/json')) {
    return response.json().then((errorData) => {
      const error = { status_code: response.status, ...errorData };
      throw error;
    });
  }
  if (checkContentType(response, 'text')) {
    return response.text().then((errorText) => {
      const error = { status_code: response.status, message: errorText };
      throw error;
    });
  }
  const error = {
    status_code: response.status,
    message: 'Unknown server error',
  };
  throw error;
}

const request = (url, params) => {
  return fetch(url, params)
    .then(checkStatus)
    .then(parseJSON);
};

export default request;
