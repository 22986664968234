import { tablet, laptop } from 'shared/styles/breakpoints';
import { generalStyles } from '../generalStyles';

const styles = {
  paragraph: {
    extend: generalStyles.paragraph,
  },
  title: {
    marginBottom: 13,
    fontSize: 24,
    lineHeight: '1.29',
  },
  textCol: {},
  imgCol: {
    marginBottom: 25,
    textAlign: (props) =>
      props.item && props.item.imagePosition === 'right' ? 'right' : 'left',
    '& > img': {
      margin: [0, 'auto'],
    },
  },
  withBackground: {
    backgroundColor: (props) => props.item.backgroundColor,
    '& $textCol': {
      padding: [24, 16],
    },
    '& $imgCol': {
      marginBottom: 0,
    },
    '& img': {
      width: '100%',
    },
  },
  simpleSection: {
    marginBottom: 0,
  },
  flexWrapper: {},
  flexReverse: {},
  col: {},
  [`@media (min-width: ${tablet})`]: {
    flexWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    flexReverse: {
      flexDirection: 'row-reverse',
    },
    col: {
      width: '45%',
    },
    title: {
      marginBottom: 20,
      fontSize: 33,
      lineHeight: '1.19',
      letterSpacing: '-0.3px',
    },
    imgCol: {
      marginBottom: 0,
    },
    textCol: {
      boxSizing: 'border-box',
    },
    withBackground: {
      '& $flexWrapper': {
        alignItems: 'stretch',
      },
      '& $imgCol': {
        width: '48%',
      },
      '& $textCol': {
        width: '52%',
        padding: [64, 64, 32],
      },
      '& img': {
        height: '100%',
        objectFit: 'cover',
        display: 'block',
      },
    },
    simpleSection: {
      marginBottom: 0,
      '& $imgCol': {
        width: '100%',
      },
      '& $textCol': {
        width: '100%',
        padding: 32,
      },
    },
  },
  [`@media (min-width: ${laptop})`]: {
    title: {
      marginBottom: 32,
      fontSize: 37,
      lineHeight: '1.08',
      letterSpacing: '-0.3px',
    },
    col: {
      width: '40%',
    },
    simpleSection: {
      marginBottom: 0,
    },
  },
};

export default styles;
