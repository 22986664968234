import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import {
  getUserStoreCreditsPoints,
  saveUserStoreCreditsPoints,
} from 'actions/loyalties';
import { showLoading, hideLoading } from 'actions/ui';

import { hideIfNoData } from 'utils/enhancers';
import { wrapWithSideEffect } from 'utils';

import PointsStoreCredits from './PointsStoreCredits';

const mapDispatchToProps = {
  onInit: getUserStoreCreditsPoints,
  onSave: saveUserStoreCreditsPoints,
  showLoading,
  hideLoading,
};

const state = compose(withState('initialData', 'setInitialData'));

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const refresh = (props) => {
  const { userId, onInit, setInitialData } = props;
  return onInit({ userId }).then(setInitialData);
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSave: (props) => (event) => {
    const { onSave } = props;
    return wrapWithProgress(props)(onSave(event).then(() => refresh(props)));
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    this.props.onInit();
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => !props.initialData)
)(PointsStoreCredits);
