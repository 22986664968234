import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Input from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toastr } from 'react-redux-toastr';

import { APP_SETTINGS_DATA_NAME } from 'consts/settings';
import { DEFAULT_TAX } from 'consts';

import Section from 'components/Section';
import PageHeading from 'components/PageHeading';
import ImageUpload from 'components/ImageUpload';

import Delivery from './Delivery';

import styles from './styles';

const stateFromProps = ({ initialData = {} }) => {
  const settings =
    initialData && initialData.settings ? initialData.settings : {};
  const restaurantsDeliveryTimeoutAttr =
    initialData && initialData.restaurantsDeliveryTimeout
      ? initialData.restaurantsDeliveryTimeout
      : {};
  return {
    siteTitle: settings.siteTitle || '',
    metaInformation: settings.metaInformation || '',
    orderBackgroundImageURL: settings.orderBackgroundImageURL || '',
    defaultTax: settings.defaultTax || DEFAULT_TAX * 100,
    minTime:
      restaurantsDeliveryTimeoutAttr && restaurantsDeliveryTimeoutAttr.value
        ? restaurantsDeliveryTimeoutAttr.value
        : 0,
  };
};

class SettingsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleSubmit = () => {
    const { onSubmit, initialData } = this.props;

    const {
      siteTitle,
      metaInformation,
      orderBackgroundImageURL,
      defaultTax,
      minTime,
    } = this.state;
    const validateError = this.validateError({ ...this.state });
    const restaurantsDeliveryTimeoutAttr =
      initialData && initialData.restaurantsDeliveryTimeout
        ? initialData.restaurantsDeliveryTimeout
        : {};
    const dataToSend = {
      isNew: !Object.keys(initialData).length,
      name: APP_SETTINGS_DATA_NAME,
      value: JSON.stringify({
        siteTitle,
        metaInformation,
        orderBackgroundImageURL,
        defaultTax,
      }),
      minTime,
      minTimeAttrId: restaurantsDeliveryTimeoutAttr.id,
    };
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      onSubmit(dataToSend)
        .then(() => {
          toastr.success('Success!', 'Settings has been saved');
        })
        .catch(() => {
          toastr.error('Error!', 'Something went wrong');
        });
    }
  };

  validateError = (data) => {
    if (data.defaultTax < 0) {
      return 'Default tax should be more than 0';
    }
    if (
      isNaN(parseInt(data.minTime)) ||
      parseInt(data.minTime) <= 0 ||
      parseInt(data.minTime) > 15
    ) {
      return 'Minimum time should be a number between 1 and 15';
    }
  };

  render() {
    const {
      siteTitle,
      metaInformation,
      orderBackgroundImageURL,
      defaultTax,
      minTime,
    } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <PageHeading
          title="Settings"
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        <div>
          <div className={classes.sectionTitle}>Web</div>
          <div>
            <Input
              value={siteTitle}
              onChange={this.handleChange('siteTitle')}
              placeholder="Site title"
              label="Site title"
              margin="normal"
              style={{ width: 360 }}
            />
          </div>
          <div>
            <Input
              value={metaInformation}
              onChange={this.handleChange('metaInformation')}
              placeholder="Meta Information"
              label="Meta Information"
              margin="normal"
              style={{ width: 360 }}
            />
          </div>
          <div className={classes.sectionTitle}>App</div>
          <div>
            <ImageUpload
              image={orderBackgroundImageURL}
              onChange={this.handleChange('orderBackgroundImageURL')}
              placeholder="Background image URL"
              label="Order tab - background image URL"
            />
          </div>
          <div className={classes.sectionTitle}>Taxes</div>
          <div>
            <Input
              value={defaultTax}
              type="number"
              onChange={this.handleChange('defaultTax')}
              placeholder="Default tax %"
              label="Default tax %"
              margin="normal"
              style={{ width: 360 }}
            />
          </div>
          <div className={classes.sectionTitle}>
            Pickup default minimim time
          </div>
          <div>
            <Input
              value={minTime}
              type="number"
              onChange={this.handleChange('minTime')}
              placeholder="Minimum time"
              label="Minimum time (minutes)"
              margin="normal"
              style={{ width: 360 }}
            />
          </div>
          <Section heading="Delivery">
            <Delivery />
          </Section>
        </div>
      </React.Fragment>
    );
  }
}

SettingsPage.propTypes = {
  classes: PropTypes.object,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default injectSheet(styles)(SettingsPage);
