import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Input from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import ActionHeading from 'components/ActionHeading';
import TableActionCell from 'components/TableActionCell';
import SmallButton from 'components/SmallButton';

import MenuItemsDropdown from './MenuItemsDropdown';

import {
  calculateFreeItems,
  processDescriptionFreeItems,
  calculateMenuItemPrice,
  calculateTotalMenuItemsPrice,
} from './utils';

const styles = () => ({
  totalSumCell: {
    textAlign: 'right',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 13,
    fontWeight: 'bold',
  },
});

class OrderItems extends React.PureComponent {
  state = {
    selectedItem: null,
  };

  onSelectItem = (item) => {
    this.setState({ selectedItem: item });
  };

  handleSubmit = () => {
    const { selectedItem } = this.state;
    if (selectedItem) {
      this.props.onMenuItemAdd(selectedItem);
      this.setState({ selectedItem: null });
    }
  };

  onCountChange = (item, value) => {
    const parsedValue = parseInt(value, 10);
    if (parsedValue < 1) return;
    this.props.onMenuItemChange(item, parsedValue);
  };

  processGroupDescription = (group, numberOfItems) => {
    /*
     * All items in group should already be selected in minicart.
     * Here selected comes for items that are detault, so we need to select all of them.
     */
    const freeItems = calculateFreeItems(
      group.values.map((val) => ({
        ...val,
        selected: true,
      })),
      group.countLimit * numberOfItems
    );
    return group.values.map((val) => {
      const description = processDescriptionFreeItems(
        group.countLimit * numberOfItems,
        freeItems,
        val
      );
      if (!val.numberOfItems) {
        return null;
      }
      return (
        <p key={`${group.groupId}-${val.id}`} style={{ color: '#838383' }}>
          {val.label} {description}
        </p>
      );
    });
  };

  descriptionText = ({ item, numberOfItems, selectedGroupItems }) => {
    if (item && selectedGroupItems && selectedGroupItems.length) {
      const selectedGroups = selectedGroupItems.filter((g) => g.values.length);
      return (
        <React.Fragment>
          {selectedGroups.map((group) =>
            this.processGroupDescription(group, numberOfItems)
          )}
        </React.Fragment>
      );
    }
    return null;
  };

  render() {
    const { classes, items, restaurantId, onMenuItemDelete } = this.props;
    const { selectedItem } = this.state;

    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Number of items</TableCell>
                <TableCell>Price</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((orderItem) => (
                <TableRow key={orderItem.item.id}>
                  <TableCell>
                    <div>
                      <p>{orderItem.item.name}</p>
                      {this.descriptionText({
                        item: orderItem.item,
                        numberOfItems: orderItem.numberOfItems,
                        selectedGroupItems: orderItem.selectedGroupItems,
                      })}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Input
                      value={orderItem.numberOfItems}
                      onChange={(e) =>
                        this.onCountChange(orderItem.item, e.target.value)
                      }
                      placeholder="Number of items"
                      type="number"
                      min="1"
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'right' },
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {calculateMenuItemPrice({ ...orderItem })} kr
                  </TableCell>
                  <TableActionCell
                    action={() => onMenuItemDelete(orderItem.id)}
                    icon="delete"
                  />
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4} className={classes.totalSumCell}>
                  Total price: {calculateTotalMenuItemsPrice(items)} kr
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <div style={{ marginTop: 40 }}>
          <ActionHeading>Add menu item</ActionHeading>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <MenuItemsDropdown
              restaurantId={restaurantId}
              value={selectedItem}
              onChange={this.onSelectItem}
              excludeIds={[...items.map(({ id }) => id)]}
            />
            <SmallButton
              onClick={this.handleSubmit}
              leftSpace
              id="add-menu-item-button"
            >
              Add
            </SmallButton>
          </div>
        </div>
      </div>
    );
  }
}

OrderItems.propTypes = {
  classes: PropTypes.object,
  restaurantId: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  onMenuItemChange: PropTypes.func.isRequired,
  onMenuItemAdd: PropTypes.func.isRequired,
  onMenuItemDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrderItems);
