import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import SmallButton from 'components/SmallButton';

import ArticleSection from '../ArticleSection';
import CardSection from '../CardSection';
import ListSection from '../ListSection';

import { LANDING_SECTION_TYPES } from 'shared/consts/settings';

class EditLinkModal extends React.PureComponent {
  render() {
    const { open, data, type } = this.props;

    return (
      <Dialog
        onClose={this.props.onClose}
        aria-labelledby="preview-modal"
        open={open}
        fullScreen
      >
        <DialogTitle id="preview-title">
          <Typography color="secondary" style={{ fontSize: 18 }}>
            Preview
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0 20px 0',
            fontFamily: 'Theinhardt,Arial,sans-serif',
          }}
        >
          <React.Fragment>
            {type === LANDING_SECTION_TYPES.article ? (
              <ArticleSection item={data} />
            ) : null}
            {type === LANDING_SECTION_TYPES.cart ? (
              <CardSection item={data} />
            ) : null}{' '}
            {type === LANDING_SECTION_TYPES.list ? (
              <ListSection items={data.items} itemsPerRow={data.itemsPerRow} />
            ) : null}
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <SmallButton
            style={{ cursor: 'pointer' }}
            onClick={this.props.onClose}
          >
            Close
          </SmallButton>
        </DialogActions>
      </Dialog>
    );
  }
}

EditLinkModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOfType(Object.values(LANDING_SECTION_TYPES)),
  data: PropTypes.oneOfType(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string,
      textAlign: PropTypes.string,
      openInNewTab: PropTypes.bool,
    }),
    PropTypes.shape({
      itemsPerRow: PropTypes.number.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          link: PropTypes.object,
          textColor: PropTypes.string,
          titleColor: PropTypes.string,
          backgroundColor: PropTypes.string,
        })
      ),
    })
  ),
};

export default EditLinkModal;
