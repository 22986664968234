import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import {
  searchByOrganNumber,
  getCompanyById,
  saveCompany,
} from 'actions/companies';
import { showLoading, hideLoading } from 'actions/ui';

import { routerPassId, hideIfNoData } from 'utils/enhancers';
import { wrapWithSideEffect } from 'utils';

import CompanyDetailsPage from './CompanyDetailsPage';

const mapDispatchToProps = {
  onInit: getCompanyById,
  onSearchOrganizationNumber: searchByOrganNumber,
  onSave: saveCompany,
  showLoading,
  hideLoading,
};

const state = compose(withState('initialData', 'setInitialData'));

const wrapWithProgress = (props) => (promise) => {
  return wrapWithSideEffect(props.showLoading)(promise).finally(
    props.hideLoading
  );
};

const refresh = (props) => {
  const { id, onInit, setInitialData } = props;
  return wrapWithProgress(props)(onInit(id).then(setInitialData));
};

const handlers = withHandlers({
  onInit: (props) => () => refresh(props),
  onSave: (props) => (event) => {
    return wrapWithProgress(props)(
      props.onSave(event).then((data) => {
        if (!props.id) {
          props.history.push(`/company-details/${data.id}`);
        }
        refresh({ ...props, id: data.id });
      })
    );
  },
  onSearchOrganizationNumber: (props) => (event) => {
    const { onSearchOrganizationNumber } = props;
    return wrapWithProgress(props)(onSearchOrganizationNumber(event));
  },
});

const retrieveData = lifecycle({
  componentDidMount() {
    const { id, onInit } = this.props;
    if (id) {
      onInit(id);
    }
  },
});

export default compose(
  routerPassId,
  connect(
    null,
    mapDispatchToProps
  ),
  state,
  handlers,
  retrieveData,
  hideIfNoData((props) => props.id && !props.initialData)
)(CompanyDetailsPage);
