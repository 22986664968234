import React from 'react';
import PropTypes from 'prop-types';

import { toastr } from 'react-redux-toastr';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import PageHeading from 'components/PageHeading';

import {
  validatePhoneNumber,
  validateZipCode,
  normalizePhoneNumber,
} from 'utils/processors';

const stateFromProps = ({ initialData = {} }) => ({
  name: initialData.name || '',
  address: initialData.location.streetAddress || '',
  postcode: initialData.location.postcode || '',
  phone: initialData.phone || '',
  notice: initialData.notice || '',
});

const styles = () => ({
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formBlock: {
    flex: 1,
    '&:nth-child(1)': {
      marginRight: 30,
    },
    '&:nth-child(2)': {
      marginTop: 25,
    },
  },
});

class CustomerDeliveryDetailsPage extends React.PureComponent {
  state = stateFromProps(this.props);

  handleChange = (fieldName) => ({ target: { value } }) => {
    this.setState({
      [fieldName]: value,
    });
  };

  validateError = (data) => {
    if (!validatePhoneNumber(data.phone)) {
      return 'Valid Phone Number is required';
    }
    if (!validateZipCode(data.postcode)) {
      return 'Valid ZipCode is required';
    }
  };

  handleSubmit = () => {
    const { ...data } = this.state;
    const { onSubmit, initialData, userId } = this.props;
    const validateError = this.validateError(data);
    if (validateError) {
      toastr.error('Error!', validateError);
    } else {
      onSubmit({
        ...data,
        phone: data.phone ? normalizePhoneNumber(data.phone) : '',
        id: initialData.id,
        userId,
        locationId: initialData.locationId,
        city: initialData.location.city,
        latitude: initialData.location.latitude,
        longitude: initialData.location.longitude,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { name, address, postcode, phone, notice } = this.state;
    return (
      <div>
        <PageHeading
          title="Customer Delivery"
          right={
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          }
        />
        <div className={classes.formWrapper}>
          <div className={classes.formBlock}>
            <div>
              <Input
                value={name}
                onChange={this.handleChange('name')}
                placeholder="Name"
                label="Name"
                margin="normal"
                style={{ width: 300 }}
              />
            </div>
            <div>
              <Input
                value={address}
                onChange={this.handleChange('address')}
                placeholder="Address"
                label="Address"
                margin="normal"
                style={{ width: 300 }}
              />
            </div>
            <div>
              <Input
                value={postcode}
                onChange={this.handleChange('postcode')}
                placeholder="Postcode"
                label="Zip Code"
                margin="normal"
                style={{ width: 300 }}
              />
            </div>
            <div>
              <Input
                value={notice}
                onChange={this.handleChange('notice')}
                placeholder="Add.info/code"
                label="Add.info/code"
                margin="normal"
                style={{ width: 300 }}
              />
            </div>
            <div>
              <Input
                value={phone}
                onChange={this.handleChange('phone')}
                placeholder="Phone"
                label="Phone"
                margin="normal"
                style={{ width: 300 }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CustomerDeliveryDetailsPage.propTypes = {
  userId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  initialData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomerDeliveryDetailsPage);
