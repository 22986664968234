export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const PRE_AUTH_REQUEST = 'PRE_AUTH_REQUEST';
export const PRE_AUTH_SUCCESS = 'PRE_AUTH_SUCCESS';

export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const LOGOUT = 'LOGOUT';
