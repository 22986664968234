import { LINK_TYPES_VALUES } from 'shared/consts/settings';

export const GET_DELIVERY_COSTS_REQUEST = 'GET_DELIVERY_COSTS_REQUEST';
export const GET_DELIVERY_COSTS_SUCCESS = 'GET_DELIVERY_COSTS_SUCCESS';
export const GET_DELIVERY_COSTS_FAILURE = 'GET_DELIVERY_COSTS_FAILURE';

export const GET_DELIVERY_COSTS_BY_ID_REQUEST =
  'GET_DELIVERY_COSTS_BY_ID_REQUEST';
export const GET_DELIVERY_COSTS_BY_ID_SUCCESS =
  'GET_DELIVERY_COSTS_BY_ID_SUCCESS';
export const GET_DELIVERY_COSTS_BY_ID_FAILURE =
  'GET_DELIVERY_COSTS_BY_ID_FAILURE';

export const SAVE_DELIVERY_COSTS_REQUEST = 'SAVE_DELIVERY_COSTS_REQUEST';
export const SAVE_DELIVERY_COSTS_SUCCESS = 'SAVE_DELIVERY_COSTS_SUCCESS';
export const SAVE_DELIVERY_COSTS_FAILURE = 'SAVE_DELIVERY_COSTS_FAILURE';

export const DELETE_DELIVERY_COSTS_REQUEST = 'DELETE_DELIVERY_COSTS_REQUEST';
export const DELETE_DELIVERY_COSTS_SUCCESS = 'DELETE_DELIVERY_COSTS_SUCCESS';
export const DELETE_DELIVERY_COSTS_FAILURE = 'DELETE_DELIVERY_COSTS_FAILURE';

export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';

export const SAVE_SETTINGS_REQUEST = 'SAVE_SETTINGS_REQUEST';
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_FAILURE = 'SAVE_SETTINGS_FAILURE';

export const GET_PRODUCTION_LIST_REQUEST = 'GET_PRODUCTION_LIST_REQUEST';
export const GET_PRODUCTION_LIST_SUCCESS = 'GET_PRODUCTION_LIST_SUCCESS';
export const GET_PRODUCTION_LIST_FAILURE = 'GET_PRODUCTION_LIST_FAILURE';

export const GET_PACK_LIST_REQUEST = 'GET_PACK_LIST_REQUEST';
export const GET_PACK_LIST_SUCCESS = 'GET_PACK_LIST_SUCCESS';
export const GET_PACK_LIST_FAILURE = 'GET_PACK_LIST_FAILURE';

export const GET_GENERAL_SETTINGS_REQUEST = 'GET_GENERAL_SETTINGS_REQUEST';
export const GET_GENERAL_SETTINGS_SUCCESS = 'GET_GENERAL_SETTINGS_SUCCESS';
export const GET_GENERAL_SETTINGS_FAILURE = 'GET_GENERAL_SETTINGS_FAILURE';

export const SAVE_GENERAL_SETTINGS_REQUEST = 'SAVE_GENERAL_SETTINGS_REQUEST';
export const SAVE_GENERAL_SETTINGS_SUCCESS = 'SAVE_GENERAL_SETTINGS_SUCCESS';
export const SAVE_GENERAL_SETTINGS_FAILURE = 'SAVE_GENERAL_SETTINGS_FAILURE';

export const APP_SETTINGS_DATA_NAME = 'app_settings_data_name';
export const PRODUCTION_LIST_SORTING_NAME = 'production_list_sorting_name';
export const PACK_LIST_SORTING_NAME = 'pack_list_sorting_name';
export const FOOTER_SETTINGS_DATA_NAME = 'footer_settings_data_name';
export const LANDING_SETTINGS_DATA_NAME = 'landing_settings_data_name';
export const HEADER_SETTINGS_DATA_NAME = 'header_settings_data_name';

export const DEFAULT_MINIMUM_DELIVERY_TIME = 15;
export const DEFAULT_DELIVERY_TAX = 0.12;

export const IMAGE_POSITIONS = [
  {
    label: 'Left',
    value: 'left',
  },
  {
    label: 'Right',
    value: 'right',
  },
];

export const TEXT_ALIGN = [
  {
    label: 'Left',
    value: 'left',
  },
  {
    label: 'Right',
    value: 'right',
  },
  {
    label: 'Center',
    value: 'center',
  },
];

export const LINK_TYPES = [
  {
    label: 'Url',
    value: LINK_TYPES_VALUES.url,
  },
  {
    label: 'Panini website url',
    value: LINK_TYPES_VALUES.websiteUrl,
  },
  {
    label: 'Email',
    value: LINK_TYPES_VALUES.email,
  },
  {
    label: 'Phone',
    value: LINK_TYPES_VALUES.phone,
  },
  {
    label: 'Pdf',
    value: LINK_TYPES_VALUES.file,
  },
];

export const SOCIAL_ICON_LINK_TYPES = [
  {
    label: 'Url',
    value: LINK_TYPES_VALUES.url,
  },
  {
    label: 'Email',
    value: LINK_TYPES_VALUES.email,
  },
  {
    label: 'Phone',
    value: LINK_TYPES_VALUES.phone,
  },
];

export const IMAGE_FORMAT = [
  {
    label: 'PNG',
    value: 'png',
  },
  {
    label: 'SVG',
    value: 'svg',
  },
];

export const DEFAULT_BACKGROUND_COLOR = '#fff';
export const DEFAULT_TEXT_COLOR = '#1d1d1b';

export const H_TAGS = [
  {
    label: 'H1',
    value: 'h1',
  },
  {
    label: 'H2',
    value: 'h2',
  },
  {
    label: 'H3',
    value: 'h3',
  },
  {
    label: 'H4',
    value: 'h4',
  },
  {
    label: 'H5',
    value: 'h5',
  },
  {
    label: 'H6',
    value: 'h6',
  },
];
