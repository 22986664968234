const groupItems = (items) => {
  let result = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item && item.menuItem) {
      const prevItem = result.find((r) => r.id === item.menuItem.id) || {};
      const newItem = {
        id: item.menuItem.id,
        name: item.menuItem.name,
        quantity: (prevItem.quantity || 0) + item.quantity,
        order: item.order,
        visible: item.visible,
        categoryName: item.menuItem.categoryName,
      };
      result = [...result.filter((r) => r.id !== newItem.id), newItem];
    }
  }
  const newAtems = result.filter((item) => typeof item.order !== 'number');
  const oldItems = result
    .filter((item) => typeof item.order === 'number')
    .sort((a, b) => a.order - b.order);
  return [...oldItems, ...newAtems];
};

const groupToSections = (orders) =>
  orders.map((order) => ({
    ...order,
    items: groupItems(order.items),
  }));

export default groupToSections;
