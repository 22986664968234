export const GET_MENU_ITEMS_REQUEST = 'GET_MENU_ITEMS_REQUEST';
export const GET_MENU_ITEMS_SUCCESS = 'GET_MENU_ITEMS_SUCCESS';
export const GET_MENU_ITEMS_FAILURE = 'GET_MENU_ITEMS_FAILURE';

export const GET_MENU_ITEMS_LIST_REQUEST = 'GET_MENU_ITEMS_LIST_REQUEST';
export const GET_MENU_ITEMS_LIST_SUCCESS = 'GET_MENU_ITEMS_LIST_SUCCESS';
export const GET_MENU_ITEMS_LIST_FAILURE = 'GET_MENU_ITEMS_LIST_FAILURE';

export const GET_MENU_ITEM_BY_ID_REQUEST = 'GET_MENU_ITEM_BY_ID_REQUEST';
export const GET_MENU_ITEM_BY_ID_SUCCESS = 'GET_MENU_ITEM_BY_ID_SUCCESS';
export const GET_MENU_ITEM_BY_ID_FAILURE = 'GET_MENU_ITEM_BY_ID_FAILURE';

export const SAVE_MENU_ITEM_REQUEST = 'SAVE_MENU_ITEM_REQUEST';
export const SAVE_MENU_ITEM_SUCCESS = 'SAVE_MENU_ITEM_SUCCESS';
export const SAVE_MENU_ITEM_FAILURE = 'SAVE_MENU_ITEM_FAILURE';

export const DELETE_MENU_ITEM_REQUEST = 'DELETE_MENU_ITEM_REQUEST';
export const DELETE_MENU_ITEM_SUCCESS = 'DELETE_MENU_ITEM_SUCCESS';
export const DELETE_MENU_ITEM_FAILURE = 'DELETE_MENU_ITEM_FAILURE';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const GET_CATEGORY_MENU_ITEMS_REQUEST =
  'GET_CATEGORY_MENU_ITEMS_REQUEST';
export const GET_CATEGORY_MENU_ITEMS_SUCCESS =
  'GET_CATEGORY_MENU_ITEMS_SUCCESS';
export const GET_CATEGORY_MENU_ITEMS_FAILURE =
  'GET_CATEGORY_MENU_ITEMS_FAILURE';

export const ADD_TO_RESTAURANT_REQUEST = 'ADD_TO_RESTAURANT_REQUEST';
export const ADD_TO_RESTAURANT_SUCCESS = 'ADD_TO_RESTAURANT_SUCCESS';
export const ADD_TO_RESTAURANT_FAILURE = 'ADD_TO_RESTAURANT_FAILURE';

export const DELETE_FROM_RESTAURANT_REQUEST = 'DELETE_FROM_RESTAURANT_REQUEST';
export const DELETE_FROM_RESTAURANT_SUCCESS = 'DELETE_FROM_RESTAURANT_SUCCESS';
export const DELETE_FROM_RESTAURANT_FAILURE = 'DELETE_FROM_RESTAURANT_FAILURE';

export const GET_MENU_ATTRIBUTES_REQUEST = 'GET_MENU_ATTRIBUTES_REQUEST';
export const GET_MENU_ATTRIBUTES_SUCCESS = 'GET_MENU_ATTRIBUTES_SUCCESS';
export const GET_MENU_ATTRIBUTES_FAILURE = 'GET_MENU_ATTRIBUTES_FAILURE';

export const CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_REQUEST =
  'CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_REQUEST';
export const CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_SUCCESS =
  'CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_SUCCESS';
export const CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_FAILURE =
  'CHANGE_MENU_ITEM_ATTRIBUTE_TO_RESTAURANT_FAILURE';

export const ADD_CATEGORY_TO_RESTAURANT_REQUEST =
  'ADD_CATEGORY_TO_RESTAURANT_REQUEST';
export const ADD_CATEGORY_TO_RESTAURANT_SUCCESS =
  'ADD_CATEGORY_TO_RESTAURANT_SUCCESS';
export const ADD_CATEGORY_TO_RESTAURANT_FAILURE =
  'ADD_CATEGORY_TO_RESTAURANT_FAILURE';

export const REMOVE_CATEGORY_FROM_RESTAURANT_REQUEST =
  'REMOVE_CATEGORY_FROM_RESTAURANT_REQUEST';
export const REMOVE_CATEGORY_FROM_RESTAURANT_SUCCESS =
  'REMOVE_CATEGORY_FROM_RESTAURANT_SUCCESS';
export const REMOVE_CATEGORY_FROM_RESTAURANT_FAILURE =
  'REMOVE_CATEGORY_FROM_RESTAURANT_FAILURE';

export const RESTORE_MENU_ITEM_REQUEST = 'RESTORE_MENU_ITEM_REQUEST';
export const RESTORE_MENU_ITEM_SUCCESS = 'RESTORE_MENU_ITEM_SUCCESS';
export const RESTORE_MENU_ITEM_FAILURE = 'RESTORE_MENU_ITEM_FAILURE';

export const MENU_ITEMS_ATTRIBUTES = {
  available: 'available',
  popular: 'popular',
  recommendedOnCheckout: 'recommendedOnCheckout',
  outOfStock: 'outOfStock',
};

export const RESTAURANT_MENU_ITEMS_ATTRS_DEFAULT = {
  [MENU_ITEMS_ATTRIBUTES.available]: 'true',
  [MENU_ITEMS_ATTRIBUTES.popular]: 'false',
  [MENU_ITEMS_ATTRIBUTES.recommendedOnCheckout]: 'false',
  [MENU_ITEMS_ATTRIBUTES.outOfStock]: 'false',
};
