export function validateArticle(data) {
  if (!data.title && !data.description) {
    return 'Title or description are required';
  }
  if (data.linkLabel && !data.linkURL) {
    return 'Valid link URL is required';
  }
  if (data.linkURL && !data.linkLabel) {
    return 'Link label is required';
  }
  if (data.imageURL && !data.imageSEOAlt) {
    return 'Image SEO alt text is required';
  }
}
