import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORY_BY_ID_REQUEST,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_BY_ID_FAILURE,
  SAVE_CATEGORY_REQUEST,
  SAVE_CATEGORY_SUCCESS,
  SAVE_CATEGORY_FAILURE,
  GET_CATEGORIES_BY_PARENTID_REQUEST,
  GET_CATEGORIES_BY_PARENTID_SUCCESS,
  GET_CATEGORIES_BY_PARENTID_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  GET_SUBCATEGORY_CANDIDATS_REQUEST,
  GET_SUBCATEGORY_CANDIDATS_SUCCESS,
  GET_SUBCATEGORY_CANDIDATS_FAILURE,
  ADD_SUBCATEGORY_REQUEST,
  ADD_SUBCATEGORY_SUCCESS,
  ADD_SUBCATEGORY_FAILURE,
  REMOVE_SUBCATEGORY_REQUEST,
  REMOVE_SUBCATEGORY_SUCCESS,
  REMOVE_SUBCATEGORY_FAILURE,
} from 'consts/categories';

export function getCategories({ sortName, sortDirection }) {
  return {
    type: GET_CATEGORIES_REQUEST,
    sortName,
    sortDirection,
    [WAIT_FOR_ACTION]: GET_CATEGORIES_SUCCESS,
    [ERROR_ACTION]: GET_CATEGORIES_FAILURE,
  };
}

export function getCategoryById(id) {
  return {
    type: GET_CATEGORY_BY_ID_REQUEST,
    id,
    [WAIT_FOR_ACTION]: GET_CATEGORY_BY_ID_SUCCESS,
    [ERROR_ACTION]: GET_CATEGORY_BY_ID_FAILURE,
  };
}

export function saveCategory({
  id,
  name,
  label,
  seoTitle,
  seoDescription,
  seoNoIndex,
}) {
  return {
    type: SAVE_CATEGORY_REQUEST,
    id,
    name,
    label,
    seoTitle,
    seoDescription,
    seoNoIndex,
    [WAIT_FOR_ACTION]: SAVE_CATEGORY_SUCCESS,
    [ERROR_ACTION]: SAVE_CATEGORY_FAILURE,
  };
}

export function getCategoriesByParentId({ parentId, sortName, sortDirection }) {
  return {
    type: GET_CATEGORIES_BY_PARENTID_REQUEST,
    parentId,
    sortName,
    sortDirection,
    [WAIT_FOR_ACTION]: GET_CATEGORIES_BY_PARENTID_SUCCESS,
    [ERROR_ACTION]: GET_CATEGORIES_BY_PARENTID_FAILURE,
  };
}

export function deleteCategory(id) {
  return {
    type: DELETE_CATEGORY_REQUEST,
    id,
    [WAIT_FOR_ACTION]: DELETE_CATEGORY_SUCCESS,
    [ERROR_ACTION]: DELETE_CATEGORY_FAILURE,
  };
}

export function getSubcategoryCondidats(categoryId) {
  return {
    type: GET_SUBCATEGORY_CANDIDATS_REQUEST,
    categoryId,
    [WAIT_FOR_ACTION]: GET_SUBCATEGORY_CANDIDATS_SUCCESS,
    [ERROR_ACTION]: GET_SUBCATEGORY_CANDIDATS_FAILURE,
  };
}

export function addSubcategory({ categoryId, subcategory }) {
  return {
    type: ADD_SUBCATEGORY_REQUEST,
    categoryId,
    subcategory,
    [WAIT_FOR_ACTION]: ADD_SUBCATEGORY_SUCCESS,
    [ERROR_ACTION]: ADD_SUBCATEGORY_FAILURE,
  };
}

export function removeSubcategory(subcategory) {
  return {
    type: REMOVE_SUBCATEGORY_REQUEST,
    subcategory,
    [WAIT_FOR_ACTION]: REMOVE_SUBCATEGORY_SUCCESS,
    [ERROR_ACTION]: REMOVE_SUBCATEGORY_FAILURE,
  };
}
